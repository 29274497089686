/*
 * Orchestrator - a class that executes a number of sub-tasks.
 * Used as ancestor of AagPage.
 *
 * Its main job is to provide safely(label, fn)
 */
export default class Orchestrator {
    constructor() {

    }

    getClassName() {
        if (this && this.constructor)
            return this.constructor.name;
        return "Unknown(Orchestrator)";
    }

    error(msg) {
        if (this._tagLogMessage)
            msg = this._tagLogMessage(msg, 'ERROR');

        this.errorMessage = msg;

        console.error(msg);
    }

    log(msg) {
        if (this._tagLogMessage)
            msg = this._tagLogMessage(msg);

        console.log(msg);
    }


    // Add cass name and severity to message
    _tagLogMessage(msg, severity) {
        try {
            let name = this.getClassName();
            if (severity)
                return `${name}: ${severity} ${msg}`;

            return `${name}: ${msg}`
        } catch (err) {
            console.error("[error occurred while expanding previous error message]");
            console.error(msg);
            return msg;
        }
    }

    safely(label, fn) {
        try {
            //if (!this.quiet)
            //    this.log("execute safely: " + label);
            return fn();
        } catch(err) {
            this.error("error in " + (label || 'unknown') + ": " + err);
            window.LAST_ERROR = err;
            return null;
        }
    }
};
