import React, {Component} from 'react';

import GenericAddressWidget from "./GenericAddressWidget";
import SmartyLookup from "../../services/SmartyLookup";
import CheckoutFormLabel from "../form/CheckoutFormLabel";
import AutocompleteResults from "./AutocompleteResults";
import ValidationMessage from "../form/ValidationMessage";

export default class GenericSmartyWidget extends GenericAddressWidget {
  componentDidMount() {
    console.log("smarty widget did mount");

    this.setState({
      suggestions: [],
      smartyLookup: this.buildSmartyLookup()
    })
  }

  // subclass can override params if needed
  buildSmartyLookup() {
    let {currentAgent} = this.props;

    return new SmartyLookup({agent: currentAgent})
  }

  onChange = (evt) => {
    let {value} = evt.target;
    let oldValue = this.state.value;

    console.error(`SMARTY WIDGET - ON CHANGE - old='${oldValue}' new='${value}'`);
    window.V1 = value;
    window.V0 = oldValue;

    if (value == oldValue) {
      console.error("no change in value, returning");
      return null;
    } else {
      console.error("value did change");
    }

    this.setState({value: value}, () => {
      this.requestAutocomplete(value);
      this.afterChange();
    });
  }

  onBlur = (evt) => {
    const { suggestions } = this.state;

    if (_.isEmpty(suggestions)) {
      // update parent's activeField...
      let {callbacks} = this.props;
      callbacks.focusChanged(null);

      // and send the change to Redux
      this.sendUpdate();
    } else {
      console.log("have suggestions, not touching redux")
    }

  }


  requestAutocomplete(value) {
    const {smartyLookup} = this.state;

    if (!smartyLookup) {
      console.error("Smarty Address Service failed to initialize.")
      return;
    }

    smartyLookup.call({
      query: value,
      onSuccess: ({response, results, ...stuff}) => {
        window.SMARTY_RESPONSE = response;

        this.setState({suggestions: results, response: response})
      }
    })
  }

  renderAutocomplete() {
    const {suggestions} = this.state;
    const {name, activeField} = this.props;

    // you must still render even if activeField is another field,
    // otherwise the suggestions box disappears before it can register a click
    // console.log("render autocomplete; active=" + activeField);

    // BAD! if (_.isEmpty(suggestions)) return null;
    if (_.isEmpty(suggestions) || (activeField != name)) return null;

    return <AutocompleteResults
      suggestions={suggestions}
      onSelectAddress={this.onSelectAddress}/>
  }


  render() {
    const {optional, focused} = this.props;

    // alert(JSON.stringify(currentAgent, null, 2))
    const helper = this.getHelper();
    const error = helper.getError();

    // console.log(this.props.name + ": render with value: " + this.props.value +
    //   "; active=" +this.props.activeField + ", suggestions = " + _.size(this.state.suggestions) +
    //   "-------");

    return (<div className={helper.getClassString()}>
      <CheckoutFormLabel label={helper.getLabel()}
        optional={optional} target={helper.getId()}/>

      {this.renderInput(helper)}

      {this.renderAutocomplete()}

      {error && !focused && <ValidationMessage error={error}/>}
    </div>);
  }
}
