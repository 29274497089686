// FIXME:
// remove blueimp-tmpl, blueimp-file-upload,
// jquery, jquery-ui, jquery-ujs
//
import jQuery from 'jquery';
import {generateStackTrace} from "../Utility/errors";

const isDev = (process.env.NODE_ENV == 'development');

// Jquery plugin.
(function ($) {
  // SerializeObject - given a jquery selector that is form elements
  // (like $form.find('input'))
  // return an Object (Hash) of name=value


  // 2024-04 used by GenericForm, which is on the Create Review page
  $.fn.serializeObject = function () {
    return _.reduce(this.serializeArray(), function (hash, item) {
      hash[item.name] = item.value;
      return hash;
    }, {});
  }
}(jQuery));

globalThis.jQuery = jQuery;
globalThis.$ = jQuery;
globalThis.realJQuery = jQuery;

// jquery-ujs has a reference to (global.) jQuery

// Load JQuery plugins. These don't export anything, but instead
// the look for $ or jQuery in the root and add functions to it.
require('jquery-ujs');
require('jquery-ui/ui/core');
// 2024-06 JQUERY TABS REMOVED
// require('jquery-ui/ui/widgets/tabs');   // $(foo).tabs() - used by Group page

// used solely bo CartConnector! 2024
require('../vendor/jquery.parsequery');     // test it: $.parseQuery should not be undef; 2023 in cartConnector and
                                            // LocationService
require("../vendor/jquery.barrating.js");   // bar rating - used by ProductReviews page

// File Upload - use the uploader we previously had used with
// the Sprockets module jquery-file-upload.
// DEFINES: $.fn.fileupload
require('blueimp-file-upload');

export default function initJquery() {
  // alert("Jquery version is " + $.fn.jquery);
  // console.log("Jquery version is " + $.fn.jquery);
}

if (false) { // FIXME: isDev
  console.warn("DIVERTING JQUERY...");
  const realJQuery = jQuery;

  function fakeJquery(...args) {
    console.warn("Deprecated JQUERY called: ", ...args);
    let trace = generateStackTrace();
    console.warn(trace[1]);
    return realJQuery(...args);
  }

  for (let k of ['ajax']) {
    fakeJquery[k] = function (...args) {
      console.warn(`Deprecated JQUERY.${k} called: `, ...args);
      let trace = generateStackTrace();
      console.warn(trace[1]);
      return (realJQuery[k])(...args);
    }
  }

  globalThis.jQuery = fakeJquery;
  globalThis.$ = fakeJquery;

}
