import React, {Component} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import LloydMatChooserItem from './LloydMatChooserItem';
import LloydItemFactory from "../logic/LloydItemFactory";
import LloydCatalogue from "../models/LloydCatalogue";

/**
 * The first part of the LloydUI - at the top, present images of
 * floor mats with a checkbox for each.
 */
export default class LloydMatChooserItems extends React.Component {
  static propTypes = {
    lloyd: PropTypes.instanceOf(LloydCatalogue).isRequired,
    cart: PropTypes.object.isRequired,
    callbacks: PropTypes.object.isRequired
  }

  render() {
    let {cart, callbacks, lloyd} = this.props;

    let items = lloyd.getItemsAsList();

    // get only valid items - that have a product. In the CARGO groups we don't have
    // products for anything except cargo area mats.
    items = items.filter((i) => i.product && i.product.id);

    return <div id="lloyd-mat-chooser-items">
      {
        items.map(item => {
          let partNumber = item.extended_part_number;

          return <LloydMatChooserItem
            lloyd={lloyd}
            callbacks={callbacks}
            key={partNumber}
            lloydItem={item}
            cartItem={cart.getItem(partNumber)}/>
        })
      }
    </div>
  }

}
