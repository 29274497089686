import React from 'react';
import Money from '../../../lib/Money';

// 2023-11 modernised
export default function GridItemDesc({item}) {
  let price = new Money(item.price);

  return (
    <div className="grid-item-pricing">
      <ul>
        <li className="price">From {price.toString()}</li>
        <li className="shipping">Free Shipping
        </li>
      </ul>
    </div>
  );
}
