import React from 'react';

import TaxExemptWidget from "../below/TaxExemptWidget";
import ChannelPicker from "./ChannelPicker";

export default function AgentBelowCartToolbar({...props}) {
  const { currentAgent } = props;
  if (!currentAgent || !currentAgent.id)
    return null;

  return (
    <div className="agent-bg clearfix relative">
      <div className="hang-left">
        <div className="fa fa-lg fa-user-secret"></div>
      </div>
      <div className="col-6"><ChannelPicker {...props} /></div>
      <div className="col-6 omega"><TaxExemptWidget {...props} /></div>
      <div className="clear-me"></div>
    </div>
  );
}

//
