import React  from 'react';


import HiddenModalManager from "../Modals/HiddenModalManager";

const TubeHelper = ({...props}) => {

  return (
    <div>
      <HiddenModalManager />
    </div>
  );
}

// TubeHelper.propTypes = { }

export default TubeHelper;

