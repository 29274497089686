import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {getCurrentSecond, isOpenNow} from "./logic";
import PhoneHoursDisplay from "./PhoneHoursDisplay";

const isDev = (process.env.NODE_ENV == 'development');

const INTERVAL = isDev ? 1000 : 10_000; 

export default function PhoneHoursApp({
  phoneNumber,
  caption = '7 days/week',
  phoneStatus = {}
}) {
  const [localStartSecond, ] = useState(getCurrentSecond); // no setter

  const [currentSecond, setCurrentSecond] = useState(getCurrentSecond);

  useEffect(() => {

    window.setInterval(() => {
      // caution, constants retain their original value in here.
      // use setTimeout if you need it to change
      // console.log(`tick..... now=${now}`);

      setCurrentSecond(getCurrentSecond);
    }, INTERVAL)

  }, []);


  const openNow = isOpenNow({
    ...phoneStatus,
    elapsed: (currentSecond - localStartSecond)
  });

  return <PhoneHoursDisplay
    phoneNumber={phoneNumber}
    caption={caption}
    openNow={openNow} />
}

PhoneHoursApp.propTypes = {
  phoneStatus: PropTypes.shape({
    // "Second" is seconds since midnight in the server's zone
    startupSecond: PropTypes.number,
    openSecond: PropTypes.number,
    closeSecond: PropTypes.number,
    openNow: PropTypes.bool
  }).isRequired
}
