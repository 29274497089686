import React, {Component} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import SaveCartFallbackContents from './SaveCartFallbackContents';

// COUPON
// Use this flavour if a coupon is active. Slightly different if the coupon has
// a positive effect, or zero due to ineligible items.
export default class SaveCartCouponContents extends SaveCartFallbackContents {
  static defaultProps = _.extend({}, SaveCartFallbackContents.defaultProps, {
    //submitCaption: "Activate My Discount",
    // closeCaption:  "No thanks, I'd rather pay full price."
  });

  render() {
    let savings = this.getSavings();

    if (savings.positive()) {
      return this.defaultRender();
    } else {
      return <SaveCartFallbackContents
        {...this.props}
        prompt="Your order may qualify for an additional discount."/>
    }
  }

  renderInstructions() {
    return (<div className="save-cart-prompt">
      <p className="headline">
        <span className="text-before"> Lock in savings of </span>
        {' '}
        <span className="money">${this.getSavings().toDollars()}</span>!
      </p>
      <p className="instructions">Enter your email below and we'll send you a link to your cart.</p>
    </div>);
  }
}

