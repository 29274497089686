import React from "react";
import clsx from "clsx";

export default function TextContentBox({item={}, content, config={}}) {
  if (!content)
    return null;

  const classNames = clsx(config.overlayClasses, item.overlayClasses);

  const styles = Object.assign({},{
    position: 'absolute',
    width: 'auto',
  }, config?.overlayStyles, item?.overlayStyles);

  return <div className={classNames} style={styles}
    dangerouslySetInnerHTML={{__html: content}}/>
}
//{ "name": "15% Off Magnum RT & RT Gen 2 Step Bars!",
// "flavour": "1",
// "brandId": null,
// "imagePath": "/img/banners/category/MagnumRT&RT-Gen2BarsSave15.jpg",
// "link":
// "/Running-Boards-Side-Steps?filter_brand=377",
// "altText": null, "textContent": null }
