// Immutable class to hold a currency amount.
//  new Money(7799)     // $77.99
export default class Money {
  constructor(cents = null) {
    if (cents == null || isNaN(cents)) {
      this.c = null;
    } else {
      this.c = Math.round(cents);
    }
  }

  value() {
    return this.c;
  }

  positive() {
    return this.c > 0;
  }

  negative() {
    return this.c < 0;
  }

  zero() {
    return this.c === 0;
  }

  toDollars() {
    if (this.c == null)
      return null;

    return (this.c / 100.0).toFixed(2);
  }

  valid() {
    return this.c !== null;
  }

  centsPart() {
    return (this.valid() ? Math.abs(this.c % 100) : null);
  }

  dollarsPart() {
    if (!this.valid())
      return null;

    let c = this.c % 100;
    return Math.round((this.c - c) / 100);
  }

  // toLocalString, where supported, puts in the commas.
  formatDollars(value) {
    if (value.toLocaleString)
      return value.toLocaleString();

    return "" + value;
  }

  toString(sym = '$') {
    if (!this.valid())
      return "";

    let cents = this.centsPart();
    let dollars = this.formatDollars(Math.abs(this.dollarsPart()));

    let pad = cents < 10 ? "0" : '';
    let sign = this.c < 0 ? '-' : '';

    return `${sign}${sym}${dollars}.${pad}${cents}`;
    //return `${dollars} and ${cents}`
  }
}

// Static pseudo-constructor.
Money.fromDollars = function (d) {
  if (d === null)
    return new Money();
  else
    return new Money((100 * (+d)));
}

// Static converter - cents to floating USD
// Money.dollars(3995) => +39.95
Money.dollars = function(cents) {
  return new Money(cents).toDollars();
}

// Money.toFormatted(3995) => $39.95
Money.formatted = function(cents) {
  return new Money(cents).toString()
}
