import SpeakerToStorage from "../../ClientOnly/SpeakerToStorage";
import scrapyardSlice from "../../ReduxStore/scrapyardSlice";
import _ from 'lodash';
import {safeInvokeSliceAction} from "../../Utility/ReduxHelper";

const STASH_KEY = "chat_info";

/**
 * 2022-08 MH refactored from SpeakerToLiveChat.
 *
 */
export default class ChatInfoStash {
  constructor({contents, read = false} = {}) {
    this.contents = contents || {};

    if (read)
      this.readFromStorage();
  }

  isEmpty() {
    let cleaned = _.omit(this.contents, 'persisted');

    return _.isEmpty(cleaned);
  }

  // On start of chat, populate from API and save to LocalStorage.
  onChatStarted({api, details = {}} = {}) {
    this.contents = Object.assign(this.contents || {}, details, {
      status: 'started',
      chat_start_at: new Date().toISOString(),
      url: (globalThis.location || window.location).toString(),
      persisted: false  // not yet saved to back-end
    });

    // gather more details from API.
    this.populate({api});

    console.log("ChatInfoStash: onChatStarted", this.contents);

    this.writeToStorage();
    this.writeToRedux();
  }

  merge(stuff = {}) {
    this.contents = Object.assign(this.contents, stuff);
  }

  update(stuff = {}) {
    this.contents = Object.assign(this.contents, stuff);
    this.writeToStorage();
  }

  // Call a few API functions to gather chat info.
  populate({api} = {}) {
    Object.assign(this.contents, ChatInfoStash.readFromApi(api));

    console.log("ChatInfoStash: populated from API: ", this.contents);
    return this.contents;
  }

  clear() {
    this.contents = {};
    this.writeToStorage();
  }

  readFromStorage() {
    let s = new SpeakerToStorage();
    this.contents = s.read(STASH_KEY) || {};
    return this.contents;
  }

  writeToStorage() {
    let s = new SpeakerToStorage();
    return s.write(STASH_KEY, this.contents);
  }

  // 2022-08 - save chat state to Redux
  writeToRedux({store, dispatch} = {}) {
    safeInvokeSliceAction({
      dispatch, store,
      callerName: 'ChatInfoStash',
      slice: scrapyardSlice,
      action: 'setItems',
      payload: {chatInfo: this.contents}
    })
  }

  static readFromApi(api) {
    try {
      api || (api = globalThis.LC_API);
      if (!api) {
        console.error("ChatInfoStash.readFromApi - LC_API not available.")
        return;
      }

      let chat_id = api.get_chat_id && api.get_chat_id();
      let visitor_id = api.get_visitor_id && api.get_visitor_id();

      return {
        chat_id,
        visitor_id
      }
    } catch (err) {
      console.error("ChatInfoStash error: " + err);
    }
  }
}
