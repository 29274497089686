import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

export default function OpenModalButton({classNames, ...props}) {
  return (
    <div className="debug">
      <h3>OpenModalButton</h3>
      Props {JSON.stringify(_.keys(props))}
    </div>
  );
}
