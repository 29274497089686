import Validator from "./Validator";

/**
 * Base class for various editors, such as EditAddress.
 *
 * This will MUTATE the object passed in to it, so only call
 * this from somewhere object mutation is allowed, like with Immer.
 */
export default class EditObject {
  static fieldRules = {}; // validation rules; subclasses should override.
  static fieldLabels = {}; // names for fields; subclasses should override.

  // Adds the various internal state fields to
  constructor({object, edits, rules, labels}) {
    this.object = this.constructor.initObject(object);
    this.edits = edits || {};

    // we store rules/labels locally, rather than using the
    // static values, to allow customization - e.g.,
    // email is not present on the second address
    this.rules = rules || this.constructor.fieldRules;
    this.labels = labels || this.constructor.fieldLabels;
  }

  // Add meta fields to the target object.
  static initObject(object = {}) {
    if (!object) object = {};

    // touched - the user has visited and left the field, even if not changed.
    // usually called from an onBlur event
    object.touched || (object.touched = {});

    // changes[name] = [old, new] - updated only on actual changes
    object.changes || (object.changes = {});

    // errors[name] = message
    object.errors || (object.errors = {});

    return object;
  }

  // MAIN ENTRY POINT.
  call() {
    this.applyEdits(this.edits);
  }

  // Given a hash of { field_name => new_value },
  // cally applyChange() repeatedly.
  applyEdits(edits) {
    return Object.keys(edits).reduce(
      (changed, name) => {
        changed[name] = this.applyChange(name, edits[name]);
        return changed;
      }, {});
  }

  validateAll() {
    return this.getValidator().validateAll();
  }


  touchField(name) {
    // if (name && name.startsWith('_')) {
    //   console.error("IGNORE TOUCH : ")
    // }
    this.object.touched[name] = true;
  }

  setFieldError(name, message) {
    if (message === null) {
      delete this.object.errors[name];
    } else {
      this.object.errors[name] = message;
    }
  }

  /**
   * Update object with new name=value, record changes,
   * validate.
   *
   * @param name
   * @param value
   * @returns {boolean}
   */
  applyChange(name, value) {
    // Mark field as touched even if not changing.
    this.touchField(name);

    const oldValue = this.object[name];
    // Continue even if value is unchanged, so we can get
    // validation errors when user tabs off an initially-empty
    // field.
    // DONT: if (oldValue === value) return false;

    // Make the change!
    this.object[name] = value;

    // clear any validation errors
    this.setFieldError(name, null);

    // update object.changes
    this.recordChange(name, oldValue, value);

    // Validate it - validator will update this.errors[name]
    this.validateField(name, value);

    return true;
  }

  validateField(name, value) {
    let validator = this.getValidator();
    if (validator)
      return validator.validateField(name, value);
  }

  // Subclasses may override if a different class is desired.
  getValidator() {
    return new Validator({
      usage: this.usage,
      object: this.object,
      rules: this.rules,
      labels: this.labels,
    })
  }

  /**
   * Record a change in object.changes
   *  object.changes[name] = [ original, new-value ]
   *
   * @param name
   * @param oldValue
   * @param value
   * @returns {*}
   */
  recordChange(name, oldValue, value) {
    let {changes} = this.object;

    if (!changes[name])
      changes[name] = [oldValue, null];
    changes[name][1] = value;

    return changes[name];
  }
}
