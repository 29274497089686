import React from 'react';
import AgentStatusContainer from './AgentStatusContainer';

/*
 * Not sure why this top-level component exists.
 * Perhaps in hopes of converting to Redux?
 */
export default function AgentStatusApp(props) {
  return <AgentStatusContainer {...props} />
}
