/*
 * Base class for CartSaveWatcherSentinel.
 *
 * Using calendar date as a sentinel value, store this in local storage at
 * a given key.  .sentinelPresent() will return true if that value is there,
 * signifying that the associated action has already been done and should not
 * be done again.
 *
 * cart_save_watcher = 2017327, for example.
 * a future version might do:
 * cart_save_watcher = { 2017327 => number_of_uses }
 * and require it be > some specified value to inhibit.
 */

import _ from 'lodash';

export default class OnceADaySentinel {
  constructor({key} = {}) {
    this.key = key;
  }

  // Call this to determine whether the controlled action should run at all
  sentinelPresent() {
    const stored = this.read();

    if (!stored) return false;

    console.log(`${this.key}: found stored value ${stored}`)

    return (stored === this.buildValue());
  }

  // We stash today's date, encoded like 2017327.
  buildValue() {
    return this.formatDate(new Date());
  }

  formatDate(d) {
    const m = 1 + d.getMonth();
    const day = d.getDate();

    // it's 2022, why am I doing this shit by hand?
    return _.join([
      d.getFullYear(),
      (m < 10 ? "0" + m : m),
      (day < 10 ? "0" + day : day)
    ], '-');
  }

  getStorage() {
    if (globalThis.localStorage)
      return globalThis.localStorage;
    return {};
  }

  clear() {
    const storage = this.getStorage();
    storage[this.key] = null;
    storage.removeItem(this.key);
  }

  read() {
    const storage = this.getStorage();
    return storage.getItem(this.key)
  }

  write(value) {
    if (!value)
      value = this.buildValue();

    const storage = this.getStorage();
    console.log("Putting sentinel value " + value + " into " + this.key);
    storage.setItem(this.key, value);
  }
}
