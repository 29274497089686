import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import GridItem from "./GridItem";

import clsx from "clsx";
import {useDispatch} from "react-redux";

/**
 * 2024-05 MH multi-grid aware
 * 2023-04 MH - modernized this component and all components it uses.
 *
 * ProductGroupGrid - usually part of GroupGridApp (the bulk of a TopCategory page)
 * but could be called without it.
 *
 * https://lisa.aagarage.net/Tonneau-Covers/_Honda-Ridgeline
 *
 "dispatch",
 "startupState",
 "gridState",
 "customerVehicle",
 "vehicleContext",
 "callbacks",
 "visibleItems"
 */
export default function Top10Grid({
  gridId, visibleItems, gridState,
}) {
  const subLists = [
    _.slice(visibleItems, 0, 6),
    _.slice(visibleItems, 6, 10)
  ];

  let classNames = clsx('product-grid', 'product-grid-view',
    gridState.showCompare && 'grid-with-compare');

  return (
    <div className={classNames}>
      {subLists.map((items, index) => <TopTenGridChunk
        gridId={gridId}
        key={index}
        items={items}
        offset={index ? subLists[0].length : 0}
        columns={index ? 4 : 3}
        gridState={gridState}
      />)}
    </div>
  );
}

function TopTenGridChunk({gridId, items, columns = 4, offset=0, gridState}) {
  const dispatch = useDispatch();

  let classNames = clsx("product-grid-inner", "grid", "grid-cols-2");
  if (columns >= 4) {
    classNames = clsx(classNames, `lg:grid-cols-${columns - 1}`)
  }

  classNames = clsx(classNames, `xl:grid-cols-${columns}`)

  return <div className={classNames}>
    {
      items.map((item, i) => <GridItem
        key={item.id}
        gridId={gridId}
        dispatch={dispatch}
        item={item} index={+offset + +i}
        showRanks={true}
        gridState={gridState}
      />)
    }
  </div>

}

Top10Grid.propTypes = {
  gridState: PropTypes.object.isRequired,
};
