import React from 'react';

/**
 * AgentCredit - shown only to Agents, this tells them who has claimed the
 * cart and allows them to change that, setting themselves as the agent
 * who will receive credit for the order.
 *
 * @param props
 * @returns {JSX.Element|null}
 * @constructor
 */
export default function AgentCredit(props) {
  const {currentAgent, shoppingCart} = props;

  // only agents may see this.
  if (!currentAgent)
    return null;

  if (shoppingCart.agentName)
    return <AgentCreditClaimed {...props} />
  else
    return <AgentCreditUnClaimed {...props} />
}

function AgentCreditClaimed(props) {
  const {currentAgent = {}, callbacks} = props;
  const {updateCart} = (callbacks || {});

  return <p id="agent-credit">
    <span className="fa fa-user-secret"/>
    {' '}
    <strong>{currentAgent.username}</strong>
    {' '}
    will be credited for this sale.
    {' '}
    <a id="agent-take-credit" onClick={() => updateCart({agentName: ''})}>(remove)</a>
  </p>
}

function AgentCreditUnClaimed(props) {
  const {currentAgent = {}, callbacks} = props;
  const {updateCart} = (callbacks || {});
  const {username} = currentAgent;

  const handleClick = () => {
    updateCart({agentName: username})
  }

  return <p id="agent-credit">
    <span id="no-agent-credit">
        <span className="fa fa-exclamation-triangle"/>
        <strong> No CSR </strong>
        will be credited for this sale.
      {' '}
      <a id="agent-take-credit" onClick={handleClick}>
        Click Here to set
        {' '}
        <span className="fa fa-user-secret"/>
           <strong>{username}</strong>
        </a>
      {' '} as the agent responsible for this cart.
    </span>
  </p>
}
