import React, {useState, useEffect} from 'react';
import _ from 'lodash';
import { getDataCollector} from "../../logic/braintreeHelper";

/**
 * 2023-03 MH modernized to React18.
 *
 * Get Device Data from Braintree and save it to the back-end via POST.
 *
 * The front end app doesn't care about braintree device data at all,
 * it simply sends it to the back end (via redux).
 *
 */
export default function DeviceDataRequestor({braintreeClient, callbacks, ...props}) {
  const [requested, setRequested] = useState(null);

  const saveDeviceData = function(result) {
    const { deviceData } = (result || {empty: true});
    const { saveCheckout } = callbacks;

    if (!deviceData || !saveCheckout) {
      console.error("Braintree deviceData or saveCheckout callback missing: ",
        result, callbacks)
      return;
    }

    saveCheckout({
      edits: {deviceData},
      route: 'new_checkout',
      quiet: true
    });
  }

  useEffect(() => {
    try {
      // already requested? or client not ready?
      if (requested || _.isEmpty(braintreeClient)) return;

      const dataCollector = getDataCollector();

      if (!dataCollector?.create) return;

      // mark as requested so we don't do it again
      setRequested(new Date().getTime());

      dataCollector.create({client: braintreeClient})
        .then(result => saveDeviceData(result))
        .catch(err => alert(err))
    } catch (err) {
      console.error("error getting braintree deviceData: ", err);
      window.LAST_ERROR = err;
    }
  }, [requested, braintreeClient])

  return <span id="braintree-device-data-requestor" />
}
