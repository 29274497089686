import ErrorEvent from "./ErrorEvent";
import LocalUrlMaker from "../../lib/LocalUrlMaker";
import JsonPost from "../../ReduxStore/Helpers/JsonPost";

const isDev = (process.env.NODE_ENV == 'development');

export default class ErrorReporter {
  static config = null;

  constructor({errorEvent, config}) {
    if (config && !ErrorReporter.config)
      ErrorReporter.config = config;

    this.errorEvent = ErrorEvent.toErrorEvent(errorEvent);
  }

  get errorServiceUrl() {
    return LocalUrlMaker.jsonPath("/site_errors.json");
  }

  call() {
    this.reportToErrorService();

    if (!isDev)
      this.reportToNewRelic();
  }

  reportToErrorService() {
    try {
      let post = this.buildRequest();
      return post.perform()
        .then(response => console.log("error service response: ", response));

    } catch (err) {
      console.error("error in error service: ", err);
    }
  }

  buildRequest() {
    let e = this.errorEvent || {};

    let payload = {
      site_error: e.contents
    };

    return new JsonPost({
      url: this.errorServiceUrl, payload})
  }

  reportToNewRelic() {
    try {
      let newrelic = globalThis.newrelic;

      if (!newrelic || !newrelic.noticeError) {
        console.warn("cannot find newrelic api in browser, not reporting error.")
        return false;
      }

      newrelic.noticeError(error, {caller: props.caller})
    } catch (badThing) {
      console.error(`ErrorReporter internal error: #reportToNewRelic ${badThing}`);

    }
  }
}
