import OptionDefinition from "./OptionDefinition";

let camelize = (hash) => _.mapKeys(hash, (v, k) => _.camelCase(k));

export default class OptionsCatalogue {
  constructor({lloydAttributes = {}}) {
    _.extend(this, camelize(lloydAttributes))
  }

  hasOption(name) {
    return this[name] ? true : false;
  }

  getOption(name) {
    let raw = this[name];
    if (!raw) {
      console.warn(`no such option ${name} in ${Object.keys(this)}`)
      return null;
    }

    return new OptionDefinition(raw);
  }

}
