import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {fetchBraintreeToken} from "../../ReduxStore/scrapyardSlice";

/**
 * 2023-06 uses dispatch and scrapyardSlice instead of callbacks, so we can
 *    use it in apps other than checkout
 * 2021-12 refactored from BraintreeProvider.
 *
 * As soon as possible after page loads, fetch /new_checkout/CART_CODE/braintree_token,
 * which will cause the back-end to allocate a fresh braintree token,
 * and then save it in Redux as [checkout-store].braintree.token.
 *
 */
export default function BraintreeTokenFetcher(props) {
  const {cartCode, dispatch} = props;

  const [requested, setRequested] = useState(false);

  // POST to /new_checkouts/XY-ZZY/braintree_token.json url,
  // which will receive an object with { braintree: { token: XXXX } }
  useEffect(() => {
    if (requested || !cartCode || !dispatch) return;

    console.log('################################### fetch braintree token for ' + cartCode);
    setRequested(true);

    dispatch(fetchBraintreeToken({cartCode}));
  }, [cartCode, requested, dispatch])

  return <div className="braintree-token-fetcher" />
}

BraintreeTokenFetcher.propTypes = {
  cartCode: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired
};


