/*
 * Value object that wraps the pageType string (a group attribute)
 * and provides query methods.
 */
export default class GroupPageType {
  constructor(str) {
    str = str ? ("" + str) : "";

    this.types = _.keyBy(str.split(""));
  }

  // What fields should the VehicleChangeWatcher
  // watch on customerVehicle?
  watchedFields() {
    let fields = ['id'];

    if (!this.fitmentInCluster())
      fields.push('fitment');

    if (this.lloyd())
      fields.push('lloyd_unique');

    return fields;
  }

  // given the current customer vehicle and our
  // watched fields, can we now load products?
  canLoadProducts(customerVehicle ={}) {
    if (!customerVehicle) return false;

    let fields = this.watchedFields();
    for (let k of fields) {
      if (!customerVehicle[k])
        return false;
    }

    return true;
  }

  hasType(ch) {
    return !!this.types[ch];
  }

  multiAdd() {
    return this.hasType('M')
  }

  clustered() {
    return this.hasType('C');
  }

  fitmentInCluster() {
    return this.hasType('f'); //lowercase
  }

  lloyd() {
    return this.hasType('L');
  }

  // comes from group flag not page type but seems present...
  universal() {
    return this.hasType('U');
  }
}
