export default class ReviewGauge {
      constructor($elem) {
        this.elem = $elem[0];
        this.waitForLibrary((function(_this) {
          return function() {
            return _this.initChart();
          };
        })(this));
      }

      waitForLibrary(callback) {
        if (this.libraryLoaded()) {
          return callback();
        }
        return window.setTimeout(((function(_this) {
          return function() {
            return _this.waitForLibrary(callback);
          };
        })(this)), 100);
      }

      libraryLoaded() {
        let g;
        g = window.google;
        return g && g.visualization && g.visualization.Gauge && true;
      }

      initChart() {
        this.chart = new google.visualization.Gauge(this.elem);
        return this.draw();
      }

      draw() {
        return this.chart.draw(this.data(), this.options());
      }

      update(count) {
        let d, value;
        value = count;
        d = this.data();
        if (d) {
          this.data().setValue(0, 1, Math.floor(value));
          return this.draw();
        }
      }

      data() {
        if (!this.libraryLoaded()) {
          return null;
        }
        return this._chart_data || (this._chart_data = google.visualization.arrayToDataTable([['Label', 'Value'], ['', 0]]));
      }

      options() {
        let s;
        s = this.getWidgetSize();
        return this._chart_opts || (this._chart_opts = {
          width: s,
          height: s,
          minorTicks: 5,
          max: 40,
          yellowFrom: 20,
          yellowTo: 30,
          greenFrom: 30,
          greenTo: 40
        });
      }

      getWidgetSize() {
        let dd, mobile;
        dd = window.device_detector;
        mobile = false;
        if (dd) {
          mobile = dd.isMobile();
        }
        if (mobile) {
          return 80;
        } else {
          return 120;
        }
      }
    }
