import SubscriberList from './SubscriberList';

//
// Generate a clock tick every 0.5 seconds.
// Monitor how long a user has been idle, and inform subscribers of this.
//
export default class IdleTimer
{
    constructor({ subscribers }) {
        this.start_time = new Date().getTime();
        this.activity_time = this.start_time;

        this.subscribers = subscribers;

        // watch for mousemove, keydown, etc. and reset idle time
        this.watchInteractions();
        // Do a tick every half second
        this.setupInterval();
    }

    subscribe(obj) {
        this.subscribers.add(obj);
    }

    watchInteractions() {
        let it = this;
        let $d = $(document);

        //$d.mousemove( (evt) => it.resetIdle(evt) );
        $d.on('mousemove', (evt) => it.resetIdle(evt) );
        $d.on('keydown', (evt) => it.resetIdle(evt) );
        $d.on('scroll', (evt) => it.resetIdle(evt) );
        $d.on('click', (evt) => it.resetIdle(evt) );
    }

    resetIdle(evt) {
        this.activity_time = new Date().getTime();
        // if (evt) {
        //     console.log("you are not idle at " + this.activity_time + ", event is " + _.upperCase( evt.type));
        // }
    }

    setupInterval() {
        let it = this;
        window.setInterval( () => it.tick(), 500);
    }

    totalTime(t) {
        t = t || new Date().getTime();
        return t - this.start_time;
    }

    idleTime(t) {
        t = t || new Date().getTime();
        return t - this.activity_time;
    }

    // every half second, get the idle time and total time, and
    // notify all subscribers.
    tick() {
        let t = new Date().getTime();
        let total_time  = t - this.start_time;
        let idle_time = t - this.activity_time;

        if (idle_time > 400) {
            var args = {timer: this, idle_time: idle_time, total_time: total_time};
            this.subscribers.notifyAll(args);
        }
    }

    notifySubscribers(idle_time, total_time) {
        alert("idle timer notify subs");
        for (var i in this.subscribers) {
            var sub = this.subscribers[i];
            sub(args);
        }
    }
}
