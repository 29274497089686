import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

const MultiCartHeadline = ({coreCart, ...props}) => {
  if (!coreCart || !coreCart.itemCount)
    return null;

  const { itemCount, cartUrl, cartCode, subtotalMoney} = coreCart;

  return (
    <div className="multi-cart-headline">
      <div className="my-accessories-cart-heading">
        <span>My Accessories Cart</span>

        <div className="tooltip-item">
          <i className="fas fa-info-circle"></i>
          <div className="tooltip">
            <p>NAPA Custom Accessories ship directly to your door and may not be available in-store.</p>
          </div>
        </div>
      </div>

    </div>
  );
}

// MultiCartHeadline.propTypes = { }

export default MultiCartHeadline;
