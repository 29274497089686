import React from 'react';
import LocalUrlMaker from "../../../lib/LocalUrlMaker";

export default function AppleFormSection(props) {
  // const {checkout, braintreeClient} = props;

  return (
    <div className="apple-form-section">

      <p>To check out with Apple Pay&reg;, please return to the
        {' '}
        <a className="nobr" href={LocalUrlMaker.prefixedPath("/cart")}>View Cart Page</a>.
      </p>
    </div>
  );
}

