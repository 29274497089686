import React, {Component} from "react";
import PropTypes from "prop-types";

function getClasses(props) {
  let {step, current} = props;

  let list = [];
  if (current == step)
    list.push('current');
  if (step > current)
    list.push('future');
  if (step == 3)
    list.push('last');
  return list.join(' ');
}

export default function CheckoutStep(props) {

  const {step, current, caption, setStep} = props;

  const handleClick = () => { setStep(step);}

  // caption = caption + `C=${current}` + this.getClasses();

  const enabled = (+step) <= (+current);

  return <li className={getClasses(props)}>
    {enabled && <a onClick={handleClick}>
      <strong>{step}</strong>
      {' '}
      <span>{caption}</span>
    </a>}
    {enabled || <div>
      <strong>{step}</strong>
      {' '}
      <span>{caption}</span>
    </div>}
  </li>
}

CheckoutStep.propTypes = {
  step: PropTypes.number.isRequired,
  current: PropTypes.number.isRequired,
  setStep: PropTypes.func.isRequired,
  caption: PropTypes.string.isRequired
};
