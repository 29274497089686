
import ReactOnRails from 'react-on-rails';
import ProductGridApp from "./components/container/ProductGridApp";
import GridSidebarApp from "./components/container/GridSidebarApp";
import LinearProductGridApp from "./components/container/LinearProductGridApp";

ReactOnRails.register({
    ProductGridApp,
    GridSidebarApp,
    LinearProductGridApp
});


