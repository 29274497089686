import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import SelectWidget from "./SelectWidget";
import ChooserLevelIcon from "./ChooserLevelIcon";
import clsx from "clsx";
import {VehicleSelectorConfigContext} from "../logic/chooserConfig";

/**
 * VehicleChooserLevel - one "level" widget (year, make, etc.),
 * which includes a wrapping div, a status icon, and some sort
 * of HTML form element like a <select>.
 */
export default function VehicleChooserLevel({
  config,
  choices = {},
  options = {},
  onChoiceMade,
  level,
  highlighted,
}) {
  if (_.isEmpty(config))
    config = useContext(VehicleSelectorConfigContext);

  // choices like: {"year":"2012","make":"192","model":null,"fitment":null}
  const value = choices[level] || "";

  // [ {v: 208, l: "Ford"}, etc. ]
  const optionList = options[level + "s"] || options[level] || [];

  // selector is enabled when items are loaded.
  const enabled = !_.isEmpty(optionList);

  const classNames = getClasses({
    enabled, level, config, highlighted
  });

  return (
    <div id={"select-" + level} className={classNames}>
      <ChooserLevelIcon value={value}/>

      <SelectWidget
        config={config}
        onChoiceMade={onChoiceMade}
        level={level}
        value={value}
        optionList={optionList}
        disabled={!enabled}
      />
    </div>
  );
}

function getClasses({enabled, level, config = {}, highlighted}) {
  let classes = [
    "selector-level",
    "selector-" + (enabled ? 'enabled' : 'disabled'),
    // isActiveLevel() ? 'selector-active' : ''
  ];

  // 2024-04: DetailsOnlySelector - sends highlighted=true for fitment
  // test it: /Tonneau-Covers, pick vehicle, then go into a group page
  // that wants a fitment; fitment selector should shake after a few seconds
  if (highlighted)
    classes.push('shake-later');

  // Highlight this selector by shaking the arrow.
  // 2022-12 on products/show page when displaying no-vehicle message with selector,
  // highlight year. Feature was removed 1/11/23!
  // if (enabled && config) {
  //   const opt = `highlight_${level}`; // _.camelCase ?
  //   if (config[opt])
  //     classes.push('shake-now');
  // }

  return clsx(classes);
}

VehicleChooserLevel.propTypes = {
  onChoiceMade: PropTypes.func.isRequired,
  config: PropTypes.object.isRequired,
  highlighted: PropTypes.bool,
  level: PropTypes.string.isRequired,
  options: PropTypes.object.isRequired,
  choices: PropTypes.object // {"year":"2012","make":"192","model":null,"fitment":null}
}
