import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

/**
 * After Color or Binding is selected, replace the OptionGallery with
 * a SelectedOptionDisplay, a "roll-up" that describes the user's choice.
 * For color or border/binding attribute, show the user's selection
 * as an image, with a link to discard it.
 *
 * modernized 2023-02
 */
const SelectedOptionDisplay = ({...props}) => {
  const {selection, option, callbacks} = props;

  if (!selection)
    return null;

  // FIXME snake_case
  const { name = "option", small_image: smallImage, license_image: licenseImage } = selection;

  const handleReset = (e) => {
    console.log("clear option - " + option.name);
    callbacks.selectOption(option.name, null);
  }

  // return <div><h2>{JSON.stringify(Object.keys(props))}</h2></div>

  return (
    <div className="user-selection">
      <div className="image-box">
        {smallImage && <img src={smallImage} alt={name}/>}
      </div>
      <div className="description-box">
        <h4>Your selected {option.name}:</h4>
        <h3>
          {name || selection.description}
        </h3>
      </div>
      <div className="change-selection">
        <a className="reset" onClick={handleReset}>
          <i className="fa fa-plus" aria-hidden="true"></i>
          change selection
        </a>
        {licenseImage && <img src={licenseImage} className="license-image"/>}
      </div>
    </div>);
}

// SelectedOptionDisplay.propTypes = { }

export default SelectedOptionDisplay;

SelectedOptionDisplay.propTypes = {
  selection: PropTypes.object,
  option: PropTypes.object.isRequired
}
