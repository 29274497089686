import React from 'react';
import _ from 'lodash';
import {connect, Provider} from "react-redux";
import SaveCartContainer from "./SaveCartContainer";
import {getReduxStore} from "../../ReduxStore/store";

/**
 * SaveCartApp 2022 - a top-level React app, rendered by
 * cart_engine/app/views/cart_engine/shopping_carts/_cart_app.html.haml.
 * In the HTML it's outside (and just before) the cart itself.
 *
 * @param state
 * @returns {PartialObject<{}>}
 */
function mapStateToProps(state) {
  let {cart = {}, saveCart = {}} = state;

  // omit: cartItemId, contextKeys, callInNumber...
  return Object.assign(
    {},
    saveCart,
    _.pick(cart, 'shoppingCart'),
  );
}

let Wrapper = connect(mapStateToProps)(SaveCartContainer);

export default function SaveCartApp(props) {
  return <Provider store={getReduxStore()}>
    <Wrapper {...props} />
  </Provider>
}

