import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import clsx from "clsx";

const DEFAULTS = {
  classNames: [],
  leftIcon: 'text-xl md:text-3xl text-gray-400 fas fa-chevron-double-left',
  rightIcon: 'text-xl md:text-3xl text-gray-400 fas fa-chevron-double-right'
}

export default function AdvanceButton({
  count = 1, callbacks = {}, buttonConfig = {}, ...props
}) {

  buttonConfig = (typeof buttonConfig === 'object') ?
    buttonConfig : {};

  buttonConfig = Object.assign({}, DEFAULTS, buttonConfig);

  const negative = (+count) < 0;

  const styles = {
    bottom: '2rem'
  }

  if (negative)
    styles.left = '0.5rem';
  else
    styles.right = '0.5rem'

  Object.assign(styles, buttonConfig.styles);

  const classNames= clsx(
    'absolute',
    negative ? buttonConfig.leftIcon : buttonConfig.rightIcon,
    buttonConfig.classNames
  )

  return <button className={clsx(buttonConfig.classNames)}>
    <a onClick={() => callbacks.advance(count)}>
      <i style={styles} className={classNames}/>
    </a>
  </button>

}
