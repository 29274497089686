// New in 2018-12 - take some clutter out of LocalApi
// by placing each navigation widget here.

import Orchestrator from '../../lib/Orchestrator';
import SearchForm from "./SearchForm";
import HamburgerButton from './HamburgerButton';
import CategoryMenuBar from './CategoryMenuBar';
import SearchButton from './SearchButton';
import DropBannerBar from './DropBannerBar';

export default class PageNavigation extends Orchestrator {

    startup(page) {
        this.search_button = new SearchButton().startup(page);

        this.search_form = new SearchForm().startup(page);

        this.hamburger_button = new HamburgerButton().startup(page);

        this.category_menu_bar = new CategoryMenuBar().startup(page);

        this.drop_banner_bar = new DropBannerBar().startup(page);

        return this;
    }
}
