import React from 'react';

import {connect, Provider} from 'react-redux';

import AddSingleItemContainer from "./AddSingleItemContainer";
import {getReduxStore} from "../../ReduxStore/store";

function mapStateToProps(state) {
  let {groupItemsSlice = {}} = state;

  return groupItemsSlice;
}

let Wrapper = connect(mapStateToProps)(AddSingleItemContainer);

/**
 *
 * Top-level app, used in product_groups/show template.
 * Always present for Universal groups, this will show something
 * only if there's just a single buyable item.
 *
 */
export default function AddSingleItemApp(props) {

  return <Provider store={getReduxStore()}>
    <Wrapper {...props} />
  </Provider>
}

