import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

import PostWithParams from "../Helpers/PostWithParams";
import LocalUrlMaker from "../../lib/LocalUrlMaker";
import {addRailsPrefix, camelizeKeysDeep, snakeifyKeys} from "../Helpers/transformObject";
import JsonDelete from "../Helpers/JsonDelete";
import scrapyardSlice from "../scrapyardSlice";

// fetch the cart from /cart/cart-code.json and store in redux
export const fetchCart = createAsyncThunk(
  "fetchCart",
  async (payload, thunkAPI) => {
    let {cartCode} = payload;
    const {dispatch} = thunkAPI;

    if (!cartCode) {
      throw new Error("cartSlice.fetchCart: no cart code, cannot fetch")
    }

    let url = LocalUrlMaker.jsonPath(`/cart/${cartCode}.json`);

    fetch(url).then(r => r.json())
      .then(data => dispatch(cartSlice.actions.setCart(data)));
  });

// Create cart item.
export const addToCart = createAsyncThunk(
  "addToCart",
  async (payload = {}, thunkAPI) => {
    const {dispatch} = thunkAPI;

    let params = buildAddParams(payload);

    let post = new PostWithParams({
      url: LocalUrlMaker.jsonPath("/cart_items.json"),
      params
    });

    post.perform()
      .then(r => r.json())
      .then(data => {
        if (data.error || (data.status == 'error')) {
          throw new Error(data.message || data.error || "Server error");
        }

        const update = Object.assign({dynamicCart: {open: true}}, data);
        dispatch(cartSlice.actions.setCart(update))
      }).catch(err => {
        // display in ErrorModal
        dispatch(scrapyardSlice.actions.setError({error: err.toString() }));

        // throw again so window.onerror gets it
        throw err;
      }
    )
  });

export const deleteCartItem = createAsyncThunk(
  'deleteCartItem',
  async (payload, thunkAPI) => {
    const {dispatch} = thunkAPI;

    let {id, cartCode} = payload;

    let slayer = new JsonDelete({
      url: LocalUrlMaker.jsonPath(`/cart_items/${id}.json`),
      params: snakeifyKeys({cartCode})
    });

    slayer.perform().then(r => r.json())
      .then(data => dispatch(cartSlice.actions.setCart(data)));
  });

export const updateCartItem = createAsyncThunk(
  'updateCartItem',
  async (payload, thunkAPI) => {
    let {id} = payload;
    const {dispatch} = thunkAPI;

    const params = addRailsPrefix(
      snakeifyKeys(payload.changes), 'cart_item');

    let post = new PostWithParams({
      method: 'PATCH',
      url: LocalUrlMaker.jsonPath(`/cart_items/${id}.json`),
      params: params
    });

    post.perform().then(r => r.json())
      .then(data => dispatch(cartSlice.actions.setCart(data)));
  }
);

export const updateCart = createAsyncThunk(
  'updateCart',
  async (payload, thunkAPI) => {
    const {cartCode} = payload;
    const {dispatch} = thunkAPI;

    const params = addRailsPrefix(
      snakeifyKeys(payload.changes), 'shopping_cart');

    let post = new PostWithParams({
      method: 'PATCH',
      url: LocalUrlMaker.jsonPath(`/cart/${cartCode}.json`),
      params: params
    });

    console.log("----------- post update: ", post.fullUrl(), params);

    post.perform().then(r => r.json())
      .then(data => dispatch(cartSlice.actions.setCart(data)));
  }
);

export const fetchForecasts = createAsyncThunk(
  'fetchForecasts',
  async (payload, thunkAPI) => {
    const {cartCode} = payload;
    const {dispatch} = thunkAPI;

    const url = LocalUrlMaker.jsonPath(
      `/cart_shipping_forecasts/${cartCode}.json`)

    // set 'forecasts' object
    fetch(url).then(r => r.json())
      .then(data => dispatch(cartSlice.actions.setCart(data)));
  });

const initialState = {
  shoppingCart: null,
  forecasts: {},
  // data used by the popup on group pages
  dynamicCart: {open: false}
};

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    setCart: (state, action) => {
      const payload = Object.assign(
        // defaults - clear itemErrors after reload, but
        // payload.itemErrors may override
        {itemErrors: {}},
        camelizeKeysDeep(action.payload)
      );

      Object.assign(state, payload);
    },

    // just merge in any arbitrary new state.
    update: (state, action) => {
      _.assign(state, action.payload);
    },

    // merge changes in the dynamicCart object.
    updateDynamicCart: (state, action) => {
      let {payload} = action;

      state.dynamicCart = Object.assign(state.dynamicCart || {}, payload);
    }
  },

  extraReducers: builder => {
    // 'addToCart/pending', 'addToCart/fulfilled', 'addToCart/rejected'
  }
});

export default cartSlice;

function buildAddParams({product = {}, application = {}, options = {}}) {
  let id = options.item_id || product.id || (1000000 * Math.random()).toFixed(0);

  let {vehicle = {}} = (application || {});

  let fields = {
    quantity: options.quantity || 1,
    product_id: product.id,
    application_id: application.id,
    vehicle_id: vehicle ? vehicle.id : 0,
    vehicle_fitment_id: application.vehicle_fitment_id
  }

  return _.mapKeys(fields, (_v, k) => `cart_item${id}[${k}]`)
}

