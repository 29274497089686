//
// Given an AAG checkout object,
// build the parameters for sending to affirm.checkout()
//
// https://docs.affirm.com/Integrate_Affirm/Direct_API#2._Initiate_a_checkout
//
import {camelizeKeysDeep} from "../../ReduxStore/Helpers/transformObject";
import EmbeddedConfig from "../../lib/EmbeddedConfig";
import LocalUrlMaker from "../../lib/LocalUrlMaker";

function isValidAddr(addr) {
  return !!(addr && addr.city);
}

export default class AffirmCheckoutAdapter {
  constructor(checkout) {
    checkout = camelizeKeysDeep(checkout);

    this.checkout = checkout;

    this.cartCode = checkout.cartCode;

    const {shippingAddress, billingAddress, serverUrl} = checkout;

    this.shippingAddress = shippingAddress;

    if (billingAddress && isValidAddr(billingAddress)) {
      this.billingAddress = billingAddress;
    }

    // get url of our own server, for making self-referential urls
    this.serverUrl = serverUrl || this.defaultServerUrl();
  }

  build() {
    let items = this.buildItems();

    const payload = _.extend({
      merchant: this.buildMerchant(),     // REQUIRED.
      shipping: this.buildAddress(this.shippingAddress),  // REQUIRED.
      billing: this.buildAddress(this.billingAddress || this.shippingAddress),  // REQUIRED.
      items: items,     // REQUIRED LIST
      metadata: this.buildMeta(),
      order_id: this.cartCode,
    }, this.getCartTotals());

    console.log("Affirm parameters: ", payload);

    return payload;
  }

  getCartTotals() {
    let {cartItems = [], totals} = this.checkout;

    let subtotal = _.sumBy(cartItems,
      (i) => i.effectiveExtendedPrice || (i.quantity * i.effectiveUnitPrice));

    let shipping = totals.effectiveShipping || 0;
    let tax = totals.effectiveTax || _.sumBy(cartItems,
      item => (+item.quantity * +item.effectiveTaxEach));

    return {
      shipping_amount: shipping,    // cents
      tax_amount: tax,       // cents
      total: subtotal + shipping + tax
    }
  }

  buildItems() {
    let {cartItems} = this.checkout;

    if (_.isEmpty(cartItems))
      throw new Error("no cart items");

    return cartItems.map((item) => this.buildItem(item))
  }

  buildItem(cartItem) {
    return {
      "display_name": cartItem.name,
      "sku": cartItem.vendorsku,
      "unit_price": cartItem.effectiveUnitPrice, // Tested with agent adjustment.
      "qty": cartItem.quantity,
      "item_image_url": `${this.serverUrl}${cartItem.smallImage}`,
      "item_url": `${this.serverUrl}${cartItem.groupUrl}`,
      // below here is optional
      //"categories": []
    }
  }

  buildMeta() {
    return {
      shipping_type: "Standard Shipping",
      // mode: 'modal',
      cart_code: this.cartCode
    }
  }

  // continue at:
  //   https://marge/cart_engine/affirm_checkouts/32-BY2H5?checkout_token=764HK0RDPAQXTDWH
  buildMerchant() {
    let {cartCode} = this;

    return {
      // Started POST "/new_checkout/EF-1ZE5C/affirm_payment.json" for 192.168.0.4 at 2021-06-30 12:35:41 -0700
      // not a JSON url as the user will be sent directly here, so it will be format=html
      "user_confirmation_url": this.localUrl(`/new_checkout/${cartCode}/affirm_payment`),
      // "Return to AAG" at top of page links to this.
      // POST as an "update" action.  If we do GET it may still work,
      // as affirm_checkouts_controller#show would look for checkout token
      "user_cancel_url": this.localUrl(`/cart/${cartCode}`),
      "user_confirmation_url_action": "POST", // or "GET"
      "name": EmbeddedConfig.getDisplayName()
    }
  }

  localUrl(path) {
    let {serverUrl} = this;
    path = LocalUrlMaker.prefixedPath(path);
    return `${serverUrl}${path}`;
  }

  // Convert AAG StreetAddress and Customer objects (or the JS equivalents thereof)
  // into Affirm shipping / billing hashes.
  buildAddress(addr) {
    addr = addr || {};

    return {
      name: {
        first: addr.firstName,
        last: addr.lastName,
        // full: ...
      },
      address: {
        line1: addr.street,
        line2: addr.street2 || addr.company,
        city: addr.city,
        state: addr.stateCode,
        zipcode: addr.zip,
        country: addr.countryCode
      },
      phone_number: addr.phone,
      email: addr.email
    }
  }

  defaultServerUrl() {
    let {location} = globalThis;

    if (location) {
      return `${location.protocol}//${location.host}`;
    }
  }

}

