import React, {useState, useEffect} from 'react';

import clsx from 'clsx';

export default function SubscribeSuccess({
  classNames,
  caption,
  ...props
}) {
  caption ||= "Subscribed successfully"
  classNames ||= "my-4 font-bold";

  // bg-venom text-brand-dark
  return (
    <div className={clsx(classNames)}>{caption}</div>
  );
}


