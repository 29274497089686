import React, {Component} from 'react';
import PropTypes from 'prop-types';

import GroupReviewRequestLink from "./GroupReviewRequestLink";
import PulsingLinesThrobber from "../../Common/components/PulsingLinesThrobber";
import {loadGroupReviews} from "../../ReduxStore/ProductGroupPage/groupReviewsSlice";

export default class ReviewLoaderUI extends Component {
  static propTypes = {
    reviewSummary: PropTypes.object,
    initialCount: PropTypes.number,
    // "scope" is limiting params for the reload request to the back-end.
    //
    // {vehicle_id: 2083273, product_id: null, group_id: 2856}
    scope: PropTypes.shape({
      vehicle_id: PropTypes.number,
      product_id: PropTypes.number,
      group_id: PropTypes.number
    })
  }

  static defaultProps = {
    initialCount: 10,
    reviewSummary: {},
    scope: {}
  }

  // callback, invoked when user clicks link.
  // Invoke /reviews/list?group_id=... which will update the reviews store.
  loadMoreReviews = (e) => {
    if (e) e.preventDefault();

    // console.log("ReviewLoaderUI - loadMoreReviews()...");

    let {dispatch, initialCount = 0, reviewsLoaded = 0, nextOffset = null, scope = {}, reviewSummary = {}} = this.props;

    let offset = nextOffset ? nextOffset :
      ((+initialCount) + (+reviewsLoaded));

    let payload = {
      params: Object.assign({}, scope, {offset})
    }

    dispatch(loadGroupReviews(payload));
  }

  getVisibleCount() {
    let {initialCount = 10, reviewsLoaded = 0, nextOffset} = this.props;
    // console.log("GET VISIBLE COUNT" + JSON.stringify(this.props, null, 2));

    if (nextOffset && nextOffset > 0) return nextOffset;

    // console.log(`init ${this.props.initial_count} off ${count}`);
    return (+initialCount) + (+reviewsLoaded);
  }

  render() {
    let {reviewSummary = {}, ProductReviews = {}, loading} = this.props;

    return <div className="review-loader">
      <GroupReviewRequestLink
        onClick={this.loadMoreReviews}
        count={this.getVisibleCount()}
        max={reviewSummary.count}/>
      {loading && <PulsingLinesThrobber/>}
    </div>
  }
}

