import React from 'react';
import PropTypes from 'prop-types';
import LocalUrlMaker from "../lib/LocalUrlMaker";

export default function ModernCartIcon({
  cartCode, cartUrl, itemCount,
  className = "top-cart-display",
  iconClasses = "fas fa-shopping-cart",
  countClasses = "cart-count"
}) {

  cartUrl ||= LocalUrlMaker.prefixedPath(`/cart/${cartCode}`)

  const showCount = ((+itemCount) > 0);

  return (
    <div className={className}>
      <a href={cartUrl} rel="nofollow">
        <i className={iconClasses} />
        {showCount && <div className={countClasses}>{itemCount}</div>}
      </a>
    </div>
  );
}


