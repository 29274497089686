import {camelCase, mapKeys, mapValues, snakeCase} from 'lodash';

export const camelizeKeys = obj =>
  mapKeys(obj, (v, k) => camelCase(k));

// _.camelCase(k)
export const camelizeKeysDeep = obj =>
  mapKeysDeep(obj, camelCase);

export const snakeifyKeys = obj =>
  mapKeys(obj, (v, k) => snakeCase(k))

export const snakeifyKeysDeep = obj =>
  mapKeysDeep(obj, snakeCase)

// Given a param bundle and a prefix, rewrite the params' keys in Rails style
export const addRailsPrefix = (obj, prefix) =>
  mapKeys(obj, (v, k) => `${prefix}[${k}]`);

/**
 * Iterate through a collection (either object or array). For
 * all plain objects in the collection, replace them with a new
 * object with transformed keys
 *
 * @param obj a hash or array of hashes
 * @param fn a function that takes a key as param, like camelCase
 */
export const mapKeysDeep = (obj, fn) => {
  if (Array.isArray(obj)) {
    return obj.map(item => mapKeysDeep(item, fn));
  } else if (_.isPlainObject(obj)) {
    obj = mapKeys(obj, (_, k) => fn(k));
    return mapValues(obj, (val, _) => mapKeysDeep(val, fn));
  } else {
    // do not apply function to this simple value, as it's not a "key"
    return obj;
  }
}
