import React from 'react';
import _ from 'lodash';

const INSTALL_TIMES = {
  "0": "Instant",
  "0-30": "Under 30 minutes",
  "30-60": "30 - 60 minutes",
  "60-120": "1 - 2 hours",
  "120-more": "More than 2 hours"
}

const InstallTimeCol = ({item, scoreData}) => {
  if (!scoreData) return <td/>;
  let installTimes = scoreData.installTimes || scoreData.install_times || {};
  // which option had the most votes?
  let popular = "" + _.maxBy(_.keys(installTimes), k => +(installTimes[k] || 0));

  return <td>
    {INSTALL_TIMES[popular]}
  </td>
}

const InstallTimeRow = ({items, allScores}) => {
  if (_.isEmpty(allScores))
    return null;

  return (
    <tr className="install-time-row">
      <th>Install Time</th>
      {items.map((item) => <InstallTimeCol key={item.id} item={item} scoreData={allScores[item.id]}/>)}

    </tr>
  );
}

// "install_times": { "0": 1, "30-60": 34, "0-30": 27, "60-120": 20, "120-more": 3 }
// InstallTimeRow.propTypes = { }

export default InstallTimeRow;

