import React, {Component, useEffect, useState} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

/**
 * This will render either its children or a link that, when clicked, reveals the
 * component's hidden children. Upon doing so the link disappears.
 *
 * See also RevealContentButton, for a button that reveals content elsewhere on the page.
 *
 * @param defaultVisible
 * @param tabIndex
 * @param caption
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function HiddenContentWrapper({defaultVisible, tabIndex, caption, ...props}) {
  const {id = null, className = "", children} = props;
  const [visible, setVisible] = useState(defaultVisible);

  useEffect(() => {
    setVisible(defaultVisible)
  }, [defaultVisible]);

  let stateClass = visible ? 'shown' : 'not-shown';

  return (
    <div id={id} className={`${className} ${stateClass}`}>
      {visible ? children : <RevealContentLink caption={caption}
        tabIndex={tabIndex} setVisible={setVisible}/>}</div>
  )

}

function RevealContentLink({setVisible, tabIndex, caption}) {
  const handleClick = (e) => setVisible(true);

  return (
    <a onClick={handleClick} tabIndex={tabIndex}>{caption}</a>
  );
}

HiddenContentWrapper.propTypes = {
  tabIndex: PropTypes.number
};
