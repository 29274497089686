import React from 'react';
import _ from 'lodash';
import NewItemDisplay from "./NewItemDisplay";
import Headline from "./Headline";
import ModalCrossSells from "./ModalCrossSells";
import {getNewItems} from "../logic/cartHelpers";
import AddToCartReporter from "./AddToCartReporter";
import CatchErrors from "../../Utility/CatchErrors";

/**
 * Modernized 2023-04
 */
export default function DynamicCartDisplay(props) {
  let {handleClose, customerVehicle, newItemIds = [], shoppingCart, recommendations, cartItems} = props;

  cartItems ||= shoppingCart?.cartItems;

  // recommendations is hash with gridState, buckets, gridItems
  let newItems = getNewItems({shoppingCart, cartItems, newItemIds});

  if (_.isEmpty(newItems)) {
    console.error("cannot find new item in DynamicCartDisplay, using best guess");
    newItems = [_.last(cartItems)];
  }

  let newItem = newItems[0];

  return (
    <div className="dynamic-cart-display p-4">
      <CatchErrors>
        <Headline item={newItem} />

        {newItems && <NewItemDisplay
          newItem={newItem}
          shoppingCart={shoppingCart}
          handleClose={handleClose}
          customerVehicle={customerVehicle}
        />}

        <AddToCartReporter shoppingCart={shoppingCart}
          newItemIds={newItemIds}/>
      </CatchErrors>

      <CatchErrors>
        <ModalCrossSells
          recommendations={recommendations}
          customerVehicle={customerVehicle}
          item={newItem} />
      </CatchErrors>
    </div>
  );

}

