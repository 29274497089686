import SubmitButtonHandler from "./SubmitButtonHandler";

export default class SubmitPaypalCheckout extends SubmitButtonHandler {
  constructor({checkout, callbacks}) {
    super(_.merge({
      route: 'paypal_payment'
    }, {checkout, callbacks}));
  }

  call() {
    this.updateStoredCheckout();
  }
}
