import React from 'react';
import {capitalize} from "lodash";

const DEFAULT_PROMPTS = {
  fitment: "Your Vehicle Details",
  lloyd_unique: "Additional Details"
};

/**
 * 2024 OK
 *
 * The first option in each <select> - just a prompt.
 */
export default function DummyOption({
  loading = false, level, config = {}}) {

  // FIXME: 'loading' not currently supported
  if (loading) {
    return <option className="loading" value="">Loading...</option>
  }

  let prompt = DEFAULT_PROMPTS[level] || `Your Vehicle ${capitalize(level)}`;

  if (level == 'fitment') {
    const {fitmentLabel} = config;

    if (fitmentLabel && (fitmentLabel !== 'Fitment'))
      prompt = `Your Vehicle ${fitmentLabel}`
  }

  return <option className="placeholder" value="">{prompt}</option>;
}
