import EmbeddedConfig from "../../../lib/EmbeddedConfig";

export const SAVE_CART_CONFIG = {
  default: {},

  aagarage: {
    logo: "/img/logo/aag-logo-drk-med.png",
    alt: "AutoAccessoriesGarage"
  },

  napaonline: {
    logo: "/img/napaonline/logos/napa-primary.svg",
    alt: "NAPA Online"
  }
}

export function getSaveCartConfig(theme) {
  if (!theme) theme = EmbeddedConfig.getTheme();

  return Object.assign({},
    SAVE_CART_CONFIG["default"],
    SAVE_CART_CONFIG[theme],
  );
}

export function getSaveCartFlavour(shoppingCart) {

  if (_.isEmpty(shoppingCart)) {
    console.error("SaveCartInvoker: shopping cart param is blank, can only use fallback")
    return "fallback";
  }

  // 2022 test: https://marge.aagarage.net/accessories/mgp-brake-caliper-covers
  // coupon: user already put in coupon, and it actually works.
  if (shoppingCart.specialOffer) { // object: {id, name, ...}
    if (shoppingCart.subtotalSavings > 0)  // and has actual savings...
      return 'coupon';
  }

  let {maxPublicSavings = 0, maxPersonalSavings = 0} = shoppingCart;

  // keep the flavour names as snake_case because the CSS expects that.
  if (maxPublicSavings > 0)
    return 'public_savings';

  if (maxPersonalSavings > 0)
    return 'personal_savings';

  // I give up. Whatever.
  return 'fallback';
}
