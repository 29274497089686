import React, {useState, useEffect} from 'react';
import clsx from 'clsx';
import MenuBarItem from "./MenuBarItem";
import LocalUrlMaker from "../lib/LocalUrlMaker";
import OpenMenuPanel from "./OpenMenuPanel";
import {useSkeletonLoading} from "../hooks/useSkeletonLoading";

export default function MenuBar({
  menuConfig = {}, menuItems = [], classNames, ...props
}) {

  menuItems ||= [];
  menuConfig ||= {};

  classNames = menuConfig.classNames || "bg-brand-blue text-white py-2";
  let innerClasses = menuConfig.innerClasses || "container max-auto";

  const [openSection, setOpenSection] = useState(null);
  const [panelContent, setPanelContent] = useState(null);

  const myRef = useSkeletonLoading();

  const toggleMenuItem = item => {
    const {name, tubeSectionId} = item;
    const url = LocalUrlMaker.prefixedPath(`/tube_sections/${tubeSectionId}`);

    if (name === openSection) {
      setPanelContent(null);
      setOpenSection(null);
    } else {
      setPanelContent(`<div>i am the ${name} menu, my content lives at ${url}</div>`)
      setOpenSection(name);
    }
  }

  return <div ref={myRef}>
    <nav className={clsx(classNames)}>
      <div className={clsx(innerClasses)}>
        <ul className="inline-block">
          {menuItems.map((item, index) => <MenuBarItem
            key={index} item={item}
            toggleMenuItem={toggleMenuItem}
          />)}
        </ul>
      </div>
    </nav>
    {panelContent && <OpenMenuPanel name={openSection} html={panelContent}/>}
  </div>
}
