import React  from 'react';

import FieldsForItem from "./FieldsForItem";

/**
 * CartFields - generates HTML form fields for
 * the entire shopping cart (all items). Mostly just
 * loops through FieldsForItem.
 *
 * Used by EnabledFormBottom and SubmittingFormBottom
 */
export default function CartFields(props) {
  const {cart={}} = props;

  if (!cart.items)
    return null;

  let cartItems = cart.items.toArray();

  // Shows only when cart items are present.
  if (!cartItems[0])
    return null;

  return <div id="lloyd-item-section-bottom-summary">
    {cartItems.map((cartItem, n) =>
      <FieldsForItem
        {...props}
        key={cartItem.extended_part_number || n}
        cartItem={cartItem}
      />)
    }
  </div>
}
