import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import ReportOrderToGoogleAnalytics4 from "./ReportOrderToGoogleAnalytics4";
import ReportOrderToFacebook from "./ReportOrderToFacebook";
import CatchErrors from "../Utility/CatchErrors";

export default function ReportOrderCompleteApp(props) {
  const {order} = props;
  if (!order?.id)
    return null;

  return (
    <CatchErrors invisible={true}>
      <ReportOrderToGoogleAnalytics4 {...props} />
      <ReportOrderToFacebook {...props} />
    </CatchErrors>
  );
}
