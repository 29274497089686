export default class ScrollDirectionTracker {
  constructor() {
    this.diffs = [0];
    this.pos = this.position();
  }

  getList() {
    return this.diffs;
  }

  position() {
    // this.$w = $('body');  JQUERY REMOVED 2024
    // let value = this.$w.scrollTop(); JQUERY REMOVED 2024
    return window.scrollY;
  }

  getChange(p) {
    if (!p)
      p = this.position();

    let diff = p - this.pos;
    this.pos = p;
    return diff;
  }

  add() {
    let current = this.position();
    let diff = this.getChange(current);

    //console.log('add scroll: diff= ' + diff);
    // iphone generates stupid spurious scroll events in a direction *opposite* the
    // real direction.
    if (Math.abs(diff) < 11)
      return false;

    if (diff === 0)
      return false;

    let changed = this.addToList(diff);

    // console.log("scrollTracker: " + diff + ", changed = " + changed + " list = " + this.diffs);
    return changed;
  }

  // If the event has the same sign as the previous, merge them,
  // else append.
  addToList(diff) {
    let last = +(_.last(this.diffs));

    let sign = diff >= 0 ? 1 : -1;
    let last_sign = last >= 0 ? 1 : -1;

    if (sign === last_sign) {
      this.diffs[this.diffs.length - 1] = last + diff;
      return false;
    } else {
      this.diffs.push(diff);
      return true;
    }
  }
}
