import React from 'react';

//
// Empty divs but with CSS that makes it look like parallel throbbing lines.
// Styled in global/_page_components.scss, with animation in _spinner.scss
//
export default function PulsingLinesThrobber(props) {
  return (
    <div className="pulsing-lines-throbber container">
      <div className="overlay" />

      <div className="throbber">
        <div className="la-line-scale-pulse-out la-2x">
          <div/>
          <div/>
          <div/>
          <div/>
          <div/>
        </div>
      </div>
    </div>);
}
