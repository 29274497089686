import {boldlyLog} from "../Utility/logging";

export function facebookTrack(eventName, params) {
  return safeFbq('track', eventName, params);
}

export function safeFbq(...args) {
  const fbq = globalThis.fbq;
  if (fbq) {
    boldlyLog(`Reporting to Facebook: ${JSON.stringify(args, null, 2)}`)
    return fbq(...args);
  } else {
    console.info("facebook fbq missing (ignored)", args);
    return false;
  }
}
