import React from 'react';
import PropTypes from 'prop-types';
import _, {capitalize} from 'lodash';
import Money from "../../../lib/Money";
import CartOptions from "../models/CartOptions";

// Lloyd calls this "Binding", we call it "Border" to avoid conflicts
// with 'binding' in Ruby.


/**
 * 2023-02 modernized
 *
 * Presents one of two radio buttons with labels:
 *
 * (*) Standard matching nylon velour edge binding
 * ( ) Neon vinyl-edge binding ($15.00 per mat set)
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const BorderOption = (props) => {

  const {
    borderType, // plain / premium
    lloyd,    // complex object with methods, holds entire lloyd data set
    selected = false,
    selectOption
  } = props;

  // WARNING lloyd is an object with methods
  const label = lloyd.groupConfig(`lloyd.binding.${borderType}.label`,
      () => `${capitalize(borderType)} Binding`);

  const price = (borderType == 'plain') ? null :
    lloyd.groupConfig(`lloyd.binding.${borderType}.price`, 1000);

  const moneyPrice = price && (price > 0) && new Money(price);

  const handleSelect = (e) => {
    // borderType => "premium" or "plain"
    selectOption(CartOptions.BORDER_TYPE, borderType);

    // border => {id, name, small_image}
    if (borderType === 'plain') {
      selectOption(CartOptions.BORDER, CartOptions.STANDARD_BORDER);
    } // else, if premium, the color picker widget will set this.
  }

  return (
    <div id={"lloyd-borders-" + borderType} className={selected ? 'selected' : ''}>
      <label>
        <input type="radio" name="opt_border" value={borderType}
          checked={!!selected}
          onChange={handleSelect}/>
        <span>{label}</span> {' '}
        {moneyPrice && <em>({moneyPrice.toString()} per mat set)</em>}
      </label>
    </div>
  );
}

export default BorderOption;
