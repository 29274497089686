export function sortGridItems(items, sortOrder) {
  let reversing = _.includes(sortOrder, "_reverse");
  if (reversing)
    sortOrder = _.replace(sortOrder, "_reverse", "");

  items = _.sortBy(items, makeSorter(sortOrder))
  return reversing ? _.reverse(items) : items;
}

/**
 *
 * @param expr {string}
 * @returns {function(*): *}
 */
function makeSorter(expr) {
  if (expr === 'brand') {
    return function (i) {
      // [ bn (brand name), - rating ]
      return [("" + i.bn), -(+i.r)];
    }
  } else if (expr === "new") {
    return (i) => 0 - i.id;
  } else if (expr === "price") {
    // ascending by default
    return (i) => +i.price;
  } else if (expr === "rating") {
    // descending by default, so negate it.
    return (i) => -(+i.r);
  } else if (expr === "rel" || expr === "name" || expr === "pop") {
    // can be an attribute name instead of a function
    return (i) => i[expr];
  } else {
    console.error("GridSort unknown sort expr: " + expr);
    return (i) => i[expr]
  }
}


export function selectPageOfItems(items, pageSize = 24, currentPage = 1) {
  if (+currentPage < 1)
    currentPage = 1;

  if (pageSize == 'all')
    return items;

  let start = (+currentPage - 1) * +pageSize;

  let page =  _.slice(items, start, start + pageSize);
  if (_.isEmpty(page) && currentPage > 1)
    return selectPageOfItems(items, pageSize, currentPage - 1);

  return page;
}
