import React from "react";
import PropTypes from "prop-types";

const isDev = (process.env.NODE_ENV == 'development');

/**
 * One hidden input field for one cart item.
 * example: LloydCartField name='product_id' value=3977000001
 */
export default function LloydCartField(props) {

  let {name, value, prefix} = props;

  let fullName = `${prefix}[${name}]`

  if ((value === null) || (typeof value == 'undefined'))
    value = "";

  // // DEV ONLY - make them VISIBLE.
  // if (isDev) {
  //   return <div style={{float: 'left', width: '25%', height: '4rem'}}>
  //     <div style={{fontSize: "0.75rem"}}>{fullName}</div>
  //     <input type="text" name={fullName}
  //       readOnly={true}
  //       value={value} placeholder={fullName}/>
  //   </div>
  // }

  return <input type="hidden" name={fullName} readOnly={true} value={value}/>
}

LloydCartField.propTypes = {
  name: PropTypes.string.isRequired,
  prefix: PropTypes.string.isRequired,
  value: PropTypes.any
}
