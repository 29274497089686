
import AgentStatusCart from './AgentStatusCart';
import AgentStatusMain from './AgentStatusMain';
import AgentStatusReloader from './AgentStatusReloader';
import AgentStatusCallerIcon from './AgentStatusCallerIcon';

export default class AgentStatusDisplay extends React.Component {
  render() {
    let {phone_call, agent, callbacks} = this.props;
    if (phone_call) {
      return this.renderWithCaller();
    } else {
      return this.renderWithoutCaller();
    }
  }

  renderWithCaller() {
    // update legacy orange toolbar
    $('#agent-toolbar').addClass('active-caller');

    return <div className="with-caller">
      <AgentStatusCallerIcon {...this.props} />
      <AgentStatusMain {...this.props} />
      <AgentStatusCart {...this.props} />
      <AgentStatusReloader {...this.props} />
    </div>
  }

  renderWithoutCaller() {
    // update legacy orange toolbar
    $('#agent-toolbar').removeClass('active-caller');

    return <div className="without-caller">
      <AgentStatusDefaultIcon {...this.props} />
      <AgentStatusMain {...this.props} />
      <div className="agent-status-cart"><p> </p></div>
      <AgentStatusReloader {...this.props} />
    </div>

  }
}

// Default icon - shown when there is NO CALL, this
// just has a "person" icon and the agent's own extension number,
// which should hopefully validate their existence as a human being
// and provide some comfort in these bleak times.
class AgentStatusDefaultIcon extends React.Component {
  render() {
    let { agent={} } = this.props;

    return <div className="agent-status-icon">
      <span className="fa fa-user-secret fa-lg"
        title={agent.username} />
      <div className="subtitle">{agent.phone_extension || '???'}</div>
    </div>
  }
}


// class AgentStatusWithoutCaller extends React.Component {
//   render() {
//     let { callbacks } = this.props;
//
//     //
//     let timerProps = _.pick(this.props,
//       "seconds", "retry_seconds", "retry_interval", "callbacks" )
//
//     return <div className="no-caller">
//       <AgentStatusSelf agent={this.props.agent} />
//     </div>
//   }
// }

/*
<AgentStatusReloadTimer {...timerProps} />
<AgentStatusReloadButton callbacks={callbacks} />
<ThereIsNoPhone />
*/
