import _ from 'lodash';

// Not a component, just a plain old object.

export default class AgentStatusTimer {
  constructor(values = {}) {
    // merge in defaults
    values = _.extend({
        elapsed: 0,   // counts upward, never reset
        interval: 5,  // how often to trigger an event
        seconds: 5    // counts down to zero then becomes interval
    }, values);

    //console.log("new timer - "  + JSON.stringify(values));
    _.extend(this, values);
  }

  tick() {
    let elapsed = this.elapsed + 1;

    let seconds = this.seconds;
    if (seconds <= 0) {
      seconds = this.interval - 1;
    } else {
      seconds = seconds - 1;
    }

    return new AgentStatusTimer(
      _.merge({}, this, {
        elapsed: elapsed,
        seconds: seconds
      })
    )
  }

  isZero() {
    return (this.seconds == 0);
  }

}
