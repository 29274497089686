import LocalUrlMaker from "../../lib/LocalUrlMaker";

export default class CartPathHelper {
  constructor({cartCode, order}) {
    this.order = order;  // must have id, obfsucatedId
    this.cartCode = cartCode;
  }

  orderCompletePath() {
    let raw = this.rawOrderCompletePath();

    if (raw)
      return LocalUrlMaker.prefixedPath(raw);
  }

  rawOrderCompletePath() {
    const {order, cartCode} = this;

    if (!order || !cartCode || !order.id) {
      console.error("CartPathHelper: no order/cartCode")
      return null;
    }

    const base = `/new_checkout/${cartCode}/completions/${order.id}`
    const params = `code=${order.obfuscatedId}`;

    return `${base}?${params}`
  }
}
