import {buildItemCategories, buildItemListEvent} from "./googleAnalyticsItems";
import {safeGtag} from "./googleAnalyticsFour";

/**
 * From the product grid page, report an event to
 * Google Analytics.  The gridItems are product groups
 * but with a different JSON representation.
 *
 * @param gridItems
 * @param eventType
 * @param params
 * @param props
 * @returns {[string,string,{items: *}]}
 */
export function reportGridEvent(
  {
    gridItems,
    eventType = 'view_item_list',
    params,
    itemDetail = {},  //  overrides for the item
    props = {}  // React props from any Grid component
  }) {

  gridItems ||= props?.gridItems || [];

  const event = gridItemsToGoogleEvent({
    gridItems, eventType, params, itemDetail
  });

  safeGtag(...event);
  return event;
}

export function gridItemsToGoogleEvent(
  {
    gridItems,
    eventType = "view_item_list",
    params = {},
    itemDetail = {}
  }) {

  // gtag's remote back-end gives a 413 error if request is huge,
  // so I'm limiting it arbitrarily to 24, the default size of
  // the topcat page.
  gridItems = gridItems.slice(0, 24);

  const googleItems = gridItems.map(
    (item, index) => gridItemToGoogleItem({item, itemDetail, index})
  );

  let total = _.sum(
    googleItems.map(item => item?.price ? +item.price : 0));

  params = Object.assign({
    value: (total ? total.toFixed(2) : 0),
    currency: 'USD'
  }, params);

  return buildItemListEvent({
    items: googleItems,
    eventType,
    params
  })
}

export function gridItemToGoogleItem({item = {}, itemDetail = {}, index}) {
  let out = {
    item_id: item.id,
    item_name: item.name,
    // zero-based indexing as seen in
    // https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtag
    index: (index || 0),
    item_brand: item.bn || '',
    item_variant: "group", // not used at group level
    price: +(new Money(item.price).toDollars()),
    quantity: 1
  }

  buildItemCategories({out, categoryNames: item.cats});

  // anything in "itemDetail" is a high-priority override,
  // such as 'index' from handleGridItemClick
  return Object.assign(out, itemDetail)
}

export function reportGridItemClick({ item, flavour, index = -1}) {
  try {
    // clone item and add index
    item = Object.assign({}, item, {index});

    reportGridEvent({
      gridItems: [item],
      eventType: `grid_click_${flavour || 'unknown'}`,
      params: { index },
      itemDetail: { index }
    });
  } catch (err) {
    console.error(err);
  }
}
