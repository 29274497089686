import React from 'react';
import SaveCartRequestedContents from "./SaveCartRequestedContents";
import SaveCartFallbackContents from "./SaveCartFallbackContents";
import SaveCartSuccess from "../components/SaveCartSuccess";
import SaveCartFailed from "../components/SaveCartFailed";
import SaveCartPersonalSavingsContents from "./SaveCartPersonalSavingsContents";
import SaveCartPublicSavingsContents from "./SaveCartPublicSavingsContents";
import SaveCartCouponContents from "./SaveCartCouponContents";

const getContentsClass = function (flavour) {
  // keep flavours  as snake_case because the CSS expects that.
  let classes = {
    requested: SaveCartRequestedContents,

    // brands coupons work on: MGP, StopTech 5/10/15, Aries 5/5/5
    coupon: SaveCartCouponContents,
    //
    // for public savings, test with same brands but remove coupon.
    public_savings: SaveCartPublicSavingsContents,
    //
    // personal savings - with non-advertised coupons
    // BAK - 0/5/12
    personal_savings: SaveCartPersonalSavingsContents,

    // fallback - no discount possible (Weathertech)
    fallback: SaveCartFallbackContents
  };

  return classes[flavour] || classes.fallback;
}

export default function SaveCartContents(props) {
  const {saveCartStatus = {}} = props;

  const { flavour } = saveCartStatus;

  const {status} = saveCartStatus;

  if (status === 'error') {
    return <SaveCartFailed {...props} />
  } else if (status === 'success') {
    return <SaveCartSuccess {...props} />
  } else {
    const InnerClass = getContentsClass(flavour);
    return <InnerClass {...props} />;
  }
}

