import React from 'react';
import _ from 'lodash';

import DummyOption from "./DummyOption";

/**
 * 2024-OK
 *
 * Pull-down "select" widget inside a VehicleChooserLevel.
 */
export default function SelectWidget({
  config,
  onChoiceMade, // FIXME deprecated
  level, value, optionList,
  disabled = false
}) {

  // const loading = _.isEmpty(itemList);

  // onSelect callback - user has chosen a year/make/whatever
  // in this widget; communicate that back to the store.
  const handleSelect = (evt) => {
    let newValue = evt?.target?.value;

    // get fitment (or whatever) description
    const optionHash = _.keyBy(optionList, item => item.v);
    const item = optionHash[newValue] || optionHash[+newValue] || optionHash['' + newValue];

    const choice = {
      level,
      name: level,  // FIXME: deprecated
      value: newValue,
      description: item?.l
    };

    onChoiceMade(choice);
  }

  return (<div>
    <select value={value || ""}
      name={`select-vehicle-${level}`}
      disabled={disabled}
      onChange={handleSelect}>

      <DummyOption config={config} level={level} loading={false}/>

      {optionList.map((c) => <option key={c.v} value={c.v}>{c.l}</option>)}
    </select>
  </div>);
}

