import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

export default function AlternatePayment({visible, children}) {
  if (!visible)
    return null;

  return (
      <div className="alternate-payments">
      {children}
      </div>
  );
}
