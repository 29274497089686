/**
 * 2022-02 Chooser Options Slice 2.0.
 *
 * vehicleChoicesSlice depends on this, as its action creator
 * will invoke this slice's action creators too.
 *
 */
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import LocalUrlMaker from "../../lib/LocalUrlMaker";
import ParamsHelper from "../../lib/ParamsHelper";
import {camelizeKeys} from "../Helpers/transformObject";

const isDev = (process.env.NODE_ENV == 'development');


// /accessories/vehicle_selector/makes.json?year=2021
// /accessories/vehicle_selector/models.json?year=2021&make=220
// /accessories/vehicle_selector/fitments.json?group_id=3977&year=2021&make=220&model=22002&
// /accessories/vehicle_selector/lloyd_uniques.json?year=2021&make=220&model=22002&fitment=2438841
export const buildUrl = ({level, params}) => {
  // params may be a list of hashes; merge into one hash.
  params = new ParamsHelper(params).getParams();

  let usp = new URLSearchParams(params);

  let url = LocalUrlMaker.jsonPath(
    `/vehicle_selector/${level}s.json?${usp.toString()}`);

  return url;
}

export const loadChooserOptions = createAsyncThunk(
  "chooserOptions/load",
  ({level, params = {}, ...opts}, thunkAPI) => {

    if (!level)
      throw new Error("loadChooserOptions called with no level");

    const url = buildUrl({level, params});

    return fetch(url)
      .then(r => r.json())
      .then(data => thunkAPI.dispatch(
        chooserOptionsSlice.actions.setChooserOptions(data)));
  }
);

export const chooserOptionsSlice = createSlice({
  name: 'chooserOptionsSlice',
  initialState: {
    options: {
      years: expandYearList(2001, 1 + (new Date().getFullYear()))
    }
  },
  reducers: {
    setYearRange: (state, action) => {
      const {payload = {}} = action;
      const {yearRange} = payload;
      if (_.isEmpty(yearRange)) {
        console.error("setYearRange called with empty range");
      } else {
        state.options.years = expandYearList(yearRange[0], yearRange[1]);
      }
    },

    setChooserOptions: (state, action) => {
      const {payload = {}} = action;

      // on "reset", dump everything but years.
      if (payload.reset) {
        alert("set chooser options RESET forbidden")
        state.options = _.pick(state.options, 'years');
      }

      state.options = Object.assign(state.options, action.payload);

      // console.log("setChooserOptions: ", state.options);
      return state;
    }
  },
  extraReducers: builder => {
    // Listen in on a vehicleSlice action.
    // see also vehicleChoicesSlice!

    // 2024-04 migrate to clearVehicle
    builder.addCase(
      "vehicleSlice/clearVehicle",
      (state, action) => {
        console.log(`[chooserOptionsSlice] clearVehicle called: ${JSON.stringify(action.payload)}`)
        state.options = _.pick(state.options, 'years');
      }
    );
  }
});


function expandYearList(min, max) {
  return _.range(min, max + 1)
    .map(y => ({v: y, l: y}))
    .reverse(); // print in reverse order.
}

export default chooserOptionsSlice;
