import React from "react";
import $ from "jquery";

export default class AgentStatusCartExists extends React.Component  {
  customerCartCode() {
    let {caller_cart_helper} = this.props;
    return caller_cart_helper.customer_cart_code || 'MISSING!';
  }

  connectUrl(cart_code) {
    let {agent, phone_call={}} = this.props;

    let { pbx_job_id='' } = phone_call;

    return "/cart_engine_cart_picker/go?cart_code=" + cart_code +
      "&pbx_job_id=" + pbx_job_id + 
      "&utm_campaign=AAG-Phone&utm_medium=Phone-Order&utm_source=AAG-CSR-" +
      (agent ? agent.username : "unknown") ;
  }

  unlinkPhoneCall() {
    if (!confirm("Remove this Connect ID from this call?"))
      return false;

    let { phone_call } = this.props;

    let url = `/cart_engine/phone_calls/${phone_call.id}`;

    $.ajax({
      method: 'put',
      url: url,
      data: {
        "phone_call[shopping_cart_id]": ""
      },
      success: (response) => { window.location = '/cart/forget' },
      error: (response) => { alert("Error removing cart") }
    });
  }

  cartMatches() {
    let {caller_cart_helper} = this.props;

    return caller_cart_helper.linkedToCart();
  }

  getIcon() {
    if (this.cartMatches()) {
      return "fa-check-circle";
    } else {
      return "fa-arrow-circle-right";
    }
  }

  getMessage() {
    if (this.cartMatches()) {
      return "Connected";
    } else {
      return "Connect to Cart";
    }
  }

  render() {
    let { caller_cart_helper, phone_call  } = this.props;
    let status = caller_cart_helper.getStatus();

    let cart_code = caller_cart_helper.customer_cart_code || 'MISSING!';
    let url = this.connectUrl(cart_code);

    return <div className={ "agent-status-cart " + status }>
      <div className="cart-code">
        <span className={"icon fa " + this.getIcon()} />
        <a className="cart-link" href={url}>{cart_code}</a>
        {this.cartMatches() && <a className="fa fa-times" onClick={this.unlinkPhoneCall.bind(this)} /> }
      </div>
      {!this.cartMatches() &&
      <a className="cart-message" href={url}>
        {this.getMessage()}
      </a>
      }
    </div>

    // return <div className="agent-status-cart">
    // </div>
  }
}
