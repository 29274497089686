import React, {Component} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import ScrollTo from "../../lib/ScrollTo";

// 2024 OK
export default function ScrollToProductsButton({
  disabled = false,
  caption = "Select Options",
  icon = 'fa-arrow-down'}) {
  const handleClick = (evt) => {
    evt.preventDefault();

    const scroller = new ScrollTo($('#product-section'));     // FIXME jquery
    scroller.call();
  }

  return <div>
    <button
      className="btn scroll-to-products"
      onClick={handleClick}
      disabled={disabled}>
      {caption}
      <i className={`fa fa-lg ${icon}`}/>
    </button>
  </div>
}
