import $ from 'jquery';
import React from 'react';
import ScrollTo from '../../lib/ScrollTo';
import {initModals} from "../../ClientOnly/LocalApi";

export default class ReviewInserter {
  constructor({offset = 0, reviewCount} = {}) {
    this.offset = offset;
    this.reviewCount = reviewCount;
    this.batchSize = 10;  // will be updated if we load a partial batch
  }

  // given a block of html, parse reviews and insert them.
  insertHtml(text) {
    let nodes = this.parseHtml(text);
    this.insertAll(nodes);
    return nodes.length;
  }
  parseHtml(text) {
    let nodes = jQuery(text).find('.review').toArray();
    return nodes;
  }

  // MAIN ENTRY POINT - invoked by redux action
  insertAll(list) {
    console.log(`ReviewInserter: inserting ${list.length} reviews`)

    this.batchSize = list.length;

    this.$separator = this.addSeparator(list.length);

    this.target_boxes = this.buildInsertBoxes();

    for (let i in list) {
      this.insert(list[i], i, list.length);
    }

    initModals();

    //this.ready_scroll = true;
    // this.target_boxes.map( (box) => box.fadeIn(1000, () => this.scrollToNewReviews()));
    new ScrollTo(this.$separator).call();
  }


  buildInsertBoxes() {
    let $w = $('<div class="review-box-wrapper" />');
    $('#review-list').append($w);

    return [this.buildInsertBox('even', $w), this.buildInsertBox('odd', $w)]
  }

  buildInsertBox(parity, $w) {
    let box = $('<div  class="box-of-reviews ' + parity + '"/>');
    $w.append(box);
    return box;
  }

  addSeparator(len) {
    let start = this.offset + 1;
    let batchSize = this.batchSize || 10;
    let stop = start + batchSize - 1;

    let headline = `Reviews ${start}-${stop}`;
    if (this.reviewCount)
      headline += ` of ${this.reviewCount}`;

    let s = $(`<div class="review-boxes-separator"><h3>${headline}</h3><hr/></div>`);
    $('#review-list').append(s);
    return s;
  }


  //scrollToNewReviews() {
  //    if (this.ready_scroll) {
  //        this.ready_scroll = false;  // ensure we only do it once per load.
  //        let s = new ScrollTo(this.$separator)
  //        s.call();
  //    }
  //}

  insert(html, index, max) {
    let target_count = this.target_boxes.length;
    let capacity = Math.ceil(max / target_count);       // 10/2 => 5

    let column = (capacity < 1) ? 0 : Math.floor(index / capacity);

    // console.log(`item ${index} capacity ${capacity} target=${column}`);

    let $div = $(html);
    //let author = $div.find('author').text();
    //console.log("insert review by " + author);

    //$div.css('display', 'none');

    let target = this.target_boxes[column];
    target.append($div);
  }
}
