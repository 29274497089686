/**
 * 2023-05 safely read/write strings from local storage.
 *
 * @param key
 * @param defaultValue
 * @returns {null|string}
 */
export function readLocalStorage(key, defaultValue = null) {
  return safeReadStorage(key, defaultValue, globalThis.localStorage);
}

export function writeLocalStorage(key, value = null) {
  return safeWriteStorage(key, value, globalThis.localStorage);
}

/*
 * read/write SESSION storage - note that this per-tab, multiple tabs
 * on the same site will each have their own sessionStorage!
 */
export function readSessionStorage(key, defaultValue = null) {
  return safeReadStorage(key, defaultValue, globalThis.sessionStorage);
}

export function writeSessionStorage(key, value = null) {
  return safeWriteStorage(key, value, globalThis.sessionStorage);
}

/*
 * read local or session storage
 */
function safeReadStorage(key, defaultValue = null, storage = null) {
  if (!storage || !storage.getItem) {
    console.warn("localStorage not available")
    return defaultValue;
  }

  let value = storage.getItem(key);
  console.log(`local storage read ${key} = ${value}`)
  return (value === null) ? defaultValue : value;
}

function safeWriteStorage(key, value = null, storage = null) {
  if (!storage || !storage.setItem) {
    console.warn("localStorage not available")
    return false;
  }

  if (value === null) {
    localStorage.removeItem(key);
  } else {
    localStorage.setItem(key, value);
    console.log(`local storage write ${key} = ${value}`)
  }
}
