import React, {useState, useEffect} from 'react';

import CatchErrors from "../Utility/CatchErrors";
import CarouselContainer from "./CarouselContainer";

export default function CarouselApp({contents = {}, items = []}) {
  return <CatchErrors>
    <CarouselContainer config={contents} items={items}/>
  </CatchErrors>
}
