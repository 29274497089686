import React, {useState} from 'react';
import PropTypes from 'prop-types';
import clsx from "clsx";

/**
 * This component renders a button that, when clicked, will toggle the
 * visibility of something already present (butt hidden), elsewhere on the
 * page.  It does so by removing the '.hidden' class from its target.
 *
 * It is not a wrapper for hidden content - for that, use HiddenContentWrapper.
 *
 * Used on pages like:
 *
 * https://marge.aagarage.net/accessories/truck
 *
 * 2024-06 used solely in main page's vehicles section
 * 2024-06 legacy css removed
 */
export default function RevealContentButton({
  caption, icon, target, selfDestruct, classNames=[], ...props}) {

  const [clicked, setClicked] = useState(false);
  const [destroyed, setDestroyed] = useState(false);

  const handleClick = (evt) => {
    evt.preventDefault();

    const elem = document.querySelector(target);
    if (!elem) {
      console.error("no such element: " + target);
      return;
    }

    let classes = ('' + elem.className).split(/\s+/);

    elem.className = classes.filter((x) => x != 'hidden')
      .join(' ');

    setClicked(true);

    if (selfDestruct)
      setDestroyed(true);
  }

  if (destroyed)
    return null;

  return (
      <a onClick={handleClick} className={clsx(classNames)}>
        {caption}
        {icon && ' '}
        {icon && <span className={clsx(icon)}/>}
      </a>);
}

RevealContentButton.propTypes = {
  target: PropTypes.string.isRequired,
  caption: PropTypes.string
};

RevealContentButton.defaultProps = {
  target: '.hidden',
  caption: 'Show More',
  icon: false,
  selfDestruct: false
};
