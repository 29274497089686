
import _ from 'lodash';
import {createContext, useContext} from "react";

export const VehicleSelectorConfigContext = createContext({});

export const useVehicleSelectorConfig = function() {
  return useContext(VehicleSelectorConfigContext)
}

export const CORE_LEVELS = ['year', 'make', 'model'];

export function isCoreLevel(level) {
  return _.includes(CORE_LEVELS, level);
}

// get the non-core levels, for use in the DetailsOnlySelector
export function nonCoreLevels(config = {}) {
  const all = config?.levels || CORE_LEVELS;

  return _.filter(all, level => !isCoreLevel(level))
}

export function getEnabledChooserLevels({
  config = {}, levels, options = {}
}) {
  levels ||= config?.levels || CORE_LEVELS;

  return levels.filter((name) =>
    _.includes(CORE_LEVELS, name) ||      // YMM always present
    !_.isEmpty(options[name] || options[name + 's'])  // optional levels only if loaded
  );
}
