import React from 'react';
import _ from 'lodash';

const RestoreHiddenItems = ({hiddenItems, callbacks}) => {
  if (_.isEmpty(hiddenItems))
    return null;

  return <div className="restore-removed">
    <a onClick={callbacks.restoreAllItems}>
      Restore {hiddenItems.length} removed {' '}
      {hiddenItems.length==1 ? "item" : "items"}
    </a>
  </div>

}

export default RestoreHiddenItems;

