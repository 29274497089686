import hostedFields from 'braintree-web/hosted-fields';

import { fieldConfig, styles} from './BraintreeConfig';
import Address from "../logic/Address";

export default class BraintreeHostedFieldBuilder {
  /**
   *
   * @param client {Client} - braintree client
   */
  constructor({client, shippingAddress, billingAddress}) {
    this.client = client;
    this.shippingAddress = new Address(shippingAddress);

    this.billingAddress = billingAddress && (billingAddress.zip) ?
      new Address(billingAddress) : this.shippingAddress;
  }

  // Get the field config options for sending to Braintree.
  // Mix in values from the customer.
  getFieldConfig() {
    const { billingAddress } = this;

    let conf = _.extend({}, fieldConfig);

    if (billingAddress && billingAddress.getFullName) {
      conf.cardholderName = _.extend(conf.cardholderName, {
        prefill: billingAddress.getFullName()
      });

      // postal code widget is presently hidden.
      if (conf.postalCode) {
        conf.postalCode = _.extend(conf.postalCode, {
          prefill: billingAddress.zip
        })
      }
    }
    return conf;
  }

  // args for hostedFields.create()
  getOptions() {
    return {
      client: this.client,
      styles: styles,
      fields: this.getFieldConfig()
    }
  }

  /**
   * Invoke Braintree's hostedFields.create
   * @returns {Promise}
   */
  call() {
    return hostedFields.create(this.getOptions());
      //.then( h => this.configure(h) );
  }

  // configure(fields) {
  //   console.log("configure hosted fields");
  //   return fields;
  // }
}
