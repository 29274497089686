import React, {useMemo} from 'react';
import PropTypes from 'prop-types';

import PaymentTypePicker from "./PaymentTypePicker";
import DeviceDataRequestor from "./DeviceDataRequestor";
import DynamicPaymentPanel from "./DynamicPaymentPanel";
import {getEnabledPaymentTypes} from "../../../ShoppingCart/services/checkoutConfig";

const isDev = (process.env.NODE_ENV == 'development');

export default function PaymentPanel(props) {
  const {dispatch, checkout, callbacks, paymentType, braintreeClient, status, currentAgent} = props;

  const shoppingCart = props.shoppingCart || checkout;

  const enabledPaymentTypes = useMemo(() => {
    return getEnabledPaymentTypes({shoppingCart, currentAgent})
  }, [shoppingCart, currentAgent]);

  return (
    <div id="checkout-payment-panel" className="checkout-form-section">
      <h2>Payment Details</h2>

      <h3>Payment Method:</h3>

      <PaymentTypePicker
        selectedType={paymentType}
        allTypes={enabledPaymentTypes}
        callbacks={callbacks}
      />

      <DynamicPaymentPanel
        paymentType={paymentType}
        dispatch={dispatch}
        braintreeClient={braintreeClient}
        status={status}
        checkout={checkout}
        callbacks={callbacks}
        currentAgent={currentAgent}
      />

      {
        /* send device data once we have it - but not if we are agent, don't
         overwrite customer device info with agent device info */
        (!currentAgent) && <DeviceDataRequestor
          cartCode={checkout.cart_code}
          braintreeClient={braintreeClient} callbacks={callbacks}/>
      }
    </div>
  );

}

PaymentPanel.propTypes = {
  allTypes: PropTypes.object,
  status: PropTypes.string,
  braintreeClient: PropTypes.object
};

PaymentPanel.defaultProps = {
  allTypes: {credit_card: true, paypal: true, affirm: true}
};
