import React from 'react';

import ActiveGridSizeControl from "./ActiveGridSizeControl";
import clsx from "clsx";

export default function GridSizeControl({
  gridId,
  placement = '', // top/bottom
  className = '',
  gridItemCount = 0, // total items loaded on page
  filteredItemCount = 0, // how many survived the filters
  visibleItemCount = 0, // how many on the current page
  gridState = {}
}) {

  className ||= "hidden md:inline-block";

  // 24 is the smallest option selectable for page size,
  // suppress widget if we don't have enough
  if (filteredItemCount <= 24) {
    return <DummyGridSizeControl
      className={className}
      placement={placement}
      count={visibleItemCount}/>
  } else {
    return <ActiveGridSizeControl
      gridId={gridId}
      placement={placement}
      className={className}
      gridItemCount={gridItemCount}
      filteredItemCount={filteredItemCount}
      visibleItemCount={visibleItemCount}
      gridState={gridState}
    />
  }
}

//
// Render this GridSizeControl when there
// are too few items in the grid to make
// a size control worthwhile.
//
function DummyGridSizeControl({className, count}) {
  className = clsx('grid-size-control ', className);

  // fallback - if count is missing - don't pretend we know it
  if ((+count) <= 0) {
    return <div className={className}>
      Showing all products
    </div>
  }

  return (
    <div className={className}>
      Showing {' '}
      <strong>all</strong>
      {' '} of {' '}
      <strong>{count}</strong>
      {' '} products
    </div>
  );
}
