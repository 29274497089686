import { produce } from 'immer';

/**
 * Scrub the Checkout object before doing an Ajax POST with it;
 * eliminate the stuff that the back end does not care about.
 *
 * This is really just about simplifying the JSON for performance.
 */
export default class ScrubCheckoutForPost {
  constructor(checkout) {
    this.checkout = checkout;
  }

  getCleanCheckout() {
    if (!this.cleaned)
      this.cleaned = this.cleanCheckout();
    return this.cleaned;
  }

  cleanCheckout() {
    return produce(this.checkout, checkout => {
      delete checkout.version;
      delete checkout.shippingAddressWarnings;
      delete checkout.billingAddressWarnings;
      delete checkout.updatedAt;
      delete checkout.cartItemsCount;
      delete checkout.paymentService;
      delete checkout.totals;

      //this.cleanAddress(checkout.billingAddress);
      this.cleanAddress(checkout.shippingAddress);

      if (checkout.sameAddress) {
        // don't send billing address at all; back end will use shipping address here.
        delete checkout.billingAddress;
      } else {
        this.cleanAddress(checkout.billingAddress);
      }
    });
  }

  /*
  * mutates, don't use this without
  *  wrapping in an immer 'produce' call
  */
  cleanAddress(addr) {
    if (!addr)
      return {};

    // this is a hash from the CartEngine::State record, we care only about state_code not the grotty details
    delete addr.state;

    // update tracking hashes used by EditObject
    delete addr.touched;
    delete addr.errors;
    delete addr.changes;
  }
}
