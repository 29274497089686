import React, {useEffect, useRef} from "react";
import clsx from "clsx";

/**
 * 2023-02 used by ErrorModal, HiddenContentContainer, DynamicCartModal...
 *
 * @param id
 * @param classNames
 * @param handleClose
 * @param children
 * @param rest
 * @returns {JSX.Element}
 * @constructor
 */
export default function Modal(
  {
    id,
    classNames = [],
    handleClose,
    children,
    contentNode,
  }) {

  const modalRef = useRef(null);
  const contentRef = useRef(null);

  // When we have a ref, see if there is any content to inject into it.
  useEffect(() => {
    if (!modalRef?.current) return;
    if (!contentRef?.current) return;

    if (contentNode) {
      contentRef.current.append(contentNode);

      // call this function after it becomes visible.
      if (typeof(contentNode.onShowModal) == 'function') {
        contentNode.onShowModal({modalRef, contentRef})
      }
    }
  }, [modalRef?.current, contentRef?.current]);



  if (!handleClose) {
    console.error("Modal component called without handleClose");
    handleClose = (e) => {
      throw new Error("Modal component called without handleClose");
    }
  }

  classNames = clsx("open-modal", "modal", classNames);

  return (
    <div ref={modalRef} id={id || ""} className={classNames}>
      <div className="modal-fade-screen" onClick={handleClose}>
        <div className="quasimodal-inner" onClick={(e) => e.stopPropagation()}>
          <div className="quasimodal-close" onClick={handleClose}></div>

          <div className="modal-content" ref={contentRef}>{children}</div>
        </div>
      </div>
    </div>)
}
