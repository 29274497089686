
export default class AgentStatusMessage extends React.Component {

  render() {
    let { phone_call, caller_cart_helper  } = this.props;
    let childProps = { phone_call, caller_cart_helper };

    // No call.
    if (!phone_call || !caller_cart_helper) {
      return <AgentStatusMessageNoCall />;
    }

    // Customer has cart. Don't care what Agent has.
    if (! caller_cart_helper.hasCart()) {
      return <AgentStatusMessageNoCart {...childProps} />
    }

    if (caller_cart_helper.linkedToCart()) {
      return <AgentStatusMessageCartLinked {...childProps} />
    } else {
      return <AgentStatusMessageWrongCart {...childProps} />
    }
  }

}



// No call in progress.
class AgentStatusMessageNoCall extends React.Component {
  render() {
    return <div className="message disconnected">
      Call not loaded - hit refresh button if you are on a call.
    </div>
  }
}

// Caller has NO CART
class AgentStatusMessageNoCart extends React.Component {
  render() {
    return <div className="message cart-missing">
      <span className="fa fa-info-circle" />{' '}
      Ask the user for their <b>ConnectID</b> and enter it at
      right to be connected to their cart.
    </div>;
  }
}

// Agent has Wrong Cart
class AgentStatusMessageWrongCart extends React.Component {

  render() {
    let {caller_cart_helper} = this.props;

    if (caller_cart_helper.agent_cart_id)
      return this.renderDifferentCart();
    else
      return this.renderNoCart();
  }

  renderDifferentCart() {
    return <div className="message linked-wrong-cart">
      CAUTION: you are connected to a <u>different cart</u>.
      Click ConnectID at right to connect to this customer's cart.
    </div>;
  }

  renderNoCart() {
    return <div className="message linked-wrong-cart">
      You are not connected to a shopping cart.
      Click ConnectID at right to connect to this customer's cart.
    </div>;
  }
}

// Agent is successfully linked to right cart
class AgentStatusMessageCartLinked extends React.Component {
  render() {
    return <div className="message linked">
      <span className="fa fa-check-circle" />{' '}
      You are linked to this customer's cart.
    </div>;
  }
}
