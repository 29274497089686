import React from 'react';
import _ from 'lodash';

const RatingBar = ({name, rating}) => {
  let percent = isNaN(rating) ? 0 : (+rating) * 10;
  let stars = (rating/2).toFixed(1);

  let caption = name ? `${_.capitalize(name)}: ${stars}` : `${stars}`;

  if (percent < 1)
    return null;

  return (
    <div className="progress-bar-indication">
    <span className="meter" style={ {width: `${percent}%`} }>
      <p>{caption}</p>
    </span>
    </div>
  );

}

// StarWidget.propTypes = { }

export default RatingBar;

