/*
 * These functions are installed into window on startup by init.js
 * invoking installGlobalFunctions() (also defined here)
 *
 */


/**
 * toggleClass(selector, classNames)
 *
 * 2024-03 used by SPD top navigation button to reveal/conceal the blue menu
 * as a pull-down.
 *
 * @param target
 * @param classNames
 * @return undefined
 */
export function toggleClass(target, classNames) {
  if (!Array.isArray(classNames))
    classNames = (""+classNames).split(/\s+/);

  let list = document.querySelectorAll(target); // NodeList
  list.forEach( node => { // node is Element
    for (let klass of classNames) {
      if (node.classList.contains(klass)) {
        node.classList.remove(klass);
      } else {
        node.classList.add(klass);
      }
    }
  })
}

const GLOBAL_FUNCTIONS = { toggleClass };

export function installGlobalFunctions(w) {
  Object.assign(w || globalThis, GLOBAL_FUNCTIONS);
}
