import React, {useEffect} from 'react';
import {isNola} from "../../../lib/EmbeddedConfig";
import {sendViewCartEvent} from "../../../Analytics/adobeCartEvents";

export default function AdobeViewCartReporter({shoppingCart, cartItems, ...props}) {

  shoppingCart ||= {cartItems: []};
  cartItems ||= shoppingCart.cartItems;

  // Do this every time the cart is refreshed from the back end - after every
  // add/remove, qty change, price change, coupon code entry.
  useEffect( () => {
    if (!isNola()) return;

    if (_.isEmpty(cartItems)) return;

    const event = sendViewCartEvent({
      shoppingCart, cartItems
    });

    //setReported(event);
  }, [shoppingCart.updatedAt || "" ]);


  // return (
  //   <div className="debug">
  //     <h3>AdobeViewCartReporter</h3>
  //     <pre>{JSON.stringify(reported, null, 2)}</pre>
  //   </div>
  // );
  return null;
}
