import React, {Component} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import SaveCartPopup from "./SaveCartPopup";
import {hideSaveCart, postSaveCart, saveCartSlice, showSaveCart} from "../../ReduxStore/ShoppingCart/saveCartSlice";
import EmbeddedConfig from "../../lib/EmbeddedConfig";
import {addRailsPrefix, snakeifyKeys} from "../../ReduxStore/Helpers/transformObject";
import SaveCartInvoker from "../../ShoppingCart/components/save_cart/SaveCartInvoker";
import SaveCartValidator from "../services/SaveCartValidator";
import {getStashedUser} from "../../ReduxStore/userSlice";

/**
 * SaveCartContainer is rendered by SaveCartApp which is physically just above
 * the shopping cart in the page.  It provides logic for, and holds, both the
 * SaveCartInvoker (an invisible component) and the SaveCartPopup (a modal
 * that exists only when needed).
 */
export default class SaveCartContainer extends Component {
// props :["authToken","saveCartStatus","shoppingCart","dispatch"]
  static propTypes = {
    shoppingCart: PropTypes.object.isRequired
  };

  static defaultProps = {};

  state = {}

  callbacks = {
    // 2022-07 invoke the showSaveCart() redux action,
    // which creates a new saveCartStatus in the saveCart store.
    openSaveCart: (opts = {}) => {
      this.props.dispatch(showSaveCart(opts));
    },

    close: (evt) => {
      this.props.dispatch(hideSaveCart())
    },

    /**
     * Update local state
     *
     * @param changes a hash of { field: value, field: value...}
     */
    updateContents: (changes = {}) => {
      console.error("contents were: " +
        JSON.stringify(this.state.contents));

      let contents = _.extend({},
        this.state.contents, changes);

      this.setState({contents: contents})
    },

    submit: (evt) => {
      const {dispatch, shoppingCart, authToken, saveCartStatus = {}} = this.props;

      let val = new SaveCartValidator({
        saveCartStatus, inputs: this.state.contents
      });

      if (!val.valid()) {
        let status = Object.assign({},
          saveCartStatus, {
            error: val.getMessage() || "Please check your inputs and try again."
          })
        dispatch(
          saveCartSlice.actions.update({saveCartStatus: status}));
        return;
      }

      // clear error
      let status = Object.assign({}, saveCartStatus, { error: null})
      dispatch(saveCartSlice.actions.update({saveCartStatus: status}));

      // post the form contents
      let params = Object.assign({
          cart_code: shoppingCart.cartCode,
          authenticity_token: authToken,
          'saved_cart[flavour]': saveCartStatus.flavour,
        }, addRailsPrefix(snakeifyKeys(this.state.contents), 'saved_cart')
      );

      dispatch(postSaveCart({
        params: params,
        authToken: this.props.authToken,
      }));
    }
  }
  componentDidMount() { this.resetState() }
  componentDidUpdate(prevProps) {
    let wasOpen = _.get(prevProps, 'saveCartStatus.open');
    let isOpen = _.get(this.props, 'saveCartStatus.open');

    if (isOpen && !wasOpen) {
      this.resetState();
    }
  }

  resetState() {
    // let cartCode = _.get(this.props, 'shoppingCart.cartCode');

    let config = EmbeddedConfig.instance();

    let newState = {
      // flavour: _.get(this.props, 'saveCartStatus.flavour'),
      // pristine: true,
      // valid: true,
      theme: config.fetch('theme') || config.fetch('name'),
      contents: {
        name: "",
        email: this.getDefaultEmail(),
        mailingListSignup: "",
        // cartCode,
        // authenticityToken: this.props.authToken
      }
    };

    console.log("SaveCart: ResetState; newState=", newState);

    this.setState(newState)
  }

  getDefaultEmail() {
    console.log("calling legacy getStashedUser...")
    let user = getStashedUser();
    return user?.email;
  }

  render() {
    let {saveCartStatus, currentAgent, shoppingCart} = this.props;

    console.log("SAVE CART CONTAINER RENDER - ", saveCartStatus);

    return (
      <div className="save-cart-container">
        {!currentAgent && <SaveCartInvoker shoppingCart={shoppingCart} saveCartStatus={saveCartStatus}
          openSaveCart={this.callbacks.openSaveCart}/>}

        {saveCartStatus.open &&
          <SaveCartPopup {...this.props} {...this.state} callbacks={this.callbacks}/>}
      </div>
    );
  }
}

