import LocalUrlMaker from "../lib/LocalUrlMaker";

function promptText(vehicleModel) {
  if (!vehicleModel)
    return "Select Your Vehicle";

  const { name, shortName, makeName, shortMakeName } = vehicleModel;

  return `What year is your ${shortMakeName || makeName} ${shortName || name}?`
}

export default function YearPrompt({vehicleModel, prompt, uplink = '/'}) {
  prompt ||= promptText(vehicleModel);

  uplink = LocalUrlMaker.prefixedPath(uplink);

  return <div className="prompt">
    {prompt}
    <a className="change-link" href={uplink}>
      Change
    </a>
  </div>
}
