export function getCurrentSecond() {
  return Math.floor(new Date().getTime() / 1000);
}

const DAY = 24 * 3600;

export function isOpenNow({
  elapsed, startupSecond, openSecond, closeSecond}) {

  // if (isNaN(startupSecond) || isNaN(elapsed) ||
  //   isNaN(openSecond) || isNaN(closeSecond)) {
  //   console.warn("Invalid")
  //   return false;
  // }

  const thisSecond = (+startupSecond + elapsed) % DAY;

  return ( 
    (thisSecond >= +openSecond) &&
    (thisSecond < +closeSecond) )
}
