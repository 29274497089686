import React from 'react';
import {IncompatibleHeadline} from "./headlines";
import clsx from "clsx";
import {resetVehicle} from "../logic/callbacks";

export default function IncompatibleVehicleDisplay(props) {
  const {dispatch, customerVehicle, callbacks, config = {}} = props;

  const {parentName, parentPath} = (config || {});

  const  classes = clsx(
    "vehicle-selector",
    "vehicle-selector-incompatible",
    "vehicle-selector-" + (config.layout || 'stacked')
  );

  const handleReset = evt => {
    resetVehicle({dispatch});
  }

  return (
    <div id="vehicle-selector" className={classes}>
      <IncompatibleHeadline customerVehicle={customerVehicle}/>

      {parentPath && parentName &&
        <a href={parentPath}> Click here for
          <strong> {parentName} </strong> that fit your vehicle
        </a>
      }

      <div className="change-it">
        <a className="link" onClick={handleReset}>Change Vehicle</a>
      </div>

    </div>);
}
