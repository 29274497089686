import React from 'react';
import _ from 'lodash';

const AttributeCell = ({item, valueList}) => {
  if (_.isEmpty(valueList)) {
    return <td></td>
  }

  return (
    <td>
      {valueList.map( (val, i) => <div key={i}>{val.content}</div>)}
    </td>
  );
}

// AttributeCell.propTypes = { }

export default AttributeCell;

