import React, {Component} from 'react';
import _ from 'lodash';

import EmptyProductsSection from "../components/GroupItems/EmptyProductsSection";
import GroupProductsUI from "../components/GroupItems/GroupProductsUI";
import PropTypes from "prop-types";
import VehicleChangeWatcher from "../components/GroupItems/VehicleChangeWatcher";
import GroupPageType from "../models/GroupPageType";
import VehicleChangeHandler from "../logic/VehicleChangeHandler";
import {loadGroupItems} from "../../ReduxStore/ProductGroupPage/groupItemsSlice";
import ReportGroupView from "../components/Reporting/ReportGroupView";

// ["group_id","page_type","groupProperties","customerVehicle","clusterIndex",
// "item_applications","products","productDetail","dispatch"]

export default class GroupProductsContainer extends Component {
  static propTypes = {
    // static - from the Rails template, via GroupProductsApp
    id: PropTypes.string,
    groupId: PropTypes.number.isRequired,
    pageType: PropTypes.string.isRequired,
    groupProperties: PropTypes.object.isRequired,
    productGroup: PropTypes.object.isRequired,

    // dynamic
    // loading: PropTypes.bool.isRequired, // FIXME 2021: Failing
    dispatch: PropTypes.func.isRequired,

    customerVehicle: PropTypes.object,

    // pre-product-loading:
    // customerVehicle dispatch
    // after loading:
    // itemsUrl clusterIndex itemApplications products lloydAttributes lloydProducts
  }

  static defaultProps = {
    id: 'product-section'
  }

  state = {};

  // changes is a hash like:

  // {"vehicle_model_id":[null,19267], "fitment": [old,new]}
  onVehicleChanged = (changes) => {
    let {pageType, customerVehicle, dispatch, groupId} = this.props;

    let handler = new VehicleChangeHandler({
      changes, customerVehicle, pageType, groupId, location: globalThis.location
    });

    handler.call({dispatch});
  }

  componentDidMount() {
    let pageType = new GroupPageType(this.props.pageType);

    this.setState({watchedFields: pageType.watchedFields()})

    if (pageType.universal()) {
      this.loadUniversalItems();
    }
  }

  loadUniversalItems() {
    let { groupId, dispatch} = this.props;

    dispatch(loadGroupItems({params: {group_id: groupId}}));
  }

  getChildProps() {
    return _.omit(this.props, 'id', 'loading');
  }

  isEmpty() {
    let {itemApplications = [], loading} = this.props;
    // console.log(`GroupProductsContainer: loading=${loading} and apps={typeof itemApplications}
    // size=${_.size(itemApplications)}`);

    // Items not yet loaded.
    return (loading || _.isEmpty(itemApplications));
  }

  // Loaded? Render the UI, else show a placeholder.
  render() {
    let {id, loading, productGroup, products} = this.props;
    let {watchedFields} = this.state;

    let cp = this.getChildProps();

    // if (this.isEmpty()) {
    //   return <EmptyProductsSection id={id} loading={loading} {...cp}/>
    // }

    const {customerVehicle} = this.props;

    return <section id={id} className="product-section">
      <ReportGroupView productGroup={this.props.productGroup} />

      {this.isEmpty() ?
        <EmptyProductsSection id={id} loading={loading} {...cp}/> :
        <GroupProductsUI {...cp} />
      }

      {watchedFields && <VehicleChangeWatcher
        watchedFields={watchedFields}
        customerVehicle={customerVehicle}
        onVehicleChanged={this.onVehicleChanged}/>}
    </section>
  }

}

