import React from 'react';
import _ from 'lodash';

// 2024-04 OK
export default function YearSelector({onSelectYear, years}) {
  const yearList = yearRange(years);   // ensure populated and sorted

  const handleChange = evt => {
    let year = evt && evt.target ? evt.target.value : null;
    onSelectYear(+year);
  }

  return (
    <div id="select-year" className="selector-level selector-enabled selector-active">
        <span className="fa-wrapper">
          <span className="fa fa-arrow-right"/>
        </span>
      <div>
        <select name="year" onChange={handleChange}>
          <option>Your Vehicle Year</option>
          {yearList.map((y) => <option key={y} value={y}>{y}</option>)}
        </select>
      </div>
    </div>);
}

function yearRange(years) {
  if (_.isEmpty(years)) {
    const thisYear = new Date().getFullYear() + 1;
    years = [...Array(40).keys()].map(i => thisYear - i);
  }

  return _(years).sort().reverse().value();
}

