import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

/**
 * Label, to be positioned above the field.
 * The superscript asterisk indicates required fields, which is most of them.
 *
 */
export default function CheckoutFormLabel({id=null, label, target, optional = false}) {
  return <label className="stealth" htmlFor={target} id={id}>
    {label}
    {!optional && <sup>*</sup>}
  </label>
}
