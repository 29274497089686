import $ from 'jquery';

/**
 * Scroll to a particular item on the page.
 *
 * scroller = new ScrollTo($jquery-selector, opts); scroller.call()
 *
 * new ScrollTo(
 *     $('#product-section'),
 *     {
 *       duration: '500',
 *       easing: 'easeInOut'
 *     }
 * ).call()
 *
 * options:
 *  duration (default 1000)
 *  easing (default: easeOutExpo)
 */
export default class ScrollTo {
  // 2024-04 FIXME JQUERY

  constructor($target, options = {}) {
        this.$target = $target;
        this.options = options || {};
    }

    offset() {
        if (! this.$target)
            return null;

        return this.$target.offset();
    }

    topOffset() {
        let off = this.offset();
        if (off)
            return +off.top;

        return null;
    }

    call() {
        let off = this.topOffset();
        if (off != null)
            this.scrollToPosition(off);
    }

    scrollToPosition(offset) {
        $('html,body').animate({
                scrollTop: offset
            },
            this.options.duration || 1000,
            // this.options.easing || 'easeOutExpo'
        );
    }
}
