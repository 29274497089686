import _ from 'lodash';

export const CORE_LEVELS = ["year", "make", "model"];

//
// Examine ItemChooser properties (mostly choices) to determine
// whether status is complete. This is used for selecting whether
// to display the selector at all, and whether it is already complete on startup.
//
export default class ChooserStatus {
  constructor({levels, customerVehicle, choices, options, productDetail}) {
    this.levels = levels || CORE_LEVELS;
    this.customerVehicle = customerVehicle;
    this.choices = choices;
    this.options = options || {};
    this.productDetail = productDetail || {};
  }

  hasVehicle() {
    return !!(this.customerVehicle && this.customerVehicle.id);
  }

  hasCoreLevels() {
    return this.hasLevels(CORE_LEVELS);
  }

  hasAllLevels() {
    return this.hasLevels(this.levels);
  }

  hasLevels(levels) {
    let {choices} = this;

    return _.every(levels, (level) => choices[level]);
  }

  isComplete() {
    return this.hasVehicle() && this.hasAllLevels();
  }

  isIncompatible() {
    if (this.productDetail.productLoadStatus === 'fail')
      return true;

    return !!(this.getFailedLevel());
  }

  getFailedLevel() {
    let lastLevel = this.options.loaded;
    if (!lastLevel) return false;

    let opts = this.options[lastLevel] || this.options[lastLevel + 's'];
    if (_.isEmpty(opts))
      return lastLevel;

    return null;
  }

  productStatus() {
    return this.productDetail.productLoadStatus || 'never';
  }

}
