import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import { isEmpty } from 'lodash';

const CURRENT_USER_KEY = "aag_user";

function createInitialState() {
  try {
    return {
      // customer: // FUTURE: customer as managed by back-end.

      // emailUser - user as managed by Justuno/Klaviyo
      emailUser: getStashedUser(CURRENT_USER_KEY)
    }
  } catch (err) {
    console.error("userSlice#createInitialState", err);
  }
}

// on STARTUP, read from localStorage!
const initialState = createInitialState();


// Stores the user.
export const userSlice = createSlice({
  name: 'userSlice',
  initialState,
  reducers: {
    // merge in anything
    mergeState: (state, action) => {
      const { payload } = action;
      console.log("- userSlice.mergeState", payload);
      Object.assign(state, payload);
    },
  }
});

/**
 * This is the main way that a user from Justuno or Klaviyo is injected
 * into Redux (and also LocalStorage, for the next time the page loads).
 *
 * setEmailUser({user: { stuff }}
 *
 * @type {AsyncThunk<void, void, AsyncThunkConfig>}
 */
export const setEmailUser = createAsyncThunk(
  "setEmailUser",
  (payload, thunkAPI) => {

    // if email member is missing, consider it null
    const user = (payload && payload.email) ? payload : null;

    console.log(`userSlice.setEmailUser: ${JSON.stringify(user, null, 2)}`)

    stashUser(user, CURRENT_USER_KEY);

    thunkAPI.dispatch(userSlice.actions.mergeState({emailUser: user}));
  }
);


/***
 * @param user {
 *   "email": "matt@autoaccessoriesgarage.com",
 *   "source": "ju",
 *   "updatedAt": "Tue Aug 27 2024 12:18:20 GMT-0700 (Pacific Daylight Time)"
 * }
 * @param key localStorage key
 */
export function stashUser(user, key = CURRENT_USER_KEY) {
  if (isEmpty(user)) {
    localStorage?.removeItem(key);
  } else {
    user.updatedAt ||= new Date().toISOString();
    localStorage?.setItem(key, JSON.stringify(user));
  }

  return user;
}

/**
 * Read from LocalStorage. This is used on startup.
 *
 * @returns {any|null}
 */
export function getStashedUser(key = CURRENT_USER_KEY) {
  try {
    const encoded = localStorage?.getItem(key);
    if (!encoded)
      return null;

    return JSON.parse(encoded);
  } catch (err) {
    return null;
  }
}


export default userSlice;
