import React, {useEffect, useState} from 'react';
import {useReportCartToGoogleOnce} from "../../../Analytics/googleShoppingCart";

const GoogleViewCartReporter = ({shoppingCart, ...props}) => {

  useReportCartToGoogleOnce({shoppingCart});

  return null;
}

// GoogleViewCartReporter.propTypes = { }

export default GoogleViewCartReporter;

