/**
 * Given a shipping address, find any possible reasons why
 * we can't ship to it.
 *
 * example:
 *   let impediments = new ShippingAddressAnalyser(this.props.checkout).shippingImpediments();
 */
export default class ShippingAddressAnalyser {
  constructor({shippingAddress}) {
    this.shippingAddress = shippingAddress;
  }

  shippingImpediments() {
    const {shippingAddress} = this;

    if (!shippingAddress) {
      return [ "Shipping Address is missing - please go back and enter an address."]
    }

    const { state={} } = shippingAddress;

    let { country_code='US', country_name } = shippingAddress;

    let list = [];
    if (country_code != 'US') {
      let msg = "Sorry, we cannot ship to " + (country_name ? country_name : country_code) + ".";
      list.push(msg);
    }

    if (state && state.hasOwnProperty("can_ship")) {
      if (state.can_ship === false) {
        let msg = `Sorry, we cannot ship to ${state.name}.`;
        if (shippingAddress.city === 'APO')
          msg = `Sorry, we cannot ship to APO or overseas addresses.`;
        list.push(msg);
      }
    }

    return list;
  }
}
