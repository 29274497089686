import React from 'react';
import VehicleChooserLevel from "./VehicleChooserLevel";
import FitmentNote from "./FitmentNote";

import {getEnabledChooserLevels, useVehicleSelectorConfig} from "../logic/chooserConfig";
import {useSkeletonLoading} from "../../hooks/useSkeletonLoading";

export default function FullVehicleSelector(props) {
  const { options, onChoiceMade, } = props;
  const config = useVehicleSelectorConfig();

  const myRef = useSkeletonLoading();

  const levels = getEnabledChooserLevels({config, options});

  let classes =
    "vehicle-selector vehicle-selector-incomplete mt-4 vehicle-selector-" +
    (config.layout || 'stacked');

  return (
    <div ref={myRef} id="vehicle-selector" className={classes}>
      <ChooserHeadline layout={config.layout} headline={config.headline}/>

      <div className={"selectors selectors-" + _.size(levels)}>
        {levels.map((level, index) =>
          <VehicleChooserLevel
            key={level}
            onChoiceMade={onChoiceMade}
            level={level}
            index={index}
            config={config}
            {...props}
          />)}
      </div>

      {_.includes(levels, 'fitment') && <FitmentNote config={config} />}
    </div>);
}

/**
 * With the unfortunate class name of .prompt,
 * this has styles in group_top.scss and maybe elsewhere.
 *
 * @param layout
 * @param headline
 * @returns {Element}
 * @constructor
 */
function ChooserHeadline({layout, headline}) {
  return <div className="prompt">
    {headline || "What Do You Drive?"}</div>
}
