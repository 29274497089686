import {toURL} from "../../../Utility/urls";
import {GRID_PARAMS, gridParamName} from "./gridParams";

export function buildInitialGridState({gridId, gridState}) {
  gridState = _.cloneDeep(gridState);

  const values = readGridParams({gridId});
  if (! _.isEmpty(values)) {
    const { sort, currentPage, pageSize, compare } = values;

    if (pageSize)
      gridState.pageSize = pageSize;
    if (sort)
      gridState.sort = sort;
    if (! isNaN(currentPage))
      gridState.currentPage = +currentPage;

    if (compare) {
      gridState.compareList = (""+compare).split(',')
        .filter( n => !isNaN(n))
        .map(n => +n);
    }
  }

  return gridState;
}

function readGridParams({gridId, url}) {
  url = toURL(url || globalThis.location);
  if (!gridId) return {};

  return _.mapValues(GRID_PARAMS, (param, _k) => {
    let paramName = gridParamName(gridId, param);
    return paramName ? url.searchParams.get(paramName) : null;
  });
}
