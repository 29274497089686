import React, {useEffect} from 'react';

import {
  buildParamsFromFilters,
} from "../filters/filterParams";
import {editUrlParams, updateCurrentUrl} from "../../../Utility/urls";
import PropTypes from "prop-types";
import {buildParamsFromGridState} from "../logic/gridParams";

const isDev = (process.env.NODE_ENV == 'development');

/**
 * Watch for changes in allFilterSelections,
 * and update the document URL when this occurs.
 *
 * @param filterMap
 * @param filtersTouched
 * @param allFilterSelections
 * @param allFilterOptions
 * @param props
 * @returns {null}
 * @constructor
 */
export default function GridChangeWatcher({
  gridId, gridState = {}, filterState = {}
}) {
  // Update URL when filters change.
  useEffect(() => {
    const p1 = buildParamsFromFilters({
      gridId, filterState
    });

    const p2 = buildParamsFromGridState({
      gridId, gridState
    });

    const params = Object.assign({}, p1, p2);

    updateCurrentUrl({params});
  }, [filterState, gridState]);
  
  return null;
}

GridChangeWatcher.propTypes = {
  gridId: PropTypes.string.isRequired
}
