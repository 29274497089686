import React, {useEffect, useRef, useState} from 'react';
import ProductImageCollection from "./ProductImageCollection";
import createLightGallery from "../../../../Common/services/createLightGallery";

// Image box on the left side of the clustered item page.
// Modernised 2024-05
export default function SingleItemImageDisplay({product, ...props}) {
  const ref = useRef(null);
  const [gallery, setGallery] = useState(null);

  const image = getImageFromProduct(product);

  // Destroy and recreate gallery when the image changes
  useEffect(() => {
    if (!ref || !image) return;

    if (gallery && gallery.destroy) {
      gallery.destroy();
    }

    // console.log("create gallery, image is " + image);
    const lg = createLightGallery(ref.current);
    setGallery(lg);
  }, [ref, image])

  if (!image)
    return null;

  const bigPath = image.replace("/sm/", "/lg/").replace("/md/", "/lg/");  // FIXME ZOIDBERG

  return <div ref={ref} className="image-box text-center pb-2">
    <a className="inline-block pic" href={bigPath}><img
      alt={product.name} src={image}/></a>
  </div>
}


function getImageFromProduct(product) {
  if (!product) return null;

  const coll = new ProductImageCollection({product: product});
  return coll.getFirstImagePath('small') || coll.getFirstImagePath('mid');
}
