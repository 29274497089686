import React, {useState, useEffect} from 'react';
import {useForm} from "react-hook-form";
import RequestReceiptFormContents from "./RequestReceiptFormContents";
import PostWithParams from "../ReduxStore/Helpers/PostWithParams";
import RequestReceiptInstructions from "./RequestReceiptInstructions";

export default function RequestReceiptForm({
  authToken, orderId, orderToken, formPath, handleClose, ...props
}) {

  // result: status, count, message
  const [result, setResult] = useState({});

  const form = useForm();

  const {handleSubmit} = form;

  const onSubmit = (inputs) => {
    const params = prepareSubmission(inputs);

    const post = new PostWithParams({url: formPath, params});
    post.perform()
      .then(r => r.json())
      .then(response => {setResult(response);})
      .catch(err => {
        if (err?.message) {
          setResult({status: 'error', errors: {error: err.message}})
        } else {
          alert("An error has occurred.");
        }
      })
  }

  function prepareSubmission(inputs) {
    inputs.obfuscated_order_id ||= orderToken;
    inputs.order_id ||= orderId;
    inputs = _.mapKeys(inputs, (v, k) => `receipt_request[${k}]`);

    if (authToken)
      inputs.authenticity_token = authToken;
    return inputs;
  }

  return <form action={formPath} method="POST"
    className="p-2 text-left"
    onSubmit={handleSubmit(onSubmit)}>

    <div>
      <div className="text-left">

        <h2 className="text-xl text-brand-orange">
          <span className="fa fa fa-envelope text-brand-orange"/>
          {' '}
          Send Order Receipt
        </h2>

        <RequestReceiptInstructions result={result}/>
      </div>

      <RequestReceiptFormContents
        form={form}
        result={result}
        handleCancel={handleClose}
      />
    </div>


  </form>
}

