/**
 * Given a URL (or a url string), apply edits to parameters.
 *
 * @param url
 * @param edits
 * @returns {URL}
 */
export function editUrlParams(url, edits) {
  url = toURL(url || globalThis.location);

  // merge edits into params
  let params = new URLSearchParams(url.search)
  for (let k in edits) {
    if (edits[k] === null) {
      params.delete(k)
    } else {
      params.set(k, edits[k]);
    }
  }

  url.search = params.toString();
  return url;
}

/**
 * Update the presently displayed URL without reloading.
 *
 * @param url
 * @param params
 * @returns {URL}
 */
export function updateCurrentUrl({url = null, params = {}, force = false}) {
  url = editUrlParams(url, params);

  if (force || (url.toString() !== globalThis.location?.toString())) {
    pushUrlState(url);
  }
  return url;
}

export function pushUrlState(url,
  {data = {}, title = null} = {}) {

  const {history} = globalThis;
  if (history && history.pushState) {
    history.pushState(data,
      title || document.title,
      url.toString())
  }
}

/**
 *
 * @param thingy {String|URL}
 * @returns {module:url.URL}
 */
export function toURL(thingy) {
  if (thingy?.constructor === URL)
    return thingy;
  else if (thingy?.toString)
    return new URL(thingy.toString());
  else
    return new URL(globalThis.location)
}
