import React from 'react';
import ContinueButton from "../form/ContinueButton";
import SpeakerToAffirm from "../../affirm/SpeakerToAffirm";
import ReviewAddress from "./ReviewAddress";
import ShippingAddressAnalyser from "../../logic/ShippingAddressAnalyser";
import {sendCartEvent} from "../../../Analytics/googleShoppingCart";

export default function AffirmFormSection(props) {
  const {checkout = {}, callbacks} = props;
  const {shippingAddress = {}} = checkout;
  const affirmClasses = "__affirm-logo __affirm-logo-white __ligature__affirm_full_logo__ __processed";
  let impediments = new ShippingAddressAnalyser(checkout).shippingImpediments();

  const handleSubmit = () => {
    let speaker = new SpeakerToAffirm();
    speaker.startCheckout(props.checkout);

   // google analytics 4
    sendCartEvent({
      eventType: 'add_payment_info',
      shoppingCart: checkout,
      payment_type: "affirm"
    });
  }

  return (
    <div className="affirm-form-section">

      <ReviewAddress
        usage="shipping"
        impediments={impediments}
        address={shippingAddress}
        changeAddress={callbacks.changeAddress}/>

      <p className="affirm-disclaimer">You will be redirected to Affirm to set up your payment.</p>

      <ContinueButton className="continue-with-affirm-wrapper"
        id="pay-with-affirm"
        caption=""
        onSubmit={handleSubmit}>
        <span className="fa fa-lock"/>
        <b> Continue with </b>
        <span className={affirmClasses}>Affirm</span>
      </ContinueButton>
    </div>
  );
}


