import React, {Component} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import LloydCartField from "./LloydCartField";

const isDev = (process.env.NODE_ENV == 'development');

/**
 * Renders all inputs for ONE ITEM, as a series of LloydCartField's
 */
export default function FieldsForItem(props) {
  let {cartItem} = props;

  /** @type {ItemFormAdapter} */
  let formAdapter = cartItem.formFields();
  let fields = formAdapter.contents();
  let prefix = formAdapter.prefix;

  let style = isDev ? {clear: 'both'} : {};

  // cart_item1[field] where field in product_id, quantity, vehicle_id, vehicle_fitment_id, suggested_brand_shipper_id
  return <div className="lloyd-cart-fields" style={style}>
    {_.keys(fields).map((k) =>
      <LloydCartField key={k}
        prefix={prefix} name={k} value={fields[k]}/>
    )}
  </div>

}




