import React from 'react';
import _ from 'lodash';

import groupGridSlice from "../../../ReduxStore/GroupGrid/groupGridSlice";
import CompareNow from "./CompareNow";
import clsx from "clsx";

export default function GridItemCompare({
  gridId, item, dispatch, gridState
}) {
  if (!gridState || !item || !gridId) return null;

  const {compareList = []} = gridState;
  const {id} = item;
  const selected = _.includes(compareList, +id);

  const handleChange = e => {
    let newList = compareList;

    if (selected) {  // remove it.
      newList = compareList.filter(n => n !== id);
    } else { // add it
      if (compareList.length >= 5) {
        alert("Maximum of 5 items can be compared at a time.")
      } else {
        newList = [...compareList, id];
      }
    }

    dispatch(groupGridSlice.actions.updateGridState({
      gridId,
      gridState: {compareList: newList}
    }));
  }

  const fieldId=`${gridId}-cmp-${item.id}`; // silence google Issues tab

  const canCompare = (selected && compareList.length > 1);
  if (canCompare) {
    return <CompareNow id={fieldId} compareList={compareList} handleChange={handleChange}/>
  } else {
    return <CheckToCompare id={fieldId} selected={selected} handleChange={handleChange}/>
  }
}

function CheckToCompare({id, selected, handleChange}) {
  const classNames = clsx("grid-item-compare", selected && "compare-selected");

  return (
    <div className={classNames}>
      <label>
        <input id={id} type="checkbox" value="1" onChange={handleChange} checked={selected}/>
        <span>Check to Compare</span>
      </label>
    </div>
  );
}
