import React from 'react';

import CartItemRow from './CartItemRow';

export default function CartItemsBody(props) {
  let {shoppingCart = {}} = props;
  let {cartItems = []} = shoppingCart;

  if (!cartItems[0])
    return null;

  return <div id="cart-items-body">
    {cartItems.map((item) =>
      <CartItemRow key={item.id} {...props} item={item}/>)}
  </div>;

}
