// 2023-07 functions to parse query params

// 2023-07 - this will be eventually a catalogue
// of parameter names
export const KNOWN_PARAMS = {
  APPLE_PAY_ENABLED: "apple-pay"
}

function getURLSearchParams(source) {
  const {URLSearchParams} = globalThis;

  if ((typeof source) === 'undefined')
    source = location.search;

  return new URLSearchParams(source);
}

export function getParam(name, defaultValue = null) {
  const usp = getURLSearchParams();
  if (usp.has(name))
    return usp.get(name)
  else
    return defaultValue;
}

export function getParams(source) {
  const usp = getURLSearchParams(source);

  const keys = [...(usp.keys())];

  return _.reduce(keys, (hash, key) => {
    hash[key] = usp.get(key);
    return hash;
  }, {});
}


