import React from 'react';
import FilterPill from "./FilterPill";

import {describeAllFilterSelections} from "../logic/filterLogic";
import ResetAllFilters from "../filters/ResetAllFilters";
import {useDispatch} from "react-redux";
import clsx from "clsx";

// 2023-11 modernised
export default function FilterPillBar({
  gridId,
  filterState,
  flavour,
}) {
  // Get a list of { id, label, filterName } for each
  const selections = describeAllFilterSelections(filterState);

  const pills = _(selections).values()
    .reject(v => _.isEmpty(v))
    .flattenDeep()
    .sortBy(v => v.selectedAt || v.label)
    .value();

  if (_.isEmpty(pills))
    return <div className="pt-1 my-2"/>

  return (
    <div className="pt-1 my-2">
      {pills.map((p) => <FilterPill gridId={gridId} key={p.id} selection={p}/>)}

      <ResetAllFilters
        gridId={gridId}
        label={"Clear All"}
        />
    </div>
  );
}



