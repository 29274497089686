import React from 'react';
import GridItemsList from "../grid/GridItemsList";

/**
 * 2024 - simplified - no longer uses Redux or gridState
 *
 * Formerly GroupUpSellsApp, now renamed to
 * LinearProductGridApp
 *
 * @param gridId
 * @param items
 * @param props
 * @param showControls
 * @returns {JSX.Element}
 * @constructor
 */
export default function LinearProductGridApp({
  gridId,
  items,
  showControls = false, // prev/next buttons
  columns = null,
  mobileColumns = null,
  ...props
}) {

  const scroll = (direction) => {
    // console.log("I knew I should have taken that " + direction + " turn at Albuquerque.")
  }

  const scrollLeft = e => scroll('left');
  const scrollRight = e => scroll('right');

  columns ||= items.length;
  mobileColumns ||= items.length;

  return (
    <div className="group linear-product-grid">
      { showControls && <div className="tools-left"><a className="scroll-button" onClick={scrollLeft}><i
        className="fas fa-arrow-alt-circle-left"/></a></div> }

      <div className={`product-grid-inner grid grid-cols-${mobileColumns} md:grid-cols-${columns}`}>
        <GridItemsList
          gridId={gridId}
          items={items}
        />
      </div>

      { showControls && <div className="tools-right"><a className="scroll-button" onClick={scrollRight}><i
        className="fas fa-arrow-alt-circle-right"/></a></div> }

      <div className="group-hover:hidden h-4"/>
    </div>
  );

  // the group-hover div at the bottom replaces scrollbar when not hovered
}
