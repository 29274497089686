import React from "react";
import LocalUrlMaker from "../../../lib/LocalUrlMaker";
import {affirmAvailableForCart} from "../../../lib/affirmHelpers";
import {reportStartCheckout} from "../utility/startCheckout";

/**
 * Note we have two similarly named components in different SPA's.
 *
 * This one is on the CART page, and it simply sends the user into
 * checkout with Affirm preselected - it doesn't actually talk to Affirm.
 */
export default function StartAffirmButton(props) {
  const { shoppingCart={} } = props;

  if (!affirmAvailableForCart(shoppingCart))
    return null;

  let checkoutPath = LocalUrlMaker.jsonPath(
    `/new_checkout/${shoppingCart.cartCode}?pt=affirm`);

  const handleClick = (evt) => {
    reportStartCheckout({
      shoppingCart, checkoutType: "affirm"
    });
  };

  return <div id="affirm-button-wrapper">
    <div id="affirm-container">
      <a className="button" id="start-affirm-button" href={checkoutPath} onClick={handleClick}>
        <img src="/img/badges/affirm_200.png" alt="Checkout with Affirm" className="m-auto" /></a>
    </div>
  </div>
}
