import React, {useEffect, useState} from 'react';
import {connect, Provider} from "react-redux";

import ProductGridContainer from "./ProductGridContainer";
import {getReduxStore} from "../../../ReduxStore/store";
import groupGridSlice from "../../../ReduxStore/GroupGrid/groupGridSlice";
import {generateGridId} from "../logic/gridLogic";
import {buildInitialGridState} from "../logic/initialGridState";

function mapStateToProps(state) {
  const {groupGridSlice = {}, vehicleSlice = {}, gridFilterSlice = {}} = state;

  return Object.assign(
    {},
    groupGridSlice,
    gridFilterSlice,
    vehicleSlice);
}

const Wrapper = connect(mapStateToProps)(ProductGridContainer);

/**
 * PROPERTIES: (2024) - gridState gridItems gridId currentAgent
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function ProductGridApp({gridState, gridId, ...props}) {
  // allocate a random gridId if none is provided. The grid will work
  // but won't be able to communicate with filter widgets.
  const [myGridId, setMyGridId] = useState(
    () => { return gridId || generateGridId() });

  // the gridState prop is static; send it to Redux on startup,
  // and ignore it after. It is not passed to children.
  useEffect(() => {
    if (!_.isEmpty(gridState)) {
      gridState = buildInitialGridState({
        gridId: myGridId, gridState});

      const payload = { gridId: myGridId, gridState };
      getReduxStore().dispatch(
        groupGridSlice.actions.initGridState(payload))
    }
  }, [myGridId, gridState]);

  return <Provider store={getReduxStore()}>
    <Wrapper gridId={myGridId} {...props} />
  </Provider>
}
