import EditCheckout from "../../Checkout/logic/EditCheckout";
import {camelizeKeys} from "../Helpers/transformObject";



/**
 *
 * @param checkoutProxy {Proxy}
 * @param edits {Object}
 */
export function applyEdits(checkoutProxy, edits) {
  if (! _.isEmpty(edits)) {
    let editor = new EditCheckout({
      checkout: checkoutProxy, // really a Proxy
      payload: edits
    });

    editor.applyChanges();
  }
  return checkoutProxy;
}


/**
 *
 * @param error {Error|Object|String}
 * @param defaultMessage
 * @returns {string|string}
 */
export function stringifyError(error, defaultMessage = "Unknown error") {
  if (!error)
    return defaultMessage;

  if (error.getMessage)
    error = error.getMessage();
  else
    error = error.toString ? error.toString() : (""+error);

  return error || defaultMessage;
}

function scrubAddress(addr) {
  if (!addr) return {};

  // remove leading hyphen, which the klaviyo/justuno stuff
  // has unfortunately put into the last name fields
  addr = _.mapValues(addr,
    (val) => (typeof val === 'string') ? val.replace(/^-+/, '') : val);

  return addr;
}

export function normalizeCheckout(rawCheckout) {
  let checkout = camelizeKeys(rawCheckout)

  let billingAddress = checkout.billingAddress = scrubAddress(checkout.billingAddress);
  let shippingAddress = checkout.shippingAddress = scrubAddress(checkout.shippingAddress);

  // 2023-06 new object shall be camelcase from day one
  checkout.checkoutConfig = camelizeKeys(checkout.checkoutConfig);
  checkout.totals = camelizeKeys(checkout.totals);

  // so we can default the checkbox to true...
  shippingAddress.subscribing = true;

  // set sameAddress to true/false on load, but the SameAddressSwitch can change this later
  checkout.sameAddress = (!billingAddress.id) || (!shippingAddress.id) || (billingAddress.id === shippingAddress.id);

  return checkout;
}

export function extractErrorMessage(action) {
  if (!action) action = {};

  let {payload = {}, error = null} = action;

  if (!error)
    error = payload.error;

  if (error) {
    if (error.getMessage)
      return error.getMessage();

    if (typeof error == "string")
      return error;
  }

  return "An unknown error has prevented updating the checkout.";
}
