import clsx from "clsx";

export const SIZE_LABELS = {
  sm: 'Small',
  md: 'Medium',
  lg: 'Large',
  icon: 'Category Thumb'
}

export const STATUS_CLASSES = {
  active: '',
  pending: 'border-2 border-blue-400 opacity-80 hover:opacity-100',
  hidden: 'border-2 border-red-400 opacity-50 hover:opacity-100',
  condemned: 'border-2  border-red-400 opacity-50 hover:opacity-100',
  unknown: 'bg-blue-200'
}

export function cellStyles({sizeCode}) {
  let styles = {maxWidth: '220px'};

  return styles;
}

export function cellClasses(image={}) {
  const { sizeCode, status } = image;

  //let status = _.sample(_.keys(STATUS_CLASSES));

  return clsx('relative max-w-56', STATUS_CLASSES[status]);
}
