import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {fetchCheckout} from "../../../ReduxStore/Checkout/checkoutSlice";
import {useEffect} from "react";

// Invoked on returning from Paypal.
// Send the token/payerID params to the back end,
// and set step=4 and paymentType = paypal
export default function PaypalReturnHandler(
  {
    cartCode, paypal = {}, dispatch,
    setStep, setPaymentType, ...props
  }) {

  useEffect(() => {
    if (_.isEmpty(paypal)) return;

    const payerId = paypal.PayerID;

    if (!payerId) return;

    // update the checkout server-side
    dispatch(fetchCheckout({
      route: "paypal_return",
      cartCode: cartCode,
      checkout: {
        paypal: paypal
      }
    }));

    // now display the paypal return page.
    if (setPaymentType)
      setPaymentType('paypal');
    if (setStep)
      setStep(4);
  }, [paypal])

  // paypal looks like: {"PayerID":"ZRXXXXXXXX","token":"EC-1KU40XXXXXXXXXXX"}

  return null;
}

