import React from 'react';
import ProductPicker from "./ProductPicker";
import SingleItemImageDisplay from "./SingleItemImageDisplay";
import DebugClusterState from "./DebugClusterState";

export default function ItemClusterImages(props) {
  const productPicker = new ProductPicker(props);
  const product = productPicker.getProductForImage();

  return <div className="cluster-images">
    {product && <SingleItemImageDisplay product={product}/>}
  </div>
  //
}

