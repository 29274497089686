import React, {Component} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import LloydItem from "../models/LloydItem";
import LloydMatImage from "./LloydMatImage";
import LloydMatCaption from "./LloydMatCaption";
import PartNumberNote from "./PartNumberNote";
import LloydCartItem from "../models/LloydCartItem";

// LloydMatChooserItem - one per available item, inside LloydMatChooser
export default class LloydMatChooserItem extends React.Component {
  static propTypes = {
    lloydItem: PropTypes.instanceOf(LloydItem).isRequired,
    cartItem: PropTypes.instanceOf(LloydCartItem),
    callbacks: PropTypes.object.isRequired
  }

  // SelectItem - event handler called when anything is clicked.
  onSelectItem = () => {
    let {lloydItem, callbacks} = this.props;
    let { extended_part_number } = lloydItem;

    console.log("LloydMatChooserItem: selectItem: " + extended_part_number);

    callbacks.selectItem(extended_part_number);
  }

  getClasses() {
    let {cartItem} = this.props;
    return ["lloyd-mat-chooser-item", cartItem ? 'selected' : ''];
  }

  // props: Object {lloydProduct: LloydItem, cartItem: undefined, callbacks: Object}
  render() {
    let {lloydItem, cartItem} = this.props;

    return (
      <div className={this.getClasses().join(' ')}>
        <div className="inner">
          <a onClick={this.onSelectItem}>
            <LloydMatImage lloydItem={lloydItem} />
            <PartNumberNote lloydItem={lloydItem} />
            <LloydMatCaption lloydItem={lloydItem} cartItem={cartItem} />
          </a>
        </div>
      </div>);
  }
}


