import $ from 'jquery';

import Tmpl from 'blueimp-tmpl';

export default class PhotoUploaderDisplay {
    constructor(uploader) {
        this.uploader = uploader;
        this.$form = this.uploader.$form;

        // this is where to inject things
        this.$progress_display = this.$form.find('.progress-display');
    }

    // Create a progress bar for this file,
    // and add to doc.
    buildProgressBar(upload, file) {
        console.log("display - show progress");
        upload.context = $(Tmpl('template-upload', file));
        this.$progress_display.prepend(upload.context);
        return upload;
    }

    // called several times during upload.
    onProgress(upload) {
        if (upload) {
            let { context, total, loaded } = upload;
            //console.log("uploading, loaded is " +loaded);
            if (!isNaN(total) && !isNaN(loaded)) {
                let progress = parseInt(loaded / total * 100, 10);
                console.log("upload progress: " + progress);
                return context.find('.bar')
                    .css('width', '' + progress + '%');
            }
        }
        return true;
    }

    // When finished, run upload.result through the #template-download
    // template, and append to the upload area.
    // New in 2019-01, as the old version seemed to do this
    // automatically...
    onFinished(upload, evt) {
        if (!upload) return false;

        let { result } = upload;
        if (!result)
            return false;

        let html = $(Tmpl('template-download', result));
        this.$progress_display.append(html);
    }
}
