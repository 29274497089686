/**
 * Build the options for a fetch call.
 *
 * @param attrs
 * @returns Object
 */
export function jsonOptions(attrs = {}) {
  const defaults = {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    cache: 'no-cache',
    credentials: 'include'
  };

  return Object.assign({}, defaults, attrs);
}

export function clearState(stateProxy) {
  for (let k of _.keys(stateProxy)) {
    stateProxy[k] = null;
    delete stateProxy[k];
  }
  return stateProxy;
}


