import ScrubCheckoutForPost from "../../Checkout/logic/ScrubCheckoutForPost";
import LocalUrlMaker from "../../lib/LocalUrlMaker";
import {jsonOptions} from "../Helpers/requestHelpers";

export default class FetchCheckoutRequest {
  constructor({checkout, cartCode, route}) {
    this.route = route;

    this._cartCode = cartCode;
    this.checkout = checkout || { cartCode: cartCode };
  }

  async call() {
    let url = this.url;

    console.log("FetchCheckoutRequest: fetching url: " + url + " with options " + JSON.stringify(this.options));

    return await fetch(url, this.options)
      .then(r => r.json());
  }

  get cartCode() {
    return this._cartCode || this.checkout.cartCode;
  }

  get url() {
    return this._url || (this._url = this.buildUrl());
  }

  get options() {
    return this._options || (this._options = this.buildOptions());
  }

  buildUrl() {
    if (! this.cartCode) {
      console.error("no cart code, cannot generate URL")
      return '/new_checkout.json'; // dunno if this will work
    }

    let url = `/new_checkout/${this.cartCode}.json`;
    if (this.route === 'new_checkout') {
      url = `/new_checkout/${this.cartCode}.json`;
    } else if (this.route) {
      url =  `/new_checkout/${this.cartCode}/${this.route}.json`;
    } else {
      url = `/new_checkout/${this.cartCode}.json`;
    }

    return LocalUrlMaker.jsonPath(url);
  }

  buildOptions() {
    let options = jsonOptions();

    if (this.route) {
      options.method = "POST";
      options.body = JSON.stringify({
        checkout: new ScrubCheckoutForPost(this.checkout).getCleanCheckout()
      });
    }

    return options;
  }

}
