import React from 'react';

const PricingCol = ({item}) => {
  let price = new Money(item.price);

  return <td className="price">
    From {price.toString()}
  </td>
}

const PricingRow = ({items}) => {
  return <tr>
    <th>Price</th>
    {items.map( item => <PricingCol item={item} key={item.id} />)}
  </tr>
}

// PricingRow.propTypes = { }

export default PricingRow;

