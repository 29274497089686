import React from 'react';
import _ from 'lodash';

import GenericAddressWidget from "./GenericAddressWidget";
import Validator from "../../logic/Validator";

export default class EmailWidget extends GenericAddressWidget {
  // Send update to parent - and also post the email address,
  // first/last name to the back-end.
  sendUpdate = () => {
    let {name, callbacks} = this.props;
    let {value: email} = this.state;

    callbacks.updateAddress(name, email);

    if (this.isValidEmail(email))
      this.saveCustomer({email: email});
    else
      console.log("not valid email: " + email);
  }

  isValidEmail(email) {
    let validator = new Validator({
      object: {email: email}
    });
    return validator.validateEmail('email', email)
  }

  saveCustomer(attrs = {}) {
    const {callbacks = {}, address} = this.props;
    const {saveCheckout} = callbacks;

    const customer = _.merge(
      _.pick(address, 'first_name', 'last_name', 'email'),
      attrs
    );

    if (saveCheckout && customer.email) {
      saveCheckout({edits: {customer}, route: 'new_checkout', quiet: true});
    }
  }
}
