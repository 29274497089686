import React, {Component} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import LloydItem from "../models/LloydItem";

/**
 * Show the mat shape image for one lloydItem.
 */
export default class LloydMatImage extends React.Component {
  static propTypes = {
    lloydItem: PropTypes.instanceOf(LloydItem).isRequired
  }

  render() {
    let {lloydItem} = this.props;
    let {media_list = []} = lloydItem;

    let image = media_list[0];
    if (!image)
      return (<div className="error">(no images)</div>);

    return (<div className="mat-image"><img src={image} alt="Floor Mat Diagram"/></div>);
  }
}
