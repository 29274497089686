import LloydCatalogue from "./LloydCatalogue";
import CartOptions from "./CartOptions";

export default class CartValidator {
  /**
   *
   * @param lloyd {LloydCatalogue}
   */
  constructor({lloyd, cart} = {}) {
    this.cart = cart;

    if (lloyd && lloyd.options) {
      this.lloyd = lloyd;
      this.optionsCatalogue = lloyd.options;
    } else {
      console.error("CartValidator: lloyd catalogue missing")
    }
  }

  validate() {
    let errs = this.validateItems();
    if (!_.isEmpty(errs))
      return errs;

    return Object.assign(
      this.validateRequiredAttributes(),
      this.validateCustomisations()
    );
  }

  validateItems() {
    let {items} = this.cart;

    let itemList = items.toArray();

    if (_.isEmpty(itemList))
      return {"count": "Select floor mats to add to cart"}

    return this.validateEachItem((item) => {
      let variant = item.options.getOption(CartOptions.PRODUCT_UNIQUE);
      if (!variant) {
        return `Item ${item.lloydItem.package_content_description} requires additional details.`
      }
    });
  }

  /**
   * Run a validator on each item.
   *
   * @param func function taking an item
   * @returns {Object}
   */
  validateEachItem(func) {
    let {items} = this.cart;
    let itemList = items.toArray();

    return items.toArray().reduce((errs, item) => {
      let err = func(item);
      if (err) {
        let {lloydItem} = item;
        errs[lloydItem.part_number] = err;
      }
      return errs;
    }, {});
  }

  attributeExists(name) {
    if (this.optionsCatalogue) {
      return this.optionsCatalogue.hasOption(name);
    }
  }

  validateRequiredAttributes() {
    let {cart} = this;

    let errs = {};

    if (this.attributeExists(CartOptions.COLOR)) {
      if (!cart.options.getOption(CartOptions.COLOR))
        errs.color = "Step 2: Select Color.";
    }

    if (this.attributeExists(CartOptions.BORDER)) {
      // if (!this.options.border)
      //    errs.border = "Step 2: Select Binding.";
    }

    return errs;
  }

  validateCustomisations() {
    // if (!this.attributeExists('logo'))  // && !this.attributeExists('lettering_thread or whatever?
    //   return {};

    // Is a logo selected at all?
    let logo = this.cart.options.getOption(CartOptions.LOGO);

    if (!logo)
      return {};

    // if a logo is selected, we must have items to apply it to
    let fancyItems = this.cart.items.toArray().filter((item) => {
      let rows = item.options.getOption(CartOptions.CUSTOMISE_ROWS);
      return rows && (+rows > 0);
    });

    if (_.isEmpty(fancyItems)) {
      return {'logo': "Step 3: Select items to customize with your chosen logo, or choose 'Plain'."}
    }

  }

}
