import React, {Component} from 'react';

/*
 * At the bottom of the add-to-cart column.
 */
export default class CartNotesDisplay extends Component {
  render() {
    let { index, product, application } = this.props;

    // TODO: "Ships between" here.
    return (

      <div className="CartNotesDisplay">
        <ul>
        </ul>
      </div>
    );

    //<li>SKU= {product.vendorsku} I={index}</li>
    //<li>{application.footnote || 'no footnote'}</li>
  }
}
