
import _ from 'lodash';

import {eachWithObject} from "../../../lib/enumerable";
import {toURL} from "../../../Utility/urls";
import {gridParamName} from "./gridParams";
import {decodeFilterParamValue, extractFilterParamNames} from "../filters/filterParams";

/**
 * Called on startup, this will build a filterState structure
 * to send to Redux, parsing filter parameters.
 *
 * @param gridId
 * @param initialFilterSet
 * @returns {*}
 */
export function buildInitialFilterState(gridId, initialFilterSet) {
  const filterState = _.cloneDeep(initialFilterSet);

  const newSelections = filterParamsToSelections({
    gridId,
    url: globalThis.location,
    filterMap: filterState.filterMap
  });

  filterState.allFilterSelections = Object.assign(
    filterState.allFilterSelections, newSelections);

  return filterState;
}

/**
 * Used by buildInitialFilterState only - this determines which
 * filter checkboxes are preselected from the URL.
 *
 * https://lisa.aagarage.net/Tonneau-Covers?page=1&fb=3,k,s&fp=1aao,n5c
 *
 * returns something like:
 * {
 *   "brands": {
 *     "3": "2024-05-22T21:27:07.068Z",
 *     "20": "2024-05-22T21:27:07.069Z",
 *     "28": "2024-05-22T21:27:07.069Z"
 *   },
 *   "priceRanges": {
 *     "30000": "2024-05-22T21:27:07.069Z",
 *     "60000": "2024-05-22T21:27:07.069Z"
 *   }
 * }
 *
 * @param gridId
 * @param url
 * @param filterMap
 * @returns {{}}  {filterName => {id: timestamp, id: timestamp...}, ...}
 */
export function filterParamsToSelections({
  gridId, url, filterMap = {}
}) {
  if (!gridId) return {};

  url = toURL(url); // force to URL class.

  // { filterName => paramName }
  const paramMap = extractFilterParamNames(gridId, filterMap);

  return _.mapValues(paramMap, (paramName, filterName) => {
    return paramValueToSelectionHash(url.searchParams.get(paramName));
  });
}

/**
 *
 * @param value
 * @returns {{}|undefined}
 */
function paramValueToSelectionHash(value) {
  let ids = decodeFilterParamValue(value);
  if (_.isEmpty(ids))
    return; // undefined!

  return eachWithObject(ids,
    (id, hash) => hash["" + id] = new Date().toISOString());
}



