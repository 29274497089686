import {setVehicleChoice} from "../../ReduxStore/VehicleChooser/vehicleChoicesSlice";
import {deleteSessionVehicle} from "../../ReduxStore/CustomerVehicle/vehicleSlice";
import {getDispatch} from "../../Utility/ReduxHelper";
import {vehicleToChoices} from "./vehicle";

/**
 * Dispatch several actions to reset the vehicle.
 *
 * @param dispatch
 */
export function resetVehicle({dispatch, by}) {
  dispatch ||= getDispatch();

  // tell the front end to forget vehicle choices
  // dispatch(setVehicleChoice({
  //   name: 'reset',
  //   choices: {}
  // }));

  // tell the front end to forget vehicle chooser options
  // dispatch(chooserOptionsSlice.actions.setChooserOptions({
  //   reset: true,
  //   options: {}
  // }));

  // // tell back end to forget the vehicle
  // dispatch(saveSessionVehicle({
  //   reset: true,
  //   vehicleContext: {reset: true}
  // }));

  dispatch(deleteSessionVehicle({
    by: by || "callbacks.resetVehicle"}));
}

// Only for DetailsOnlySelector

export function setChoicesFromVehicle({dispatch, config, choices, vehicle}) {
  if (!choices)
    choices = vehicleToChoices(vehicle);

  const {productContext, levels} = config;

  dispatch(setVehicleChoice({
    name: 'model',
    value: choices.model,
    choices,
    levels, productContext
  }));
}

// export function onChoiceMade({choice, config={}, dispatch}) {
//   // {level: "year", value: "2020", description: "2020" }
//   let {level, value, description} = choice;
// }
