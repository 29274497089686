import React, {Component} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import LloydCartItem from "../models/LloydCartItem";

/**
 * Selector for the "ProductUnique", where two different products
 * are available at the same product code (like UM1).
 *
 * ex, Ford F150 2009 Standard Cab 1Pc Front -
 *    available in bench/bucket seat configs.
 */
export default class ProductUniqueOption extends Component {
  static propTypes = {
    cartItem: PropTypes.instanceOf(LloydCartItem).isRequired,
    callbacks: PropTypes.shape({
      // invokes selectItemOption when selection is made
      selectItemOption: PropTypes.func.isRequired
    }).isRequired
  }

  onChange = (evt) => {
    let { callbacks, cartItem } = this.props;

    let { extended_part_number } = cartItem;

    let opt = { id: this.getValue(), name: this.getUniqueName() }

    callbacks.selectItemOption(extended_part_number, "product_unique", opt);
  }

  getFieldName() {
    let {cartItem} = this.props;
    return cartItem.formFields().makeName('product_unique_proxy');
  }

  // Lloyd's code for this product unique, like 3306510
  getValue() {
    let {unique} = this.props;
    return unique.brand_application_code;
  }

  // Name of the ProductUnique, will be communicated to parent.
  // ex. "Convertible", "Coupe"
  getUniqueName() {
    const {unique} = this.props;

    let str = unique.product_unique_string || '';
    return str.replace('|', ', ');
  }

  //FIXME: use controlled input here.
  render() {
    let {unique} = this.props;

    let {
      product_unique_note // footnote, like "No embroidery on rear mats"
    } = unique;

    let title = this.getUniqueName();
    let value = this.getValue() || "";

    return (<div>
      <label>
        <input type="radio"
          name={this.getFieldName()} value={value}
          onChange={this.onChange} />
        <span>{title}</span>
        {' '}
        {product_unique_note && <div className="note">{product_unique_note}</div>}
      </label>
    </div>)
  }
}
