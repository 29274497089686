import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

export default function EditImageForm({image, callbacks, ...props}) {

  const [formValues, setFormValues] = useState(() => {
    return _.pick(image, 'title', 'status')
  })

  return <div>

  </div>
}
