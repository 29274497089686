import $ from 'jquery';

//
// 2018-12 ported from old canyonero
//
export default class DeviceDetector {
  constructor() {
    let $w = $(window); // fixme jquery
    if ($w.height) {
      this.height = $w.height();
      this.width = $w.width();
    }
  }

  isMobile() {
    return this.deviceType() === 'mobile';
  }

  deviceType() {
    this.device_type = this.device_type || this.readDeviceType();
    return this.device_type;
  }

  readDeviceType() {
    // KLUGE - we just trust the back end!
    let $b = $('body');

    if ($b.hasClass('body-mobile'))
      return 'mobile';

    if ($b.hasClass('body-desktop'))
      return 'desktop ';

    return 'unknown';
  };

  // This does not work.
  // Apple intentionally obfuscates the user agent string on iPad -
  // it contains neither iPad nor IOS, but claims to be Mac Safari
  //
  // isIOS() {
  //   const { window, navigator } = globalThis;
  //   if (!window || !navigator)
  //     return false;
  //
  //   const userAgent = navigator.userAgent || navigator.vendor;
  //   if (!userAgent)
  //     return false;
  //
  //   if (userAgent.match(/iPhone|iPad|iPod/i))
  //     return true;
  //
  //   return false;
  // }

}


// 2023 - start to migrate to static functions
export function isIPhone() {
  const { navigator={} } = globalThis;
  const { userAgent="" } = navigator;

  if (userAgent.match(/iPhone/i)) {
    return true;
  }

  return false;
}
