import React from 'react';

export default function FitmentNote({config = {}}) {
  const {fitmentNote} = config;
  if (!fitmentNote) return null;

  return (
    <div className="text-sm" dangerouslySetInnerHTML={{__html: fitmentNote}}/>
  );
}
