import React from 'react';

//
// ValidationMessage component - renders the error text (if present)
// under a form widget.
//
export default function ValidationMessage({name, error, focused}) {
  // skip rendering when element is focused
  if (focused || !error)
    return null;

  return (<div className="invalid-error">
    {error}
  </div>);
}

