//
// 2020 - given a Product data object that has a .images member (or just the .images),
// provide a structured way to access it.
//
export default class ProductImageCollection {
  constructor({product, images}) {
    this.product = product || {images: {}};
    this.images = images;

    if (!this.images)
      this.images = this.product.images || {};
  }

  // this.images has keys small, mid, large, square;
  // each value in the hash is a list of { {path: "/virtual/path"}, { path: "/virtual/path" } }
  getImages(size) {
    return this.images[size];
  }

  // returns the first virtual path of an image in the lsit
  getFirstImagePath(size) {
    let list = this.images[size];
    if (!list) return null;

    let img = list[0];
    return ((img && img.path) ?
      img.path : img)
  }
}
