import React from 'react';
import PropTypes from 'prop-types';
import SkuDisplay from "../../../Common/components/SkuDisplay";
import ProductInstructions from "./ProductInstructions";
import NapaSkuDisplay from "./NapaSkuDisplay";
import FootnoteList from "./FootnoteList";

const  isDev = (process.env.NODE_ENV == 'development');

export default class ItemFactBox extends React.Component {
  static propTypes = {
    product: PropTypes.shape({
      vendorsku: PropTypes.string.isRequired,
      bullets: PropTypes.string,
    }).isRequired,
    application: PropTypes.shape({
      vehicle: PropTypes.object,
      footnote: PropTypes.string,
      fitment_text: PropTypes.string
    })
  }

  render() {
    let {application = {}, product} = this.props;
    let {vehicle = {}} = application;

    return <div className="fact-box">
      <div className="product-name">{product.name}</div>

      <SkuDisplay sku={product.vendorsku}/>

      <NapaSkuDisplay product={product} />

      <FootnoteList footnote={application.footnote} />

      {product.bullets && <ItemBullets text={product.bullets}/>}
      <ProductInstructions path={product.instructions_path} />
    </div>

    // {isDev &&
    // <div className="debug">
    //   {vehicle.year} {vehicle.vehicle_model_name}
    //   {application && application.fitment_text || ""}
    // </div>}
  }


}


function ItemBullets({text}) {
  if (!text)
    return null;

  let bullets = text.split('@@');
  if (!bullets[0])
    return null;

  return <ul className="item-bullets">
    {bullets.map(b => <li key={b}>{b}</li>)}
  </ul>;
}
