import React from 'react';

import SaveCartInputWidget from './SaveCartInputWidget';

export default class SaveCartNameInput extends SaveCartInputWidget {
  render() {
    const { contents={} } = this.props;

    return (<div id="save_cart_name_wrapper">
      <input type="text"
        id="saved_cart_name"
        name="first_name"
        value={contents.first_name || ''}
        onChange={this.onChange}
        autoComplete="given-name"
        placeholder="First Name" />

     </div>);
  }
}


