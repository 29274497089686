import {extractAttributes} from "./html";

/**
 * Get a component function by name, from
 * ReactOnRails.
 *
 * @param name
 * @returns {*|null}
 */
export function getRegisteredComponent(name) {
  name = (name || "").toString().trim();

  // Not a POJSO, a Map, which has get/set funcs.
  let map = ReactOnRails.registeredComponents();

  let definition = map.get(name);
  if (!definition) {
    let keys = [...map.keys() ];
    Array.sort(keys);

    console.error(
      `getRegisteredComponent: unrecognized name ${name}; known components are:`,
      keys);
    return null;
  }

  // definition has: name, renderFunction, isRenderer, component members.
  return definition.component;
}


export function nodeListToReactElements(nodeList) {
  return _.map(nodeList, node => nodeToReactElement(node));
}

export function nodeToReactElement(node) {
  if (node.tagName) {
    let attrs = extractAttributes(node);
    attrs.className = attrs.class;
    delete attrs.class;

    return React.createElement(
      (node.tagName || 'span').toLowerCase(),
      attrs,
      ...nodeListToReactElements(node.childNodes));
  } else {
    return node.textContent;
  }
}
