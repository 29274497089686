/**
 * VEHICLE CHOOSER 3.0 - 2021.
 *
 * uses React and Redux.
 *
 */
import React from 'react';
import {Provider, connect} from 'react-redux';
import VehicleChooserContainer from "./VehicleChooserContainer";
import PropTypes from 'prop-types';
import _ from 'lodash';
import {getReduxStore} from "../../ReduxStore/store";

// All values in state.vehicleChooser fields will be mapped to props
function mapStateToProps(state) {
  let {chooserOptionsSlice={}, vehicleSlice={},
    groupItemsSlice = {}, vehicleChoicesSlice = {}, scrapyardSlice} = state;

  return Object.assign(
    {},
    vehicleSlice,
    chooserOptionsSlice,
    vehicleChoicesSlice,
    _.pick(scrapyardSlice, 'currentAgent'),
    _.pick(groupItemsSlice, 'productDetail'));
}

const VehicleChooserWrapper = connect(mapStateToProps)(VehicleChooserContainer);

export default function VehicleChooserApp({config, ...props}) {
  // merge in the defaults.
  //config = Object.assign({}, VehicleChooserApp.defaultProps, config);

  return <Provider store={getReduxStore()}>
    <VehicleChooserWrapper config={config} {...props} />
  </Provider>
}

VehicleChooserApp.propTypes = {
  config: PropTypes.shape({
    flavour: PropTypes.string, // group, ...?
    headline: PropTypes.string,
    layout: PropTypes.string, // stacked, horizontal
    levels: PropTypes.array, // [year, make, model],
    yearRange: PropTypes.array, // [ 1988, 2021]
    initialVehicle: PropTypes.object, // {id, year, vehicle_make_id...}
  }).isRequired,
};


