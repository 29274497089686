export const fieldConfig = {
  // https://braintree.github.io/braintree-web/3.76.4/module-braintree-web_hosted-fields.html#~field
  cardholderName: {
    container: '#credit_card-cardholder_name',
    placeholder: 'Name as it appears on card',
  },
  number: {
    container: '#credit_card-number',
    placeholder: '0000 0000 0000 0000',
    // bullet=&#x2022;
    // maskInput: {character: '•', showLastFour: true}
  },
  // postalCode: {
  //   container: '#credit_card-postal_code',
  //   placeholder: '',
  // },
  expirationMonth: {
    container: '#credit_card-expiration_month',
    placeholder: "Select Month",    // will render as blank option
    select: {
      options: [
        "01 January", "02 February", "03 March",
        "04 April", "05 May", "06 June",
        "07 July", "08 August", "09 September",
        "10 October", "11 November", "12 December"]
    }
  },
  expirationYear: {
    container: '#credit_card-expiration_year',
    // placeholder: "year",
    placeholder: "Select Year",
    select: true // options not available
  },
  cvv: {
    container: '#credit_card-cvv',
    placeholder: '000',
    // type: 'password'
  },
  // expirationDate: {
  //   container: '#expiration-date',
  //   placeholder: '10/2022'
  // }
  // postalCode
};

// stuff shared between input and select
const inputShared = {
  // configure background & border in CSS, not here.
  "font-size": "1rem",
  height: "1.1rem",
  padding: "1rem 0.7rem 0.7rem 0.7rem",
  //color: 'black'
};

// https://developers.braintreepayments.com/guides/hosted-fields/styling/javascript/v3
export const styles = {
  input: _.extend({}, inputShared, {
    padding: "1rem 0.7rem 0.7rem 0.7rem",
  }),
  select: _.extend({}, inputShared, {
    // configure background & border in CSS, not here.
    padding: "0.7rem 0.7rem 0.7rem 0.7rem",
  }),
  option: {
    padding: "14px"
  },

  //'.invalid': {color: 'red'},
  //'.valid': {color: 'green'},
  ':focus': {
    // 'color': 'blue'
  }

  // Media query example:
  // '@media screen and (max-width: 700px)': {
  //   'input': { 'font-size': '14px  }
  // }
}
