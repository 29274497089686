// Pricing Column
import React from "react";
import AgentPriceAssist from '../agents/AgentPriceAssist';
import Money from '../../../lib/Money';

export default function CartItemPrices(props) {
  let {item, shoppingCart, currentAgent, callbacks} = props;

  return <div className="col-prices">
    {item.extendedSavings ?
      <ItemPricingDetailWithSavings item={item}/> :
      <ItemPricingDetail item={item}/>}
    {currentAgent && <AgentPriceAssist {...props} />}
  </div>
}

// Prices section at top of pricing column.
// version is used only when there is no discount; see also ItemPricingDetailWithSavings
function ItemPricingDetail(props) {
  let {item, shoppingCart, agent, callbacks} = props;

  return <div className="total-prices">
    <div className="pr effective-extended-price">
      <div className="pr effective-extended-price">
        <strong>{new Money(item.effectiveExtendedPrice).toString()}</strong>
      </div>
    </div>
  </div>
}

// Prices section at top of pricing column.
// version is used only when there is a discount; see also ItemPricingDetail
function ItemPricingDetailWithSavings(props) {
  let {item, shoppingCart, currentAgent, callbacks} = props;

  return <div className="total-prices">
    <div className="pr regular-extended-price">
      <strong>{new Money(item.regularExtendedPrice).toString()}</strong>
    </div>

    {item.agentExtendedSavings > 0 ?
      <div className="agent-assisted">Your private discount:</div> : null}

    <div className="pr extended-savings">
      <strong>{new Money(-item.extendedSavings).toString()}</strong>
    </div>

    <div className="pr effective-extended-price">
      <strong>{new Money(item.effectiveExtendedPrice).toString()}</strong>
    </div>
  </div>
}

