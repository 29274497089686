import PageLogic from '../PageLogic';

import SendNoteForm from './SendNoteForm';
import PhotoUploader from '../PhotoUploader/PhotoUploader';

export default class OrderStatusPage extends PageLogic {

  startup(localApi) {
    // Grab actual order ID from H1
    this.order_id = +($('h1#order-id').text());  // FIXME jQuery

    this.sendNoteForm = new SendNoteForm();

    this.photoUploader = new PhotoUploader($('#new_private_photo'));

    this.bindEverything();
  }

  bindEverything() {
    // FIXME jQuery
    $('#attach-photos-link').click(() => this.attachPhotos());

  }

  attachPhotos() {
    // FIXME jQuery
    $('.private-photo-upload-fields').show(400);
    $('#attach-photos-link').hide();
    return false;
  }
}
