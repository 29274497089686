import React, {useState} from 'react';
import {calculateLeftPosition} from "./logic";
import clsx from "clsx";
import TextContentBox from "./TextContentBox";

export default function CarouselItem({
  item, index, itemOffset = 0, config = {},
  itemsPerSlide = 1
}) {
  const {name, flavour, imagePath, link, altText, textContent} = item;

  // parent has itemClasses that are shared for all items
  const {itemClasses = {}} = config;

  const [startPos, setStartPos] = useState(null);
  const [touchOffset, setTouchOffset] = useState(0);

  const handleTouchStart = (e) => {
    const t = e.touches[0];
    if (t) {
      setStartPos(t.clientX);
      console.log("set start pos: " + t.clientX);
    }
  }

  const handleTouchMove = (e) => {
    // console.log("TOUCH MOVE,", e);
    //
    // const t = e.touches[0];
    // if (t && !isNaN(startPos)) {
    //   let diff = t.clientX - startPos;
    //   console.log("touched update: ", t);
    //
    //   if (!isNaN(diff)) {
    //     console.log("offset: " + diff);
    //     setTouchOffset(diff);
    //   }
    // }
  }

  const handleTouchEnd = e => {
    console.log("TOUCH END", e);
    setStartPos(null);
  }

  const leftPos = (touchOffset || 0 ) +
    calculateLeftPosition({
    config, itemsPerSlide, itemOffset
  })

  const width = 100 / itemsPerSlide;

  const styles = Object.assign({
    left: `${leftPos}%`, top: 0, width: `${width}%`,
    transition: "left ease 0.5s"
  }, item.styles);

  const classNames = clsx([
      'carousel-item',
      `carousel-item-${flavour || 'default'}`,
      'absolute inline w-full m-0'
    ],
    itemClasses,
    item.classes);

  return (
    <div className={classNames} style={styles}>
      <a href={link}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}>
        <img className="slide w-full" src={imagePath} style={{height: "100%"}}
          alt={altText || name}/>
      </a>

      {_.isEmpty(textContent) ? null :
        <TextContentBox item={item} content={textContent} config={config}/> }

    </div>
  );
}

