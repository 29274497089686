import {createSlice} from "@reduxjs/toolkit";

import {rebuildFilterState} from "../../GroupGrid/components/logic/filterState";
import {requirePayloadItems} from "../Helpers/reduxHelpers";
import {createContext} from "react";

const isDev = (process.env.NODE_ENV == 'development');

const defaultState = {
  allFilterStates: {
    // grid-1234-5678: defaultFilterState
  }
};

const defaultFilterState = {
  gridId: null,

  // a list, appended to when a filter is populated,
  // with items removed when filter is empty.
  filtersTouched: [],

  // ALL the items below are hashes indexed by
  // filterName.
  filterMap: {},
  allFilterSelections: {
    // whether user has checked/unchecked a filtering option
    // subCategories: { [id]: true,...} ...
    // brands: { [id]: ... }
  },
  disabledFilterOptions: {},
  allFilterOptions: {},
  allFilterBuckets: {},
  sidebarOpen: false
};

export const FilterStateContext = createContext(defaultFilterState);

function requireFilterUpdate(action) {
  return requirePayloadItems(action, ['gridId', 'filterState'])
}

const gridFilterSlice = createSlice({
  name: 'gridFilterSlice',
  initialState: defaultState,
  reducers: {
    // 2024-05 OK
    // Called by GroupGridContainer, this copies the members of the
    // filterSet param (which is thereafter ignored) into state.
    // allFilterSelections, allFilterOptions, allFilterBuckets
    initFilterState: (state, action) => {
      const {gridId, filterState} = requireFilterUpdate(action);

      if (gridId) {
        let newState = Object.assign({}, defaultFilterState, filterState, {gridId: gridId})
        state.allFilterStates[gridId] = rebuildFilterState(newState);
      } else {
        console.error("initFilterState: no gridId");
      }
    },

    // 2024-05 OK
    toggleSidebar: (state, action) => {
      const { gridId } = requirePayloadItems(action, ['gridId']);

      if (gridId) {
        const filterState = state.allFilterStates[gridId];
        console.log(`${gridId} filter state:`, Object.assign({}, filterState));
        filterState.sidebarOpen = !filterState.sidebarOpen;
        console.log(`${gridId} filter state AFTER: `, Object.assign({}, filterState));
      }
    },

    // 2024-05 OK
    // toggle visibility of a particular FilterWidget (ie, brands, prices, etc.)
    toggleOpenClosed: (state, action) => {
      const { gridId, filterName } = action.payload;
      if (gridId && filterName) {
        const filterState = state.allFilterStates[gridId];
        const filter = filterState.filterMap[filterName];

        if (filter) {
          const isOpen = (filter.displayMode === 'open');
          filter.displayMode = isOpen ? 'closed' : 'open';
        }
      }
    },

    // 2024-05 deprecated
    updateFilter: (state, action) => {
      if (isDev)
       alert("DEPRECATED updateFilter");
    },

    // 2024-05 OK replace deprecated resetFilter(all:true)
    resetAllFilters: (state, action) => {
      const { gridId } = requirePayloadItems(action, ['gridId']);

      if (gridId) {
        const filterState = state.allFilterStates[gridId];
        const {allFilterSelections} = filterState;

        for (let k in allFilterSelections) {
          allFilterSelections[k] = {};
        }

        state.allFilterStates[gridId] = rebuildFilterState(filterState)
      }
    },

    // 2024-05 OK
    // Select/Deselect an item, or reset an entire filter.
    // updates allFilterSelections & filtersTouched list
    selectFilterItem: (state, action) => {
      const {payload} = action;

      const {gridId, filterName} = requirePayloadItems(action, ['gridId', 'filterName']);

      if (gridId && filterName) {
        // Get the selections has to modify.
        const filterState = state.allFilterStates[gridId];

        const {allFilterSelections} = filterState;
        const selections = (allFilterSelections[filterName] ||= {});

        const {selected, id} = payload;
        if (selected) {
          // we use a timestamp instead of true, in order to sort
          // them in the FilterPillBar
          selections[""+id] = new Date().toISOString();
        } else {
          delete selections[""+id]
        }

        state.allFilterStates[gridId] = rebuildFilterState(filterState);
      }
    },
  }
});

export default gridFilterSlice;
