import LocalUrlMaker from "../../lib/LocalUrlMaker";
import PropTypes from "prop-types";

export default function CartButtons({cartCode, handleClose}) {
  let cartUrl = LocalUrlMaker.prefixedPath(`/cart/${cartCode}`);

  const handleViewCart = () => {
    handleClose({label: 'view_cart_button'});
    window.location = cartUrl;
  }

  return <div className="cart-buttons">
    <a className="btn button" id="view-cart-button"
      onClick={handleViewCart}
       href={cartUrl}>View Cart</a>

    <a className="btn button" id="keep-shopping-button"
      onClick={handleClose}>Keep Shopping</a>

  </div>
}

CartButtons.propTypes = {
  handleClose: PropTypes.func.isRequired
}
