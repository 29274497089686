import React, {Component} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import LloydCartItem from "../models/LloydCartItem";
import ProductUniqueOption from "./ProductUniqueOption";

/*
 * ProductUniquePicker - for one cart item, display the product uniques,
 * which are minor fit options like "with/without subwoofer".
 * Not all products have them.
 *
 * Some vehicles that use this:
 *
 * 2009 Ford F-150, Standard Cab [1 Pc Front]
 * 2016 Bentley Continental
 */
export default class ProductUniquePicker extends Component {
  static propTypes = {
    cartItem: PropTypes.instanceOf(LloydCartItem).isRequired
  }

  getProductUniques() {
    let {cartItem} = this.props;
    let {lloydItem} = cartItem;
    let list = lloydItem.productUniques();

    return _.sortBy(list, (item) => item.product_unique_string);
  }

  // receives props: callbacks | items | cart | vehicle | fitment | lloyd_unique | lloyd_attributes | cartItem
  render() {
    let {cartItem, callbacks} = this.props;
    let {lloydItem} = cartItem;

    let list = this.getProductUniques();
    if (_.isEmpty(list)) return null;

    return <div className="lloyd-product-unique-picker">
      <h4>
        <strong>{lloydItem.package_content_description}</strong>:
        to ensure proper fit, please select:
      </h4>

      {
        list.map((u, i) =>
          <ProductUniqueOption
            key={u.brand_application_code || i}
            unique={u}
            cartItem={cartItem}
            callbacks={callbacks}
          />)
      }
    </div>
  }
}
