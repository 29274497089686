/**
 * 2023-06 - invoked by OptanonWrapper in header, whenever
 * a user interacts with the OneTrust cookie banner. This uses
 * no parameters, instead we have to harvest data from
 * global variables.
 */

let originalGroups = null;
let currentGroups = null;

export function handleConsentChange() {
  let activeGroups = getActiveConsentGroups();
  if (!activeGroups) return false;

  if (originalGroups)
    consentDidChange(activeGroups);
  else
    stashOriginalConsentGroups(activeGroups);

  return activeGroups;
}

function stashOriginalConsentGroups(activeGroups) {
  originalGroups = activeGroups;
  currentGroups = activeGroups;

  // console.log(`cookie groups: stashed initial setting: ${originalGroups}`)
}

function consentDidChange(activeGroups) {
  currentGroups = activeGroups;

  // at this point we're responding to an explicit user action
  console.log(`cookie groups: CHANGED from ${originalGroups} to ${activeGroups}`)
  // alert(`cookie groups CHANGED from ${originalGroups} to ${activeGroups}`)
}


/**
 * Return a list like C0001, C0002, etc.
 * @returns {*|null}
 */
function getActiveConsentGroups() {
  let {OnetrustActiveGroups: groupList} = globalThis;

  if (!groupList) {
    console.warn("OneTrustActiveGroups missing");
    return null;
  }

  return _(groupList.toString())
    .split(',')
    .filter(n => !!n)
    .sortBy(n => n)
    .value();
}
