import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import $ from "jquery";

import FieldWithApply from '../utility/FieldWithApply';
import AgentsOnly from '../utility/AgentsOnly';
import ActivateLink from '../utility/ActivateLink';
import Agent from '../../models/Agent';

/*
 * Tax Exempt Widget - seen only by Agents (ordinary users get nothing),
 * if this is available it first presents as a "Tax Exempt?" text link.
 *
 * Once clicked, if the agent is a Team Lead it becomes an input field
 * with some instructions; if the agent is not a Team Lead it just becomes
 * instructions.
 */
export default function TaxExemptWidget(props) {
  const {currentAgent, shoppingCart = {}, callbacks} = props;

  const {taxExemptCode} = shoppingCart;

  if (!currentAgent) return null;

  let agent = new Agent(currentAgent);

  let teamLead = agent.hasPower("team_lead", "top_agent", "special_agent");

  const prompt = <span><NoTaxIcon/>{' '} Tax Exempt?</span>;

  return <div id="tax-exempt-entry">
    <ActivateLink prompt={prompt} active={!!taxExemptCode}>
      {teamLead && <div style={{color: 'black'}}><FieldWithApply
        shoppingCart={shoppingCart} callbacks={callbacks}
        id="tax-exempt-code" name='taxExemptCode'
        placeholder="Tax Exemption Number"
      /></div>}

      {teamLead ? <div className="instructions">
        Tax Exempt customers (resellers, government agencies)
        will have a Tax Exemption Number. Enter it here and press "apply".
        Customer will see what you enter here,
        in the Cart Summary box at right.
      </div> : <div className="instructions">
        Tax Exempt customers (resellers, government agencies)
        will have a Tax Exemption Number.
        Customer will see this number and a tax of zero
        in their Cart Summary box at right.
        <div className="red">Contact a Team Lead to connect to this cart
          and enter the information.</div>
      </div>
      }
    </ActivateLink>
  </div>
}

function NoTaxIcon(props) {
  return <span className="fa-stack">
      <i className="fa fa-slash fa-stack-1x red"/>
      <i className="fa fa-hand-holding-usd fa-stack-1x"/>
      </span>
}
