//
// 2024-08 - Replaces old UserStash / AAGUser
// in a more functional style.
//
// JUSTUNO: add #juProfile to reveal reset button
//
// https://lisa.aagarage.net/Tonneau-Covers#juProfile

import {snakeifyKeys} from "../ReduxStore/Helpers/transformObject";
import PostWithParams from "../ReduxStore/Helpers/PostWithParams";

const isDev = (process.env.NODE_ENV == 'development');

// FIXME: rename once legacy class is gone!
const CURRENT_USER_KEY = "aag_user";




export function saveUserToCart(user) {
  let pp = new PostWithParams({
    url: "/cart_engine/customers.json",
    params: userToCartParams(user),
  });

  pp.perform()
    .then(r => r.json())
    .catch(err => console.warn(err))
}

export function userToCartParams(user) {
  user = snakeifyKeys(user);

  const params = {};

  const fields = ['email', 'first_name', 'last_name', 'source'];
  for (const i in fields) {
    const k = fields[i];
    const value = user[k];
    if (!value)
      continue;

    params["customer[" + k + "]"] = value;
  }

  return params;
}
