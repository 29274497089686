import React, {useState, useEffect} from 'react';
import {isEmpty} from 'lodash';
import {
  sendKlaviyoIdentify,
  trackKlaviyoEvent,
  trackViewedItemAttrs,
  withKlaviyo
} from "../ExternalServices/Klaviyo/KlaviyoHelpers";

const isDev = (process.env.NODE_ENV == 'development');

/**
 * On startup, update Klaviyo with an "identify" action
 * FIXME ...and also send current page info.
 *
 * @param cartCode
 * @param customerVehicle
 * @param controllerName
 * @param user
 * @param props
 * @returns {null}
 * @constructor
 */
export default function KlaviyoInformant({
  cartCode, customerVehicle, controllerName, user, ...props
}) {

  const [sentEvents, setSentEvents] = useState(false);

  useEffect(() => {
    // UGLY KLUGE -
    // Do not run this when in subscriptions controller; it has its own
    // KlaviyoUnsubscribeApp to do the identify.
    if (controllerName === 'subscriptions')
      return;

    console.log("KlaviyoInformant: user is " + JSON.stringify(user));

    if (isEmpty(user))
      return;

    // put everything inside a withKlaviyo block so it runs synchronously
    withKlaviyo(k => {

      sendKlaviyoIdentify({
        user, cartCode, customerVehicle,
        attrs: {_debug: 'KlaviyoInformant'}
      });

      if (!sentEvents) {
        setSentEvents(true);
        sendPageEvents();
      }
    })


  }, [user, cartCode, customerVehicle]);

  // speak no evil
  return null;
}

function sendPageEvents() {
  const eventList = getPageEvents();
  if (isEmpty(eventList)) return false;

  for (let e of eventList) {
    trackKlaviyoEvent(e.type, e.data);
  }

  // If there is a ViewedProduct event in the list, send a trackViewedItem with
  // slightly different data.
  let productEvent = eventList.find( e => e.type == "Viewed Product");
  if (productEvent) {
    let attrs = trackViewedItemAttrs(productEvent.data);
    console.log(`sending trackViewedItem: ${JSON.stringify(attrs, null, 2)}`)
    //withKlaviyo( k => k.push(["trackViewedItem", attrs]) );
    withKlaviyo( k => k.trackViewedItem(attrs));
    // FIXME KLAVIYO - not sending trackViewedItem
    window.ITEM  = attrs;
  }
}

function getPageEvents() {
  let script = document.getElementById('klaviyo-page-events');
  if (script) {
    return JSON.parse(script.textContent);
  }
}
