import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import clsx from "clsx";
import HideButton from "./HideButton";
import RenderButton from "./RenderButton";
import DownscaleButton from "./DownscaleButton";

export default function EditImageButtons({image, callbacks, ...props}) {
  const buttonClasses = "py-2 px-4 text-white mx-auto inline-block text-white";

  return (
    <div className="grid grid-cols-3 text-center py-3">
      <div className="text-center">
        <HideButton callbacks={callbacks} image={image}
          classNames={buttonClasses}/>
      </div>
      <div className="text-center">
        {image.sizeCode != 'lg' &&
        <DownscaleButton callbacks={callbacks} image={image}
          classNames={buttonClasses}/> }
      </div>
      <div className="text-center">
        <RenderButton callbacks={callbacks} image={image}
          classNames={buttonClasses}/>
      </div>
    </div>)
}


