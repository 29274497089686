import produce from "immer";
import EditObject from "../logic/EditObject";
import EditDummyCard from "./EditDummyCard";

export default class UpdateDummyCard {
  constructor({creditCard}) {
    // this.creditCard is IMMUTABLE. Use immer/produce
    // to make changes.
    this.creditCard = creditCard;
  }

  getCard() {
    return this.creditCard;
  }

  // Get a dummy value placeholder from the fieldStatus object.
  dummyValueFrom(fieldStatus) {
    if (!fieldStatus || fieldStatus.isEmpty)
      return '';

    return fieldStatus.isValid ? '[ok]' : '[invalid]';
  }

  /**
   * touch(fieldname) - called when the user tabs off a field (onBlur).
   *
   * @param fieldStatusName
   * @param fieldStatus {Object} - braintree events.fields[fieldStatusName]
   *  - has members isEmpty, isValid, isPotentiallyValid, isFocused (booleans)
   *  - and container (html element)
   */
  updateField(fieldStatusName, fieldStatus={}) {
    // cardholderName => cardholder_name
    let name = _.snakeCase(fieldStatusName);
    // get dummy value like [ok] or [invalid] or "" (if empty)
    let value = this.dummyValueFrom(fieldStatus);

    this.replaceCreditCard(draft => {
      // Apply changes to the card object
      let editor = new EditDummyCard({
        object: draft,
        edits: { [name]: value },
        allStatuses: { [name]: fieldStatus }
      })

      editor.call();
    });
  }

  // mutate an immutable object via immer "produce"
  replaceCreditCard(func) {
    return this.creditCard = produce(this.creditCard, func);
  }
}
