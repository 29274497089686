
export default class PhotoUploaderFormHelper {
    constructor(uploader) {
        this.uploader = uploader;
        this.$form = this.uploader.$form;
        this.$form.on('submit', function () {
            return false;
        });
    }
}
