import {filterNamesBefore} from "./filterLogic";

/**
 * Called on startup, get all group ids mentioned in
 * any filter bucket.
 *
 * @param allFilterBuckets
 * @returns {*}
 */
export function extractGroupIdsFromAllBuckets(allFilterBuckets) {
  let result = {};

  for (let buckets of _.values(allFilterBuckets)) {
    for (let bucket of _.values(buckets)) {
      let {groupIds} = bucket;
      if (groupIds) {
        for (let groupId of groupIds) {
          result[groupId] = true;
        }
      }
    }
  }

  return _.keys(result);
}

export function requireBuckets({filterName, allFilterBuckets = {}}) {
  const buckets = allFilterBuckets && allFilterBuckets[filterName];
  if (!buckets)
    throw new Error(`${filterName}: cannot find buckets in: ${_.keys(allFilterBuckets).join(" ")}`);

  return buckets;
}

/**
 * Given one filter and its buckets,
 * return the list of matching item ids.
 *
 * @param filterName
 * @param selections
 * @param buckets
 * @param allFilterSelections
 * @param allFilterBuckets
 * @returns {number[]}
 */
export function selectionsToItemIds({
  filterName,
  selections,
  buckets,
  allFilterSelections = {},
  allFilterBuckets = {}
}) {
  buckets ||= requireBuckets({filterName, allFilterBuckets});
  selections ||= allFilterSelections[filterName];

  return _(selections).keys()
    .map(k => buckets[""+k] || [])
    .flatten().uniq().value();
}

/**
 * Used to build filteredItemIds in grid filter state - this will then be
 * used to select items.
 *
 * @param filterNames
 * @param allFilterSelections
 * @param allFilterBuckets
 * @returns {object}   { String => Array<Number>}
 */
export function allFilterSelectionsToItemIds({
  filterNames,
  allFilterSelections = {},
  allFilterBuckets = {}
}) {
  filterNames ||= _.keys(allFilterBuckets); // 2024-06 was allFilterSelections

  return _.reduce( filterNames, (result, filterName) => {
    result[filterName] = selectionsToItemIds({
      filterName, allFilterSelections, allFilterBuckets })
    return result;
  }, {});
}

/**
 * Given the hash of {filterName => [itemId, itemId...], filterName => []}
 * produced by allFilterSelectionsToItemIds, consolidate it into a single
 * itemId list, which is the INTERSECTION of all NON-EMPTY right-hand-sides.
 *
 * @param filteredItemIdLists
 * @param filterNames
 * @returns {number[]}
 */
export function selectFilteredItemIds(filteredItemIdLists, filterNames= null) {
  if (!filterNames) {
    filterNames = _.keys(filteredItemIdLists);
  }

  const idLists = filterNames.map( k => filteredItemIdLists[k] ).filter(list => !_.isEmpty(list));
  return _.intersection(...idLists);
}

export function selectDisabledOptionIds({
  filtersTouched,
  filteredItemIdLists, // { filterName: [group-id, group-id, ...] }
  allFilterBuckets,
  allFilterOptions
}) {
  if (_.isEmpty(allFilterBuckets) || _.isEmpty(allFilterOptions) || _.isEmpty(filteredItemIdLists))
    return {};

  const filterNames = _.keys(allFilterOptions);
  return _.reduce(filterNames, (result, filterName) => {
    result[filterName] = selectDisabledOptionIdsForFilter({
      filterName,
      filtersTouched,
      filteredItemIdLists,
      buckets: allFilterBuckets[filterName],
      // FIXME options: allFilterOptions[filterName],
    });
    return result;
  }, {});
}

export function selectDisabledOptionIdsForFilter({
  filtersTouched,
  filterName,
  filteredItemIdLists = {},
  buckets={}
}) {
  // only filters touched BEFORE this one can occlude our options.
  let beforeFilters= filterNamesBefore(filtersTouched, filterName);

  if (_.isEmpty(buckets) || _.isEmpty(beforeFilters))
    return [];

  let result = [];

  for (let otherFilter of beforeFilters) {
    let goodItems = filteredItemIdLists[otherFilter];

    if (_.isEmpty(goodItems))
      continue;

    for (let bucketId in buckets) {
      let goodIds = (buckets[bucketId] || [])
        .filter( id => _.includes(goodItems, id))
      if (_.isEmpty(goodIds))
        result.push(bucketId);
    }
  }

  return _.uniq(result);
}

/**
 * Given a list of gridItems, return only those whose ids appear in the
 * filteredItemId list produced by selectFilteredItemIds.
 *
 * @param gridItems
 * @param filteredItemIds
 * @returns {[{foo: number}]}
 */
export function selectFilteredGridItems({gridItems, filteredItemIds}) {
  if (_.isEmpty(filteredItemIds)) {
    console.warn("filteredItemIds not present, showing all grid items")
    return gridItems;
  }

  return gridItems.filter( item => _.includes(filteredItemIds, item.id));
}

