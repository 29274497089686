import React, {useState, useEffect, useContext} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import IncompatibleVehicleDisplay from "../components/IncompatibleVehicleDisplay";
import CompletedVehicleChooserDisplay from "../components/CompletedVehicleChooserDisplay";
import DetailsOnlySelector from "../components/DetailsOnlySelector";
import FullVehicleSelector from "../components/FullVehicleSelector";
import ChooserStatus from "../logic/ChooserStatus";
import {useVehicleSelectorConfig} from "../logic/chooserConfig";

export default function VehicleSelectorUi({
  choices, options, customerVehicle,
  onChoiceMade, //callback
  productDetail, // lloyd,
  ...props
}) {
  const config = useVehicleSelectorConfig();

  // FIXME: verify that these are in config
  // FIXME: ignoreInitialVehicle - this is true on item pages - move to config
  const { ignoreInitialVehicle = false, productContext = {}  } = config;

  // FIXME deprecated
  let status = new ChooserStatus({
    levels: config.levels,
    customerVehicle, choices, options, productDetail
  })

  if (customerVehicle && (customerVehicle.source == 'initial')) {
    if (status.isIncompatible() || (status.productStatus() === 'fail')) {
      return <IncompatibleVehicleDisplay {...props} />
    }
  }

  // 2022-08 ignore initial vehicle on item pages
  if (ignoreInitialVehicle && customerVehicle) {
    if (customerVehicle.source == 'initial') {
      customerVehicle = null;
    }
  }

  if (status.isComplete()) {
    // On group pages only, when it appears complete on startup,
    // wait for products to load.
    if (productContext.groupId && (status.productStatus() === 'never')) {
      // prevent a brief flash claiming it fits when we don't know that
      return null;
    }

    return <CompletedVehicleChooserDisplay
      customerVehicle={customerVehicle}
    />

    // FIXME: vehicle already set - if config.flavour == item, do something else
  } else if (customerVehicle && (customerVehicle.source == 'initial')) {
    return <DetailsOnlySelector
      customerVehicle={customerVehicle}
      choices={choices}
      options={options}
      onChoiceMade={onChoiceMade}
    />
  } else {
    // Some or all levels are not yet selected.
    return <FullVehicleSelector
      choices={choices}
      options={options}
      onChoiceMade={onChoiceMade}
    />
  }

}
