import EditObject from "./EditObject";
import Validator from "./Validator";

export default class EditAddress extends EditObject {

  static fieldLabels = {
    email: 'Email Address',
    street: 'Street Address',
    street2: 'Street (Line 2)',
    state_code: 'State',
  }


  static fieldRules = {
    first_name: {presence: true},
    last_name: {presence: true},
    email: {presence: true, format: Validator.formats.email},
    street: {presence: true},
    city: {presence: true},
    state_code: {presence: true},
    zip: {
      presence: true, format: {
        format: Validator.formats.zip_us, message: 'Please enter a 5 or 9 digit American zip code'
      }
    },
    phone: {presence: true, phone: {reformat: true}},

    'shipping/street': {
      presence: true, format: {
        negate: true,
        format: Validator.formats.po_box,
        message: "Sorry, we cannot ship to PO Boxes."
      }
    },

    'shipping/street2': {
      // presence not required
      format: {
        negate: true,
        format: Validator.formats.po_box,
        message: "Sorry, we cannot ship to PO Boxes."
      }
    },

  }

  // Adds the various internal state fields to
  constructor({usage, ...opts}) {
    super(opts);
    this.usage = usage;

    // billing address has no email field.
    if (usage === 'billing') {
      this.rules = _.omit(this.rules, 'email');
    }
  }
}
