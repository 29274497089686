import React, {Component} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import ItemFactBox from "./ItemFactBox";
import ItemImageBox from "./ItemImageBox";
import ItemCartBox from "./ItemCartBox";

export default class UniversalItemDisplay extends React.Component {
  static propTypes = {
    product: PropTypes.object.isRequired,
    application: PropTypes.object, // optional, for universal
    dispatch: PropTypes.func.isRequired,
    shoppingCart: PropTypes.object
  }

  static defaultProps = {
    application: {}
  }


  render() {
    const { shoppingCart, product } = this.props;

    const cartIndex = _.keyBy( (shoppingCart && shoppingCart.cartItems), 'productId');

    const cartItem = cartIndex[product.id];

    return <div className="item-display">
      <ItemImageBox {...this.props} cartItem={cartItem} />
      <ItemFactBox {...this.props} cartItem={cartItem} />
      <ItemCartBox {...this.props} cartItem={cartItem} />
    </div>;
  }
}
