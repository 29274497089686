import TopNavWidget from './TopNavWidget';

//
// CategoryMenuBar - the blue bar primary nav.
//
export default class CategoryMenuBar extends TopNavWidget {
    startup(page) {
        this.$targets = $('.dropdown-menu');
        this.active_toggle_class = 'dropdown-toggle-active';
        this.active_menu_class = 'dropdown-menu-active';

        this.bindToggles($('.dropdown-toggle'));
        this.watchBodyClicks(page);
        return this;
    }

    targetForButton(link) {
        let { id } = link;
        if (!id) {
            return null;
        }

        return $('#' + id.replace('-toggle', ''));
    };

    // 2020 - do other things after open/close.
    // when any of these menus are open, add a class to the header element that contains them.
    afterToggle(is_open) {
        //console.log("category menu bar - " + is_open);

        // 2020 - get <nav> element above this, and harvest its id.
        let id = this.getNavbarId(this.$clicked);

        let klass = id + "-is-open";
        this.setNavIsOpenClass($('header').first(), klass, is_open);
    }

    setNavIsOpenClass(header, new_class, is_open) {
        // first remove ALL nav-is-open classes from header
        let classes = (""+ header.attr('class')).split(/\s+/);
        for (let klass of classes) {
            if (klass.match(/nav-is-open/)) {
                if (!is_open || (new_class != klass))
                    header.removeClass(klass);
            }
        }

        // and then maybe add the new one in.
        if (is_open) {
            header.addClass(new_class);
        }

        // console.log("header: " + JSON.stringify( header.attr('class') ))
    }

    getNavbarId($button) {
        let id = null;

        if ($button) {
            let parent = $button.parents('nav').first()
            id = parent ? parent.attr('id') : null;
        }

        return id || 'primary-nav';
    }
}

