
// uses jquery barrating extension

export default class StarWidgetGroup {
    constructor() {
        // cannot arrow function here, jquery insists on owning 'this'
        let self = this;
        $('.score-widget select').each( function() { self.setup(this) } );
    }

    setup(elem) {
        let $e;
        $e = $(elem);

        if (! $e.barrating) {
            return console.error("jquery barrating extension not installed");
        }
        return $e.barrating({
            initialRating: -1,
            theme: 'css-stars',
            showSelectedRating: true
        }).barrating('show');
    }
}
