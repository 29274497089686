import React from 'react';
import PropTypes from 'prop-types';

import ItemFactBox from "./ItemFactBox";
import ItemImageBox from "./ItemImageBox";
import ItemCartBox from "./ItemCartBox";

export default function StandardItemDisplay(props) {
  const { cartItem } = props;

  const classes = cartItem ? 'item-display in-cart' : 'item-display';

  return <div className={classes}>
    <ItemImageBox {...props} />
    <ItemFactBox {...props} />
    <ItemCartBox {...props} />
  </div>;

}

StandardItemDisplay.propTypes = {
  product: PropTypes.object.isRequired,
  application: PropTypes.object, // optional, for universal
  dispatch: PropTypes.func.isRequired,
  cartItem: PropTypes.object
}

StandardItemDisplay.defaultProps = {
  application: {}
}
