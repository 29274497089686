import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

// TODO: use logic in ActivateComponentTags to scan for react components in these tube sections!

export default function OpenMenuPanel({
  name = '',
  html = '',
  ...props
}) {

  useEffect(() => {
    console.log(`FIXME: opened panel ${name}, scan for components in html`)
  }, [name])

  return <div className="container mx-auto bg-gray-200 text-black min-h-16">
    <div dangerouslySetInnerHTML={{__html: html}}/>
  </div>

}
