import CartCodeCookie from './CartCodeCookie';

export default function CartCodeFinder() {
}

CartCodeFinder.prototype.getCartCode = function () {
    var cc;
    cc = this.getCartCodeFromDocument();
    if (cc) {
        return cc;
    }
    cc = this.getCartCodeFromCookie();
    return cc;
};

CartCodeFinder.prototype.getCartCodeFromDocument = function () {
    var $cc, code;
    $cc = $('#connect-id');
    if ($cc[0]) {
        code = $cc.text();
        if (code) {
            return code;
        }
    }
    return null;
};

CartCodeFinder.prototype.getCartCodeFromCookie = function () {
    var c;
    c = new CartCodeCookie();
    return c.cart_code;
};

CartCodeFinder.prototype.getItemCountFromCookie = function () {
    var c;
    c = new CartCodeCookie();
    return c.item_count;
};

