import TopNavWidget from './TopNavWidget';
import $ from "jquery";

export default class HamburgerButton extends TopNavWidget {
    startup(page) {
        this.$targets = $('#container-header');
        this.$toggles = $('#menu-button');
        this.active_toggle_class = 'hamburger-open';
        this.active_menu_class = 'menu-open';

        this.bindToggles(this.$toggles);
        this.finishSetup();
        return this;
    }

    // 2020 - do other things after open/close.
    afterToggle(is_open) {
        if (is_open) {
            this.$targets.removeClass("menu-closed");
        } else {
            this.$targets.addClass("menu-closed");
        }
    }
}
