import React from 'react';
import PropTypes from 'prop-types';
import {isEmpty} from 'lodash';

// Found on the Show brand page (like /brand/Coverking)
const StaticLinkBox = ({title, links}) => {
  if (isEmpty(links)) return null;

  return (
    <div className="grid-sidebar-box">
      <h4>{title}</h4>
      <div className="choices">
        {links.map( (link, i) => <label key={i}>
          <a href={link.path}>{link.title}</a>
        </label> ) }
      </div>
    </div>
  );
}

// StaticLinkBox.propTypes = { }

export default StaticLinkBox;

