import React, {useState, useEffect, useCallback} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import MenuBarItemLabel from "./MenuBarItemLabel";
import LocalUrlMaker from "../lib/LocalUrlMaker";

export default function MenuBarItem({
  item,
  toggleMenuItem,
  ...props
}) {

  let {
    name,
    className = "inline-block mr-4",
    tubeSectionId,
    displayFlavour = 'text',
    icon,
  } = item;

  const handleClick = useCallback((evt) => {
    evt?.preventDefault();
    toggleMenuItem(item);
  }, [tubeSectionId]);

  return <li className={className}>
    <a className="text-white hover:text-brand-orange pointer-cursor" onClick={handleClick}>
      <MenuBarItemLabel item={item}/>
    </a>
  </li>
}

