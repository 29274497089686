import React from 'react';
import {camelizeKeysDeep} from "../ReduxStore/Helpers/transformObject";
import {describeVehicle} from "../VehicleChooser/logic/vehicle";
import {deleteSessionVehicle} from "../ReduxStore/CustomerVehicle/vehicleSlice";
import {useDispatch} from "react-redux";
import clsx from "clsx";

/**
 * Displayed in the Top Nav bar when a vehicle is selected,
 * allowing for the vehicle to be reset.
 */
export default function ShowCustomerVehicleUI({
  uplink, customerVehicle, icon="", classNames=""}) {

  const dispatch = useDispatch();

  // FIXME: camelize when putting into redux
  customerVehicle = camelizeKeysDeep(customerVehicle);

  const {vehicleUrl, source} = customerVehicle;
  const caption = describeVehicle(customerVehicle);

  console.log("HAVE VEHICLE: ", customerVehicle);

  const handleClick = (evt) => {
    evt.preventDefault();

    console.log(`ShowCustomerVehicle: delete; uplink=${uplink}`);

    // inform back-end that it should be cleared from session;
    // this will also clear from Redux, after the operation completes.
    dispatch(deleteSessionVehicle({
      by: 'ShowCustomerVehicleUI.handleClick', uplink: uplink}));

    // clear from Redux
    // dispatch(vehicleSlice.actions.clearVehicle({by: 'ShowCustomerVehicleUI.handleClick'}));
  }

  classNames = clsx(classNames, 'stored-vehicle-inner',
    `${customerVehicle.source || 'vehicle'}-selected`);

  // 2022-03 link to vehicle page if it exists
  return (
    <div className={classNames}>
      {icon && <i className={icon}/>}
      <strong>
        {vehicleUrl ? <a href={vehicleUrl}>{caption}</a> : caption}
      </strong>
      {' '}
      <a className="nobr click-me" id="clear-vehicle-top"
        onClick={handleClick}>change vehicle</a>
    </div>)
}

