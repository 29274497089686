import React from 'react';
import _ from 'lodash';
import ZoidbergFormation from "./ZoidbergFormation";
import {SIZE_LABELS} from "../constants";

// make one big grid, like a table, with a Fragment for each row.


export default function ZoidbergFlavourSection({
  flavour, imageList, ...props
}) {

  // img has status, flavour, ordinal
  const formations = _.groupBy(imageList, img => img.sortOrder || 0);

  const formationNumbers = _.sortBy(_.keys(formations), k => +k);

  // [ icon sm md lg ] is the order i want, conveniently reverse alpha!
  const sizeCodes =
    _.reverse(_.uniqBy(imageList.map(img => img.sizeCode)));

  return (
    <div className={`pt-4 relative grid gap-x-2 gap-y-4 grid-cols-${sizeCodes.length}`}>
      {sizeCodes.map(size => <SizeHeader key={size} size={size} />)}

      {formationNumbers.map(k => <ZoidbergFormation
        key={k}
        ordinal={k}
        imageList={formations[k]}
        sizeCodes={sizeCodes}
        {...props}/>
      )}
    </div>
  );

}

function SizeHeader({size}) {
  return <div style={{maxWidth: '220px'}}
    className="text-xl text-center font-bold">
    {SIZE_LABELS[size] || size}
  </div>
}
