import React from 'react';

export default function EditImageTitleBar({image, callbacks, ...props}) {

  const {productGroupId, status, sizeCode, sortOrder, flavour,
  width, height} = image;

  function handleGoBack() {
    callbacks.setSelectedId(null);
  }

  return <div className="text-xl relative my-2">
    <div className='float-right font-bold'>Id {image.id}</div>

    <button className="bg-gray-300 text-gray-600 rounded-1 px-2 py-1 mr-2"
      onClick={handleGoBack}>
      <span className="fa fa-chevron-left"/>
    </button>

    <b>{`Group ${productGroupId}: ${_.startCase(image.flavour)} Image `}
      {sortOrder} &bull; {sizeCode} &bull; {' '}
      {width}x{height} {' '}
    </b>
  </div>
}
