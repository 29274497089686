import React, {useContext} from 'react';

import GridSizeControl from "./GridSizeControl";
import GridSortControl from "./GridSortControl";
import GridFilterActivator from "./GridFilterActivator";
import GridPaginator from "./GridPaginator";

/**
 * 2024-05 multi-grid aware
 * 2023-11 modernised, excess props removed.
 *
 * TOP: SIZE and SORT widgets; SIZE is hidden for Mobile
 * BOTTOM: PAGINATOR, SIZE and SORT widgets; SORT is hidden for Mobile
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function GridControlBar({
  gridId,
  placement, // "top" or "bottom"
  gridState,
  filterState,
  gridItems = [],
  filteredItems = [],
  visibleItems = []
}) {
  const {sidebarOpen = false} = filterState;

  return (
    <div className="grid-control-bar relative text-right clearfix">
      <GridFilterActivator
        gridId={gridId}
        className="grid-filter-activator inline md:hidden"
        sidebarOpen={sidebarOpen}
        caption="Filter"/>

      {(placement !== "top") && <GridPaginator
        gridId={gridId}
        filteredItemCount={filteredItems?.length}
        gridState={gridState}
      />}

      <GridSizeControl
        gridId={gridId}
        placement={placement}
        gridItemCount={gridItems?.length}
        filteredItemCount={filteredItems?.length}
        visibleItemCount={visibleItems?.length}
        gridState={gridState}
      />

      <GridSortControl
        gridId={gridId}
        placement={placement}
        gridState={gridState}
        gridItems={gridItems}/>
    </div>
  );
}
