/*
 * 2018-12
 */

import SpeakerToAnything from './SpeakerToAnything';

export default class SpeakerToAffirm extends SpeakerToAnything {

  isLoaded() {
    const { affirm } = globalThis;
    // script appears to create:
    // _affirm_post_message_toast-application
    // affirm affirm_tracker_promise

    // 2023-05 the obfuscated loader creates a dummy window.affirm,
    // but when the real script loads it merges more content into it
    return !!(affirm && affirm.VERSION)
    //return (typeof window.affirm === 'object');
  }

  // Affirm loader uses window._affirm_config, which looks like:
  // {public_api_key: 'SOMETHING', script: 'https://cdn1-sandbox.affirm.com/js/v2/affirm.js'}
  // after loading it removes the 'script'
  preconfigure() {
    // global._affirm_config = this.getServiceConfig();
    // console.log("AFFIRM CONFIG:", global._affirm_config )
  }

}
