import React from 'react';
import HideButton from "./HideButton";

function FooterCell({item, items, callbacks}) {
  let path = item.path;

  return <th>
    <div className="more-details"><a className="button" href={path}>More Details</a></div>

    {items.length > 1 && <div className="remove"><HideButton item={item} callbacks={callbacks}/></div>}
  </th>
}

const TableFoot = ({items, callbacks, ...props}) => {
  return (
    <tfoot>
    <tr>
      <th className="empty"></th>
      {items.map((item, i) => <FooterCell key={item.id} items={items} item={item} callbacks={callbacks}/>)}
    </tr>
    </tfoot>
  );
}

// TableHead.jsx.propTypes = { }

export default TableFoot;

