import React from 'react';
import TableHead from "./TableHead";
import AttributeRow from "./AttributeRow";
import TableFoot from "./TableFoot";
import RemoveItemsRow from "./RemoveItemsRow";
import ScoresRow from "./ScoresRow";
import PricingRow from "./PricingRow";
import PromosRow from "./PromosRow";
import FitRow from "./FitRow";
import InstallTimeRow from "./InstallTimeRow";

const CompareItemsTable = ({items, callbacks, attributes, allValues = {}, ...props}) => {

  const {compatibility = {}, vehicle = {}, allScores = {}} = props;

  let classes = [
    'compare-items', 'product-grid'];

  const visibleItems = items;

  return (
    <table id="compare-items-table" className={classes.join(' ')}>
      <TableHead items={visibleItems} callbacks={callbacks}/>
      <tbody>
      <RemoveItemsRow items={visibleItems} callbacks={callbacks}/>
      <PricingRow items={visibleItems}/>

      <tr>
        <th>Shipping</th>
        {visibleItems.map((item) => <td key={item.id}>Free Shipping</td>)}
      </tr>

      <PromosRow items={visibleItems}/>

      <ScoresRow items={visibleItems} allScores={allScores}/>

      <InstallTimeRow items={visibleItems} allScores={allScores}/>

      <FitRow items={visibleItems} compatibility={compatibility} vehicle={vehicle}/>

      {attributes.map((a, i) =>
        <AttributeRow attribute={a} items={visibleItems} key={a.name || i} values={allValues[a.id]}/>)}
      </tbody>
      <TableFoot items={visibleItems} callbacks={callbacks}/>
    </table>
  );

}

// CompareItemsTable.propTypes = { }

export default CompareItemsTable;

