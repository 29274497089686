import React from 'react';
import _ from 'lodash';

const FitCol = ({item, vehicle, compat}) => {

  if (_.includes(item.pt, 'U')) {
    return <td className="uni">Universal Fit</td>
  }

  if (vehicle) {
    return <td className={compat ? "fits-vehicle" : "no-fits-vehicle"}>
      <span className={compat ? "fa fa-check" : "far fa-ban"} />

      {compat ? " Fits " : " Does not fit" }
      {' '}
      {vehicle.year} {vehicle.shortMakeName} {vehicle.vehicleModelName}
    </td>
  }

  return <td className="specific">Vehicle Specific Fit</td>
}

const FitRow = ({items, vehicle, compatibility={}}) => {
  if (_.isEmpty(compatibility))
      vehicle = null;

  return (
    <tr className="fit-row">
      <th>Fit</th>
      {items.map(item => <FitCol key={item.id}
        item={item} vehicle={vehicle} compat={compatibility[item.id]} />)}
    </tr>
  );
}

// PromosRow.propTypes = { }

export default FitRow;

