import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';

import braintree from "braintree-web";
import {applePayAvailable} from "../../../ShoppingCart/services/applePayHelpers";
import {startApplePay} from "../../../ShoppingCart/services/startApplePay";
import {boldlyLog} from "../../../Utility/logging";
import {reportStartCheckout} from "../../../ShoppingCart/components/utility/startCheckout";
import {displayError} from "../../../Utility/ReduxHelper";

const isDev = (process.env.NODE_ENV == 'development');

export default function ApplePayButton({braintreeClient, dispatch, shoppingCart, ...props}) {

  const [applePayExists, setApplePayExists] = useState(false);

  const [applePayInstance, setApplePayInstance] = useState(null);

  useEffect(() => {
    if (!braintreeClient) return;

    const exists = applePayAvailable();
    setApplePayExists(exists);

    braintree.applePay.create({client: braintreeClient})
      .then(instance => { setApplePayInstance(instance); })
      .catch(error => displayError({dispatch, error}));

  }, [braintreeClient]);

  if (!applePayExists) // may become true on next render
    return null;

  if (!shoppingCart)
    shoppingCart = props.checkout;

  // shows the pop-up payment form.
  // This also registers callbacks that will be invoked by Apple
  // when payment info is present - see {startApplePay} for details.
  const handleClick = e => {
    boldlyLog("ApplePayButton click - will start");
    try {
      reportStartCheckout({
        shoppingCart, checkoutType: 'apple'})

      startApplePay({
        applePayInstance,
        dispatch,
        shoppingCart
      });

    } catch (error) {
      console.error(`ApplePayButton error: ${error}`);
      displayError({error, dispatch})
    }
  }

  // Provide a blank div for
  // apple to render the button
  return (
    <div className="apple-pay-button-wrapper">
      <div className="apple-pay-button apple-pay-button-black"
        onClick={handleClick} />
    </div>
  );

}

ApplePayButton.propTypes = {
  braintreeClient: PropTypes.object
}
