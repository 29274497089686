import React from 'react';
import clsx from "clsx";

export default function PhoneHoursDisplay({
  phoneNumber, caption, openNow = false
}) {
  const classNames = clsx("call-now", openNow ? 'open-now' : 'not-open-now');

  return <div className={classNames}>
    <OpenNowCaption openNow={openNow} caption={caption}/>
    {phoneNumber && <strong>{phoneNumber}</strong>}
  </div>
}

function OpenNowCaption({openNow = false, caption}) {
  if (openNow) {
    const styles = {
      animation: "fade-in-out-half 4s infinite"
    }
    return <span className="text-brand-orange phones-open"
      style={styles}>
      Phone Lines are Open
      {' '} <em className="underline">NOW</em>!
    </span>
  } else {
    return <span>{caption}</span>
  }
}
