import React from 'react';
import _ from 'lodash';

const PromoCell = ({item}) => {
  let promos = item.promos;
  if (_.isEmpty(promos))
    return <td className="empty"/>

  return <td>
    {promos.map((promo, i) => <div key={i} className={promo.type}>{promo.message || promo.label}</div>)}
  </td>
}

const PromosRow = ({items}) => {
  // return null if any have an empty promo
  if (items.every(item => _.isEmpty(item.promos)))
    return null;

  return (
    <tr className="offers-row">
      <th>Special Offers</th>
      {items.map(item => <PromoCell item={item} key={item.id}/>)}
    </tr>
  );
}

// PromosRow.propTypes = { }

export default PromosRow;

