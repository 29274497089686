/**
 * Given an action with a payload, extract and return the named keys,
 * generating warnings for each.  The first param can be either an action
 * (with a payload member) or the bare payload; pass in the action for
 * better error messages.
 *
 * @example
 *    const { foo, bar } = requirePayloadItems(action, ['foo', 'bar'])
 *
 * @param action
 * @param keys
 * @returns {{}} a hash with the keys you ask for
 */
export function requirePayloadItems(action, keys = []) {
  const out = {};
  const payload = action.payload ? action.payload : action;

  for (let k of keys) {
    if (payload.hasOwnProperty(k)) {
      out[k] = payload[k];
    } else {
      console.warn(`required payload item missing: ${k}}`, action);
      out[k] = null;  // prefer nulls to undefined
    }
  }

  return out;
}
