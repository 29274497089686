import React, {Component} from 'react';
import PropTypes from 'prop-types';
import CartPriceDisplay from "./CartPriceDisplay";
import CartItemHiddenFields from "./CartItemHiddenFields";
import CartNotesDisplay from "./CartNotesDisplay";
import DynamicAddButton from "../../DynamicAddButton";
import CheckoutLink from "../../StandardItems/CheckoutLink";
import ProductWrapper from "../../../logic/ProductWrapper";

export default class AddToCartForm extends Component {
  static propTypes = {
    enabled: PropTypes.bool,
    cartItem: PropTypes.object
    // itemApplications
    // products
    // dispatch
    // pageType  cluster error clusterState itemCache updateState index application product productGroup
    // matchingProducts
  };

  static defaultProps = {enabled: true};

  state = {message: null}

  isEnabled() {
    let {enabled} = this.props;

    if (!enabled)
      return false;

    let oos = this.outOfStock();

    return !oos;
  }

  getClasses() {
    let classes = ["cluster-cart-form"];

    if (this.outOfStock()) {
      classes.push("out-of-stock");
    }

    classes.push(this.isEnabled() ? "cart-enabled" : "cart-disabled");

    return classes;
  }

  // 2022-09 not used

  // onSubmit = (evt) => {
  //   let {clusterState, updateState} = this.props;
  //
  //   if (this.isEnabled())
  //     return true;
  //
  //   if (evt && evt.preventDefault)
  //     evt.preventDefault();
  //
  //   let cs = _.extend({}, clusterState, {highlighted: true});
  //
  //   updateState({clusterState: cs});
  //
  //   return false;
  // }

  // getUrl() {
  //   return LocalUrlMaker.jsonPath("/cart_items");
  // }

  outOfStock() {
    let p = new ProductWrapper(this.props.product);
    return p.outOfStock();
  }

  render() {
    let enabled = this.isEnabled();

    const {cartItem, shoppingCart} = this.props;

    const {message} = this.state;

    // 2022-09 this is NO LONGER A FORM due to the DynamicAddButton doing
    // all the heavy lifting.

    // method="post" action={this.getUrl()} onSubmit={this.onSubmit}
    return <div className={this.getClasses().join(' ')}>
      <CartPriceDisplay {...this.props} />

      {enabled && <CartItemHiddenFields {...this.props} />}

      <DynamicAddButton {...this.props} disabled={!enabled}/>

      {cartItem && <CheckoutLink shoppingCart={shoppingCart}/>}

      {this.outOfStock() && <div className="stock-status out-of-stock">Out Of Stock</div>}

      {message && <div className="cart-message">{message}</div>}

      {enabled && <CartNotesDisplay {...this.props} />}
    </div>
  }
}
