import AirmansAlphabet from './AirmansAlphabet';

export default function CartCodeDisplay() {
}

CartCodeDisplay.prototype.mainDisplay = function () {
    return this._main_display || (this._main_display = $('#connect-id-display'));
};

CartCodeDisplay.prototype.container = function () {
    return this._container || (this._container = $('#footer-cart-code'));
};

CartCodeDisplay.prototype.displayId = function (cart_code) {
    this.mainDisplay().empty().append("<span>Connect ID: </span>").append($('<strong id="connect-id">').text(cart_code));
    this.container().find('h3').empty().text("How to say it:");
    return this.container().find('.phonetic').empty().html(this.cartCodePhonetic(cart_code));
};

CartCodeDisplay.prototype.cartCodePhonetic = function (cart_code) {
    return new AirmansAlphabet().expand(cart_code);
};

CartCodeDisplay.prototype.showEmpty = function () {
    this.mainDisplay().empty().append($('<a class="request-cart-code" rel="nofollow">').text("Create Connect ID"));
    this.container().find('h3').empty().text("Request a Connect ID:");
    return this.container().find('.phonetic').empty().append(this.createButton());
};

CartCodeDisplay.prototype.createButton = function () {
    return $('<button class="request-cart-code" rel="nofollow">').text("Create Connect ID");
};

