import React from 'react';

export default function SaveCartButton({callbacks, shoppingCart = {}, ...props}) {
  const handleClick = (e) => {
    let {openSaveCart} = callbacks;

    openSaveCart({flavour: 'requested'});
  }

  const {customerId} = shoppingCart;

  return (
    <div id="save-cart-container" className={customerId ? "saved" : "unsaved"}>
      <button className="btn save-cart-button" type="button"
        onClick={handleClick}>
        Save Your Cart
      </button>
    </div>
  );
}
