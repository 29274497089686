import React from 'react';

const HideButton = ({item, callbacks}) => {
  let itemId = item.id;

  const onClick = (evt) => {
    evt.preventDefault();
    callbacks.hideItem(item.id);
  }

  return (
    <a className="click-me hide-button" onClick={onClick}>
      <span className="fa fa-lg fa-times" />
      {' '}
      Remove
    </a>
  );
}

// HideButton.jsx.propTypes = { }

export default HideButton;

