import React, {Component} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

export default class GroupReviewRequestLink extends Component {
  static propTypes = {
    onClick: PropTypes.func.isRequired,
    count: PropTypes.number,
    max: PropTypes.number
  }

  render() {
    let {count, max, onClick} = this.props;

    if (count >= max)
      return null;

    return <div className="see-all-reviews"><p>
      <a href="#" onClick={onClick}>
        {'See Next '}
        <strong>10</strong>
        {' of '}
        <strong>{max}</strong>
        {' reviews. '}
      </a>
    </p></div>
  }
}
