import React, {Component} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import OptionGallery from '../OptionBuilder/OptionGallery';
import LogoPicker from "./LogoPicker";
import OptionDefinition from "../models/OptionDefinition";
import LloydCart from "../models/LloydCart";

export default class LloydLogoChooser extends Component {
  static propTypes = {
    cart: PropTypes.instanceOf(LloydCart).isRequired,
    option: PropTypes.instanceOf(OptionDefinition).isRequired
  }

  getValues() {
    let {values} = this.props;
    //return _.filter(attr.allValues(), (l) => ! l.canSelectColor());
  }

  render() {
    return <OptionGallery
      pickWith={LogoPicker}
      {...this.props}
      Xvalues={this.getValues()}
    />
  }

}
