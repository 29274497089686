import React from 'react';
import _ from 'lodash';

import FilterCheckboxLabel from "./FilterCheckboxLabel";
import gridFilterSlice from "../../../ReduxStore/GroupGrid/gridFilterSlice";
import {filterNamesBefore} from "../logic/filterLogic";
import ResetAllFilters from "./ResetAllFilters";
import {useDispatch} from "react-redux";

// 2023-11 MH modernised
export default function FilterWidget({
  gridId,
  filter,
  disabledOptions = [],
  // options, buckets, selections are the hashes for THIS FILTER ONLY
  options = {},
  selections = {},
  filtersTouched
}) {
  const dispatch = useDispatch();

  const filterName = filter.name;

  // all choices available, as an array
  const optionList = _(options)
    .values().sortBy("sortOrder").value()

  const {displayMode = 'open'} = filter;

  const classNames = [
    'grid-sidebar-box',
    'grid-sidebar-box-' + displayMode
  ];

  const disabledMap = _.keyBy(disabledOptions);
  // let disabledCount = _.size(disabledMap);

  // toggle this filter widget open/closed
  const handleToggleOpen = evt => {
    dispatch(gridFilterSlice.actions.toggleOpenClosed({
      gridId,
      filterName
    }));
  }

  return (
    <div className={classNames.join(' ')} id={`${filterName}-filter`}>
      <h4>
        <a onClick={handleToggleOpen}>
          {filter.displayName || _.capitalize(filterName)}</a>
      </h4>

      <div className="content-wrapper">
        <div className="content">
          <div className="choices">
            {optionList.map(option => {
              const disabled = disabledMap[option.id];

              if (disabled) return null;

              return <FilterCheckboxLabel
                gridId={gridId}
                key={option.id}
                filter={filter}
                filterOption={option}
                disabled={disabled}
                selected={!!(selections["" + option.id])}
                filtersTouched={filtersTouched}
              />
            })}
          </div>
        </div>
      </div>

    </div>
  )
}

