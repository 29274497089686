import React, {Component} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

import UniversalItemDisplay from "./UniversalItemDisplay";

export default class UniversalItemList extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired
  };

  static defaultProps = {};

  sortedProducts() {
    let { products } = this.props;
    if (_.isEmpty(products))
      return [];

    return _(products).values().sortBy( (p) => p.name ).value();
  }


  render() {
    const { callbacks, shoppingCart, dispatch } = this.props;

    try {
      const prods = this.sortedProducts();

      // DEPRECATED LOGIC: do not render when there is exactly one universal item, for
      // it shows in the upper right, next to the group image container, instead.

      // 2022-03 render it anyway so we get bullets/pdf links
      // if (prods.length == 1) return null;

      return (
        <div className="items-list items-list-universal">
          {prods.map((p) => <UniversalItemDisplay
            shoppingCart={shoppingCart} key={p.id} product={p} dispatch={dispatch}/>)}
        </div>
      );
    } catch (err) {
      console.error("UniveralItemList error: ", err);
      return <h1>ERROR</h1>
    }
  }
}


