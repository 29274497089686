import React from 'react';

import GoogleGridItemsReporter from "./GoogleGridItemsReporter";

// this merely returns the Google implementation for now,
// but is intended to also do adobe/facebook/whatever
export default function GridItemsReporter(props) {
  return (
      <GoogleGridItemsReporter {...props} />
  );
}
