import {extractCartTotals} from "../../DynamicCart/logic/cartHelpers";
import {pluralize} from "../../lib/TextHelpers";
import EmbeddedConfig from "../../lib/EmbeddedConfig";
import {isIPhone} from "../../lib/DeviceDetector";
import {getParam, KNOWN_PARAMS} from "../../lib/params";

const isDev = (process.env.NODE_ENV == 'development');

export function applePayAvailable() {
  const {ApplePaySession} = globalThis;

  // param apple-pay=1 to enable apple pay, regardless of reality
  if (getParam(KNOWN_PARAMS.APPLE_PAY_ENABLED)) {
    console.log("enabling ApplePay")
    return true;
  }

  if (!ApplePaySession) return false;

  if (!ApplePaySession.canMakePayments || !ApplePaySession.canMakePayments())
    return false;

  return isIPhone() || isDev;
}

export function buildAppleLineItem(cents, label = "Label", stuff = {}) {
  if (cents === null) return null;

  let item = Object.assign({
    label: label,
    amount: new Money(cents).toDollars(),
    type: "final"
  }, stuff);

  return item;
}

/**
 * Used after redacted shipping address becomes available,
 * we send these to apple for display.
 *
 * @param cartTotals
 */
export function buildAppleLineItems(cart) {
  let totals = extractCartTotals(cart);
  let {shippingAddress = {}, cartItems = []} = cart;

  let state = shippingAddress.stateName || shippingAddress.stateCode;

  const {
    effectiveSubtotal = 0,
    regularSubtotal = 0,
    effectiveTax = null,  // null if undef!
    effectiveShipping = null
  } = totals;

  let lineItems = [];

  if (regularSubtotal) {
    let label = "Subtotal";
    if (cartItems.length > 0)
      label = `Subtotal (${pluralize(cartItems.length, "item")})`;

    lineItems.push(
      buildAppleLineItem(regularSubtotal, label))

    let discount = (+regularSubtotal - +effectiveSubtotal);
    if (discount > 1) {
      lineItems.push(
        buildAppleLineItem(-discount, "Discount"))
    }
  }

  if (effectiveTax !== null) {
    lineItems.push(
      buildAppleLineItem(effectiveTax,
        state ? `${state} Tax` : "Tax"))
  }

  if (effectiveShipping !== null) {
    let label = state ? `Shipping to ${state}` : "Shipping";
    if (effectiveShipping < 1)
      label = "Free Shipping"

    lineItems.push(
      buildAppleLineItem(effectiveShipping, label))
  }

  return lineItems.filter(x => x && x.label);
}

export function appleTotalLineItem(cart) {
  let totals = extractCartTotals(cart);
  let {effectiveTotal} = totals;
  if (!effectiveTotal) return null;

  return buildAppleLineItem(effectiveTotal, companyName())
}

export function companyName() {
  let name = EmbeddedConfig.getDisplayName();

  return isDev ? `${name} SANDBOX` : name;
}

/**
 * Convert an Apple address to AAG field names (camelized)
 *
 * @param input
 * @returns {{zip: string, firstName: (string|*|string), lastName: (*|string), city: string, phone: (string|*|string),
 *   street: (*|string), countryCode: string, stateCode: (string|*), street2: (*|string)}|null}
 */
export function convertAppleAddress(input = {}) {
  if (_.isEmpty(input)) return null;

  let addressLines = input.addressLines || [];

  const addr = {
    firstName: input.givenName || '',
    lastName: input.familyName || '',
    street: addressLines[0] || '',
    street2: addressLines[1] || '',
    city: input.locality || '',
    stateCode: input.administrativeArea || '',
    countryCode: input.countryCode || '',
    phone: input.phoneNumber || '',
    zip: input.postalCode || '',
  }
  return addr;
}
