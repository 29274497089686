import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import ZoidbergGridItem from "./ZoidbergGridItem";
import {cellClasses, cellStyles, SIZE_LABELS} from "../constants";
import clsx from "clsx";
import NumberIcon from "./NumberIcon";
import DownscaleButton from "../editor/DownscaleButton";

export default function ZoidbergFormation({
  imageList, ordinal, sizeCodes, callbacks, ...props
}) {

  // hash {sizeCode => {image}, sizeCode => {image}}
  const imagesBySize = _.keyBy(imageList, img => img.sizeCode);

  return (
    <React.Fragment>
      {sizeCodes.map((sizeCode, columnIndex) => {
        const image = imagesBySize[sizeCode]; // may be null

        if (!image) {
          return <MissingImagePlaceholder
            columnIndex={columnIndex}
            key={sizeCode}
            sizeCode={sizeCode} ordinal={ordinal}/>
        }

        return <ZoidbergGridItem
          key={sizeCode}
          columnIndex={columnIndex}
          image={image}
          callbacks={callbacks} {...props} />
      })}
    </React.Fragment>
  );

}

function MissingImagePlaceholder({sizeCode, ordinal, columnIndex}) {
  const classNames = clsx("relative text-center text-gray-400 pt-8", cellClasses({sizeCode}));

  return <div className={classNames}>
    {!columnIndex && <NumberIcon ordinal={ordinal}/>}
    <div className="text-2xl">{ordinal} &bull; {sizeCode}</div>
    <div className="text-lg">{SIZE_LABELS[sizeCode] || sizeCode}</div>
    <div>image not found</div>
    {(sizeCode !== 'lg') && <DownscaleButton
      classNames="text-sm py-1 px-2 text-white mx-auto inline-block text-white"/>}
  </div>
}

