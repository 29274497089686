import clsx from "clsx";
import React from "react";
import {editImage} from "../actions";

export default function HideButton({image, callbacks, classNames}) {
  const {status} = image;

  const hidden = (status === 'hidden');

  console.log("HideButton - hidden=" +hidden + " status=" + status);

  function handleHide(evt) {
    const msg = "Hide this image?\n\nThe file will remain on the server, but no one will be able to see it.";
    if (!confirm(msg))
      return;

    editImage({image, callbacks, edits: {
      status: 'hidden' }});
  }

  function handleActivate(evt) {
    const msg = "Activate this image?\n\nThis will restore a hidden image, making it visible to all.";
    if (!confirm(msg))
      return;

    editImage({image, callbacks, edits: {
        status: 'active' }});
  }


  if (hidden) {
    return <button className={clsx(classNames, 'bg-brand-orange')}
      onClick={handleActivate}>
      <span className="fa fa-eye"/>
      {' '}
      Restore to Active
    </button>
  }

  return <button className={clsx(classNames, 'bg-red-400')}
    onClick={handleHide}>
    <span className="fa fa-eye-slash"/>
    {' '}
    Hide
  </button>

}
