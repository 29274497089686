import React, {Component} from 'react';
import PropTypes from 'prop-types';

export default class CartItemHiddenFields extends Component {
  static propTypes = {
    application: PropTypes.object,
    product: PropTypes.object.isRequired,
    productGroup: PropTypes.object,
    index: PropTypes.number
  }

  getPrefix() {
    let {application, product, index} = this.props;

    if (!index)
      index = product.id;

    if (application && application.id)
      return "cart_item" + application.id;

    if (product && product.id)
      return "cart_item" + product.id;

    return "cart_item" + index;
  }

  render() {
    let {application = {}, product, quantity = 1} = this.props;

    let prefix = this.getPrefix();

    let {vehicle={}, id: application_id, vehicle_fitment_id} = application;

    return (
      <div className="cart-checkbox-fields">
        <input type="hidden" name={prefix + '[quantity]'} value={quantity}/>
        <input type="hidden" name={prefix + '[product_id]'} value={product.id}/>

        {application_id &&
        <input type="hidden" name={prefix + '[application_id]'}
          value={application.id}/>}

        {vehicle && vehicle.id &&
        <input type="hidden" name={prefix + '[vehicle_id]'}
          value={vehicle.id}/>}

        {vehicle_fitment_id &&
        <input type="hidden" name={prefix + '[vehicle_fitment_id]'}
          value={vehicle_fitment_id}/>}

      </div>
    );
  }
}

