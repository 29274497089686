// Build options for a POST.

import FetchBuilder from "./FetchBuilder";

// a Form-style POST request (send method=PATCH/PUT to do something else).
export default class PostWithParams extends FetchBuilder {

  constructor({ params, ...opts} = {}) {
    super(opts);
    this.params = params;
  }


  getDefaultHeaders() {
    return {
      'Content-Type': "application/x-www-form-urlencoded",
    };
  }

  getBody() {
    let { params } = this;
    if (params)
      return new URLSearchParams(params).toString();
    else
      return "";
  }

  // do not add parameters onto URL
  fullUrl() {
    return this.url;
  }
}
