import _ from 'lodash';

//
// let s = new SpeakerToStorage();
//
export default class SpeakerToStorage {
    constructor(opts = {}) {
        this.options = opts;
    }

    getStorage() {
        return this._storage || (this._storage = this._findStorage());
    }

    // Run with storage if it exists.
    // If localStorage/sessionStorage exists, it will invoke the function,
    // passing in that storage API as the sole parameter.
    // If not, it returns the default value supplied as the second argument.
    withStorage(fn, deflt = null) {
        try {
            let s = this.getStorage();
            if (s)
                return fn(s);
        } catch (err) {
            this.setError(err);
        }

        // no storage, or an error occurred.
        return ((typeof deflt === "function") ? deflt(this) : deflt);
    }

    read(key, deflt = {}) {
        return this.withStorage((s) => {
            let value = s.getItem(key);
            if (value)
                return JSON.parse(value);
            else
                return ((typeof deflt === "function") ? deflt(this) : deflt);
        }, deflt);
    }

    // write an object to the store.
    write(key, obj) {
        return this.withStorage((s) => {
            s.setItem(key, JSON.stringify(obj))
            return true;
        }, false);
    }

    remove(key) {
        return this.withStorage((s) => { s.removeItem(key) });
    }

    // Convert localStorage to a hash. Any value that can be parsed
    // as JSON will be; anything that isn't will be returned as-is.
    toObject() {
        return this.withStorage((s) => {
            let out = {};
            for (let i=0, len=s.length; i < len; i++) {
                let k = s.key(i);
                out[k] = this.read(k, s.getItem(k));
            }
            return out;
        }, {});
    }

    // Read, edit, write. Provided function must return the new value.
    edit(key, fn, deflt = {}) {
        let value = this.read(key, deflt);

        let out = fn(value);
        if (typeof out != "undefined")
            value = out;

        this.write(key, value);
        return value;
    }

    // merge new values into existing object and stash it
    merge(key, new_values) {
        let value = this.read(key, {});
        value = _.extend(value, new_values);
        this.write(key, value);
        return value;
    }

    _findStorage() {
        let s = (this.options && this.options.temporary) ?
            window.sessionStorage : window.localStorage;

        if (s && s.getItem)
            return s;

        this.setError("Local storage not available");
        return null;
    }

    setError(e) {
        console.error("SpeakerToStorage:" + e);
        this.error = e;
    }
}
