import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import SelectedOptionDisplay from './SelectedOptionDisplay';
import LloydCatalogue from "../models/LloydCatalogue";
import OptionDefinition from "../models/OptionDefinition";

/**
 * Modernized 2023-02
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const OptionGallery = (props) => {
  const { option, cart, pickWith: PickWith } = props;
  const { name } = option;

  const id = props.id || `${name}-chooser`

  const selection = cart ? cart.options.getOption(name) : null;

  let childProps = _(props).omit('pickWith').extend({selection}).value();

  if (selection) {
    // Display summary of selection already made, with a RESET button.
    return <div id={id} className={'lloyd-chooser complete'}>
      <SelectedOptionDisplay {...childProps} />
    </div>
  } else {
    // PickWith is an alias for the pickWith property, a component class.
    return <div id={id} className={'lloyd-chooser incomplete'}>
      <PickWith {...childProps} />
    </div>
  }
}

OptionGallery.propTypes = {
  pickWith: PropTypes.func.isRequired,
  option: PropTypes.instanceOf(OptionDefinition).isRequired,
  lloyd: PropTypes.instanceOf(LloydCatalogue).isRequired
};

export default OptionGallery;
