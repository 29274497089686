import _ from 'lodash';
import $ from 'jquery';

export default class PhotoDelete {
    constructor($elem) {
        this.$elem = $elem;
        this.url = this.adjustUrl(this.$elem.attr('href'));
    }

    adjustUrl(url) {
        url = "" + url;
        if (url.indexOf("?") > 0) {
            url += '&';
        } else {
            url += '?';
        }
        url += '_method=delete';
        return url;
    }

    click() {
        if (confirm("Delete this photo?")) {
            $.ajax({
                method: 'delete',
                url: this.url,
                success: (function(_this) {
                    return function(r, s, x) {
                        return _this.onSuccess(r, s, x);
                    };
                })(this),
                fail: (function(_this) {
                    return function(x, s, e) {
                        return _this.onFailure(x, s, e);
                    };
                })(this)
            });
        }
        return false;
    }

    onSuccess(response, status, xhr) {
        let parent;
        window.response = response;
        parent = this.$elem.parents('.uploaded');
        return parent.hide(500, function() {
            return parent.remove();
        });
    }

    onFailure(xhr, status, err) {
        return alert("Error deleting photo: " + err);
    }
}
