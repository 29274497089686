import React from "react";

import createLightGallery from "../../../Common/services/createLightGallery";

export default class ItemImageBox extends React.Component {
  state = {}

  initGallery = (r) => {
    this.setState({
      ref: r, lg: createLightGallery(r)
    });
  }

  render() {
    let {product} = this.props;
    let {small = [], mid = [], large = []} = product.images;

    let pic = (small && small[0]) || (mid && mid[0]);

    // No image - return image-box-empty
    if (!pic || !pic.path) {
      // 2022-04 it won't float properly if nbsp isn't present
      return <div className="image-box image-box-empty">
        <div>&nbsp;</div>
      </div>
    }

    let bigPath = pic.path.replace("/sm/", "/lg/").replace("/md/", "/lg/");

    return <div className="image-box" ref={this.initGallery}>
      <a className="pic" href={bigPath}><img alt={product.name} src={pic.path}/></a>
    </div>
  }
}

//      <img src={pic.path} alt={product.name} />
