import React, {useState, useEffect} from 'react';
import clsx from 'clsx';

export default function MenuBarItemLabel({item, ...props}) {

  let {
    name,
    className = "inline-block mr-4",
    tubeSectionId,
    displayFlavour = 'text',
    icon,
  } = item;

  if (displayFlavour === 'hamburger') {
    return <span className={"fas fa-bars"}/>
  }

  return <span>
    {name}
    {icon && <span className={clsx(icon, "ml-2")} />}
  </span>
}
