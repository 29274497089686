//
// canyonero.js - 2018-12
//
// The main entry point is packs/application.js (source).
//
// This file will require canyonero.js, which initializes
// globals and stuff, and loads all the React too.
//

import {preflight} from "./preflight";
import initClientSide from '../ClientOnly/init';

// require('@babel/polyfill');  // or babel-polyfill
require('babel-polyfill');

require('./initGlobals');

if (isClientSide()) {
  require('./initJquery');
} else {
  console.log("not client side")
}

require('./components');

require("../GroupGrid/init");
require("../ProductGroupPage/init");

// // 2019 - redux
import {boldlyLog} from "../Utility/logging";

preflight(globalThis);

if (isClientSide()) {
     initClientSide();
} else {
  boldlyLog("-- canyonero server side startup");
}

