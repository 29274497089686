function findModalContentFromAttributes(trigger) {
  const {attributes} = trigger;
  if (!attributes) return;

  for (let k of ['data-target', 'href']) {
    let attr = attributes[k];
    if (!attr) continue;

    let value = '' + attr.value;

    if (value && (value != '#')) {
      try {
        let element = document.querySelector(value);
        if (element) return element;
      } catch (err) {
        // Uncaught DOMException: Failed to execute 'querySelector' on 'Document': '#' is not a valid selector.
        console.warn(err.message || err.toString());
      }
    }

  }
}

function findModalChild(trigger) {
  const {childNodes} = trigger;
  if (!childNodes) return;

  for (let node of childNodes) {
    if (node && node.classList && node.classList.contains) {
      if (node.classList.contains('hidden-modal-content')) {
        return node;
      }
    }
  }
}

//
// Find the modal content associated with its trigger.
// because the 'trigger' (an event.target from an onClick handler) is often an image,
// look at parents recursively, until finding one successfully
export function findModalContent(trigger, level = 0) {
  // critical - end recursion
  if (!trigger) return null;

  // don't recurse too much
  if (level && level > 2)
    return null;

  return (findModalContentFromAttributes(trigger) ||
    findModalChild(trigger) ||
    findModalContent(trigger.parentElement, level + 1));
}
