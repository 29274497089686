// Form embedded in the OrderStatus page.
// Will send a note, possibly with a photo.

import LocalUrlMaker from "../../lib/LocalUrlMaker";

export default class SendNoteForm {
  constructor() {
    this.$form = $("#new_private_photo");  // was $('#new_order_note')
    this.$textarea = this.$form.find('textarea');

    // Actual 'submit' is forbidden. Use the button.
    this.$form.submit(() => false);

    $("#send-note-submit-button").bind('click', () => this.handleSubmit());
  }

  handleSubmit() {
    let self = this;

    let text = this.$textarea.val();
    if (!text) {
      alert("Please write your message in the space provided, then press the button again.");
      return;
    }

    // var url = this.$form.attr('action') + '.json';
    // html, not json.
    let url = LocalUrlMaker.prefixedPath('/order_notes.html');
    let args = {
      order_id: this.$form.find('input[name=order_id]').val(),  // obfuscated id!
      "order_note[note_text]": this.$textarea.val()
    };

    $.ajax(url, {
      type: 'POST',
      data: args,
      success: function (result, st) {
        self.handleResult(result, st);
      },
      error: function (result, st) {
        alert("E");
        self.handleResult(result, st);
      }
    });
    return false;
  }

  handleResult(result, st) {
    let self = this;

    let $result = $(result);
    if ($result.hasClass('order-note')) {
      $('#notes-container-inner').prepend($result);
      let msg = "Thanks for contacting us. A customer service agent will respond as soon as possible, but please allow up to one business day.";
      $('#new-note-result').show('200').text(msg);
      this.$textarea.val('').text('');
      this.hidePhotoWidget();
    } else {
      window.NOTE_RESPONSE = result;
      alert("There was an error saving your message, please contact us by phone");
    }

  }

  hidePhotoWidget() {
    $('.uploaded').remove();
    $('.private-photo-upload-fields').hide(100);
    $('#attach-photos-link').show()
  }
}
