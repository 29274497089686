
import {selectionsToItemIds} from "./filterBucketLogic";

/**
 * Used by FilterPillBar only.
 *
 * @param allFilterSelections
 * @param allFilterBuckets
 * @param flavour
 * @returns {*[]}
 */
export function describeAllFilterSelections({
  allFilterSelections={},
  allFilterOptions = {}
}) {

  let filterNames = _.keys(allFilterSelections);

  return _.reduce(filterNames, (result, filterName) => {
    result[filterName] = describeSelectionsForFilter({
      filterName,
      selections: allFilterSelections[filterName],
      options: allFilterOptions[filterName]
    })
    return result;
  }, {});

}

export function describeSelectionsForFilter({
  filterName,
  options,
  selections
}) {
  return _(selections).keys().map(id => {
      let option = options[id];
      return {
        id,
        filterName,
        selectedAt: selections[id],
        label: option?.name
      }
    })
    .sortBy( item => item.label || item.id )
    .value();
}

function getSelectedIds({filterName, selections, allFilterSelections = {}}) {
  selections ||= allFilterSelections[filterName];
  return _.keys(selections).filter(k => selections[k]);
}

export function updateFiltersTouchedList(filtersTouched = [], allFilterSelections = {}) {
  // First, remove any that are empty
  filtersTouched = filtersTouched.filter(filterName => {
    const selectedIds = getSelectedIds({filterName, allFilterSelections});
    return !_.isEmpty(selectedIds);
  });

  // now add any new ones.
  for (let filterName of _.keys(allFilterSelections)) {
    // if not already present...
    if (!_.includes(filtersTouched, filterName)) {
      // ...add it only if selections are nonempty.
      const selectedIds = getSelectedIds({filterName, allFilterSelections});
      if (!_.isEmpty(selectedIds))
        filtersTouched.push(filterName);
    }
  }

  return filtersTouched;
}

// Given the list of filters (filtersTouched), return those
// that are before but not including the named filter.
export function filterNamesBefore(filterNames, filterName) {
  let index = Array.indexOf(filterNames, filterName);

  if (index < 0)
    return filterNames;

  return Array.slice(filterNames, 0, index);
}
