import React, {useEffect} from 'react';
import cartSlice from "../../ReduxStore/ShoppingCart/cartSlice";

import Modal from "../../Modals/Modal";
import DynamicCartDisplay from "../components/DynamicCartDisplay";
import {camelizeKeysDeep} from "../../ReduxStore/Helpers/transformObject";

/**
 * DynamicCartContainer - ALWAYS present on a group page, this
 * receives the dynamicCart property with a boolean "open" value,
 * which controls the display of DynamicCartModal.
 *
 * 2023-04 props are cartCode, customerVehicle, dispatch,
 * dynamicCart: {open: true}, newItemIds: [id,id],
 * recommendations, shoppingCart
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const DynamicCartContainer = (props) => {
  const {dispatch, dynamicCart = {open: false}, shoppingCart} = props;
  const {open} = dynamicCart;

  const {updateDynamicCart} = cartSlice.actions;

  const customerVehicle = camelizeKeysDeep(props.customerVehicle);

  const handleClose = ({label = 'keep_shopping'} = {}) => {
    dispatch(updateDynamicCart({open: false}));
  }

  if (!open) return null;

  return <div className="dynamic-cart-ui-wrapper">
    <Modal id="dynamic-cart-modal" handleClose={handleClose}>
      {(shoppingCart && shoppingCart.cartCode) ?
        <DynamicCartDisplay {...props}
          handleClose={handleClose}
          customerVehicle={customerVehicle}
        />
        : <p>Loading...</p>}
    </Modal>
  </div>
}

// <DynamicCartModal handleClose={handleClose} {...props} />
export default DynamicCartContainer;

