import React from 'react';
import SaveCartContents from "./SaveCartContents";

export default function SaveCartPopup(props) {
  let {callbacks = {}} = props;

  // success: shown AFTER the user saves cart.
  // Not actually a flavour, deal with it as a special case
  // if (save_cart_status === 'success') {
  //   return null;
  // }

  // this is so clicks on the popup background don't get sent to the modal overlay
  const doNothing = (evt) => {
    evt.stopPropagation();
    return false
  }

  return (
    <div id="save-cart-popup" className={getClassList(props).join(' ')}>

      <div className="modal-fade-screen">
        <div className="quasimodal-inner" onClick={doNothing}>
          <a className="quasimodal-close" onClick={callbacks.close}> </a>

          <SaveCartContents {...props} />
        </div>
      </div>
    </div>);
}

function getClassList(props) {
  const { saveCartStatus } = props;
  const {flavour = 'fallback', valid, pristine} = saveCartStatus;

  // let status = pristine ? 'pristine' : (valid ? 'valid' : 'invalid');

  let classes = [
    "modal", "open-modal",
    "save-cart-" + flavour,
    // "save-cart-" + status
  ];

  if (flavour !== 'requested')
    classes.push("save-cart-prompted");

  return classes;
}
