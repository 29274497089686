import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import OptionChoice from './OptionChoice';

/**
 * A selection of color/binding/logo swatches to choose from.
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function OptionGroup(props) {
  // option cart lloyd callbacks selection id name values
    const {values = [], lloyd, option } = props;

    const calc = lloyd.getOptionCalculator(option);

    return (
      <div className="option-group">
        {values.map( (value) =>
          <OptionChoice key={value.id} value={value} calc={calc} {...props} />)}
      </div>
    );

}
