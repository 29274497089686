import React from 'react';
import _ from 'lodash';
import SaveCartTitle from "./SaveCartTitle";
import CloseSaveCart from "./CloseSaveCart";

export default function SaveCartFailed(props) {
  let { contents={}, saveCartStatus={}  } = props;

  let email = contents.email;

  return <div className="save-cart-failed">
    <SaveCartTitle theme={props.theme} logo={false} title="Save Cart"/>

    <h3>Save Cart Failed.</h3>

    <p><b>error:</b>
      {saveCartStatus.error || saveCartStatus.message || 'Unknown error'}
    </p>

    <CloseSaveCart {...props}/>
  </div>
}


