import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import EmbeddedConfig from "../../lib/EmbeddedConfig";
import LocalUrlMaker from "../../lib/LocalUrlMaker";

export default function SkuTooltip({sku, handleClose, ...props}) {

  const url = LocalUrlMaker.prefixedPath(
    EmbeddedConfig.isNola() ? "/help": "/customer-service");

  return (
    <div className="manual-tooltip sku-alert">
      <div className="inner">
        <div className="fa fa-times close-button" onClick={handleClose}></div>
        <h3>We Price Match!</h3>
        <p>Give our product specialists a call at {getPhone()} and we'll {' '}
          <a target="_blank" href={`${url}#price-match`}>
            match any competitor's price
          </a>.
        </p>
      </div>
    </div>
  );
}


function getPhone() {
  // FIXME JQUERY

  // kluge - get phone from page header.
  let phone = _.trim($('#call-now strong').text());
  if (phone) return phone;

  let config = new EmbeddedConfig();
  phone = config.fetch("phone");
  if (phone) return phone;

  let siteId = config.fetch('site_id');
  return (siteId == 20) ? '855.392.5646' : '800.663.1570';
}
