
const DIGITS = [ "0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];

export default class AmericanPhoneNumber {
  constructor(text) {
    this.chars = (text || '').toString().split('');
    this.digits = this.chars.filter( (n) => _.includes(DIGITS, n) );
    this.parse();
  }

  parse() {
    let digits = [...this.digits]; // make a copy

    if (digits[0] == '1') {
      this.country_code = digits[0];
      digits.shift();
    }

    this.npa = digits.slice(0,3).join('');
    this.nxx = digits.slice(3,6).join('');
    this.xxxx = digits.slice(6,10).join('');
    this.ext = digits.slice(10, digits.length+1).join('');
  }

  hasPlus() {
    return (this.chars[0] === '+');
  }

  isValid() {
    return (
      (this.npa.length == 3) && (this.nxx.length == 3) && (this.xxxx.length === 4));
  }

  formatPhone() {
    // invalid? return as entered
    if (!this.isValid())
      return this.chars.join('');

    //let text = [ this.npa, this.nxx, this.xxxx].join("-");
    let text = `(${this.npa}) ${this.nxx}-${this.xxxx}`;

    if (this.country_code) {
      // ${this.hasPlus() ? '+' : ''}
      text = `+${this.country_code} ${text}`;
    }

    if (this.ext)
      text = `${text} x ${this.ext}`;

    return text;
  }
}
