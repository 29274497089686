import React, {Component} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

function getErrors(props) {
  let {error} = props;

  error = error ? error.toString() : "Unknown error";
  return error.split("\n");
}

/**
 * An error display that you can close
 */
export default function DismissibleError(props) {
  const {dismissError, id, buttonText} = props;
  const errors = getErrors(props);

  return <div id={id} className="dismissible-error">
    <button className="button" type="button" onClick={dismissError}>{buttonText}</button>

    <div className="error-inner">
      {errors.map((err, i) =>
        <div key={i} className="message">{err}</div>)}
    </div>
  </div>;

}

DismissibleError.defaultProps = {
  id: "dismissible-error",
  error: "Unknown error",
  buttonText: "OK"
}
