import _ from "lodash";
import React from "react";
import ChangeAddressLink from "./ChangeAddressLink";


export default function ValidAddressDisplay(
  {
    address, changeAddress, usage, impediments, ...props}) {
  if (!address) return null;

  let { country_code='US' } = address;
  if (_.isEmpty(country_code))
    country_code = 'US';

  const usa = (country_code == 'US');

  return <div className="address">
    <div className="full-name">{address.first_name} {address.last_name}</div>
    <div>
      {address.company && <span className="company">{address.company} </span>}

      <span className="street">{address.street} </span>

      {address.street2 && <span className="street2">{address.street2} </span>}

      <span className="city-state">{address.city}, {address.state_code} {address.zip} </span>

      {' '}
      { !usa && <span className="country">{address.country_name || country_code} </span>}
      {' '}

      <ChangeAddressLink paypal={props.paypal} changeAddress={changeAddress} />
    </div>

    { (usage == "shipping") && <ImpedimentsList impediments={impediments} /> }
  </div>
}


function ImpedimentsList({impediments}) {
  if (_.isEmpty(impediments))
    return null;

  return (<div className="shipping-impediments">
    {impediments.map( (text) => <div key={text}>
      <span className="fa fa-exclamation-triangle"/>{' ' +text + ' '}
    </div>)}
  </div>);
}
