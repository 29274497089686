const FIELD_NAMES = {
  cardholderName: "Cardholder Name",
  number: "Credit Card Number",
  postalCode: "Zip Code",
  expirationMonth: "Expiration Month",
  expirationYear: "Expiration Year"
}

export default class HostedFieldsHelper {
  /**
   *
   * @param hostedFields {HostedFields}
   */
  constructor({hostedFields}) {
    this.hostedFields = hostedFields;
    window.HF =this;
  }

  /**
   *
   * [ cardholderName, number, postalCode, expirationMonth, expirationYear ]
   * @returns {Array}
   */
  getInvalidFieldNames() {
    const fields = this.getAllFieldData();
    if (!fields) return []; // "fail open"

    let names = _.keys(fields);
    return names.filter(n => !fields[n].isValid);
  }

  // Return a hash like { number: "Credit Card Number is invalid or missing." }
  getErrorMessages() {
    let invalid = this.getInvalidFieldNames()

    return _.reduce(invalid, (result, field) => {
      let name = FIELD_NAMES[field] || field;
      result[field] = `${name} is invalid or missing.`;
      return result;
    }, {});
  }

  getAllFieldData() {
    const state = this.getStateObject();
    const fields = state.fields;

    if (_.isEmpty(fields)) {
      console.error("Braintree Hosted Fields state has no 'fields' member", state);
      return null;
    }

    return fields;
  }


  /**
   * https://braintree.github.io/braintree-web/3.76.4/HostedFields.html#~stateObject
   *
   * Return object like { cards: Array, fields: {cardholderName:, number:, expirationMonth:..}}
   *
   * @returns {HostedFields~stateObject}
   */
  getStateObject() {
    if (! this._state) {
      if (this.hostedFields)
        this._state = this.hostedFields.getState()
    }
    return this._state;
  }

}
