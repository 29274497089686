import DeviceDetector from '../lib/DeviceDetector';
import CartConnector from '../CartConnector/CartConnector';
import LocalApi from './LocalApi';
import SpeakerToStorage from './SpeakerToStorage';
import LocalUrlMaker from "../lib/LocalUrlMaker";
import {installGlobalFunctions} from "./globalFunctions";

// 2019-02 - this is also used by Checkout, for now.

export default function initClientSide() {
  const { document } = globalThis;
  if (!document) {
    console.log("WARNING: initClientSide called with no globalThis.document");
    return;
  }

  installGlobalFunctions(window);

  $(document).ready(function () {

    window.local_url_maker = new LocalUrlMaker();

    // FIXME - move more of these objects into LocalApi
    window.device_detector = new DeviceDetector();
    // alert("device detector: " + device_detector.deviceType());

    window.cartConnector = new CartConnector({cart_engine: true});
    window.cartConnector.railsStartup();

    window.SpeakerToStorage = SpeakerToStorage;

    window.localApi = new LocalApi();
    window.localApi.init();
  });
}


