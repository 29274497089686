import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {connect, Provider} from "react-redux";
import GridSidebarContainer from "./GridSidebarContainer";
import {getReduxStore} from "../../../ReduxStore/store";
import gridFilterSlice from "../../../ReduxStore/GroupGrid/gridFilterSlice";
import {generateGridId} from "../logic/gridLogic";
import {buildInitialFilterState} from "../logic/initialFilterState";

function mapStateToProps(state) {
  const {groupGridSlice = {}, gridFilterSlice={}} = state;

  return Object.assign(
    {},
    groupGridSlice,
    gridFilterSlice);
}

const Wrapper = connect(mapStateToProps)(GridSidebarContainer);

export default function GridSidebarApp({gridId, filterSet, ...props}) {
  // allocate a random grid id if none provided.
  const [myGridId, setMyGridId] = useState(
    () => { return gridId || generateGridId() });

  // *INIT FILTER STATE*: Copy the filterSet prop to Redux,
  // only on first run
  useEffect(() => {
    const filterState = buildInitialFilterState(myGridId, filterSet);

    getReduxStore().dispatch(gridFilterSlice.actions.initFilterState({
      gridId, filterState}));
  }, [myGridId, filterSet]);

  return <Provider store={getReduxStore()}>
    <Wrapper gridId={myGridId} {...props}/>
  </Provider>
}

GridSidebarApp.propTypes = {
  filterSet: PropTypes.shape({
    // all of these are keyed by filterName
    filterMap: PropTypes.object.isRequired,
    allFilterOptions: PropTypes.object.isRequired,
    allFilterSelections: PropTypes.object.isRequired,
    allFilterBuckets: PropTypes.object, // { [filterName]: { "id1": [ groupid, gronpid, ...], "id2": ...} },
    // on pages that link to parent, like subcat
    thisPage: PropTypes.object
  }).isRequired,

  // non-filter stuff
  relatedPages: PropTypes.object
}
