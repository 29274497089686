// copied from Tailwind config, see tailwind/common.config.js
export const BREAKPOINTS = {
  sm: '640',
  md: '768',
  lg: '1024',
  xl: '1280',
  '2xl': '1536',
}


export function isDesktop(width) {
  if (isNaN(width) || (+width < 1))
    width = (getWindowSize())[0];

  if (isNaN(width) || (+width < 1)) {
    console.warn("unable to determine screen size, assume mobile");
    return false;
  }

  return ( (+width) >= BREAKPOINTS.lg);
}

export function isMobile(width) {
  return !isDesktop(width)
}

/**
 * getWindowSize();
 *
 * @returns {[number,number]}
 */
export function getWindowSize() {
  if (globalThis.window)
    return [window.innerWidth, window.innerHeight];
  else {
    console.warn("getWindowSize(): no window")
    return [0, 0];
  }
}

