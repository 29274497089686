import React, {Component} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import CartOptions from "../models/CartOptions";

/**
 * Display a list of mats to customise, and toggle the 'customise'
 * option for each.
 *
 */
export default class SelectMat extends React.Component {
  // customize this mat at all?
  togglePrimary = () => { this.includeRows(1, 0); }
  toggleSecondary = () => { this.includeRows(2, 1); }

  getSelectedRowCount() {
    let {cartItem} = this.props;
    return cartItem.options.getOption(CartOptions.CUSTOMISE_ROWS) || 0;
  }

  // if multirow, do we also customise the second row>
  includeRows(max, min = 0) {
    let {cartItem, callbacks} = this.props;
    let oldValue = this.getSelectedRowCount();
    // let rows = cartItem.lloydItem.rows;

    callbacks.selectItemOption(cartItem, CartOptions.CUSTOMISE_ROWS,
      (oldValue && oldValue > min) ? min : max);
  }

  getClasses() {
    let {cartItem, callbacks} = this.props;

    return [
      "lloyd-mats-to-customise-item",
      (cartItem.rowsToCustomise() > 0) ? "customise-on" : "customise-off"
    ]
  }

  render() {
    /** @var cartItem {LloydCartItem} */
    let {cartItem, callbacks} = this.props;

    let {lloydItem} = cartItem;

    // Get the row config (an object with one key per row).
    // No product has more than 2 rows of mats.
    let rows = lloydItem.rows;

    // how many rows to customise? zero?
    let selected = cartItem.rowsToCustomise();

    return (<div className={this.getClasses().join(' ')}>
      <a onClick={this.togglePrimary}>
        <i className={"far fa-lg " + (selected ? "fa-check-square" : "fa-square")}/>
        {' '} {lloydItem.getName()}
      </a>
      {' '}

      {(selected > 0) && (_.size(rows) > 1) &&
      <span className="toggle-secondary">
        {' '}
        <a onClick={this.toggleSecondary}>
              <i className={"far fa-lg " + ((selected > 1) ? "fa-check-square" : "fa-square")}/>
          {' '}
          Apply to second seating row also?
            </a>
        </span>
      }
    </div>)
  }

}
