import AmericanPhoneNumber from "./AmericanPhoneNumber";
import WorldPhoneNumber from "./WorldPhoneNumber";

const  isDev = (process.env.NODE_ENV == 'development');

export default class Validator {
  static fieldLabels = {};
  static fieldRules = {};

  static formats = {
    // I forget where I found this, but it was in the old checkout app. Stack Overflow?
    email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    zip_us: /^[0-9]{5}(?:-[0-9]{4})?$/,
    zip_ca: /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/,
    phone: /^\+?1?[ \.\-]?[0-9][0-9][0-9][ \.\-]?[0-9][0-9][0-9][ \.\-]?[0-9][0-9][0-9][0-9]/,

    po_box: /\b(box\s*[0-9]+|pob|p\.o\.b\.|pmb|p\.m\.b\.)\b/i,
  }

  constructor({object, labels = null, rules = null, usage = 'unknown'}) {
    this.object = object;
    this.usage = usage;
    this.labels = labels ? labels : this.constructor.fieldLabels;
    this.rules = rules ? rules : this.constructor.fieldRules;

    if (!this.object.errors)
      this.object.errors = {};
  }

  /**
   * Validate all KNOWN fields - fields for which a validation rule
   * exists in this.rules
   *
   * @returns {Object}
   */
  validateAll() {
    let keys = _.keys(this.rules);

    if (_.isEmpty(keys)) {
      console.warn(`${this.constructor.name} - no validation rules configured, validateAll is useless`);
    }

    // clear all errors
    this.object.errors = {};

    for (let k of keys) {
      // we now support compound keys like shipping/street, so split on that to get the true field name
      let fn = _.last(k.split('/'));

      this.validateField(fn, this.object[fn]);
    }

    return this.object.errors;
  }

  validateField(fieldName, value) {
    this.clearError(fieldName);

    let rules = this.rules[`${this.usage}/${fieldName}`] || this.rules[fieldName];
    // console.log(`- ${this.constructor.name} validates ${fieldName} = ${value}, with rules: ${rules}`);

    if (!rules)
      return true;

    for (let keyword in rules) {
      this.applyValidationRule({
        keyword: keyword,
        options: rules[keyword],
        fieldName: fieldName,
        value: value
      });

      // if it set an error message, STOP.
      if (this.getError(fieldName))
        return;
    }
  }

  applyValidationRule({keyword, options, fieldName, value}) {
    // make 'options' into a hash, even if it's not.
    if ((options.constructor === RegExp) || !_.isObject(options)) {
      options = {[keyword]: options}
    }

    if (keyword == 'presence') {
      return this.validatePresence(fieldName, value, options);
    } else if (keyword == 'email') {
      return this.validateEmail(fieldName, value, options);
    } else if (keyword == 'phone') {
      return this.validatePhone(fieldName, value, options);
    } else if (keyword == 'format') {
      return this.validateFormat(fieldName, value, options);
    } else {
      console.error(`for ${fieldName}: unknown validation rule: ${keyword}: ${options}`)
    }
  }

  validatePhone(fieldName, value, options) {
    let {message = "Phone must be at least ten digits (including area code), or if not in North America, start with + and country code."} = options;

    let phone = new WorldPhoneNumber(value);
    if (phone.hasPlus() && !phone.isAmerican()) {
      console.warn("international phone number detected, skipping validation");
      return;
    }

    // Looks American to me.
    let parsed = new AmericanPhoneNumber(value);
    if (parsed.isValid()) {
      if (options.reformat)
        this.object[fieldName] = parsed.formatPhone();
    } else {
      this.setError(fieldName, message);
    }
  }

  validateFormat(fieldName, value, options = {}) {
    let {format = /.*/, message = "LABEL is not formatted correctly"} = options;

    if (!value)
      value = '';

    let matching = value.match(format);

    // negate = true - return true (happy)
    if (options.negate && !matching)
      return true;

    if (matching && !options.negate)
      return true;

    this.setError(fieldName, message);
    return false;
  }

  // ValidateEmail - convenience wrapper for validateFormat
  validateEmail(fieldName, value, options) {
    options = Object.assign({}, {
      format: Validator.formats.email,
      message: "Email Address should look like username@domain.com"
    }, options)

    return this.validateFormat(fieldName, value, options);
  }

  validatePresence(fieldName, value, options = {}) {
    const {message = "LABEL cannot be blank"} = options;

    if (!value) {
      this.setError(fieldName, message);
    }
  }

  getError(field) {
    let {errors} = this.object;
    return errors[field];
  }

  setError(field, message) {
    let {errors = {}} = this.object;
    errors[field] = message.replace(
      'LABEL', this.getFieldLabel(field));
  }

  clearError(field) {
    let {errors} = this.object;
    if (errors)
      delete errors[field];
  }

  getFieldLabel(fieldName) {
    let label = this.labels[fieldName];
    if (label) return label;

    return _.startCase(fieldName);
  }

}
