import React from 'react';

import SaveCartInputWidget from './SaveCartInputWidget';
import {mailingListNames} from "../../Utility/staticConfig";

export default function SaveCartMailingListInput({
  name = "mailingListSignup",
  theme, contents = {},
  callbacks = {}, ...props
}) {
  const listName = mailingListNames[theme];

  if (!theme || !listName) return null;

  const handleChange = (evt) => {
    const {target = {}} = evt;
    const {checked} = target;

    const value = checked ? 1 : '';

    console.log("SaveCartInput changed: " + name + " / " + value);

    callbacks.updateContents({[name]: value});
  }

  return (<div id="save_cart_mailing_list_signup">
    <label>
      <input type="checkbox"
        id="saved_cart_mailing_list_signup"
        name={name}
        checked={!!contents[name]}
        onChange={handleChange}
        value="1" />


      {' Sign up for '} <u>{listName}</u>? {' '}
      <em>(optional)</em>
    </label>
  </div>);

}
