/*
 * 2018-12 - adapted from legacy app, converted to modern JS.
 * Uses lots of jquery.
 */
import $ from 'jquery';

export default class TopNavWidget {
    watchBodyClicks(page) {
        let { pageEvents={} } = page;

        let { body_click_subscribers } = pageEvents;

        if (!body_click_subscribers) {
            console.error("top nav - cannot subscribe to body clicks");
            return false;
        }

        body_click_subscribers.add(this);
    }

    bindToggles($sel) {
        let self = this;

        // Cannot use arrow function here as jquery.on wants
        // to override 'this'
        $sel.on('click', function(evt) {
            TopNavWidget.stopEvent(evt);
            self.toggleClicked(this, evt);
        });
        this.$toggles = $sel;
    }

    static stopEvent(evt) {
        //console.log("STOP EVENT");

        if (evt) {
            if (evt.stopPropagation) {
                evt.stopPropagation();
            }
            if (evt.preventDefault) {
                return evt.preventDefault();
            }
        }
    }

    // Receive notifications from body click handler.
    onBodyClick() {
        return this.close();
    }

    toggleClicked(link, evt) {
        let $target = this.targetForButton(link);
        if (! $target) {
            console.error("cannot determine target for nav link " + (link ? link.id : 'NONE'))
            return false;
        }

        this.$clicked = $(link);

        // was this particular item already open?
        let was_active = $target.hasClass(this.active_menu_class);

        // Close EVERYTHING - all the menus, not just the one click'd
        this.close(was_active);

        // only if it was closed, open it.
        if (! was_active) {
            this.open($target, link);
        }

        this.afterToggle(!was_active);

        return !was_active;
    }

    // subclasses should override
    afterToggle(new_state) {
        console.log("after toggle " + new_state);
    }

    open($target, link) {
      console.log("adding class - " + this.active_menu_class + " to target", $target);

        $target.addClass(this.active_menu_class);
        if (link)
            return $(link).addClass(this.active_toggle_class);
    }

    close(was_active) {
        $('.' + this.active_toggle_class).removeClass(this.active_toggle_class);

        return this.$targets.removeClass(this.active_menu_class);
    }

    // Determine which target we're going to reveal.
    // A simple nav widget reveals EVERYTHING, but menu bars
    // reveal only one item.
    targetForButton(link) {
        return this.$targets;
    }

    ignoreBodyClicks($elem) {
        return $elem.on('click', function (evt) {
            if (evt) {
                if (evt.stopPropagation) {
                    evt.stopPropagation();
                }
                if (evt.preventDefault) {
                    return evt.preventDefault();
                }
            }
        });
    }

    finishSetup() {}
}
