import React from 'react';

import { ErrorBoundary } from "react-error-boundary";

/**
 * This is just a simple wrapper for react-error-boundary that provides
 * the required fallback param.
 *
 * @param children
 * @param message
 * @param fallback
 * @param props
 * @returns {Element}
 * @constructor
 */
export default function CatchErrors({
  children, fallbackRender=DefaultFallback, ...props}) {
  return <ErrorBoundary fallbackRender={fallbackRender}>{children}</ErrorBoundary>
}


function DefaultFallback({ error, resetErrorBoundary }) {
  // Call resetErrorBoundary() to reset the error boundary and retry the render.

  return (
    <div role="alert my-4">
      <div className="text-2xl mb-2">Something went wrong:</div>
      <div className="w-full text-red-500 whitespace-pre-wrap">
        {error.message}
      </div>
      <div className="my-2 text-right">
      {resetErrorBoundary && <a onClick={resetErrorBoundary}>Reset</a>}
      </div>
    </div>
  );
}
