/*
 * Find my server's origin url, https://domain, from location or config.
 * Used by LocalUrlMaker
 */
import EmbeddedConfig from "./EmbeddedConfig";

export default class OriginFinder {
    // Main entry point - look up (and remember) origin one of two ways.
    // Prefer to use the name from window.location because it's already in the JS.
    getOrigin() {
        return this._origin ||
            (this._origin = this.originFromLocation() || this.originFromConfig());
    }

    originFromLocation() {
        let l = global.location;
        if (l) {
            if (l.origin)
                return l.origin;
            if (l.host)
                return ( (l.protocol || 'https:') + '//' + l.host );
        }
    }

    originFromConfig() {
        let c = new EmbeddedConfig();
        let n = c.fetch("server_name");
        if (n)
            return "https://" + n;
    }

}
