import React from 'react';
import Money from "../../../lib/Money";
import CartDisplayTotal from "./CartDisplayTotal";
import CartDisplayShippingTotal from "./CartDisplayShippingTotal";

// 2023-01 modernized
export default function CartSummaryTable(props) {
  let {totals, shippingAddress} = props;

  if (!totals)
    return null;

  const {regularSubtotal, subtotalSavings,
    effectiveShipping, effectiveTax, effectiveTotal } = totals;

  return <ul id="cart-summary-table">
    <CartDisplayTotal
      id="regular-subtotal"
      label="Subtotal"
      value={regularSubtotal}
    />

    <CartDisplayTotal
      id="savings"
      label="Savings"
      optional="true"
      value={- (subtotalSavings || 0)}
    />

    <CartDisplayShippingTotal
      id="shipping"
      label="Shipping"
      value={effectiveShipping}
      shippingAddress={shippingAddress}
    />

    {
      /* use equals-null here because it could be zero and we want to print zeroes */
      (effectiveTax !== null) && <CartDisplayTotal
        id="tax"
        label="Tax"
        value={effectiveTax}
      />}

    <CartDisplayTotal
      id="total"
      label="Total"
      value={effectiveTotal}
    />

    <TaxExemptIndicator shoppingCart={totals}/>
  </ul>
}

function TaxExemptIndicator(props) {
  let {shoppingCart} = props;
  let {taxExemptCode} = shoppingCart;
  if (!taxExemptCode)
    return null;

  return <div id="tax-exempt-indicator">
    Tax Exemption No. <b>{taxExemptCode}</b>
  </div>
}

