export default class JqueryStartup {
  startup(page) {
    this.bindTabs();
    this.bindExpanderTrigger();
  }

  bindExpanderTrigger() {
    // 2020-06 - a Bourbon widget to reveal hidden text, used by
    // the Lifestyle tubes
    // 2024-04 - used in exactly 5 tubes.
    // This is a mobile-only feature! Debiggen your browser to see what happens.
    // /lifestyle/road-trip-accessories
    // /lifestyle/work-truck-accessories

    let triggers = document.getElementsByClassName('expander-trigger');
    for (let t of triggers) {
      t.onclick = (e) => {
        // remove this class, and the expander-content immediately after will show itself
        t?.classList && t.classList.remove('expander-hidden')
      }
    }
  }

  bindTabs() {
    // REFILLS tab widget. Usually called accordion-tabs but was renamed.
    // 2024-04 Used on Brand index only; not in tubes.
    let $t = $('.accordion-tabs');

    $t.each(function (index) {
      $(this).children('li').first().children('a')
        .addClass('is-active').next().addClass('is-open').show();
    });

    $t.on('click', 'li > a.tab-link', function (event) {
      if (!$(this).hasClass('is-active')) {
        event.preventDefault();
        var accordionTabs = $(this).closest('.accordion-tabs');
        accordionTabs.find('.is-open').removeClass('is-open').hide();

        $(this).next().toggleClass('is-open').toggle();
        accordionTabs.find('.is-active').removeClass('is-active');
        $(this).addClass('is-active');
      } else {
        event.preventDefault();
      }
    });

  }
}
