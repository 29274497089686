// headline - shown as plain text in "stacked" chooser, and not at all in
// "horizontal" layout chooser (where it used to be an orange pill)
import React from "react";
import {describeVehicle} from "../logic/vehicle";

export default function CompletedChooserHeadline({customerVehicle, onResetVehicle, config}) {
  let {completedHeadline = "Fits your"} = (config || {});
  let [headlineBefore, headlineAfter] = completedHeadline.split('@@');

  let fitment = customerVehicle.lloydUniqueDescription ||
    customerVehicle.fitmentDescription;

  // .success-with-action, used in the groups page, imitates
  // this structure and css, but the css was too convoluted to
  // generalize so it's a copy.
  return <div className="headline">
    <div className="headline-text">
      {' ' + (headlineBefore || '') + ' '}
      <strong className="vehicle">
        {describeVehicle(customerVehicle)}
      </strong>
      {' ' + (headlineAfter || '') + ' '}
      {fitment &&
        <span className="fitment">{fitment}</span>}
      {' '}
    </div>

    <div className="change-it">
      <a className="link" onClick={onResetVehicle}>Change</a>
    </div>
  </div>
}
