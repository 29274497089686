import React from 'react';

export default class SaveCartInputWidget extends React.Component {
  onChange = (evt) => {
    const {target={} } = evt;
    let {name, type, value, checked} = target;


    if (type === 'checkbox') {
      value = checked ? value : '';
    }

    console.log("SaveCartInput changed: " + name + " / " + value);

    this.sendUpdate(name, value);
  }

  getError(name) {
    let {validator} = this.props;
    if (!validator || !validator.getErrors)
      return null;

    let errors = validator.getErrors();
    return errors[name];
  }


  sendUpdate(name, value) {
    console.log("SEND UPDATE - " + name + " / " + value);

    const {callbacks} = this.props;

    callbacks.updateContents({[name]: value});
  }
}
