import React from 'react';
import PropTypes from 'prop-types';

import {useDispatch} from "react-redux";
import gridFilterSlice from "../../../ReduxStore/GroupGrid/gridFilterSlice";

// 2024-05 MH modernised, uses toggleSidebar, no longer requires sidebarOpen prop
// Mobile only - hide or show the sidebar menu
// by toggling gridState.filters
//
// There are multiple instances of this that look very different.
// * A button in GridControlBar with a "funnel" icon
// * in GroupGridSidebar, two revealed only when open:
// * * one with an "X" icon
// * * one as a big DONE button
export default function GridFilterActivator({
    gridId,

    // 2024-05 - using toggleSidebar now, so this is just informational
    sidebarOpen = false,

    icon = "fa fa-filter",
    caption = "",
    className = "grid-filter-activator",
  }) {

  const dispatch = useDispatch();

  const handleClick = (e) => {
    console.log(`GridFilterActivator ${gridId} clicked`)
    dispatch(gridFilterSlice.actions.toggleSidebar({gridId}));
  }

  return <div className={className}>
    <a onClick={handleClick}>
      <i className={icon}/> {caption}
    </a>
  </div>
}

GridFilterActivator.propTypes = {
  gridId: PropTypes.string.isRequired,
  // sidebarOpen: PropTypes.bool.isRequired
}
