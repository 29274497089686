import {affirmAvailableForTotal} from "../../lib/affirmHelpers";
import {applePayAvailable} from "./applePayHelpers";

/**
 * Used by both Cart and Checkout, this will get the list of payment
 * types, like ['credit_card', 'paypal']
 * @param props
 * @returns {*}
 */
export function getEnabledPaymentTypes({shoppingCart={}, currentAgent}) {
  const checkoutConfig = getCheckoutConfig({shoppingCart});

  // cart/checkout put effectiveTotal in different places because I was stupid.
  const effectiveTotal = shoppingCart?.effectiveTotal || shoppingCart?.totals?.effectiveTotal;

  if (! effectiveTotal) {
    console.warn("getEnabledPaymentTypes: cannot determine total in cart/checkout")
  }

  let list = [...(checkoutConfig.paymentTypes || ['credit_card']) ];

  if (currentAgent && currentAgent.username) {
    let agentTypes = [...(checkoutConfig.agentPaymentTypes || ['agent_none'])];
    for (let t of agentTypes)
      list.push(t);
  }

  if (!affirmAvailableForTotal(effectiveTotal)) {
    list = list.filter(item => item != 'affirm');
  }

  if (!applePayAvailable()) {
    list = list.filter(item => item != 'apple');
  }

  return _.uniq(list);
}

export function getCheckoutConfig({checkoutConfig, ...props}) {
  if (!_.isEmpty(checkoutConfig))
    return checkoutConfig;

  // look for it inside checkout/cart object
  for (let k of ['checkout', 'cart', 'shoppingCart']) {
    let obj = props[k];

    if (obj && !_.isEmpty(obj.checkoutConfig)) {
      return obj.checkoutConfig;
    }
  }

  return {};
}
