import React, {Component} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import LloydMatChooserItems from './LloydMatChooserItems';
import ProductUniquesSection from '../ProductUniques/ProductUniquesSection';
import LloydCatalogue from "../models/LloydCatalogue";
import {pluralize} from "../../../lib/TextHelpers";

// LloydMatChooser - the section.
//      LloydMatChooserItems - container
//          LloydMatChooserItem (repeated)
//      ProductUniquesSection
//          ProductUniquePicker (repeated, only if needed for each item)
export default class LloydMatChooser extends React.Component {
  static propTypes = {
    lloyd: PropTypes.instanceOf(LloydCatalogue).isRequired,
    cart: PropTypes.object.isRequired,
    callbacks: PropTypes.object,
  }

  /**
   *
   * @returns {Array}
   */
  getClasses() {
    let {cart, lloyd} = this.props;

    let cartCount = cart ? cart.items.count() : 0;

    return [
      `mat-count-${lloyd.itemCount()}`,  // for allocating columns
      (cartCount > 0) ? "cart-not-empty" : "cart-empty"
    ];
  }

  render() {
    // @param items [Array<AppliedItem>] all items available to select
    // @param cart [LloydCart]
    let {cart, callbacks, lloyd} = this.props;

    let cartCount = cart ? cart.items.count() : 0;

    return <section id="lloyd-mat-chooser" className={this.getClasses().join(' ')}>
      <h2 id="lloyd-mat-chooser-top">Step 1: Begin By Selecting Mat Locations
        {cartCount > 0 ? <span> ({pluralize(cartCount, "item")} selected)</span> : null}
      </h2>

      <LloydMatChooserItems
        lloyd={lloyd}
        callbacks={callbacks}
        cart={cart} />

      <ProductUniquesSection cart={cart} callbacks={callbacks}/>
    </section>
  }

}

