import React from 'react';
import LocalUrlMaker from "../lib/LocalUrlMaker";

const ItemCell = ({item}) => {
  let path = LocalUrlMaker.prefixedPath(item.path);

  return <th>
    <div className="title"><a href={path}>{item.name}</a></div>
    <div className="pic"><a href={path}><img src={item.img} alt={item.name} /></a></div>
  </th>
}

const TableHead = ({items, callbacks, ...props}) => {
  return (
    <thead>
    <tr>
      <th className="empty"></th>
      {items.map((item, i) => <ItemCell item={item} key={item.id} />)}
    </tr>
    </thead>
  );
}

// TableHead.jsx.propTypes = { }

export default TableHead;

