import React from 'react';

import GoogleViewCartReporter from "./GoogleViewCartReporter";
import AdobeViewCartReporter from "./AdobeViewCartReporter";
import KlaviyoViewCartReporter from "./KlaviyoViewCartReporter";
import CatchErrors from "../../../Utility/CatchErrors";
// import AdobeViewCartReporter from "./AdobeViewCartReporter";
// import {isNola} from "../../../lib/EmbeddedConfig";

// 2023-05 Report only to Google; Facebook lacks a View Cart reporting need
export default function ViewCartReporter(props) {
  return (
    <div>
      <CatchErrors><KlaviyoViewCartReporter {...props} /></CatchErrors>
      <CatchErrors><GoogleViewCartReporter {...props} /></CatchErrors>
      <CatchErrors><AdobeViewCartReporter {...props} /></CatchErrors>
    </div>
  );
}

// ViewCartReporter.propTypes = { }

