import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {cartToKlaviyoProps, withKlaviyo} from "../../../ExternalServices/Klaviyo/KlaviyoHelpers";

export default function KlaviyoViewCartReporter({shoppingCart, ...props}) {
  const [reported, setReported] = useState(false);


  useEffect(() => {
    if (reported) return;

    setReported(true);
    const klaviyoCart = cartToKlaviyoProps(shoppingCart);

    // afterKlaviyoIdentifySent( k => {...} )
    withKlaviyo(k => {
      k.track('Viewed Cart', klaviyoCart)
    });
  }, [shoppingCart, reported]);

  return null;
}
