import {createSlice} from "@reduxjs/toolkit";
import {getReduxStore} from "../store";

import {requirePayloadItems} from "../Helpers/reduxHelpers";
import {createContext} from "react";

const defaultState = {
  allGridStates: {
    // grid-1234-5678: defaultGridState
  }
};

const defaultGridState = {
  gridId: null,
  pageSize: 24,  // set by user, this is default
  sort: 'rel',  // set by user, this is default
  // filters: {},    // set by user - { brands: [list], categories: [list]...}

  compareList: [], // list of selected groups for compare-groups popup

  // FIXME: placeholders for these...
  itemCount: 0, // based solely on vehicle
  visibleCount: 0, // after applying all filters

  columns: 4,     // from back end (config)
  // showRanks: -1,   // from back end (page type dependent)
  showCompare: true
};

export const GridStateContext = createContext(defaultGridState);

function requireGridStateUpdate(action) {
  return requirePayloadItems(action, ['gridId', 'gridState'])
}

const groupGridSlice = createSlice({
  name: 'groupGridSlice',
  initialState: defaultState,
  reducers: {
    // 2024-05 OK
    initGridState: (state, action) => {
      const {gridId, gridState} = requireGridStateUpdate(action);
      if (gridId) {
        state.allGridStates[gridId] = Object.assign({},
          defaultGridState, gridState, {gridId: gridId});
      }
    },

    // 2024-05 OK
    updateGridState: (state, action) => {
      const {gridId, gridState} = requireGridStateUpdate(action);

      if (gridId) {
        // MERGE IN the update
        state.allGridStates[gridId] = Object.assign({},
          state.allGridStates[gridId], gridState);
      }
    }
  }
});

export function setGridPage({
  page, gridId, dispatch, params = {}}) {

  dispatch ||= getReduxStore().dispatch;

  dispatch(groupGridSlice.actions.updateGridState({
    gridId,
    gridState: {currentPage: page}
  }))
}

export default groupGridSlice;

