import LloydCalculator from './LloydCalculator';
import LloydCartItem from './LloydCartItem';
import CartItemFactory from "../logic/CartItemFactory";
import CartItemsCollection from "./CartItemsCollection";
import CartOptions from "./CartOptions";
import CartValidator from "./CartValidator";

// LloydCart - a PLAIN OLD OBJECT, held in LloydContainer.state.
//
// Managed by LloydContainer, this is an semi-immutable value object
// new carts are created by cloning an old one and making changes, then
// replacing it in the owner's state.
export default class LloydCart {
  /**
   * Constructor - may be built from an previous cart.
   *
   * @param data {LloydCart|{}}
   */
  constructor(data = {}) {
    _.extend(this, _.cloneDeep(data));

    this.options = this.options || new CartOptions({mode: 'cart'});

    //this.calculator = new LloydCalculator({properties: this.groupProperties});
  }

  /**
   *
   * @returns {CartItemsCollection}
   */
  get items() {
    return this._items || (this._items = new CartItemsCollection())
  }

  // Convenience methods for dealing with item collection
  isEmpty() { return this.items.isEmpty() }

  getItem(arg) { return this.items.getItem(arg) }

  // hasItem(part_number) {
  //   return this.getItem(part_number) ? true : false;
  // }

  get calculator() { console.error("FIXME deprecated cart get calc "); }

  // For a newly created cart, start out with sensible defaults.
  // Border will be standard - on those groups that support it.
  getDefaultOptions() {
    let opts = {};

    return opts;
  }

  // set an option in this.options, and also in every cart item.
  setOption(name, value) {
    this.options.setOption(name, value);
    this.items.map((item) => item.options.setOption(name, value));
  }

  // Clear only the custom logo/lettering - not the basic colour/binding
  // selections.
  clearCustomisations() {
    this.setOption('logo', null);
    this.setOption('plain', null);
    // this.setOption('lettering', null);
  }

  /**
   * Called when user makes a selection from options section
   * or product unique picker.
   *
   * @param item_or_part_number
   * @param name
   * @param value
   */
  setItemOption(item_or_part_number, name, value) {
    let item = this.items.requireItem(item_or_part_number);
    item.options.setOption(name, value);
  }

  // Return a list of each item's value for an option. Some may be undefined.
  itemValuesForOption(name) {
    // FIXME
    // return this.items.toArray().map((item) => item.options[name]).value();
  }

  // END OF OPTION STUFF

  validate({lloyd} = {}) {
    let validator = new CartValidator({lloyd: lloyd, cart: this});
    return validator.validate();
  }

  attributeExists(attr_name) {
    return _.includes(this.attribute_names, attr_name);
  }
}


