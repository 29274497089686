import React from 'react';

import YearSelector from "./YearSelector";
import DummySelector from "./DummySelector";

export default function VehicleYearChooserUI({
  vehicleModel = {}, onSelectYear, years
}) {
  return (
    <div className="inner">
      <div className="selectors selectors-3">
        <YearSelector onSelectYear={onSelectYear} years={years} />

        <DummySelector name="make" value={vehicleModel.makeName}/>
        <DummySelector name="model" value={vehicleModel.name}/>
      </div>
    </div>
  );
}


