import LocalUrlMaker from "../../lib/LocalUrlMaker";

export function checkoutUrl(
  {
    cart, cartCode, action, format = 'json'
  }) {
  cartCode ||= cart?.cartCode;
  if (!cartCode) {
    console.error("cannot determine cart code from cart: ", cart)
    return;
  }

  let url =  `/new_checkout/${cartCode}`;
  if (action)
    url += `/${action}`;

  if (format && format !== 'html')
    url += `.${format}`

  return LocalUrlMaker.prefixedPath(url);
}
