import React from 'react';
import {connect, Provider} from 'react-redux';
import _ from 'lodash';
import ReviewLoaderUI from "./ReviewLoaderUI";
import {getReduxStore} from "../../ReduxStore/store";

function mapStateToProps(state) {
  // console.error("MAP STATE TO PROPS " + _.keys(state) )
  // console.dir(state);
  const {groupReviews = {}} = state;

  return _.omit(groupReviews, 'newReviewNodes');
}

const ReviewLoaderWrapper = connect(mapStateToProps)(ReviewLoaderUI);

export default function GroupReviewLoaderApp(props) {
  return <Provider store={getReduxStore()}>
    <ReviewLoaderWrapper {...props} />
  </Provider>
}

// "GET /reviews/list?vehicle_id=&group_id=3479&product_id=&offset=60 HTTP/1.1" 200 1919
// "https://www.autoaccessoriesgarage.com/Floor-Mats-Liners/Lloyd-RubberTite-Floor-Mats"
