/**
 * 2021-07 - start the Paypal checkout process, by calling our back-end to
 * get a paypal token from
 * `/new_checkout/${cartCode}/paypal_token.json`.
 *
 * The caller should redirect to the redirect url in the response.
 *
 * This is used by PaypalFormSection in the new checkout,
 * and the paypal button on the view cart page.
 *
 */
import LocalUrlMaker from "../../lib/LocalUrlMaker";

export default class FetchPaypalToken {
  /**
   *
   * @param cartCode
   * @param flavour - string, like "new_checkout"
   */
  constructor({cartCode, flavour}) {
    this.cartCode = cartCode;
    this.flavour = flavour;
  }

  /**
   * Request token and use our internal handleResponse.
   * To do something fancier, invoke requestToken()
   * directly and do something with the Promise it returns.
   *
   * @returns {Promise<void>}
   */
  call() {
    return this.requestToken()
      .then( (r) => r.json() )
      .then( (payload) => this.handleResponse(payload))
      // .catch(err => ErrorService.onError({error: err, comment: "fetching paypal token"}))
  }

  /**
   * Fetch the token, returning a Promise that will
   * deliver the JSON response.
   *
   * @returns {Promise<Response>}
   */
  requestToken() {
    let url = this.getUrl();
    let options = this.getFetchOptions();

    return fetch(url, options);
  }

  getFetchOptions() {
    return {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      cache: 'no-cache',
      credentials: 'include',
      body: JSON.stringify(this.getParams())
    }
  }

  getUrl() {
    if (this.cartCode) {
      return LocalUrlMaker.jsonPath(`/new_checkout/${this.cartCode}/paypal_token.json`);
    }

    throw new Error("Cannot fetch paypal token without cart code.");
  }

  getParams() {
    return { cart_code: this.cartCode, flavour: this.flavour }
  }

  // response handler - either redirect or alert.
  handleResponse(payload) {
    if (!payload)
      payload = { status: 'fail' };

    if (payload.redirect) {
      window.location = payload.redirect;
    } else {
      alert("Cannot connect to PayPal:\n\n" + (payload.message || "unspecified error"))
    }
  }
}
