import React, {useEffect} from 'react';
import LocalUrlMaker from "../../../lib/LocalUrlMaker";
import {camelizeKeys} from "../../../ReduxStore/Helpers/transformObject";
import CartPathHelper from "../../logic/CartPathHelper";

/**
 * 2023-02 use new order-complete path; camelizes props.
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function OrderComplete(props) {
  const {checkout = {}, cartCode} = props;

  const order = camelizeKeys(props.order || checkout.order || {}) ;

  // get a path like
  // /new_checkout/ZC-8F4RY/completions/1405976?code=1418e0cd6875bf487846468c7459049ba63f9d
  const path = new CartPathHelper({cartCode, order}).orderCompletePath();

  // this is just to provoke useEffect to keep trying
  const tenths = new Date().getMilliseconds() / 100;

  useEffect(() => {
    if (!order || !path) return;

    console.log(`OrderComplete: redirecting to ${path}`)
    window.location = path;
  }, [order.id, path, tenths]);

  if (!order || !order.id) return null;

  const shippingAddress = camelizeKeys(checkout.shippingAddress || {});
  const statusUrl = LocalUrlMaker.prefixedPath(`/OrderStatus/${order.obfuscatedId}`);

  return <div className="OrderComplete" />

  // Fallback contents. Generally not seen as we redirect.
  // return (
  //   <div className="OrderComplete">
  //     <h1>Thanks for your order, {shippingAddress.firstName}!</h1>
  //
  //     <h2>Your Order Number is {order.id}</h2>
  //
  //     <p>You will receive an email confirmation shortly. If you have any questions at all,
  //       please do not hesitate to contact us by replying to your order confirmation email,
  //       or through the message center on your order status page.</p>
  //
  //     <a className="button" href={statusUrl}>Go to your Order Status page</a>
  //   </div>
  // );
}


