import {useEffect} from "react";
import {markItemReported} from "../../ShoppingCart/components/analytics/ReportedItemsStore";
import {camelizeKeysDeep} from "../../ReduxStore/Helpers/transformObject";

/**
 * Custom hook!  Run the function when the itemlist changes
 *
 * @param callback
 * @param props
 */
export function useNewItemListChange(callback, props) {
  const newItemIds = _.filter((props.newItemIds || []), x => x && (+x > 0));

  // stringify so we can compare-by-value instead of compare by ref
  const joinedIds = _.sortBy(newItemIds).join(',');

  return useEffect(() => {
    if (_.isEmpty(newItemIds))
      return;

    // FIXME: don't do this more than once!
    for (let id of newItemIds) {
      markItemReported(id);
    }

    return callback(props);
  }, [joinedIds])
}

export function getNewItems({
  shoppingCart = {}, cartItems, newItemIds = []
}) {
  if (!cartItems)
    cartItems = shoppingCart?.cartItems;

  if (!_.isEmpty(newItemIds) && newItemIds.map) {
    let newItems = _.compact(
      newItemIds.map(id => _.find(cartItems, (item) => item.id == id)));

    if (!_.isEmpty(newItems))
      return newItems;
  }

  // no new items
  return [];
}

/**
 *
 * @param cart -- cart or checkout object.
 */
export function extractCartTotals(cart) {

  // handle legacy checkout.totals
  let totals = cart.totals;
  if (!_.isEmpty(totals)) {
    if (totals.effectiveTotal || totals.effective_total)
      return extractCartTotals(totals)
  }

  if (cart.effective_total && !cart.effectiveTotal)
    cart = camelizeKeysDeep(cart);

  let keys = _.keys(cart).filter(k => k.startsWith("effective") || k.startsWith("regular"));
  return _.pick(cart, keys);
}

// newItemIds, shoppingCart.groupCollection, cartItems (is an array)
