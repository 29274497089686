import _ from 'lodash';
import $ from 'jquery';
import React from 'react';

export default class CallerCartHelper {
    constructor(args = {}) {
        // phone_call, agent_cart_id, agent_cart_code
        this.agent_cart_id = args.agent_cart_id;
        this.agent_cart_code = args.agent_cart_code;
        this.cart_followup = args.cart_followup;
        this.analyseCall(args.phone_call);
    }

    // copy anything we need from the call
    analyseCall(phone_call) {
        if (phone_call) {
            this.phone_connected = true;
            this.customer_cart_id = phone_call.shopping_cart_id;
            this.customer_cart_code = phone_call.cart_code;
        } else {
            this.phone_connected = false;
        }
    }

    // Customer cart exists?
    hasCart() {
        let id = this.customer_cart_id;
        return (id && !isNaN(id));
    }

    // agent cart matches customer cart
    linkedToCart() {
        return (this.agent_cart_id &&
            this.customer_cart_id === this.agent_cart_id
        );
    }

    // Linked to a *different* cart.
    wrongCart() {
        return (this.agent_cart_id && !this.linkedToCart());
    }

    // return a one-word status suitable for css class naming.
    getStatus() {
        if (!this.phone_connected)
            return 'disconnected';

        // customer has no cart or unknown cart
        // This takes priority.
        if (!this.customer_cart_id) {
            return "cart-missing";
        }

        // agent has no cart, customer has one.
        if (!this.agent_cart_id)
            return "not-linked";

        // agent has a cart. is it the right cart?
        if (this.linkedToCart())
            return "linked";
        else
            return 'linked-wrong-cart';
    }
}
