import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {buildOrderEvent} from "../Analytics/googleOrderAdapter";
import {safeGtag} from "../Analytics/googleAnalyticsFour";
import {safeFbq} from "../Facebook/facebookHelpers";

const isDev = (process.env.NODE_ENV == 'development');

export default function ReportOrderToFacebook({...props}) {
  const {order, orderedItems, groupCollection} = props;

  const [reported, setReported] = useState(null);

  useEffect(() => {
    if (reported || !order || _.isEmpty(orderedItems)) return;

    let contents = orderedItems.map(item => {
      return {
        id: item.facebookSku || item.productGroupId,
        quantity: item.quantity
      }
    });

    let event = ['track', 'Purchase', {contents}];

    safeFbq(...event)

    // inhibit reporting it again
    setReported(event)

  }, [order, reported]);

  return null;
}
