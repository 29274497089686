import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {paymentIcons, paymentLabels} from "./paymentConstants";
import {safeRefreshAffirm} from "../../../lib/affirmHelpers";

/**
 * Switch payment type between credit_card, paypal, etc.
 *
 */
export default function PaymentTypeButton(props) {
  const {paymentType, selectedType, callbacks} = props;
  const active = (paymentType === selectedType);

  const label = paymentLabels[paymentType] || _.startCase(paymentType);
  const icon = getIcon(paymentType);
  const isAffirm = (paymentType === 'affirm');
  const classes = `payment-type-${paymentType} ${active ? 'active' : ''}`

  // runs just once on startup, just for the affirm button
  useEffect(() => {
    if (paymentType === 'affirm')
      safeRefreshAffirm()
  }, [paymentType]);

  const handleClick = (evt) => {
    if (evt && evt.stopPropagation)
      evt.stopPropagation();

    callbacks.setPaymentType(paymentType);
  }

  return <li
    id={"payment-" + paymentType}
    className={classes}>

    <a className="select-link" onClick={handleClick}>
      {icon} {label}
    </a>
    {isAffirm && <a className="affirm-site-modal">
      <i className="fal fa-question-circle"/></a>}
  </li>
}

function getIcon(paymentType) {
  const icon = paymentIcons[paymentType];

  if (!icon)
    return null;

  if (icon.match(/img/)) {
    return <img className="icon inline-block" width="20" height="20" src={icon} alt={paymentType}/>;
  } else {
    return <span className={icon}/>
  }
}

PaymentTypeButton.propTypes = {
  paymentType: PropTypes.string.isRequired,
  selectedType: PropTypes.string,
  callbacks: PropTypes.shape({
    setPaymentType: PropTypes.func.isRequired
  }).isRequired
}
