/*
 * GroupProductsApp - forms most of the main part of a Group page,
 * displaying the products in any of several formats.
 *
 * Receives groupItems = { itemApplications, products, productDetail }
 * from Redux.
 */
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import {connect, Provider} from 'react-redux';

import GroupProductsContainer from "./GroupProductsContainer";
import {getReduxStore} from "../../ReduxStore/store";

function mapStateToProps(state) {
  let {vehicleSlice = {}, groupItemsSlice = {}, cart} = state;

  return Object.assign(
    _.pick(cart, 'shoppingCart', 'dynamicCart'),
    vehicleSlice, groupItemsSlice);
}

const GroupProductsWrapper = connect(mapStateToProps)(GroupProductsContainer);

/*
 * The main part of a group page - the Products Section.
 *
 */
export default function GroupProductsApp(props) {
  return <Provider store={getReduxStore()}>
    <GroupProductsWrapper {...props} />
  </Provider>
}

GroupProductsApp.propTypes = {
  // These props come from the Rails template.
  id: PropTypes.string, // optional, GroupProductsContainer will provide
  groupId: PropTypes.number.isRequired,
  pageType: PropTypes.string.isRequired,
  groupProperties: PropTypes.object.isRequired
  // 2021-10 these are the only props we have
}
