import React from 'react';
import PropTypes from 'prop-types';

import GroupPageType from "../../models/GroupPageType";
import LloydContainer from "../../lloyd/container/LloydContainer";
import UniversalItemList from "../StandardItems/UniversalItemList";
import ClusteredItemList from "../ClusteredItems/main/ClusteredItemList";
import StandardItemList from "../StandardItems/StandardItemList";

// 2024-04 props:   groupId pageType productGroup groupProperties
// shoppingCart dynamicCart customerVehicle vehicleContext clusterIndex
// itemApplications allApps products productDetail dispatch

export default function GroupProductsUi({id, loading, ...props}) {

  let pageType = new GroupPageType(props.pageType);

  if (pageType.lloyd()) {
    let {productDetail = {}} = props;
    // FIXME ugly hack
    return <LloydContainer {...props} {...productDetail} />
  } else if (pageType.clustered()) {
    return <ClusteredItemList {...props} />
  } else if (pageType.universal()) {
    return <UniversalItemList {...props} />
  } else {
    return <StandardItemList {...props} />
  }
}

GroupProductsUi.propTypes = {
  // ["groupId","pageType","groupProperties",
  // "customerVehicle","itemsUrl","clusterIndex","itemApplications",
  // "products","productDetail","dispatch"]object
  groupId: PropTypes.number.isRequired,
  pageType: PropTypes.string.isRequired
}
