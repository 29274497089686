import React from 'react';
import _ from 'lodash';
import {Provider, connect} from 'react-redux';

import CartIcon from "./CartIcon";
import {getReduxStore} from "../ReduxStore/store";

function mapStateToProps(state) {
  let {cart} = state;

  // omit: cartItemId, contextKeys, callInNumber...
  return _.pick(cart, 'shoppingCart', 'dynamicCart', 'newItems');
}

let Wrapper = connect(mapStateToProps)(CartIcon);

export default function CartIconApp({...props}) {
  return <Provider store={getReduxStore()}>
    <Wrapper {...props} />
  </Provider>
}
