import React, {useEffect, useRef} from 'react';
import PropTypes from "prop-types";
import ScrollToProductsButton from "./ScrollToProductsButton";
import {performOnCompleteAction, reportVehicleSelected} from "../logic/onComplete";
import CompletedChooserHeadline from "./CompletedChooserHeadline";
import {camelizeKeysDeep} from "../../ReduxStore/Helpers/transformObject";
import {resetVehicle} from "../logic/callbacks";
import {useVehicleSelectorConfig} from "../logic/chooserConfig";
import {useDispatch} from "react-redux";
import {removeMatchingClasses} from "../../Utility/html";
import clsx from "clsx";
import {useSkeletonLoadingWithRef} from "../../hooks/useSkeletonLoading";



/**
 * Replaces the VehicleChooser when the selector is completed -
 * all levels (including fitment/lloyd) have values.
 */
export default function CompletedVehicleChooserDisplay(props) {
  const myRef = useRef();

  useSkeletonLoadingWithRef(myRef);

  const config = useVehicleSelectorConfig();

  const dispatch = useDispatch();

  useEffect(() => {
    const current = myRef?.current;
    if (current && current.parentNode) {
      removeMatchingClasses(current.parentNode, /min-h/);
    }
  }, [myRef?.current]);

  const {layout = 'stacked', absolute = false} = config;

  const classNames = clsx(
    `vehicle-selector vehicle-selector-${layout}-completed my-2`,
    // 2024-05: if config.absolute is given, the completed vehicle selector displays at top right
    absolute && 'md:absolute md:bottom-0 md:right-0'
  )

  // CAMELIZED here and in child components
  const customerVehicle = camelizeKeysDeep(props.customerVehicle);

  const handleReset = (evt) => {
    resetVehicle({dispatch});
  }

  const wantButton = (layout === 'stacked');

  return (
    <div ref={myRef} className={classNames}>
      <CompletedChooserHeadline customerVehicle={customerVehicle}
        config={config}
        onResetVehicle={handleReset}/>
      {wantButton && <ScrollToProductsButton/>}
    </div>
  );

}

