import React, {Component} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import LloydCartFactory from '../logic/LloydCartFactory';
import LloydUI from "./LloydUI";
import CartItemFactory from "../logic/CartItemFactory";
import LloydCatalogue from "../models/LloydCatalogue";
import { produce } from "immer";

/**
 *
 * LloydContainer (React Component) - exists within a ProductSection.
 * Invoked by GroupProductsUI component when page type is Lloyd.
 * (note that unlike most containers, this does not connect to React -
 * {GroupProductsApp} does that)
 *
 *
 * This is the top of the lloyd component hierarchy - it manages state
 * and hosts stateless components within.
 * LloydContainer    = section#lloyd-products
 *   form#lloyd-cart-form
 *       LloydMatChooser
 *       LloydOptionsSection
 *       LloydFormBottom
 */
export default class LloydContainer extends React.Component {
  static propTypes = {
    // groupId, groupProperties, clusterStates, customerVehicle,
    // itemsUrl, clusterIndex, itemApplications, products,
    // lloydProducts, lloydAttributes, dispatch
    lloydProducts: PropTypes.object.isRequired,
    lloydAttributes: PropTypes.object.isRequired
  }

  static buildInitialState(props) {
    // let items = (props.allItems || []).filter((i) => i.lloyd_product);

    let cart = new LloydCartFactory(props).createCart()

    return {
      lloyd: new LloydCatalogue(props),
      cart: cart
    };
  }

  state = LloydContainer.buildInitialState(this.props);

  callbacks = {
    /**
     * Invoked by LloydMatChooserItem when the user clicks one of the
     * mat pics (or its checkbox), indicating this item should be added
     * to cart or removed from cart. (= in the cart).
     *
     * @param {string} part_number lloyd-style extended part num like UM19_3079870
     */
    selectItem: (part_number) => {
      console.log(`LloydContainer: selectItem ${part_number}`)

      this._updateCart((cart) => {
        const factory = new CartItemFactory({
          cart: cart, ...this.props
        });

        cart.items.toggleItem(part_number, {factory: factory});
      })
    },

    // Select a global option like color/border.
    // Not for setting options on a single item like logo/message.
    selectOption: (name, value) => {
      this._updateCart((cart) =>
        cart.setOption(name, value));
    },

    // select option on one item
    selectItemOption: (item_or_part_number, name, value) => {
      this._updateCart((cart) =>
        cart.setItemOption(item_or_part_number, name, value));
    },

    updateCart: (func) => {
      this._updateCart(func);
    },

    submit: () => {
      this.setState({submitting: true}, () => {
        let f = document.getElementById('lloyd-cart-form');
        f.submit();
        // $('#lloyd-cart-form').submit()
      });
    }
  };

  // componentWillReceiveProps(newProps) {
  //   // Create new cart when props changed, because this means the vehicle or
  //   // item list has changed.
  //   this.setState(LloydContainer.getDerivedStateFromProps(newProps));
  // }

  // Call the provided function on the cart, then update state.cart.
  _updateCart(func, callback) {
    // Make a copy first, then modify it.
    let cart = _.cloneDeep(this.state.cart);

    // invoke the function which will probably modify cart
    func(cart);

    window.LLOYD_CART = cart;

    // update state and invoke callback after.
    this.setState({cart: cart}, callback);
  }

  componentDidUpdate(prevProps, prevState) {
    console.log("DID UPDATE");
  }

  // _setCart(cart, after) {
  //   let newCart = new LloydCart(cart);
  //   this.setState({cart: newCart}, after);
  // }

  render() {
    let childProps = _.omit(this.props,
      "groupId", "lloydProducts", "products",
      "lloydAttributes", "groupProperties",
      "itemsUrl", "dispatch");

    window.LLOYD_PROPS = this.props;
    window.LLOYD_STATE = this.state;

    return <LloydUI
      callbacks={this.callbacks}
      {...childProps}
      {...this.state}
    />
  }

}
