import _ from 'lodash';

/**
 * 2021-02  manipulates the option selections for one cart item.
 */
export default class CartItemOptions {
  constructor({cartItem, contents}) {
    // this.cartItem = cartItem; // NO, circular ref
    this.contents = Object.assign({product_unique: null}, contents);
  }

  getOption(name) {
    return this.contents[name];
  }

  // used by form adapter
  toHash() {
    return Object.assign({}, this.contents);
  }

  setOption(name, value) {
    if (name && name.toLowerCase) {
      name = name.toLowerCase();
      if (value === null)
        delete this.contents[name];
      else
        this.contents[name] = value;
    }

    console.log(`set item option - ${name}=${JSON.stringify(value)}`);
    console.dir(this.contents);
  }

  /**
   * called when cart item is created.
   *
   * @param cartOptions
   */
  inheritOptions(cartOptions) {
    let opts = cartOptions.contents;

    for (let k in opts) {
      if (k === 'product_unique') next;
      this.setOption(k, opts[k]);
    }
  }

}

