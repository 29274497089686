// Build options for a POST.

import FetchBuilder from "./FetchBuilder";

export default class JsonDelete extends FetchBuilder {
  constructor(opts = {}) {
    super(opts);
    this.method = 'delete';
  }

  getDefaultHeaders() {
    return {
      'Content-Type': "application/x-www-form-urlencoded",
    };
  }

  getBody() {
      return null;
  }
}
