export default class Address {
  // used when making a copy.
  static CORE_FIELDS = [ "first_name", "last_name", "email", "street", "street2", "city", "state_code", "zip", "phone"];

  id;
  first_name;
  last_name;
  email;
  street;
  street2;
  city;
  state_code;
  zip;
  phone;
  subscribing;

  constructor(data) {
    _.extend(this, data);
  }

  getFullName() {
    let words = [ this.first_name, this.last_name ]
      .filter( (x) => x );
    return _.isEmpty(words) ? "" : words.join(' ')
  }


}
