import React from "react";

// 2022-09 enhance this to compensate for the GIDD
// (Google Induced Design Damage) of allowing add-to-cart with no vehicle
//
export default function CartItemVehicle({item, vehicle, quiet = false}) {
  vehicle ||= item.vehicle;

  if (vehicle && vehicle.id) {
    // 2023-11 - in the DynamicCartDisplay popup, we use the 'quiet' version of this,
    // printing NOTHING if everything is hunky-dory.
    if (quiet) return null;

    return <div className="vehicle">
      <div className="vehicle-name">{item.vehicle.description}</div>
      <div className="fitment">{item.fitmentText}</div>
    </div>
  } else if (item.universal) {
    // this is fine
    return null;
  } else if (item.universal === false) {  // exactly false, not undef!
    return <VehicleUnknown item={item} />
  }
}

function VehicleUnknown({item}) {
  return <div className="vehicle-warning dark-red">
    <div><strong>
      <span className="fa fa-exclamation-triangle"/>
      This product may not fit your vehicle.
      {' '}
    </strong>
      <a href={`${item.groupUrl}?remove_cart_item=${item.id}`}>
        Remove item and select vehicle
      </a>
      {' '}
      to ensure compatibility.
    </div>
  </div>
}
