import React, {useEffect, useState} from 'react';
import CartDisplayTotal from "./CartDisplayTotal";
import CartDisplayShippingTotal from "./CartDisplayShippingTotal";
import CartSummaryBox from "./CartSummaryBox";
import {safeRefreshAffirm} from "../../../lib/affirmHelpers";
import AffirmEstimate from "./AffirmEstimate";

// 2022-06 converted to redux and camelcase
export default function ShoppingCartTotals(props) {
  const {shoppingCart, enableAffirm = false} = props;

  if (!shoppingCart)
    return null;

  return <div id="cart-totals">
    <CartSummaryBox shoppingCart={shoppingCart}/>

    {enableAffirm && <AffirmEstimate amount={shoppingCart.effectiveTotal}/>}
  </div>
}





