/**
 * Wait for a global variable to become available. When it exists
 * (is truthy), pass it to the callback function.
 *
 * Used for interacting with external services that take time
 * to load, like the Klaviyo object (window.klaviyo)
 *
 * @param name
 * @param callback
 * @param timeout
 * @param count
 * @param max
 */
export function withGlobal(name, callback, opts = {}) {
  return withDelayedResource(() => window[name], callback, {label: name, ...opts});
}

export function withDelayedResource(fetcher, callback,
  {timeout = 20, count=0, max=100, label='unknown'} = {}) {

  const target = fetcher();
  if (target) {
    // returns something meaningful only if the resource is available on the first try
    return callback(target);
  } else if (+count >= +max) {
    throw new Error(`withDelayedResource(${label}): exceeded max ${max} attempts, I give up`);
  }

  setTimeout(() => {
    withDelayedResource(fetcher, callback, {
      timeout: timeout, count: count + 1, max: max, label: label})
  }, timeout);
}
