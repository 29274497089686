export function scrollToElement(target, opts = {behavior: 'smooth'}) {
  if (typeof target == 'string')
    target = document.getElementById(target);

  target.scrollIntoView(opts);
}

export function bindScrollButtons(links, target, opts) {
  if (typeof links == 'string')
    links = document.querySelectorAll(links);

  for (let link of links) {
    link.onclick = () => {
      scrollToElement(target, opts);
      return false;
    }
  }
}
