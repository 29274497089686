import React, {Component} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import BraintreeFormSection from "./BraintreeFormSection";

export default function CreditCardFormSection(props) {
  const { checkout={}, braintreeClient } = props;

  if (checkout)
    return <BraintreeFormSection {...props} />
  else
    return null;
}

