export const paymentLabels = {
  credit_card: "Credit Card",
  paypal: "PayPal",
  affirm: "Affirm",
  agent_none: "None Required",
  apple: "Apple Pay",
}

// Some of these use class fab to get the font awesome BRAND icons.
export const paymentIcons = {
  affirm: '/img/checkout/affirm-icon.png',
  apple: 'fab fa-apple',
  credit_card: 'fa fa-credit-card-front',
  paypal: 'fab fa-paypal',
  agent_none: 'fa fa-user-secret'
}

export const paymentTypes = ['credit_card', 'affirm', 'paypal', 'apple', 'agent_none'];
