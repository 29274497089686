import React from 'react';
import ResetAllFilters from "../filters/ResetAllFilters";
import {useDispatch} from "react-redux";

export default function NothingToSeeHere({gridId, customerVehicle}) {
  let prompt = "Sorry, no products matched your selections.";

  if (customerVehicle) {
    const {vehicleMakeName, vehicleModelName} = customerVehicle;
    if (vehicleModelName) {
      prompt = `Sorry, no products for the ${vehicleMakeName} ${vehicleModelName} ` +
        `matched your selections.`
    }
  }

  return (
    <div className="text-center text-2xl py-8 text-gray-400 clear-both">
      <p>{prompt}</p>

      <ResetAllFilters
        gridId={gridId}
        classNames={"button"}
      />
    </div>
  );
}


