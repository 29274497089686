import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import clsx from "clsx";

const STATUS_CLASSES = {
  //active: 'text-green-600 far fa-check border-green-600',

  pending: 'text-orange-600 far fa-construction border-orange-600',

  hidden: 'text-red-600 far fa-times-octagon border-red-600',
}

export default function StatusIcon({status, image, classNames, ...props}) {
  status ||= image?.status;
  if (!status) return null;

  const statusClasses = STATUS_CLASSES[status];
  if (!statusClasses) return null;

  const allClasses = clsx(classNames,
    "rounded-full bg-white px-2 py-2 border-2",
    statusClasses);

  return <span className={allClasses} />
}
