import React from 'react';
import PaypalReviewSection from "./PaypalReviewSection";

export default function ReviewPanel(props) {
  return (
    <div className="review-panel">
      <PaypalReviewSection {...props} />
    </div>
  );
}

