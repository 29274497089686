//
// Page leave watcher:
//  Notifies subscribers when the user's mouse pointer leaves the page.
//  Currently used by shopping cart to reveal the SaveCart popup.
//
// 2022 - still in use for SaveCart.

import SubscriberList from './SubscriberList';
import DeviceDetector from '../../lib/DeviceDetector';
import ScrollDirectionTracker from './ScrollDirectionTracker';

const isDev = (process.env.NODE_ENV == 'development');

export default class PageLeaveWatcher {
  constructor({subscribers}) {
    this.subscribers = subscribers || new SubscriberList();

    let dd = new DeviceDetector();
    this.mobile = dd.isMobile();

    this.watchEvents();
  }

  triggered(evt) {
    console.log(`PageLeaveWatcher: triggered by ${evt?.type || 'unknown'}`);
    this.subscribers.notifyAll(evt)
  }

  watchEvents() {
   const handleLeave = (e) => {
     //console.log("LEAVE " + e?.type + ' IN ' + e.target?.id , e)
     this.triggered(e);
   }

    for (let n of document.querySelectorAll('body')) {
      n.onmouseleave = handleLeave;
    }

    for (let n of document.querySelectorAll('#stem,#stern')) {
      n.ontouchstart = handleLeave;
    }

    if (this.mobile || isDev) {
      this.scrolls = new ScrollDirectionTracker();
      window.onscroll = (evt => this.handleScroll(evt));
    }
  }



  subscribe(obj) {
    this.subscribers.add(obj);
  }

  handleScroll(evt) {
    this.scrolls.add();

    let list = this.scrolls.getList(); // .filter( (x) => Math.abs(x) >= 20 );

    // console.error("scroll... " + JSON.stringify(list));
    let last = _.last(list);
    if (last > 0)
      return false;   // ignore on positives

    //console.error("going up");

    // scrolling up 20px with a list of length 4
    if (last < -20 && list.length >= 4) {
      if (list.length === this.scroll_trigger_list_length)
        return;

      this.scroll_trigger_list_length = list.length;

      //console.log("TRIGGER PAGE LEAVE");
      this.triggered(evt);
      //alert("trigger on " + JSON.stringify(list));
    }
  }


}
