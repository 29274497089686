import _ from 'lodash';


export default class ParamsHelper {
  constructor(sources) {
    this.sources = Array.isArray(sources) ? sources : [sources];
  }

  getParams() {
    return this._params || (this._params = this.mergeParams());
  }

  mergeParams() {
    return _.reduce(this.sources,
      (target, source) => {
        let s2 = _.pickBy(source, (v) => v != null);
        return Object.assign(target, s2);
      }, {});
  }
}
