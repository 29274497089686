import React, {Component} from 'react';
import PropTypes from 'prop-types';
import AddressTextWidget from "./AddressTextWidget";
import SelectStateWidget from "./SelectStateWidget";
import EmailWidget from "./EmailWidget";
import SubscribingWidget from "./SubscribingWidget";
import SmartStreetWidget from "./SmartStreetWidget";
import SmartApartmentWidget from "./SmartApartmentWidget";
import HiddenContentWrapper from "../../../Common/components/HiddenContentWrapper";

/*
 * These will be sent to all child props.
 */
function getSharedProps(props, state) {
  let {address, addressType, currentAgent, readOnly} = props;

  let {activeField = ""} = state;

  return {
    address,  // volatile
    activeField, // volatile
    addressType,
    currentAgent,

    readOnly: !!readOnly,
  }
}

/**
 * Holds fields for one address - shipping or billing.
 */
export default class AddressFormSection extends Component {
  state = {
    activeField: "", // field name with focus, like "first_name"
    readOnly: false
  };

  // Upon mounting, move cursor to first_name.
  componentDidMount() {
    this.focusOn("first_name");
  }

  focusOn(field) {
    const ff = this.getFieldElement(field);

    if (ff) {
      ff.focus();

      // ...and move to the end.
      if (ff.value)
        ff.selectionStart = ff.selectionEnd = ff.value.length;
    }
  }

  getFieldElement(field) {
    const {addressType} = this.props;
    return document.getElementById(`${addressType}_address-${field}`);
  }

  /**
   * Usually called from the onBlur handler of a child component,
   * this indicates that the user is finished with this field.
   * Send the changes to Redux.
   *
   * @param name field name like "first_name"
   * @param value value for that field, like "James Tiberius"
   */
  updateAddress = (name, value) => {
    const {addressType, address = {}, callbacks} = this.props;

    // Even if it hasn't changed, send the update, so that we
    // can validate. For example, a user tabbing through blank fields for
    // the first time.

    const {editCheckout} = callbacks;

    // build an object like { shippingAddress: { first_name: 'James' } }
    const payload = {[`${addressType}Address`]: {[name]: value}};
    // console.error(`edit addr ${name} => ${value} - sending to Redux`, payload);
    editCheckout(payload);
  }

  // called by onFocus() of child components (with simple field name)
  // and onBlur() with null.
  focusChanged = (name) => {
    this.setState({activeField: name});
  }

  render() {
    const {checkout = {}, address = {}, addressType, stateList = [], tabIndexBase = 100} = this.props;
    const {checkoutConfig = {}} = checkout;

    const useSmarty = !(checkoutConfig.disableSmarty);

    // also render the email/subscribe fields? only for shipping addr
    let full = addressType !== "billing";

    let cp = Object.assign(getSharedProps(this.props, this.state), {
      callbacks: {
        updateAddress: this.updateAddress,
        focusChanged: this.focusChanged,
        // email widget needs this
        saveCheckout: this.props.callbacks.saveCheckout
      }
    });

    // <div>errs = {JSON.stringify(address.errors)}<br clear={"both"} /></div>

    return (
      <div className="address-form-section">

        <AddressTextWidget {...cp}
          name="first_name"
          autoComplete="given-name"
          tabIndex={tabIndexBase + 1}
          label="First Name"
        />

        <AddressTextWidget {...cp}
          name="last_name"
          autoComplete="family-name"
          tabIndex={tabIndexBase + 2}
          label="Last Name"/>

        {full && <EmailWidget {...cp}
          name="email"
          type="email"
          autoComplete="email"
          tabIndex={tabIndexBase + 3}
          label="Email Address"
        />}

        {full && <SubscribingWidget tabIndex={tabIndexBase + 4} {...cp} name="subscribing"/>}

        {useSmarty ? <SmartStreetWidget {...cp}
          name="street" autoComplete="address-line1"
          tabIndex={tabIndexBase + 10}
          label="Street Address"/> : <AddressTextWidget {...cp}
          name="street"
          autoComplete="address-line1"
          tabIndex={tabIndexBase + 10}
          label="Street Address"/>
        }

        {useSmarty ? <SmartApartmentWidget {...cp}
          name="street2" autoComplete="address-line2"
          label="Apt / Suite / Unit"
          tabIndex={tabIndexBase + 30}
          optional={true}
        /> : <AddressTextWidget {...cp}
          name="street2"
          autoComplete="address-line2"
          tabIndex={tabIndexBase + 30}
          label="Apt / Street / UNITITS"/>}

        <HiddenContentWrapper className="company-wrapper"
          defaultVisible={!!address.company}
          caption={<p>Add line for Company Name (optional)</p>}>

          <AddressTextWidget {...cp}
            name="company"
            autoComplete="company"
            label="Company"
            tabIndex={tabIndexBase + 70}
            optional={true}/>

        </HiddenContentWrapper>

        <AddressTextWidget {...cp}
          tabIndex={tabIndexBase + 71}
          name="city" autoComplete="address-level2"
        />

        <SelectStateWidget {...cp}
          name="state_code" autoComplete="address-level1"
          label="State"
          countryCode={address.country_code}
          tabIndex={tabIndexBase + 72}
          stateList={stateList}/>

        <AddressTextWidget {...cp}
          name="zip" autoComplete="postal-code"
          tabIndex={tabIndexBase + 73}
          label="Zip Code"/>

        <AddressTextWidget {...cp}
          name="phone" autoComplete="tel"
          tabIndex={tabIndexBase + 74}
          type="tel"/>

        <div className="clear-me"/>

      </div>
    );

  }
}

AddressFormSection.propTypes = {
  addressType: PropTypes.string.isRequired,
  // checkout: PropTypes.object.isRequired,
  address: PropTypes.object.isRequired,
  callbacks: PropTypes.object.isRequired,
  tabIndexBase: PropTypes.number,
  readOnly: PropTypes.bool
};

AddressFormSection.defaultProps = {
  tabIndexBase: 100
};

/* NOT USED
 focus on {this.state.activeField || 'nothing'}

 <pre className="clear-me" style={{fontSize: "7px"}}>
 {JSON.stringify(address, null, 2)}
 </pre>

 */
