import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

import LocalUrlMaker from "../../lib/LocalUrlMaker";
import ReviewInserter from "../../ProductGroupPage/Reviews/ReviewInserter";

export const loadGroupReviews = createAsyncThunk(
  "groupReviews/load",
  ({params}, thunkAPI) => {
    let {dispatch} = thunkAPI;

    // alert("load group reviews " + JSON.stringify(params, null, 2));

    // This back-end returns HTML, not JSON; note no format in url
    let url = LocalUrlMaker.jsonPath(
      "/reviews/list?" + new URLSearchParams(params).toString());

    let { offset=0 } = params;
    let inserter = new ReviewInserter(params);

    // text looks like: "<div id='reviews-container'>\n<div class='review
    return fetch(url)
      .then(r => r.text())
      .then(text => {
        // 2022-05 - let inserter parse (and hold on to)
        // the inserted nodes.
        let count = inserter.insertHtml(text);

        // update Redux's stored review info.
        dispatch(groupReviewsSlice.actions.setGroupReviews({
          text: text, params: params,
          nextOffset: offset + count // the count, used by component to get next offset
        }))
      });
  }
);

export const groupReviewsSlice = createSlice({
  name: 'groupReviewsSlice',
  initialState: {reviewsLoaded: 0},
  reducers: {
    setGroupReviews: (state, action) => {
      let { payload } = action;

      if (payload.nextOffset)
        state.nextOffset = payload.nextOffset;

      return state;
    }
  }
});

export default groupReviewsSlice;

