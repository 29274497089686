import React from 'react';

// Show #1, #2, etc rankings in a big orange circle - used on shop by vehicle pages
export default function GridItemRank({index = 0}) {
  return (
    <div className="ranking">
      <sup>{'# '}</sup>
      <strong>{(+index) + 1}</strong>
    </div>
  );
}
