import TopNavWidget from './TopNavWidget';

export default class DropBannerBar extends TopNavWidget {
    startup(page) {
        this.$targets = $('.drop-banner');
        this.active_toggle_class = 'drop-banner-active';
        this.active_menu_class = "drop-banner-open";
        this.bindToggles($('#drop-banner-toggles li'));
        this.watchBodyClicks(page);
        return this;
    }

    targetForButton(link) {
        var id = link.id;
        if (!id) {
            return null;
        }
        return $('#' + id.replace('banners-', 'drop-banner-'));
    };
}
