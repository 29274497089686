import LocalUrlMaker from "../../lib/LocalUrlMaker";
import {camelizeKeysDeep, snakeifyKeysDeep} from "../../ReduxStore/Helpers/transformObject";

import {isEmpty, trim, flatMap} from 'lodash';
import {withGlobal} from "../../lib/globals";
import {getGroup} from "../../Data/groupCollection";

const isDev = (process.env.NODE_ENV == 'development');

export function vehicleToKlaviyoProps(vehicle) {
  vehicle = camelizeKeysDeep(vehicle);

  if (!vehicle || !vehicle.id) return null;

  const makeName = vehicle.shortMakeName || vehicle.vehicleMakeName;
  const modelName = vehicle.shortModelName || vehicle.vehicleModelName;

  return {
    Vehicle: `${vehicle.year} ${makeName} ${modelName}`,
    VehicleId: vehicle.id,
    VehicleMake: makeName,
    VehicleModel: `${makeName} ${modelName}`,

    // special case - a jeep wrangler will be sent with type=jeep
    VehicleType: (modelName == "Wrangler") ? "Jeep" : vehicle.vehicleType,

    VehicleYear: vehicle.year,
    // full url with https://whatever
    VehicleURL: vehicle.vehicleUrl ? LocalUrlMaker.url(vehicle.vehicleUrl) : null
  };
}

/**
 * Transform an AagUser into Klaviyo predefined property names.
 *
 * @param user - POJO user, snake_case or camelCase OK
 * @returns {{}}
 */
export function userToKlaviyoProps(user) {
  if (!user)
    return {};

  user = snakeifyKeysDeep(user);

  let props = {};

  let propNames = ['email', 'first_name', 'last_name', 'city', 'region', 'zip'];
  for (let k of propNames) {
    if (user[k])
      props['$' + k] = user[k];
  }

  return props;
}

/*
 * Transform the payload of a "Viewed Product"
 * event to the format Klaviyo wants for trackViewedItem
 * https://developers.klaviyo.com/en/docs/guide_to_integrating_a_platform_without_a_pre_built_klaviyo_integration#viewed-product-tracking-snippet
 *
 * */
export function trackViewedItemAttrs(data) {
  return ({
    Title: data.ProductName,
    ItemId: data.ProductID,
    Categories: data.Categories,
    ImageUrl: data.ImageURL,
    Url: data.URL,
    Metadata: {
      Brand: data.Brand,
      Price: data.Price
      // CompareAtPrice: item.compareAtPrice
    }
  })
}

// migrated from UserStash 2024-08

// sample: ["identify", {
//   "$email": "matt@autoaccessoriesgarage.com",
//   "Vehicle": "2021 Honda Ridgeline",
//   "VehicleId": 2201417,
//   "VehicleMake": "Honda",
//   "VehicleModel": "Honda Ridgeline",
//   "VehicleType": "Truck",
//   "VehicleYear": 2021,
//   "VehicleURL": "https://lisa.aagarage.net/",
//   "CartCode": "MW-JWBYW"
// }]
export function sendKlaviyoIdentify({
  user, cartCode, customerVehicle, attrs = {}
}) {
  if (isEmpty(user)) return false;

  const userProps = Object.assign({},
    userToKlaviyoProps(user),
    vehicleToKlaviyoProps(customerVehicle),
    cartCode && {CartCode: cartCode},
    attrs // misc attrs from caller, usually blank
  );

  withKlaviyo(k => {
    console.log("Sending Klaviyo IDENTIFY");
    return k.identify(userProps);
    // useless promise... then(r => null) // identify response is undefined
  })

}

export function withKlaviyo(callback) {
  return withGlobal('klaviyo', callback);
}

export function trackKlaviyoEvent(type, data) {
  if (!type || isEmpty(data)) {
    console.error(`trackKlaviyoEvent: type ${type} or data is empty`, data);
    return;
  }
  console.log(`trackKlaviyoEvent: ${type} `, data);
  return withKlaviyo(k => { return k.track(type, data) });
}

export function cartToKlaviyoProps(cart) {
  // cart should already be camelized. On the View Cart page, it already is.

  const {
    cartCode, cartItems = [], shippingAddress = {},
    groupCollection
  } = cart;

  const t = new Date().getTime();

  const itemList = cartItems?.map((item) =>
    cartItemToKlaviyoProps(cart, item));

  const allCategories = _(itemList).flatMap(item => item.ProductCategories).uniq().value();

  let stuff =  {
    "$event_id": `${cartCode}_${Math.floor(t / 1000)}`,
    "$value": new Money(cart.effectiveTotal).toDollars(),
    "ItemNames": cartItems.map(it => trim(it.name)),
    "CheckoutURL":
      LocalUrlMaker.url(`/new_checkout/${cartCode}`),
    "Categories": allCategories,
    CartCode: cartCode,
    CartItemCount: itemList.length,
    Items: itemList
  }
  return stuff;
}

export function cartItemToKlaviyoProps(cart, item) {
  const { groupCollection } = cart;
  const { productGroupId } = item;
  const group = getGroup(groupCollection, productGroupId) || [];

  const categories = _.flatten([ group.topCategories, group.subCategories ]);
  return {
    "ProductID": productGroupId,
    "SKU": item.vendorsku,
    "ProductName": trim(item.name),
    "Quantity": item.quantity,
    "ItemPrice": new Money(item.effectiveUnitPrice).toDollars(), // no comma, 2 digits precision
    "RowTotal": new Money(item.effectiveTotal).toDollars(),
    "ProductURL": LocalUrlMaker.url(item.groupUrl),
    "ImageURL": LocalUrlMaker.url(item.largeImage || item.smallImage),
    "ProductCategories": categories
  }
}
