import React from 'react';

export default function CartItemsHead(props) {
  return <div id="cart-items-head">
    <div className="col-detail">Item Description</div>
    <div className="col-qty">Qty</div>
    <div className="col-shipping">Shipping</div>
    <div className="col-price">Price</div>
  </div>
}
