import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import CheckoutSteps from "./CheckoutSteps";
import AddressPanel from "../address/AddressPanel";
import PaymentPanel from "../payment/PaymentPanel";
import SacrificialGoat from "../form/SacrificialGoat";
import DismissibleError from "./DismissibleError";
import ReviewPanel from "../review/ReviewPanel";

import BraintreeTokenFetcher from "../../braintree/BraintreeTokenFetcher";
import BraintreeClientProvider from "./BraintreeClientProvider";
import {isEmptyCheckout} from "../../logic/checkout";
import CheckoutTotals from "../sidebar/CheckoutTotals";
import {camelizeKeys, camelizeKeysDeep} from "../../../ReduxStore/Helpers/transformObject";
import CatchErrors from "../../../Utility/CatchErrors";
import GoogleCheckoutReporter from "../../analytics/GoogleCheckoutReporter";
import {paymentTypes} from "../payment/paymentConstants";

const isDev = (process.env.NODE_ENV == 'development');

export default function CheckoutUI(props) {
  const {
    backEnd = {}, checkout = {}, cartCode,
    dispatch,
    callbacks, currentAgent, error, step = 2
  } = props;

  const {action, authenticity_token} = backEnd;

  // Disable autocomplete in dev or for agent users.
  const nixAutoComplete = !!(currentAgent);

  let childProps = _.omit(props,
    'step', 'bt', 'backEnd',
    'stateList', 'cartCode');

  const loaded = !isEmptyCheckout(checkout);

  // child components want totals and cartItems camelized
  const totals = camelizeKeysDeep(checkout.totals);
  const cartItems = _.sortBy(checkout.cartItems || [], item => item.id)
    .map(item => camelizeKeysDeep(item));

  return (
    <form className="checkout-form" method="post" action={action}
      autoComplete={nixAutoComplete ? 'off' : 'on'}
      noValidate={true}
      onSubmit={() => console.error("submit suppressed") && false}>

      <input type="hidden" name="form_authenticity_token" value={authenticity_token}/>

      <CheckoutSteps current={+step} cartCode={cartCode} callbacks={callbacks}/>

      {loaded && <GoogleCheckoutReporter checkout={checkout}/> }

      <div className="row clear-me">
        <div className="checkout-main">
          {nixAutoComplete && <SacrificialGoat/>}

          {cartCode && <BraintreeTokenFetcher dispatch={dispatch} cartCode={cartCode} />}

          {error && <DismissibleError error={error} dismissError={callbacks.clearError}/>}

          {(step == 2) && <AddressPanel {...childProps}
            stateList={props.stateList}/>}

          <BraintreeClientProvider braintreeData={props.braintreeData}
            render={(btProps) => {
              return (step == 3) &&
                <PaymentPanel {...childProps} {...btProps} />
            }}
          />

          {(step == 4) && <CatchErrors><ReviewPanel {...childProps} /></CatchErrors>}
        </div>

        <div className="checkout-sidebar">
          {loaded && <CheckoutTotals
            enableAffirm={true}
            totals={totals}
            shippingAddress={checkout.shippingAddress}
            cartItems={cartItems} currentAgent={currentAgent}/>}
        </div>
      </div>
    </form>
  )

}

CheckoutUI.propTypes = {
  // braintree stuff { client, status, ...}
  // bt: PropTypes.object,
  cartCode: PropTypes.string.isRequired,
  //step: PropTypes.number,
  currentAgent: PropTypes.object, // or NULL
  paymentType: PropTypes.oneOf(paymentTypes),
  backEnd: PropTypes.shape({
    action: PropTypes.string,
    authenticity_token: PropTypes.string
  })
}

CheckoutUI.defaultProps = {
  step: 2
}
