import React from "react";
import {describeVehicle} from "../logic/vehicle";

// Multiple components in this file.

// 2024 OK
export function IncompatibleHeadline({customerVehicle}) {
  const desc = describeVehicle(customerVehicle);

  if (!customerVehicle || !desc) {
    return <div className="prompt">This product does not fit your vehicle.</div>
  }

  return <div className="prompt">
    This product does not fit your <span className="vehicle">{desc}</span>
  </div>
}

export function DetailsOnlyHeadline({headline, customerVehicle}) {
  const desc = describeVehicle(customerVehicle);

  if (!customerVehicle || !desc) {
    return <div className="prompt">{headline || "What Do You Drive?"}</div>
  }

  return <div className="prompt">
    Select Details for your <span className="vehicle-desc">{desc}</span>
  </div>

}
