import React from 'react';
import PropTypes from 'prop-types';
import Money from "../../../lib/Money";

/**
 * OptionChoice - one chooseable option within a group of options.
 *
 * Used for color, border, and logo.
 *
 */
// OPTION DISPLAY - one chooseable option within a group of options.
export default function OptionChoice(props) {
  const {option,selected, value, callbacks, calc} = props;

  // User has selected an option value - send this to the cart
  const handleClick = (evt) => {
    if (option && option.name)
      callbacks.selectOption(option.name, value);
    else
      console.error("cannot find option name")
  }

  let classes = ['opt'];
  if (selected)
    classes.push('selected');

  const centsPrice = calc && calc.getPrice(value);
  const moneyPrice = centsPrice ? new Money(centsPrice) : null;

  const smallImage = value.smallImage || value.small_image; // FIXME snake_case

  return (<div className={classes.join(' ')}>
    <a onClick={handleClick}>
      <img className="pic" src={smallImage} alt={value.label} />
      <div className="label">
        <i className="far fa-lg fa-square"/>
        {' ' + (value.label)}
      </div>
      {moneyPrice && <div className="price">{moneyPrice.toString()}</div>}
      {value.size &&
        <div className="details">Dimensions: <em>{value.size}</em></div>
      }
    </a>
  </div>)
}

OptionChoice.propTypes = {
  option: PropTypes.object,
  value: PropTypes.shape({
    id: PropTypes.any.isRequired
  }),
  callbacks: PropTypes.shape({
    selectOption: PropTypes.func.isRequired
  }).isRequired,
  calc: PropTypes.object // optional
}
