/*
 * Option collection for a Cart or a CartItem
 */
export default class CartOptions {
  // "premium" or "plain"
  static BORDER_TYPE = 'border_type';

  // product_unique - select a variant within a particular mat position
  static PRODUCT_UNIQUE = 'product_unique';

  // 0, 1, 2 - how many rows to customise.
  // (changing this param will break the back end!)
  static CUSTOMISE_ROWS = 'customise';

  static COLOR = 'color';
  static BORDER = 'border';
  static LOGO = 'logo';

  static STANDARD_BORDER = {
    id: 'STANDARD',
    name: 'Standard Binding',
    // FIXME snake_case
    small_image: '/img/design/lloyd/standard-border.png'
  }

  constructor({contents, mode = 'item'}) {
    this.mode = mode;
    this.contents = contents || {};
  }

  // use snake case names internally, always
  static normalizeName(name) {
    if (!name || !name.toLowerCase())
      name = "" + name;

    return _.snakeCase(name);
  }

  setOption(name, value) {
    name = CartOptions.normalizeName(name);

    if (!value)
      delete this.contents[name];
    else
      this.contents[name] = value;
  }

  getOption(name, value) {
    name = CartOptions.normalizeName(name);
    return this.contents[name];
  }

}

