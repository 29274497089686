// https://developers.braintreepayments.com/guides/hosted-fields/events/javascript/v3

import UpdateDummyCard from "./UpdateDummyCard";

export default class BraintreeEventHandler {
  constructor({hostedFields, getCard, updateCard}) {
    this.hostedFields = hostedFields;

    // form component's set/get state
    this.getCard = getCard;
    this.updateCard = updateCard;
  }

  bindEvents() {
    this.hostedFields.on('blur', this.onFieldEvent);
    // this.hostedFields.on('focus', this.onBlur);
    this.hostedFields.on('validityChange', this.onFieldEvent);
  }

  getUpdater() {
    let updater = new UpdateDummyCard({
      creditCard: this.getCard()
    })
    return updater;
  }

  // Invoked when user has left field or changed its status.
  // We don't see the new field value but we know if it's valid/complete/empty.
  //
  // (often these two events occur in pairs, rapid-fire, but it's fine
  // to call this more than once...)
  onFieldEvent = (evt) => {
    if (!evt) return;

    let {emittedBy: fieldName, fields} = evt;
    let field = fields[fieldName] || {};

    let up = this.getUpdater();
    up.updateField(fieldName, field);

    this.updateCard(up.creditCard);
  }

}

// event.emittedBy = "cardholderName"
// event.fields = { cardholderName => stuff, number => stuff... }
// event.feidls[event.emittedBy].isValid
// {isEmpty: true, isValid: false, isPotentiallyValid: true, isFocused: false, container:
// div#credit_card-name.bt-input2b}
