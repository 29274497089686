/**
 * Provides query methods on a Product.
 * As of 2022-09 the product's keys are snake_case
 */
export default class ProductWrapper {
  constructor(product) {
    this.product = product || {};
  }

  stockStatus() {
    return this.product.buyableStatus || this.product.buyable_status;
  }

  outOfStock() {
    let status = this.stockStatus();

    return (status === "out_of_stock") || (status === "discontinued")
  }
}
