/**
 * ABSTRACT class to build (and optionally perform) a FETCH request
 * with a body.
 *
 * Use subclasses JsonPost or PostWithParams instead.
 *
 */

import querystring from 'querystring';

export default class FetchBuilder {
  constructor({url, body, params, verbose, method = 'post'} = {}) {
    // url is optional, required only if .perform() is used
    this.url = url;
    this.method = method;
    this.body = body;
    this.verbose = verbose || false;
    this.params = params || {};
  }

  /**
   *
   * @returns {Promise<Response>}
   */
  perform() {
    let fetchOpts = this.toFetchOptions();

    if (this.verbose) {
      console.log(`performing ${this.method} to ${this.url}`, fetchOpts);
    }

    return fetch(this.fullUrl(), fetchOpts);
  }

  fullUrl() {
    let qs = this.queryString();

    if (qs) {
      return this.url + '?' + qs;
    }

    return this.url;
  }

  toFetchOptions({headers, ...opts} = {}) {
    headers = this.buildHeaders(headers);

    return Object.assign({
      method: this.method,
      headers: headers,
      body: this.getBody(),
      credentials: 'include',
      cache: 'no-cache',
    }, opts);
  }

  buildHeaders(overrides = {}) {
    return Object.assign(this.getDefaultHeaders(), overrides);
  }

  getDefaultHeaders() {
    return {};
  }

  getBody() {
    console.error("FetchBuilder: forgot to override getBody()");
    return "";
  }

  queryString() {
    return new URLSearchParams(this.params || {}).toString();
  }
}
