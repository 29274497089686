import React from "react";

export default function DummySelector({name, value}) {
  if (!value)
    value = 'Your Vehicle ' + name;

  return (
    <div className="selector-level selector-disabled">
      <span className="fa-wrapper">
        <span className="fa fa-arrow-right"/>
      </span>
      <div>
        <select disabled={true}>
          <option className="placeholder">{value}</option>
        </select>
      </div>
    </div>)
}
