import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import PulsingLinesThrobber from "../../../Common/components/PulsingLinesThrobber";

import CartFields from "./CartFields";
import LloydCart from "../models/LloydCart";

export default function SubmittingFormBottom(props) {
  return <section id="lloyd-item-section-bottom">
    <PulsingLinesThrobber/>
    <div id="lloyd-item-section-bottom-submit">
      <button className="btn" type="button" disabled="true">Please Wait...</button>
    </div>
    <CartFields {...props} />
  </section>
}

SubmittingFormBottom.propTypes = {
  cart: PropTypes.instanceOf(LloydCart).isRequired,
  callbacks: PropTypes.object.isRequired
}
