/*
 * Photo Uploader - non-react.
 *
 * Uses BlueImp templating and jquery file upload plugin.
 * $.fn.fileupload needs to be defined for this to work.
 *
 *  https://github.com/blueimp/jQuery-File-Upload
 * blueimp 9.28.0 Latest commit ee54fc3  on Nov 12, 2018
 *
 *     "blueimp-file-upload": "https://github.com/blueimp/jQuery-File-Upload",
    "blueimp-tmpl": "^3.11.0",

 */
import _ from 'lodash';
import $ from 'jquery';

import PhotoUploaderDisplay from './PhotoUploaderDisplay';
import PhotoUploaderFormHelper from './PhotoUploaderFormHelper';
import PhotoDelete from './PhotoDelete';

export default class PhotoUploader {
  constructor($form) {
    this.$form = $form;
    this.display = new PhotoUploaderDisplay(this);
    this.form_helper = new PhotoUploaderFormHelper(this);
    this.count = 0;
    this.enableUpload();
    this.enableDeletes();
  }

  enableDeletes() {
    return $('.progress-display').on('click', '.remove',
        (evt) => this.deleteClicked(evt.target));
  }

  deleteClicked(elem) {
    let $link = $(elem);
    if ($link && $link.attr('href')) {
      new PhotoDelete($link).click();
    }
    return false;
  }

  onAdd(evt, upload) {
      let file = upload.files[0];
      this.display.buildProgressBar(upload, file);
      upload.submit();

      this.afterSubmit(upload);
      return true;
  }

  onDone(evt, upload) {
      upload.context.remove();
      this.display.onFinished(upload, evt);
  }

  enableUpload() {
    return this.$form.fileupload({
      dataType: 'json',
      add: (evt, up) => this.onAdd(evt, up),
      progress: (evt, up) => this.display.onProgress(up, evt),
      done: (evt, up) => this.onDone(evt, up)
    });
  }

  afterSubmit(data) {}

  updateLabel() {
    this.count = this.count + 1;
    return $('.fileinput-button label').text("Drop photos here (" + this.count + " submitted)");
  }

  updateSortOrder() {
    let $s, val;
    $s = $('#photo_sort_order');
    val = $s.val();
    if (!isNaN(val)) {
      return $s.val(100 + (+val));
    }
  }
}



