import React from 'react';
import _ from 'lodash';

import AddressFormSection from "./AddressFormSection";
import SubmitAddress from "../../logic/SubmitAddress";
import ContinueButton from "../form/ContinueButton";
import SameAddressSwitch from "./SameAddressSwitch";
import {reportToGoogle} from "../../logic/GoogleCheckoutReporter";
import {isEmptyCheckout, showSameAddressSwitch} from "../../logic/checkout";
import scrapyardSlice from "../../../ReduxStore/scrapyardSlice";
import {sendCartEvent} from "../../../Analytics/googleShoppingCart";

/**
 * The main component for the ADDRESS section; contains a shipping address field set,
 * a billing address field set, and an off-on switch for the billinga ddress.
 */
export default function AddressPanel(props) {
  const {checkout, callbacks, status, dispatch} = props;

  const submitEnabled = (status !== 'pending');

  const handleSubmit = (evt) => {
    if (evt && evt.preventDefault)
      evt.preventDefault();

    if (!submitEnabled) {
      console.error("AddressPanel: submit is disabled, ignoring button submit attempt")
      return false;
    }

    // FIXME: uses callbacks setStep, setCheckout, saveCheckout
    const submit = new SubmitAddress({checkout, callbacks});

    if (submit.call() == 'error') {
      window.scrollTo(0, 0);

      dispatch(scrapyardSlice.actions.setError({
        headline: "Name or Address Incomplete",
        error: {message: submit.getFullError()}
      }))
    } else {
      sendCartEvent({
        eventType: 'add_shipping_info',
        shoppingCart: checkout,
      });
    }
  }

  const {sameAddress = true} = checkout;

  const shippingAddress = checkout.shippingAddress || {};
  const billingAddress = checkout.billingAddress || {};

  const sharedProps = Object.assign(
    _.pick(props, 'dispatch', 'callbacks', 'stateList', 'currentAgent'),
    {readOnly: isEmptyCheckout(props.checkout)}
  );

  const showAddressSwitch = showSameAddressSwitch(props);

  // tab index: 100-180 = shipping; 180-199 = between ship/bill; 200-280=billing; 299=continue
  return (
    <div id="checkout-address-panel" className="checkout-form-section">
      <h2>Shipping Address</h2>

      <AddressFormSection
        tabIndexBase={100}
        addressType="shipping"
        address={shippingAddress}
        callbacks={callbacks}
        {...sharedProps}/>

      {showAddressSwitch && <SameAddressSwitch tabIndex={181}
        same={sameAddress} setSameAddress={callbacks.setSameAddress}/>}

      {!sameAddress && <div className="billing-subhead"><h2>Billing Address</h2></div>}

      {!sameAddress && <AddressFormSection
        tabIndexBase={200}
        addressType="billing"
        address={billingAddress}
        callbacks={callbacks}
        {...sharedProps}/>
      }

      <ContinueButton
        id="submit-address"
        tabIndex={299}
        disabled={!submitEnabled}
        onSubmit={handleSubmit}
        caption="Continue"
      />

    </div>
  );
}
