import Money from "../../../lib/Money";
import React from "react";

export default function CartDisplayShippingTotal(props) {
  let { label, value, shippingAddress={} } = props;

  let { state_name } = (shippingAddress || {});

  // savings line is optional, suppress when zero
  if (!value && props.optional)
    return null;

  if (+value > 0 && state_name) {
    label = state_name + " Shipping"
  }

  let id = "summary-" + props.id;
  return <li id={id}>
    <div className="lbl">{label}:</div>
    <div className="amt">
      <span>{new Money(value || 0).toString()}</span>
    </div>
  </li>
}
