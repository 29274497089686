import React from 'react';

// These badges are defined in item_badges.scss.
// Presently they appear as dangly ribbons.
export default function PromoBadge({promo, ...props}) {
  if (!promo) return null;

  //  type = instant_coupon, label = "Instant Coupon",
  //  message = "$20 Price Drop - Prices already reduced, limited time!"
  const {type, label, message} = promo;

  return (
    <div className={`product-badge badge-${type}`} title={message}>{label}</div>
  );

}
