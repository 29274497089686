import React from "react";
import {getSaveCartConfig} from "../../ShoppingCart/components/save_cart/saveCartLogic";

export default function SaveCartTitle({
  title = "Save Cart", logo = true, theme, ...props
}) {
  const config = getSaveCartConfig(theme);

  return <div className="save-cart-title">
    {logo && config.logo && <div className="logo"><img
      class="my-0 mx-auto"
      src={config.logo} alt={config.alt}/></div>}

    {title && <h2>{title}</h2>}
  </div>
}

