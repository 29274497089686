import React from 'react';
import _ from 'lodash';

export default function RequestReceiptInstructions({result, ...props}) {

  const {errors, status} = result;

  // result: status, count, message
  const succeeded = status === 'success';

  const styles = {minWidth: "16rem", maxWidth: "32rem"};

  if (succeeded) {
    return (<p className="mb-2" style={styles}>
      <span className="fa fas fa-check-circle text-green-400"/>
      {' '}
      {result.message || 'Success'}
    </p>)
  }

  if (status === 'error' || !_.isEmpty(errors)) {
    return <div className="mb-2" style={styles}>
      {' '}
      <p className="mb-2 text-red-600">
        <span className="fa fas fa-exclamation-triangle text-xl"/>
        {' '}
        Sorry, there was an error with your request:
      </p>

      {_.values(errors).map(msg => <div className="pl-4">{msg}</div>)}
    </div>
  }

  return (<p className="mb-2" style={styles}>
    Enter any email address below, and we'll send you a
    fresh copy of the order receipt.
  </p>)
}
