//
// 2017 new search form.
//
// DEPENDENCIES: jquery, window.ga
//
export default class SearchForm {
    startup(page) {
        let self = this;

        let $f = this.form = $('#search-box form');

        $f.submit(function () {
            var field = $('input#search');
            if (!field[0])
                field = $f.find('input:first');
            if (!field || !page)
                return;

            // Any "q=" parameter, on any url, is counted by google as a SiteSearch.
            // (The parameter name is configurable but we left it at the default).
            // Send a spurious page view, *before* actually executing the search,
            // because the search action my redirect to something weird rather than
            // displaying a result page.
            var words = field.val();

            return true;
        });

        return this;
    }
}
