import produce from "immer";

import SubmitButtonHandler from "./SubmitButtonHandler";
import GetBraintreeNonce from "./GetBraintreeNonce";

const MSG_NO_NONCE = "The payment service did not accept your credit card.\n\n" +
  "Please check that it was entered correctly, or try a different payment method.";

export default class SubmitBraintreeCheckout extends SubmitButtonHandler {
  constructor({checkout, callbacks, hostedFields, onFailure}) {
    super({checkout, callbacks});

    this.hostedFields = hostedFields;

    this.onFailure = onFailure; // callback
  }

  call() {
    // Call the Braintree tokenizer, which will give
    // us a nonce payload - this will invoke either
    // onNonceReceived or onFailure
    this.getTokenizer().call();
  }

  modifyCheckout() {

  }

  // payload contains: nonce, details, description, type, binData
  onNonceReceived = (payload = {}) => {
    const { nonce } = payload;

    if (! nonce) {
      this.onFailure({message: MSG_NO_NONCE});
    }

    this.submitPayment(payload);
  }

  /**
   *
   * @param braintreeCheckout - already verified OK
   */
  submitPayment(braintreeCheckout) {
    const newCheckout = Object.assign({}, this.checkout,
      { braintreeCheckout });

    // add braintreeCheckout object to checckout in redux
    this.callbacks.setCheckout({status: 'pending', checkout: newCheckout});

    // and submit to back end, which will attempt to create the order
    this.callbacks.saveCheckout({
      checkout: newCheckout,
      route: 'braintree_payment',
    });
  }
  //
  // afterSubmitPayment(action) {
  //   console.dir(action)
  // }

  // Handle the result of placing the order - which may
  // or may not be a success.
  // onsubmitPaymentAttempted = ({data,dispatch,...stuff}) => {
  //   const handler = new BraintreeResultHandler({
  //     payload: data,
  //     dispatch: dispatch,
  //     ...stuff
  //   });
  // }

  getTokenizer() {
    // Invoke braintree's "tokenize" api, which will return a
    // Payment Nonce and other info. All of this will be sent to
    // the back end as braintreeCheckout.
    const { checkout, hostedFields, onNonceReceived, onFailure } = this;

    return new GetBraintreeNonce({
      checkout, hostedFields, onFailure,
      onSuccess: onNonceReceived,
    });
  }
}
