import React, {Component} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import VehicleYearChooserUI from "./VehicleYearChooserUI";
import {saveSessionVehicle} from "../ReduxStore/CustomerVehicle/vehicleSlice";
import LocalUrlMaker from "../lib/LocalUrlMaker";
import YearPrompt from "./YearPrompt";
import {camelizeKeys, camelizeKeysDeep} from "../ReduxStore/Helpers/transformObject";

/**
 * A very simplified VehicleSelector, this just uses local state
 * for options and choices, not Redux.
 *
 * Found on shop by vehicle pages and vehicle-category landing pages.
 *
 */

export default function VehicleYearChooserContainer(props) {
  props = camelizeKeysDeep(props);

  return <InnerContainer {...props} />
}

class InnerContainer extends Component {

  state = {
    year: null,
    choseYear: false // did the user select the year?
  }

  render() {
    // props: "vehicleModel" "years","customerVehicle",
    // "vehicleContext", "dispatch"
    // return <pre><code>{JSON.stringify(_.keys(this.props), null, 4)}</code></pre>

    const {years, customerVehicle, vehicleModel, uplink} = this.props;

    // Has the user already selected the vehicle we're asking about?
    // in that case we know the year and we're done...
    const isComplete = customerVehicle && vehicleModel &&
      (+customerVehicle.vehicleModelId === +vehicleModel.id);

    if (isComplete) {
      return <div/>
    }

    const onSelectYear = (year) => {
      let {dispatch, vehicleModel = {}} = this.props;

      if (!year || !vehicleModel)
        return;

      let params = {year: year, model: vehicleModel.id, from: "VehicleYearChooser"};

      dispatch(saveSessionVehicle({
        params: params,
        vehicleContext: {source: 'user'}
        // FIXME: config.productContext
      }));

      this.setState({year: year, choseYear: true})
    }

    return (
      <div id="vehicle-selector"
        className="vehicle-selector vehicle-selector-incomplete vehicle-selector-horizontal vehicle-year-chooser">

        <YearPrompt vehicleModel={vehicleModel} uplink={uplink} />

        <VehicleYearChooserUI
          uplink={uplink}
          vehicleModel={vehicleModel}
          years={years}
          onSelectYear={onSelectYear}/>
      </div>
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let {customerVehicle} = this.props;
    if (!customerVehicle || !customerVehicle.id)
      return;

    let {year, choseYear = false} = this.state;

    if (year && choseYear)
      redirectToYearPage(year);
  }

}

function redirectToYearPage(year) {
  let url = new URL(globalThis.location);
  url.search = "year=" + year;
  globalThis.location = url.toString();
}

function matchesCustomerVehicle({vehicleModel, customerVehicle}) {

  if (_.isEmpty(vehicleModel) || _.isEmpty(customerVehicle))
    return false;

  vehicleModel = camelizeKeys(vehicleModel);
  customerVehicle = camelizeKeys(customerVehicle);

  let {vehicle_model_id} = customerVehicle;
  if (!vehicle_model_id) return false;

  return +(vehicleModel.id) == +(vehicle_model_id);
}
