// This file lives in config/javascript (outside of "app"!)
// and is built by script/activate-theme.
import {SITE_CONFIG} from "siteConfigVars";

const isDev = (process.env.NODE_ENV == 'development');

export const siteConfig = function (key, defaultValue = null, throwError = false) {
  if (SITE_CONFIG.hasOwnProperty(key))
    return SITE_CONFIG[key];

  let complaint = `siteConfig is missing key ${key}`;
  if (throwError) {
    console.error(complaint);
    throw new Error(complaint);
  } else {
    console.warn(complaint);
  }

  return defaultValue;
}

export const requireConfig = function(key, defaultValue = null) {
  return siteConfig(key, defaultValue, isDev);
}
