import TopNavWidget from './TopNavWidget';

export default class SearchButton extends TopNavWidget {
    startup(page) {
        this.$targets = $('#container-header');
        this.$toggles = $('#mobile-search');
        this.active_menu_class = 'search-visible';
        this.bindToggles(this.$toggles);
        return this;
    }
}
