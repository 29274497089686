import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import PendingIcon from "./PendingIcon";
import StatusIcon from "../mainpage/StatusIcon";

export default function ImageDisplay({image, callbacks, ...props}) {
  const {fullPath, status, updatePending} = image;
  const displayStatus = _.startCase(status);

  const displayHeight = _.min([image.height, 420]);

  const active = (status === 'active');

  return <div className="text-center">
    <div className="relative">
      <StatusIcon classNames="centered text-7xl"
        image={image} status={status} />

      <a className="block" href={fullPath} target='_blank' style={{minHeight: '100px'}}>
        <img className="inline-block mx-auto"
          style={{height: `${displayHeight}px`}}
          alt={fullPath}
          src={fullPath}/>
      </a>
      {updatePending && <PendingIcon className="text-7xl" />}
    </div>

    <div className="text-center text-lg">
      {fullPath}
    </div>

    <div className="grid grid-cols-3">
      <div className="text-center">Status: <b className={active ? '' : 'text-red-400'}>{status}</b></div>
      <div className="text-center">Flavour: <b>{image.flavour}</b></div>
      <div className="text-center">ID: <b>{image.id}</b></div>
    </div>
  </div>

}
