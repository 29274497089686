import React from 'react';

//
// Shown only on subcategory pages when thisPage prop is
// present, this links back to the parent.
export default function ReturnFromSubcat({thisPage, ...props}) {
  const {title, parentPath, parentName} = thisPage;

  const handleClick = (e) => {
    if (parentPath) {
      e.preventDefault();
      globalThis.location = parentPath;
    }
  }

  // id: we override the usual props.name here
  return (
    <div className="grid-sidebar-box" id="categories-filter">
      <h4>TYPE</h4>

      <div className="go-up-a-level block py-2">
        <a className="text-gray-900" href={parentPath}>
          <i className="absolute text-3xl fal fa-arrow-circle-left"/>

          <sup className="block m-2 pl-8"> Back to View All </sup>
          <span className="relative block m-2 pl-8">{parentName}</span>
        </a>
      </div>

      <div className="choices">
        <label>
          <input type="checkbox" value={1} checked={true} onChange={handleClick}/>
          {' '}
          <a className="click-me" onClick={handleClick}>{title}</a>
        </label>
      </div>
    </div>
  )
}

// SubCategoryNav.propTypes = { }
