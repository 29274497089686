import React, {useState} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import clsx from "clsx";

const isDev = (process.env.NODE_ENV == 'development'); //FIXME REMOVE

/**
 * 2023-11 Modernised
 *
 * @param relatedPages
 * @returns {JSX.Element|null}
 * @constructor
 */
export default function RelatedPagesWidget({relatedPages}) {
  const [open, setOpen] = useState(false);

  if (_.isEmpty(relatedPages))
    return null;

  const {headline = 'Related Pages', pages} = relatedPages;

  if (_.isEmpty(pages))
    return null;

  const handleToggleOpen = evt => {
    setOpen(!open);
  }

  const classNames = ["grid-sidebar-box",
    `grid-sidebar-box-${open ? "open" : "closed"}`,
    "linked-pages"
  ];

  return (
    <div className={clsx(classNames)}>

      <h4>
        <a onClick={handleToggleOpen}>
          {headline}</a>
      </h4>

      <div className="content-wrapper">
        <div className="content">
          <div className="choices">
            {pages.map((p) => <RelatedPageLink key={p.id} page={p}/>)}
          </div>
        </div>
      </div>
    </div>
  );
}

RelatedPagesWidget.propTypes = {
  relatedPages: PropTypes.shape({
    flavour: PropTypes.string,
    headline: PropTypes.string,
    pages: PropTypes.array
  })
};

function RelatedPageLink({page}) {
  // // FIXME REMOVE WARNING AFTER FIXING
  if (isDev && page.full_path) {
    console.error("NOT CAMELIZED - ", page);
    return <div className="debug">FIXME CAMELIZE</div>;
  }
  // // FIXME REMOVE WARNING AFTER FIXING

  const {linkText, vehicleName, brandName, fullPath} = page;

  return <div>
    <a href={fullPath}>{linkText || brandName || vehicleName}</a>
  </div>
}
