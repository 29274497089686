import Orchestrator from './Orchestrator';

//
// 2019-12 - defer execution until after page loads
//
export default class QueueRunner extends Orchestrator {
    constructor(opts = {}) {
        super();

        let { queue } = opts;
        this._queue = queue;
    }

    startup(page) {
        this.safely("process run queue", () => this.processQueue());

        this.updateGlobals();
        return this;
    }

    // After cleaning out the initial queue, replace the two functions
    // that feed it with simple versions that just run immediately.
    updateGlobals() {
        window.runLater = function(f) { return f(); };
        window.runLaterWithService = window.withService;
    }

    getQueue() {
        return this._queue || global.run_queue || [];
    }

    processQueue() {
        let f = null;

        while (f = this.getQueue().shift()) {
            this.safely("run queued function", f);
        }
    }
}
