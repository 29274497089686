/*
 * Klaviyo - extracted from view templates 2019-01.
 *
 */
import SpeakerToAnything from "./SpeakerToAnything";

export default class SpeakerToKlaviyo extends SpeakerToAnything {

  isLoaded() {
    return (typeof window.klaviyo == "object");
  }

  preconfigure() {

  }

  getClient() {
    return window.klaviyo;
  }

}
