import produce from "immer";
import EditAddress from "./EditAddress";
import SubmitButtonHandler from "./SubmitButtonHandler";

/**
 * Handle the continue button on the AddressPanel.
 * Validate the shipping & billing address.
 */
export default class SubmitAddress extends SubmitButtonHandler {
  constructor({checkout, callbacks}) {
    super({checkout, callbacks});

    this.route = 'address';
  }

  call() {
    // this will modify this.checkout
    this.modifyCheckout();

    if (this.hasErrors() ) {
      // update our global state but do not save to back-end.
      this.updateReduxCheckout();
      return "error";
    } else {
      return this.saveIfChanged();
    }
  }

  getFullError() {
    let messages = _.values(this.getErrors());
    if (_.isEmpty(messages)) {
      messages.push("Error in address.");
    }

    return messages.join(".\n");
  }

  // update our global state but do not save to back-end.
  updateReduxCheckout() {
    const {setCheckout} = this.callbacks;

    // Update stored checkout in redux.
    setCheckout({checkout: this.checkout});
  }

  hasErrors() {
    // const { shippingAddress={}, billingAddress={}, sameAddress } = this.checkout;
    //
    // // has shipping errors
    // if (!_.isEmpty(shippingAddress.errors))
    //   return true;
    //
    // if (!sameAddress) {
    //   if (!_.isEmpty(billingAddress.errors))
    //     return true;
    // }
    return ! _.isEmpty(this.getErrors());
    // return false;
  }

  getErrors() {
    const { shippingAddress={}, billingAddress={}, sameAddress } = this.checkout;

    let errors = Object.assign({}, shippingAddress.errors);
    if (!sameAddress) {
      Object.assign(errors, billingAddress.errors);
    }

    return errors;
  }

  modifyCheckout() {
    const { shippingAddress, billingAddress, sameAddress } = this.checkout;

    this.validateAddress(shippingAddress, 'shipping');

    if (!sameAddress) {
      this.validateAddress(billingAddress, 'billing');
    }
  }

  afterUpdate(action) {
    this.callbacks.setStep(3);
  }

  // Use immer to mutate the immutable.
  validateAddress(addr, usage) {
    const newAddr = produce(addr, draft => {
      let editor = new EditAddress({object: draft, usage: usage});
      editor.getValidator().validateAll();

      if (!_.isEmpty(draft.errors))
        console.error("validateAddress got errors: ", draft.errors);
    });

    // no edits?
    if (newAddr === addr)
      return false;

    this.checkout = produce(this.checkout, draft => {
      console.log("save new addr into checkout...")
      draft[`${usage}Address`] = newAddr;
    })
  }
}

