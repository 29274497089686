import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  readLocalStorage,
  readSessionStorage,
  writeLocalStorage,
  writeSessionStorage
} from "../../../lib/safeLocalStorage";

const STORAGE_KEY = "reportedItemIds";

/**
 * Use LocalStorage to track which new CartItems we've reported to
 * the google, and which ones we haven't.
 */
export function markItemReported(id) {
  id = '' + id; // stringify it

  let idList = getReportedItemIds();

  if (_.includes(idList, id))
    return false;

  idList.push(id);
  updateReportedItemIds(idList);
}

export function getReportedItemIds() {
  let value = readLocalStorage(STORAGE_KEY, '');
  return ("" + value).split(',');
}

export function selectUnreportedItemIds(ids) {
  let oldItems = getReportedItemIds();
  return ids.filter(id => !_.includes(oldItems, ('' + id)))
}

export function updateReportedItemIds(idList) {
  idList = idList.filter(x => +x > 0).map(id => '' + id);

  writeLocalStorage(STORAGE_KEY,
    _.isEmpty(idList) ? null : idList.join(','))
}
