import React from 'react';
import _ from 'lodash';
import SaveCartTitle from "./SaveCartTitle";
import CloseSaveCart from "./CloseSaveCart";

export default function SaveCartSuccess(props) {
  let { contents={}  } = props;

  let email = contents.email;

  return <div className="save-cart-success">
    <SaveCartTitle theme={props.theme} logo={false} title="Save Cart"/>

    <h3>Success!</h3>

    {email ? <p>A link to your cart has been emailed to <b>{email}</b>.</p> :
      <p>A link to your cart has been emailed to you.</p>}

    <CloseSaveCart {...props}/>
  </div>
}


