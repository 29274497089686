/*
 *
 * Load frequently used libraries and attach to global/window.
 *
 */
import _ from 'lodash';
// import moment from 'moment';
import React from 'react';
import Money from '../lib/Money';

export function isClientSide() {
    try {
        return (typeof window !== 'undefined') && // OK
            (typeof window.document !== 'undefined'); // OK
    } catch (ex) {
        console.log("client side detection error: " + ex);
        return false;
    }
}


// Pollute global (window) object for backwards compatibility.
if (typeof global !== 'undefined') {
    global.application_root_url = "/";

    global.Money = Money;
    global._ = _;
    global.isClientSide = isClientSide;
    global.React = React;
    // global.moment = moment;
    // global.URL_native = global.URL;
    // global.URL = url_parse;
}

export default function initGlobals() {
    // console.log("lodash version is " + _.VERSION);
}
