import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {safeGtag} from "../../../Analytics/googleAnalyticsFour";
import {groupToGoogleItem} from "../../../Analytics/googleAnalyticsItems";

const isDev = (process.env.NODE_ENV == 'development');

const ReportGroupView = ({productGroup, ...props}) => {

  useEffect(() => {
    if (_.isEmpty(productGroup))
      return;

    console.log("GROUP", productGroup);

    let event = [ "event", "view_item", {
      currency: 'USD',
      items: [groupToGoogleItem(productGroup)]
    } ];

    safeGtag(...event);
  }, [productGroup])

  return null;
}

// ReportGroupView.propTypes = { }

export default ReportGroupView;

