import SaveCartFallbackContents from './SaveCartFallbackContents';

export default class SaveCartPublicSavingsContents extends SaveCartFallbackContents {
  getSavings() {
    let {shoppingCart = {}} = this.props;
    let {maxPublicSavings = 0} = shoppingCart;
    return new Money(maxPublicSavings);
  }

  renderInstructions() {
    return <div className="save-cart-prompt">
      <p className="headline">
        Save
        {' '}
        <span className="money">${this.getSavings().toDollars()}</span>
        {' '}
        <span className="text-after">on the items in your cart.</span>
      </p>

      <p className="instructions">
        Activate discount by entering your email below.
      </p>
    </div>
  }
}

SaveCartPublicSavingsContents.defaultProps = {
  title: "",
  logo: true,
  prompt: "Still thinking?  Save your cart now and access it from any device later.",
  submitCaption: "Activate My Discount",
  closeCaption: "No thanks, I'd rather pay full price."
};
