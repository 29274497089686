import ReactOnRails from 'react-on-rails';

import GroupProductsApp from "./containers/GroupProductsApp";
import AddSingleItemApp from "./containers/AddSingleItemApp";
import GroupReviewLoaderApp from "./Reviews/GroupReviewLoaderApp";
import VehicleChangeWatcherApp from "./containers/VehicleChangeWatcherApp";

ReactOnRails.register({
  GroupProductsApp,
  GroupReviewLoaderApp,
  AddSingleItemApp,
  VehicleChangeWatcherApp
});
