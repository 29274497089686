import React from 'react';
import _ from 'lodash';

// Caller Icon - shown when a call is in process.
// This shows the device type (smartphone, desktop, etc) of their
// Shopping Cart, if known - NOT the device type they're calling in
// on, which is less useful.  A caller on a mobile device might have
// been browsing on a mobile device, or a desktop machine, and that
// will dictate how the agent instructs them to get the
// Connect ID.
export default class AgentStatusCallerIcon extends React.Component {
  getIcon() {
    let { phone_call={} } = this.props;

    let dev = _.lowerCase(phone_call.cart_device_type);

    let icons = {
      desktop: 'fa fa-lg fa-desktop',
      tablet:  'fa fa-lg fa-desktop',
      smartphone: 'fa fa-lg fa-mobile',
      phablet: 'fa fa-lg fa-mobile',
    };

    return icons[dev] || '';
  }

  getSubtitle() {
    let { phone_call } = this.props;

    let dev = _.lowerCase(phone_call.cart_device_type || "");

    let aliases = {
      smartphone: 'phone',
      phablet: 'phone',
      tablet: 'desktop'
    };

    if (aliases[dev])
      dev = aliases[dev];

    return _.capitalize(dev);
  }

  render() {
    let { phone_call} = this.props;

    return <div className="agent-status-icon">
      <span className={this.getIcon()} />
      <div className="subtitle">{this.getSubtitle()}</div>
    </div>
  }
}
