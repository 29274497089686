import _ from 'lodash';

export default class SaveCartValidator {
  constructor({saveCartStatus, inputs}) {
    this.flavour = saveCartStatus ? saveCartStatus.flavour : 'unknown';

    // example { "name": "", "email": "jtkirk@cynico.net", "mailingListSignup": "" }
    this.inputs = inputs;
  }

  valid() {
    let errs = this.getErrors();
    return _.isEmpty(errs);
  }

  getMessage() {
    let errs = this.getErrors();
    if (_.isEmpty(errs)) return "";

    return _.values(errs).join("\n");
  }

  getErrors() {
    if (!this._errors)
      this._errors = this.validate();
    return this._errors;
  }

  validate() {
    let errs = {};

    if (this.flavour == 'requested') {
      this.validateName(errs);
    }

    this.validateEmail(errs);

    return errs;
  }

  validateName(errs) {
    let name = this.inputs.first_name;

    if (!name) {
      errs['name'] = "Please enter your name.";
    }
  }

  validateEmail(errs) {
    let email = this.inputs.email;

    if (!email || email.length < 4) {
      errs['email'] = "We'll need your email address to save your cart and activate any savings.";
    } else {
      let words = _.split(email, '@');
      if (words.length != 2)
        errs['email'] = "Email address should look like username@domain.com.";
    }
  }
}
