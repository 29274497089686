import React, {Component} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import ChatInfoStash from "../ExternalServices/LiveChat/ChatInfoStash";
import {updateCart} from "../ReduxStore/ShoppingCart/cartSlice";
import {getDispatch} from "../Utility/ReduxHelper";

const isDev  = (process.env.NODE_ENV == 'development');

export default class ChatInfoSaver extends Component {
  static propTypes = {
    cartCode: PropTypes.string,
    chatInfo: PropTypes.object,
    dispatch: PropTypes.func.isRequired
  };

  state = {reported: 0};

  initRedux() {
    const stash = this.getStash();
    if (stash.isEmpty()) {
      // console.log("ChatInfoSaver: empty")
      return false;
    }

    stash.writeToRedux({dispatch: this.props.dispatch});
  }

  getStash() {
    return new ChatInfoStash({read: true});
  }

  componentDidMount() {
    this.initRedux();
    this.componentDidUpdate({});
  }

  componentDidUpdate(prevProps, prevState) {
    const {chatInfo} = this.props;

    if (this.startedChat(chatInfo, prevProps.chatInfo)) {
      const {reported} = this.state;

      if (!reported || ((+reported) < 10))
        this.setState({
          reported: 1 + (+reported)
        }, () => { this.reportChat(chatInfo); })

    }
  }

  /**
   * Update shopping cart (if any) with chatInfo.
   *
   * @param chatInfo
   */
  reportChat(chatInfo) {
    const {cartCode} = this.props;

    if (!cartCode) {
      console.warn("cannot report chat - no cart code");
      return;
    }

    const dispatch = getDispatch(this.props);
    if (!dispatch) return;

    chatInfo = _.omit(chatInfo, 'persisted');

    console.log(`Reporting chat info for cart ${cartCode}:`, chatInfo);

    dispatch(updateCart({
      cartCode: cartCode,
      changes: {chat_info: JSON.stringify(chatInfo)}
    }))

    this.markPersisted();
  }

  markPersisted() {
    let stash = new ChatInfoStash({read: true});
    stash.update({persisted: true});
    console.log("ChatInfoSaver - mark persisted: " , stash.contents);

    stash.writeToRedux({dispatch: this.props.dispatch});
  }

  startedChat(chatInfo, oldChatInfo) {
    if (_.isEmpty(chatInfo))
      return false;

    if (chatInfo.persisted) {
      // console.log("ChatInfoSaver - already persisted - will not send again")
      return false;
    }

    return !_.isEqual(chatInfo, oldChatInfo);
  }

  render() {
    // if (isDev) {
    //   return <div className="debug">{JSON.stringify(this.props.chatInfo, null, 2)}</div>
    // }
    // Nothing to see here. Move along.
    return null;
  }
}
