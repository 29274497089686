import React, {Component} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import ProductUniquePicker from "./ProductUniquePicker";

/**
 * Immediately below the pictures of mats, prompt the user
 * to select product_uniques for those mats that need them.
 *
 * try these:
 *
 * 2009 Ford F-150, Standard Cab [1 Pc Front]
 * 2016 Bentley Continental GT
 */
export default class ProductUniquesSection extends Component {
  static propTypes = {
    cart: PropTypes.object.isRequired,
    callbacks: PropTypes.object.isRequired
  }

  getItemsNeedingSelection() {
    let {cart} = this.props;
    if (cart) {
      return cart.items.toArray((item) => item.requiresUniqueSelection());
    }
  }

  render() {
    const fancyItems = this.getItemsNeedingSelection();

    if (!fancyItems || (fancyItems.length < 1)) {
      // console.log("no items have prod unique options");
      return null;
    }

    let {cart, callbacks} = this.props;
    let {vehicle} = cart;

    return <div id="lloyd-mat-chooser-bottom">
      <div id="lloyd-product-unique-pickers">
        <h3>To ensure proper fit, we need to know more about your
          <em>{" " + (vehicle ? vehicle.fullDescription() : 'vehicle')}</em>.
        </h3>

        {
          fancyItems.map((item) => <ProductUniquePicker
            key={item.productId}
            cartItem={item} callbacks={callbacks}/>
          )
        }
      </div>
    </div>
  }
}



