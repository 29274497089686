import React, {Component} from 'react';
import PropTypes from 'prop-types';

// FIXME: using my own Money library, not good.
import Money from '../../../../lib/Money';

export default class CartPriceDisplay extends Component {
  static propTypes = {
    application: PropTypes.object,
    product: PropTypes.object,
    matchingProducts: PropTypes.object,
    products: PropTypes.object,
    productGroup: PropTypes.object
  }

  selectCheapestProduct() {
    let { matchingProducts, products } = this.props;
    if (!matchingProducts || !products)
      return null;


    let goodProds = _.values(_.pick(products, _.keys(matchingProducts)));

    goodProds = goodProds.filter((p) => p.regular_price_cents);
    return _.minBy(goodProds, p => p.regular_price_cents);
  }

  getPrice() {
    let {product} = this.props;
    if (!product) {
      product = this.selectCheapestProduct();
    }

    if (!product)
      return null;

    let {regular_price_cents} = product;

    return new Money(regular_price_cents);
  }

  render() {
    let dollars = this.getPrice();
    if (!dollars) return null;

    return (
      <div className="cart-price-display">
        <div className="unit-price">{dollars.toString()}</div>
        <div className="subtitle">Free Shipping</div>
      </div>
    );
  }
}
