import {combineReducers} from 'redux';
// configureStore is a Redux Toolkit wrapper for createStore
import {configureStore} from '@reduxjs/toolkit'

// Redux toolkit slices.
import checkoutReducer from "./Checkout/checkoutSlice";

import groupItemsSlice from "./ProductGroupPage/groupItemsSlice";
import groupReviewsSlice from "./ProductGroupPage/groupReviewsSlice";

import vehicleSlice from "./CustomerVehicle/vehicleSlice";
import chooserOptionsSlice from "./VehicleChooser/chooserOptionsSlice";
import vehicleChoicesSlice from "./VehicleChooser/vehicleChoicesSlice";
import cartSlice from "./ShoppingCart/cartSlice";
import saveCartSlice from "./ShoppingCart/saveCartSlice";
import groupGridSlice from "./GroupGrid/groupGridSlice";
import scrapyardSlice from "./scrapyardSlice";
import userSlice from "./userSlice";

import gridFilterSlice from "./GroupGrid/gridFilterSlice";
import ReactOnRails from "react-on-rails";

const isDev = (process.env.NODE_ENV === 'development');

export const dummyReducer = stuff => (stuff === undefined) ? {} : stuff;

// TODO: we can later register more reducers at runtime using
//    var newReducer = combineReducers(_.extend({}, staticReducers, {more.stuff});
//    store.replaceReducer(newReducer)

// see https://redux.js.org/usage/code-splitting
const coreReducers = {
  // checkout
  checkoutSlice: checkoutReducer,

  // used on group page
  // groupReviews: groupReviewsReducer,
  groupReviews: groupReviewsSlice.reducer, // FIXME rename

  // ReduxToolkit
  groupItemsSlice: groupItemsSlice.reducer,
  vehicleSlice: vehicleSlice.reducer,
  chooserOptionsSlice: chooserOptionsSlice.reducer,
  vehicleChoicesSlice: vehicleChoicesSlice.reducer,
  cart: cartSlice.reducer,  // FIXME rename
  saveCart: saveCartSlice.reducer, // FIXME rename
  scrapyardSlice: scrapyardSlice.reducer,

  // 2024: new convention; userStore for the data, userSlice for the logic
  userStore: userSlice.reducer,

  // grid on topcategory/subcategory page
  groupGridSlice: groupGridSlice.reducer,
  gridFilterSlice: gridFilterSlice.reducer,
  railsContext: dummyReducer // readonly after init
};

const DEFAULT_NAME = 'default';

function makeStore(initialState = {}, railsContext = {}) {
  const store = configureStore({
    reducer: combineReducers(coreReducers),
    // middleware, enhancers...
    devTools: isDev,

    // obj with same keys as reducers hash
    preloadedState: Object.assign({}, initialState, {railsContext})
  });

  return store;
}

ReactOnRails.registerStore({[DEFAULT_NAME]: makeStore})

const cachedStores = {};

//const store = makeStore();

export function getReduxStore(storeName = DEFAULT_NAME, generator = makeStore) {
  return (cachedStores[storeName] ||=
    safeGetStore(storeName) || safeCreateStore(storeName, generator));
}

function safeGetStore(storeName) {
  try {
    return ReactOnRails.getStore(storeName, false);
  } catch (err) {
    console.warn(`Redux: failed to get store ${storeName}`);
    console.warn(`(suppressed): ${err} `);
  }
}

function safeCreateStore(storeName, generator = makeStore) {
  try {
    ReactOnRails.registerStore({[storeName]: makeStore});
    return ReactOnRails.getStore(storeName, false);
  } catch (err) {
    console.warn(`Redux: error registering ${storeName} generator`);
  }

  // Fallback - call makeStore directly.
  console.warn("ReduxOnRails store generation failed, using empty content!")
  return makeStore({}, {});
}


globalThis.getReduxStore = getReduxStore;
