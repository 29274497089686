import {isNola} from "../../../lib/EmbeddedConfig";
import {sendStartCheckoutEvent} from "../../../Analytics/adobeCartEvents";


export function reportStartCheckout({
  shoppingCart, checkoutType
}) {
  try {
    if (isNola())
      reportStartCheckoutToAdobe({shoppingCart, checkoutType});
  } catch (err) {
    console.error(err);
  }
}

function reportStartCheckoutToAdobe({shoppingCart, checkoutType}) {
  sendStartCheckoutEvent({shoppingCart});
}
