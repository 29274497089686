import React from 'react';

import {compareItemsPath} from "../../../lib/urlPaths";

// 2023-11 modernised
export default function CompareNow({id, handleChange, compareList}) {
  const handleClick = e => {
    if (compareList.length < 2) {
      alert("Please select at least two items.");
      return;
    }

    window.location = compareItemsPath(compareList);
  }

  return <div className="grid-item-compare compare-selected compare-enabled">
    <span className="compare-label">
      <input id={id} type="checkbox" value="1" onChange={handleChange} checked={true}/>
      <a onClick={handleClick}>Compare Now</a>
    </span>
  </div>
}
