import React from 'react';

// FUCKING GOOGLE!
// They refuse to honour autocomplete=off because they think they know
// better than everyone else, and have essentially said as much.

// https://stackoverflow.com/questions/12374442/chrome-ignores-autocomplete-off
//
export default function SacrificialGoat() {
  return (
    <div style={{display: 'none', visibility: 'hidden'}}>
      <input id="PreventChromeAutocomplete" type="text"
        name="PreventChromeAutocomplete" autoComplete="address-level4"/>
    </div>
  );
}

