import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

// Wrap any component with this, and only agents will
// be able to see it.
export default class AgentsOnly extends React.Component {
  isAgent() {
    let { agent } = this.props;

    return (agent && agent.id && true) || false;
  }

  render() {
      if (!this.isAgent())
        return null;

      let { children, tagName='div' } = this.props;

      let TagName = tagName;  // avoid warnings

      return (<TagName>{children}</TagName>);
  }
}
