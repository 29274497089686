import React from "react";
import _ from "lodash";
import LocalUrlMaker from "../../../lib/LocalUrlMaker";

export default function CartItemTitle({item, url}) {
  url || (url = LocalUrlMaker.prefixedPath(item.groupUrl));

  return <div className="group-name">
    <PromoList promotions={item.promotions}/>
    {' '}
    <a href={url}>{item.groupName}</a>
  </div>
}

function PromoTag(props) {
  let goodPromos = ['sale', 'free-gift', 'rebate']
  if (!_.includes(goodPromos, props.name))
    return null;

  return <span className={"promo-tag promo-tag-" + props.name}>{props.label}</span>
}

function PromoList({promotions}) {
  if (_.isEmpty(promotions)) return null;

  let promoList = _.values(promotions);
  return <span>
    {promoList.map((promo) => <PromoTag key={promo.symbol} {...promo} />)}
  </span>
}
