import React from "react";
import LocalUrlMaker from "../../../lib/LocalUrlMaker";
import DynamicAddButton from "../DynamicAddButton";
import ShoppingCart from "../../../ShoppingCart/models/ShoppingCart";
import CheckoutLink from "./CheckoutLink";


export default function ItemCartBox(props) {
  const {application, product, dispatch, cartItem, shoppingCart} = props;

  let price = new Money(product.regular_price_cents);

  return <div className="cart-box">
    <div className="price">{price.toString()}</div>

    <DynamicAddButton
      dispatch={dispatch}
      application={application}
      product={product}
      cartItem={cartItem}
    />

    {cartItem && <CheckoutLink shoppingCart={shoppingCart} />}
  </div>
}
