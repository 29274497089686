import Money from "../../../lib/Money";
import React from "react";

// 2023-01 modernized
export default function CartDisplayShippingTotal(props) {
  let {label, value=0, shippingAddress = {}} = props;

  let {stateName} = (shippingAddress || {});

  if (!value && props.optional)
    return null;

  if (+value > 0 && stateName) {
    label = stateName + " Shipping"
  }

  let m = new Money(value || 0);

  return <li id={"summary-shipping"}>
    <div className="lbl">{label}:</div>
    <div className="amt">
      <span>{value == 0 ? 'FREE' : m.toString()}</span>
    </div>
  </li>
}
