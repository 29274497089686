import React, {useState} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import LocalUrlMaker from "../../lib/LocalUrlMaker";
import JsonPost from "../../ReduxStore/Helpers/JsonPost";
import PostWithParams from "../../ReduxStore/Helpers/PostWithParams";

/**
 * 2023-02 MH
 * Standalone SubscribeCheckbox component. Self-contained, can be used anywhere.
 * Initially used for new completed order page, /new_checkout/<cart_code>/completions/<order_id>
 *
 * @param customer
 * @param url
 * @param orderId
 * @param label
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const SubscribeCheckbox = (
  {
    customer = {},  // must have id, key
    url = null,
    label = "Subscribe",
  }) => {

  const [result, setResult] = useState({status: null, message: null});

  if (!url)
    url = LocalUrlMaker.jsonPath("/subscriptions")

  const handleClick = (e) => {
    if (!e.target.checked)
      return;

    const params = {
      // do not send order id, back-end wants the obfuscated id
      // order_id: orderId,
      key: customer.key,
      id: customer.id
    }

    const post = new PostWithParams({url, params});
    post.perform()
      .then(r => r.json())
      .then(response => {
        setResult(response);
      })
  }

  return (
    <div className="subscribe-widget">
      <div className="subscribe-checkbox">
        <label>
          <input type="checkbox" value="1" defaultChecked={false} onChange={handleClick}/>
          {' '}
          {label}
        </label>
      </div>

      <div className="subscribe-result">
        {((result.status === 'fail') || (result.status === 'error')) && <div className="failed">
          <span className="fa fa-exclamation-triangle"/>
          {' '}
          {result.message || "Sorry, this has failed."}
        </div>
        }

        {(result.status === 'success') && <div className="success">
          <span className="fa fa-check-circle"/>
          {' '}
          Subscribed - thank you!
        </div>
        }
      </div>
    </div>
  );
}

SubscribeCheckbox.propTypes = {
  customer: PropTypes.shape({
    id: PropTypes.number,
    key: PropTypes.string
  }).isRequired
}

export default SubscribeCheckbox;

