import SkuDisplay from "../../../Common/components/SkuDisplay";
import NapaSkuDisplay from "../../../ProductGroupPage/components/StandardItems/NapaSkuDisplay";
import _ from "lodash";
import React from "react";
import CartItemVehicle from "./CartItemVehicle";

export default function CartItemDetail({item}) {
  return <div className="product-details">
    <div className="item-name">{item.name}</div>

    <SkuDisplay sku={item.vendorsku} tagName="div"/>

    <NapaSkuDisplay sku={item.fullNapaSku} tagName="div"/>

    <CartItemVehicle item={item}/>

    <div className="options">
      {(item.cartItemOptions || [])
      .map((opt) => <CartItemOptionDisplay key={opt.name} {...opt} />)}
    </div>

    {(_.values(item.qualities))
    .map((qual, i) => <CartItemQuality key={i} {...qual} />)}
  </div>
}

// Qualities, aka attributes; "Color: Black", that sort of thing.
function CartItemQuality({label, values}) {
  return <div className="item-quality">
    <strong>{label}: </strong>
    <strong>{values}</strong>
  </div>
}

function CartItemOptionDisplay({displayName, displayValue, regularUnitPrice}) {
  let dollars = regularUnitPrice ? new Money(regularUnitPrice).toString() : null;

  return <div className="item-option">
    <span>{displayName}: </span>
    <span>{displayValue} </span>
    {dollars && <span> {dollars.toString()} </span>}
  </div>
}
