import React from 'react';
import LocalUrlMaker from "../../../lib/LocalUrlMaker";

/**
 * Show "Installation Instructions" with an icon, only if such instructions exist.
 *
 * Example:
 *    https://marge.aagarage.net/accessories/reese-receiver-hitch
 *
 * @param path
 * @param TagName
 * @param caption
 * @param icon
 * @returns {JSX.Element|null}
 * @constructor
 */
export default function ProductInstructions({path, tagName: TagName = 'div', caption = "Installation Instructions", icon = 'far fa-file-pdf'}) {
  if (!path)
    return null;

  let url = LocalUrlMaker.prefixedPath(path);

  return <TagName className="product-instructions">
    {icon && <i className={icon}/>}
    {' '}
    <a target="_blank" href={url}>{caption}</a>
  </TagName>
}
