import $ from 'jquery';

// Generic Form - used as base class by CreateReviewForm

export default class GenericForm {
  constructor($form) {
    this.$form = $form;
    if (!(this.$form && this.$form[0])) {
      return;
    }
  }

  formContents() {
    return this.$form.serializeObject();
  }

  errors() {
    console.log("Using default GenericForm.errors(), no validation");
    return {};
  }

  valid() {
    return _.size(this.errors()) === 0;
  }

  post(args = {}) {
    args = _.extend({
      method: 'POST',
      url: this.url,
      success: (data, status, xhr) => this.onSuccess(data, status, xhr),
      fail: (xhr, status, err) => this.onFailure(xhr, status, err),
    }, args);

    args.data || (args.data = this.formContents());

    if (!args.url) {
      alert("url is blank");
      return false;
    }
    return $.ajax(args);
  }

  onSuccess(response, status, xhr) {
    return window.response = response;
  }

  onFailure(xhr, status, err) {
    return alert("error submitting form: " + status + ": " + err);
  }

  displayErrors() {
    let errs, message;
    errs = this.errors();
    if (_.size(errs) > 0) {
      message = _(errs).values().join("\n \n");
      alert(message);
      return true;
    }
    return false;
  }
}
