import React from 'react';
import AgentAssistDisplay from './AgentAssistDisplay';
import AgentCredit from "./AgentCredit";
import LocalUrlMaker from "../../../lib/LocalUrlMaker";
import MultiCartHeadline from "./MultiCartHeadline";

// 2022-06 ported to Redux, modernised
export default function ShoppingCartTop(props) {
  let {shoppingCart, coreCart} = props;

  let showCore = (coreCart && (+coreCart.itemCount > 0));

  return <div className="row">
    <AgentCredit {...props} />

    <OrderExistsWarning shoppingCart={shoppingCart}/>
    <AgentAssistDisplay shoppingCart={shoppingCart}/>
    {showCore &&
      <MultiCartHeadline shoppingCart={shoppingCart} coreCart={coreCart}/>}
  </div>

}

/**
 *
 * 2024 possibly unused, as Rails has its own version,
 * which means this won't be reached.
 *
 cart_engine/app/views/cart_engine/shopping_carts/_existing_order.html.haml
 */

function OrderExistsWarning({shoppingCart}) {
  const {order} = shoppingCart;

  if (!order || !order.id)
    return null;

  let url = LocalUrlMaker.prefixedPath("/cart/forget");

  return <section className="px-4 py-4">
    <h1> Order Received </h1>

    <p>You've already placed an order with shopping cart:</p>

    <h2 className="text-center text-3xl">Order #{order.id}</h2>

    <p>Please check your email for your receipt and order status URL,
      or contact our customer service agents for further assistance.
    </p>

    <p>If you'd like to shop for something else:</p>

    <p className="text-center">
      <a className="btn" href={url}>Start Over with a New Cart</a>
    </p>
  </section>
}
