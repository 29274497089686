import React from 'react';

/**
 * 2024-OK An icon that appears at left inside each VehicleChooserLevel.
 *
 */
export default function ChooserLevelIcon({value}) {
  const icon = value ? 'fa fa-check-circle' : 'fa fa-arrow-right';

  return (
    <span className="fa-wrapper"><span className={icon}/></span>
  );
}

