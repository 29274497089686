import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import clsx from "clsx";

export default function RenderButton({classNames, ...props}) {
  function handleClick(evt) {
    alert("not yet supported")
  }

  return <button className={clsx(classNames, 'bg-gray-300')}
    onClick={handleClick}>
    <span className="fa fa-pencil-paintbrush"/>
    {' '}
    Render
  </button>
}
