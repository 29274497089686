import Money from '../lib/Money';
import {isNola} from "../lib/EmbeddedConfig";

const isDev = (process.env.NODE_ENV == 'development');

/**
 * sendAdobeEvent - the main entry point for most operations.
 *
 * Push an event onto AAData, then use the createEvent api to dispatch it.
 *
 * @param event
 * @param aaData
 * @returns {*}
 */
export function sendAdobeEvent(event, {aaData} = {}) {
  if (! isNola())
  {
    console.warn("sendAdobeEvent suppressed");
    return null;
  }

  // add to AAData.event
  pushAdobeEvent(event, {aaData});

  let eventName = event?.eventInfo?.eventName;
  if (eventName) {
    sendCustomEvent(eventName);
  } else {
    console.error("adobeAnalytics: eventName not found", event)
  }

  // if (isDev) {
  //   //alert("Sent event to Adobe: \n\n" + JSON.stringify(event, null, 2))
  //
  // }
  //
  console.log("Sent event to Adobe:", event);

  // must return event
  return event;
}

/**
 * Push an event onto AAData.event
 *
 * @param event
 * @param aaData
 * @returns {*}
 */
export function pushAdobeEvent(event, {aaData} = {}) {
  aaData = populateStandardAAData(aaData);
  if (aaData) {
    aaData.event ||= [];
    aaData.event.push(event);
  }
  return aaData;
}

/**
 * Use createEvent/dispatchEvent to send it
 *
 * @param eventName
 */
function sendCustomEvent(eventName) {
  let {document, window} = globalThis;

  try {
    if (isDev) console.log("SendCustomEvent: " + eventName);

    if (document.createEvent && document.body.dispatchEvent) {
      let event = document.createEvent("Event");
      event.initEvent(eventName, true, true);
      document.body.dispatchEvent(event);
    } else {
      if (window.CustomEvent && document.body.dispatchEvent) {
        let event = new CustomEvent(eventName, {
          bubbles: true,
          cancelable: true
        });

        document.body.dispatchEvent(event);
      }
    }
  } catch (err) {
    console.error("AdobeAnalytics sendCustomEvent: " + err);
  }
}

/**
 * Remove anything from AAData.event array that matches
 * a finder function.
 *
 * @param finder
 * @param aaData
 * @returns {*}
 */
export function removeAdobeEvents(finder, {aaData} = {}) {
  aaData ||= globalThis.AAData;
  if (aaData) {
    aaData.event = _.reject(aaData.event || [], finder);
  }
  return aaData;
}

export function populateStandardAAData(aaData) {
  aaData ||= globalThis.AAData;

  if (!aaData) {
    console.error("populateStandardAAData: no AAData!");
    return null;
  }

  aaData.section ||= 'AAG';
  aaData.authenticationStatus ||= 'registered not authenticated';
  aaData.searchPlatform ||= null;
  aaData.ecommerceChannel ||= 'Buy Online';

  return aaData;
}



