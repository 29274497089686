import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import CartCouponTool from '../below/CartCouponTool';
import CartItemsHead from './CartItemsHead';
import CartItemsBody from './CartItemsBody';
import TaxExemptWidget from '../below/TaxExemptWidget';
import AgentBelowCartToolbar from "../agents/AgentBelowCartToolbar";

export default function ShoppingCartContents(props) {
  return <div id="cart-items-area">
    <div id="cart-contents">
      <div id="cart-items-table">
        <CartItemsHead/>
        <CartItemsBody {...props} />
      </div>
      <div id="after-cart-table">
        <CartCouponTool {...props} />
        <CartRefresh {...props} />

        <div className="clear-me">
          {props.currentAgent && <AgentBelowCartToolbar {...props} /> }
        </div>

      </div>
    </div>
  </div>

}

function CartRefresh({callbacks}) {

  const onClick = (evt) => {
    if (evt.preventDefault()) evt.preventDefault()

    window.scrollTo(0, 0);

    callbacks.loadCart();
  }

  return <div id="cart-refresh">
    <a className="click-me" onClick={onClick}>
      <span className="fa fa-sync"/>
      {' Refresh'}
    </a>
  </div>
}
