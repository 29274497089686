import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

import {applyEdits, extractErrorMessage, normalizeCheckout, stringifyError} from "./checkoutSliceHelpers";
import produce from "immer";
import FetchCheckoutRequest from "./FetchCheckoutRequest";
import {camelizeKeys} from "../Helpers/transformObject";
import scrapyardSlice from "../scrapyardSlice";

/**
 * This is kind of shitty but it's my first
 * ReduxToolkit use. Sorry.
 *
 */

function buildCheckoutRequest(payload) {
  let {edits} = payload;

  // FIXME: remove this.
  if (payload.callback) {
    console.dir(payload.callback);
    throw new Error("FIXME: callback detected in fetchCheckout");
  }

  // apply edits if there are any, yielding a new checkout object
  // from payload.checkout
  let checkout = produce(payload.checkout,
    (draft) => applyEdits(draft, edits));

  let request = new FetchCheckoutRequest({
    checkout: checkout,
    cartCode: payload.cartCode, // may be null
    route: payload.route  // may be null
  });

  return request;
}

/**
 * /**
 * fetchCheckout thunk. Invoked when the checkout page first loads,
 * this will fetch the checkout from the back end via GET.
 *
 * Changes state only after receiving.
 *
 * @param cartCode

 * @type {AsyncThunk<Promise<*>, {readonly cartCode?: *}, {}>}
 */
export const fetchCheckout = createAsyncThunk('checkout/fetchCheckout',
  async (payload, thunkAPI) => {

    const {dispatch} = thunkAPI;
    let request = buildCheckoutRequest(payload);

    return await request.call()
      .then((data) => checkoutReceived({data, dispatch}))
      .catch((error) => {
        dispatch(checkoutSlice.actions.setError({
          error: stringifyError(error, "Unknown error receiving update from server.")
        }))

        // if (globalThis.onerror) {
        //   console.log("invoking error service");
        //
        //   globalThis.onerror(
        //     error.getMessage(),
        //     'checkoutSlice.js',
        //     0, 0, error
        //     )
        // }
      });
  });

// Update checkout - send something to the back end, quietly, without
// a throbber. IGNORE the result; do not update the in-memory checkout.
export const updateCheckout = createAsyncThunk('checkout/updateCheckout',
  async (payload, thunkAPI) => {
    let request = buildCheckoutRequest(payload);

    return await request.call()
      .then((data) => { console.log("Checkout updated.") })
  });

/**
 * Send checkout to redux after loading from back end.
 *
 * @param data
 * @param dispatch
 * @param callback
 */
function checkoutReceived({data, dispatch, callback}) {
  console.log("checkout received", data);

  if ((data.status == 'error') || data.error) {
    let error = data.error || data.message;
    console.error("checkoutReceived: have error: " + error);

    dispatch(scrapyardSlice.actions.setError({error: error}));
    throw new Error(error);
  }

  const {checkout} = data;
  if (!_.isEmpty(checkout)) {
    data.checkout = normalizeCheckout(checkout);
  }

  // dispatch receive checkout action
  dispatch(checkoutSlice.actions.receive(data));

  if (callback) {
    console.log("invoking callback function after receiving updated checkout")
    callback({data, dispatch});
  }
}

// 2023-06 initialState.braintree has moved to scrapyardSlice
const initialState = {
  // use dummy checkout so we can render before actual checkout loads
  checkout: {
    dummy: true,  // IMPORTANT - components look for this to determine real vs fake checkout
    shippingAddress: {}, billingAddress: {}, sameAddress: true, totals: {}
  },

  error: null,  // back end error, if any, is put here
  status: 'pending'
};

/**
 * THE SLICE.
 *
 * @type {Slice}
 */
export const checkoutSlice = createSlice({
  name: 'checkoutSlice',
  initialState,
  reducers: {
    // "set" - overwrite state with everything in payload.
    set: (state, action) => {
      Object.assign(state, action.payload);
    },

    setError: (state, action) => {
      console.log("checkoutSlice reducer: setError", action);

      state.error = extractErrorMessage(action);

      console.error("checkoutSlice.setError: " + state.error);
    },

    receive: (state, action) => {
      // normalizeCheckout has already been called to camelcase
      // the top level and selected internals
      Object.assign(state, action.payload);
    },

    edit: (state, action) => {
      state.checkout = applyEdits(state.checkout, action.payload);
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchCheckout.pending,
      (state, action) => {
        state.status = 'pending';
        delete state.error;
      });

    builder.addCase(fetchCheckout.rejected,
      (state, action) => {
        state.status = 'error';
        state.error = extractErrorMessage(action);
      });

    builder.addCase(fetchCheckout.fulfilled,
      (state, action) => {
        const {payload = {}} = action;
        const {checkout} = payload;

        state.status = 'success';
        delete state.error;

        if (checkout) {
          state.checkout = normalizeCheckout(checkout);
        }
      });
  }
});

export default checkoutSlice.reducer;

export const {
  receive: receiveCheckout,
  edit: editCheckout,
  set: setCheckout,
} = checkoutSlice.actions;
