import React, {Component} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import ClusterLevels from "../../../logic/ClusterLevels";
import ItemClusterSelectWidget from "./ItemClusterSelectWidget";
import SelectionHandler from "../../../logic/SelectonHandler";
import LevelList from "../../../../models/LevelList";

/**
 * ItemClusterLevel - one of the Select widgets within an
 * Item Cluster.
 *
 * props: ["name","cluster","clusterState"]
 */
export default class ItemClusterLevel extends React.Component {

  // get our own ID - cluster id is like front_row_neosupreme_seat_covers,
  // then 'name' is this selector's name like 'color'
  // Called when a user frobs the selector widget;
  // update selections in redux.
  onSelect = (evt = {}) => {
    const {name, cluster, clusterState, itemCache, updateState} = this.props;

    let {target={}} = (evt || {});
    let value = target.value || "";

    //console.error(`CLUSTER LEVEL ${name} onSelect() - value ${value}`);

    // SelectionHandler will create a new Redux state based
    // on the user's action. The handler memoizes various things
    // but only lives for the duration of this call.
    let handler = new SelectionHandler({
      name, cluster, clusterState, itemCache, value
    });

    let newState = handler.getUpdatedState();

    updateState({clusterState: newState}, () => {
      let { focusOn } = newState;
      if (focusOn) {
        let focusId = this.getNextHelper().getId();

        let widget = document.getElementById(focusId + '-select');
        if (widget && widget.focus)
          widget.focus();
      }
    });
  }

  /**
   * @returns {ClusterLevels}
   */
  getHelper() {
    let {name, cluster, clusterState} = this.props;

    return new ClusterLevels({
      level: name, state: clusterState, cluster: cluster
    });
  }

  getNextHelper() {
    let {name, cluster, clusterState} = this.props;

    let nextName = new LevelList(cluster.levels).levelAfter(name);

    return new ClusterLevels({
      level: nextName, state: clusterState, cluster: cluster
    });
  }

  getLevelOptions() {
    let {name, clusterState} = this.props;
    let {levelOptions} = (clusterState || {});

    return levelOptions[name] || {};
  }

  render() {
    let {name, value = "", enabled, cluster} = this.props;

    if (!enabled) {
      // FIXME: should not happen.
      return <div className="red">{name} is not enabled</div>
    }

    // do not show application_id widget if there is just a single choice.
    if (this.getLevelOptions().hidden) {
      return null;
      // return <div className="red">{name} HIDDEN! </div>
    }

    let helper = this.getHelper();
    let choices = helper.getChoices();
    let id = helper.getId();

    // ID is like cluster-first_row_floor_liners-colour, the actual select widgets
    // append -select to this, which is used to locate the selector & give it focus
    return <div id={id} className={helper.getClasses()}>
      <span className="fa-wrapper">
        <span className={helper.getIcon()} />
      </span>

      <div>
        <ItemClusterSelectWidget
          id={id + "-select"}
          name={name}
          value={value}
          choices={choices}
          onSelect={this.onSelect}
          cluster={cluster}
          enabled={enabled}
        />
      </div>

    </div>
  }


}

ItemClusterLevel.propTypes = {
  position: PropTypes.number,
  updateState: PropTypes.func.isRequired
}
