import React from 'react';

// 2023 modernized
export default function AgentAssistDisplay({shoppingCart, ...props}) {
  if (!shoppingCart) return null;

  const {agentName, agentPriceExpires} = shoppingCart;

  if (!agentName)
    return null;

  return <div id="agent-assist">
    {
      agentPriceExpires ?
        <AgentAssistWithExpires agentName={agentName} agentPriceExpires={agentPriceExpires}/> :
        <AgentAssistNoExpires agentName={agentName}/>
    }
  </div>
};

function AgentAssistWithExpires({agentName, agentPriceExpires}) {
  return (
    <div className="agent-price-expires">
      <span className="fa fa-tags"/>
      This private promotional price
      offered by <strong> {agentName} </strong>
      is valid for the next {' '}
      <strong> {agentPriceExpires}.</strong>
    </div>);
}

function AgentAssistNoExpires({agentName}) {
  if (!agentName)
    return null;

  return (
    <div className="agent-name">
      You are being assisted by {' '}
      <span className="fa fa-user"/> {' '}
      <strong>{agentName}</strong>
    </div>);
}
