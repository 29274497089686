import React from 'react';
import PropTypes from 'prop-types';
import ProductInstructions from "../../StandardItems/ProductInstructions";
import SkuDisplay from "../../../../Common/components/SkuDisplay";
import NapaSkuDisplay from "../../StandardItems/NapaSkuDisplay";

import EmbeddedConfig from "../../../../lib/EmbeddedConfig";
import {camelizeKeysDeep} from "../../../../ReduxStore/Helpers/transformObject";

const isDev = (process.env.NODE_ENV == 'development');

/**
 * Camelizes its item prop.
 *
 * @returns {JSX.Element|null}
 * @constructor
 */
export default function SelectedItemDetail({item}) {
  item = camelizeKeysDeep(item);

  const {product} = item;
  const conf = EmbeddedConfig.instance();
  const showNapaSku = conf.fetch('name') == 'napaonline';

  if (!product) {
    console.error("selected item detail: no item.product", item);
    return null;
  }

  let status = product.buyableStatus;
  let oos = (status === 'out_of_stock') || (status === 'discontinued');

  return <div className="selected-item-detail">
    <ul>
      {oos && <li className="out-of-stock">
        {product.vendorsku} is out of stock.
        Please select another option.
      </li>}

      {getBullets(item).map((txt, i) =>
        <li className="item-bullet" key={i}>{txt}</li>)}

      {getFootnotes(item).map((f, i) =>
        <li className="footnote" key={i}>{f}</li>)}

      <SkuDisplay sku={product.vendorsku} tagName="li"/>

      {showNapaSku && <NapaSkuDisplay product={product} tagName="li"/>}
    </ul>

    {isDev && <DebugApp item={item}/>}
    <ProductInstructions path={product.instructionsPath} tagName="div"/>
  </div>
}

SelectedItemDetail.propTypes = {
  item: PropTypes.object.isRequired   // AppliedItem with keys {application, product}
}

function getFootnotes(item) {
  const {application = {}} = item;

  const {footnote} = application;

  // footnotes have @@ as internal delimiter.
  // example: NF Neosupreme Seat Covers, 2019 Audi A3, Sedan, Low Back, Grey/Black
  return footnote ? ("" + footnote).split(/@@/) : [];
}

function getBullets(item) {
  const {product} = item;

  if (!product || !product.bullets)
    return [];

  return ("" + product.bullets).split(/@@/);
}

function DebugApp({item}) {
  const {product, application = {}} = item;
  const {vehicle = {}} = application;

  return <li className="debug">
    <div>{product.fullPath && <a href={product.fullPath}>{product.vendorsku}</a>}</div>
    <div>
      fits {vehicle.year} {vehicle.vehicleMakeName} {vehicle.vehicleModelName},
      id={vehicle.id}
    </div>
  </li>
}
