import React from 'react';
import GoogleAddToCartReporter from "./GoogleAddToCartReporter";
import FacebookAddToCartReporter from "./FacebookAddToCartReporter";
import AdobeAddToCartReporter from "./AdobeAddToCartReporter";
import {isNola} from "../../lib/EmbeddedConfig";
import CatchErrors from "../../Utility/CatchErrors";

export default function AddToCartReporter(props) {
  return (
    <CatchErrors>
      <GoogleAddToCartReporter {...props} />
      <FacebookAddToCartReporter {...props} />
      {isNola() && <AdobeAddToCartReporter {...props} />}
    </CatchErrors>
  );
}
