import React from 'react';

const CHANNEL_LIST = [
  [6, "AutoAccessoriesGarage"],
  [20, "NAPAOnline"],
  [5, "SnowplowsDirect"],
  [44, "Wholesale"]
]

export default function ChannelPicker({...props}) {
  const {shoppingCart = {}, callbacks} = props;
  const {channelId = 0} = shoppingCart;

  const handleChange = (e) => {
    let value = e.target.value;
    if (!value)
      return;

    callbacks.updateCart({channelId: value});
  }

  return (
    <div style={{padding: "0.25rem 1rem 0", color: '#000000'}}>
      <select name="channel_id" value={channelId} onChange={handleChange}>
        <option value="0">-- sales channel --</option>
        {CHANNEL_LIST.map(channel =>
          <option value={channel[0]}>{channel[1]}</option>)}
      </select>
    </div>
  );
}
