import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import EditImageTitleBar from "./EditImageTitleBar";
import ImageDisplay from "./ImageDisplay";
import EditImageForm from "./EditImageForm";
import EditImageButtons from "./EditImageButtons";

export default function EditImage({image , groupImages, callbacks, ...props}) {
  return (
    <div style={{minWidth: '800px'}} className="relative">
      <EditImageTitleBar image={image} callbacks={callbacks}/>
      <ImageDisplay  image={image} callbacks={callbacks}/>
      <EditImageForm image={image} callbacks={callbacks}/>
      <EditImageButtons image={image} callbacks={callbacks}/>

      <pre className="debug text-sm">{JSON.stringify(image, null, 2)}</pre>
    </div>
  );
}
