
// NO CART.
import React from "react";
import $ from "jquery";

export default class AgentStatusCartMissing extends React.Component  {
  constructor(p,c) {
    super(p,c);
    this.submit = this.submit.bind(this);
    this.keypress = this.keypress.bind(this);
  }

  submit() {
    let { phone_call } = this.props;

    let cart_code = this.getField().val();
    if (!cart_code)
      return;

    let url = "/cart_engine_cart_picker/go?cart_code=" + cart_code + "&";
    url += "phone_call_id=" + phone_call.id;

    window.location = url;
  }

  keypress(evt) {
    if (!evt) return;
    if (evt.charCode == 13) {
      this.submit();
    }
  }

  getField() {
    return $('#phone_call-cart_code');
  }

  componentDidMount() {
    this.getField().focus();
  }

  render() {
    return <div className="agent-status-cart cart-missing">
      <input id="phone_call-cart_code" name="phone_call[cart_code]"
             placeholder="ConnectID"
             onBlur={this.submit}
             onKeyPress={this.keypress}
      />

      <button id="phone_call-update-button" type="button"
              onClick={this.submit}>Go</button>
    </div>
  }
}
