import React, {Component} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';


import LloydLogoChooser from "./LloydLogoChooser";

/**
 * If you'd like to customise your mats with a logo,
 * select the checkboxes above to reveal options.
 */
export default class CustomiserTabs extends React.Component {

  static propTypes = {
    cart: PropTypes.object,
    lloyd: PropTypes.object,
    callbacks: PropTypes.object
  }

  selectTab = (index, lastIndex, evt) => {
    let { callbacks } = this.props;

    let tabs = this.tabConfig();
    let isPlain = ( (+index + 1) == _.size(tabs) );  // last tab click'd?

    if (isPlain) {
      callbacks.updateCart((cart) => cart.clearCustomisations());
    }

    // if (!this.tabCount || this.tabCount == last + 1)
    // {
    //
    // } else {
    //   callbacks.updateCart((cart) => { cart.clearCustomisations(); cart.setOption('plain', 1); } );
    // }
  }

  tabConfig() {
    let { lloyd } = this.props;
    let { options } = lloyd;

    // ["border","color","logo","logoThread","letteringThread","typestyle"]

    let tabs = {plain: true}
    if (options.logo)
      tabs.logo = true;

    // if (options.letteringThread) tabs.lettering=true;

    return tabs;
  }

  render() {
    let { lloyd } = this.props;

    // sanity check on weird import
    if (!Tab) {
      console.error("CustomiserTabs: Tab library not loaded")
      return null;
    }

    let tabs = this.tabConfig();

    return <Tabs onSelect={this.selectTab}>
      <TabList>
        { tabs.logo ? <Tab key="logo">Logo <em className="tabs-or">or</em></Tab> : 'FAIL'}
        { tabs.lettering ? <Tab key="message">Custom Message <em className="tabs-or">or</em></Tab> : null}
        <Tab key="plain">Plain Mats</Tab>
      </TabList>

      { tabs.logo && <TabPanel key="logo">
          <div id="lloyd-tab-logo-inner">
            <LloydLogoChooser option={lloyd.getOption('logo')} {...this.props} />
          </div>
        </TabPanel>
      }

      <TabPanel key="plain" id="plain-mats-tab">
        <div id="lloyd-tab-plain-inner">
          <p>Plain mats selected with no customizations.</p>
        </div>
      </TabPanel>

    </Tabs>
  }

}

