import React, {Component} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import LloydLogoChooser from './LloydLogoChooser';
import SelectMat from './SelectMat';
import CustomiserTabs from './CustomiserTabs';

export default class LloydCustomiser extends Component {
  render() {
    let {cart, lloyd, callbacks} = this.props;
    let {options} = lloyd;

    // Logo/Lettering only for some vehicles
    let logoOption = options.getOption('logo');
    let letteringOption = false; // options.getOption('lettering_thread');

    let cartItems = cart.items.toArray();
    // customiseRows = 0, 1, 2
    // let customising = cartItems.filter((item) => item.options.customise > 0);

    let customising = !!_.some(cartItems, (i) => i.rowsToCustomise());

    return <section id="lloyd-customiser">
      <h2>Step 3: Customize Mats (Optional)</h2>

      <div id="lloyd-mats-to-customise">
        <h3>Apply customization to these mats
          <em>(each row priced separately):</em>
        </h3>

        {cartItems.map((item, i) => <SelectMat
          key={item.extended_part_number || i}
          cartItem={item}
          callbacks={callbacks}/>)}
      </div>

      {customising && <CustomiserTabs {...this.props} />}
    </section>
  }
}
