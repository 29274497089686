//
// PhoneConnector - 2017-12 - invoked when user initiates a phone call
// using a tel: url, this connects to /cart_engine/dial_attempts.json
// (a CREATE action)
//
//

// WARNING - legacy app has a copy. may be slightly different.

export default function PhoneConnector() {

}

PhoneConnector.prototype.startup = function(page) {
    this.bindLinks();
    return this;
}

PhoneConnector.prototype.bindLinks = function(selector) {
    selector = selector || $('a[href^=tel]');
    var me = this;

    selector.on('click', function() {
        var href = $(this).attr('href');
        me.startCall({ call_in_number: href})
        return true;  // also do default! that's really important
    });
};
PhoneConnector.prototype.startCall = function(params) {
    params = this.fixParams(params);

    var me = this;

    $.ajax({
        method: 'post',
        url: "/cart_engine/dial_attempts.json",
        data: params,
        success: function(response) {
            if (response) {
                if (response.cart_code)
                {
                    me.cart_code = response.cart_code;
                }
            }

        }
    });
};


PhoneConnector.prototype.fixParams = function(params) {
    params = params || {};
    params.phone_call = true;

    // cachebusting - put some garbage in the url.
    var d = new Date();
    params.now = "" + d.getTime()  + "." + d.getMilliseconds();
    params.r = Math.floor(Math.random() * 999999999);

    if (!params.url) {
        location = window.location || document.location;
        if (location)
            params.path = location.pathname || location.toString();
    }

    return params;
};

PhoneConnector.prototype.getCartCode = function () {
    if (this.cart_code)
        return this.cart_code;

    // don't have it? ask the cart connector.
    var cartConnector = window.cartConnector;
    var cart_code = cartConnector && cartConnector.cart_code;
    if (cart_code)
        return cart_code;

    console.error("WARNING: cannot find cart code for phone connector")

    return null;
};

