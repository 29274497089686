import React from 'react';

import CartItemAgentStockMessage from './CartItemAgentStockMessage';
import AgentDiscountIndicator from './AgentDiscountIndicator';

export default class CartItemAgentItemToolbar extends React.Component {

  render() {
    let {item, shoppingCart, currentAgent, callbacks, forecast} = this.props;

    // sanity check
    if (!currentAgent) return null;

    return <div className="agent-bg agent-item-info clearfix">
      <div className="hang-left"><span className="fa fa-lg fa-user-secret"/></div>
      <div className="max-pct-off">
        <span className="fa fa-tags"/>
        <em>Discount: </em>

        {
          (item.privateCeilingPercent > 0) || (item.publicCeilingPercent > 0) ?
            <span>
              <AgentDiscountIndicator type="public" limit={item.publicCeilingPercent}/>
              <AgentDiscountIndicator type="suggest" limit={item.suggestedDiscountPercent}/>
              <AgentDiscountIndicator type="max" limit={item.privateCeilingPercent}/>
            </span>
            :
            <span className="agent-discount discount-never">None</span>
        }
      </div>
      { forecast && <CartItemAgentStockMessage forecast={forecast}/> }
    </div>
  }
}

