
export const AFFIRM_MINIMUM = 25000;

export function affirmAvailableForTotal(totalCents) {
  if (!totalCents) return false;

  if (+totalCents < AFFIRM_MINIMUM)
    return false;

  return affirmLoaded();
}

export function affirmAvailableForCart(cart) {
  if (!cart) return false;

  const { effectiveTotal } = cart;
  return affirmAvailableForTotal(effectiveTotal);
}

export function affirmLoaded() {
  const { affirm } = globalThis;

  return !!(affirm && affirm.ui)
}

export function safeRefreshAffirm() {
  const { affirm } = globalThis;

  if (!affirm) return false;

  if (affirm.ui && affirm.ui.refresh) {
    affirm.ui.refresh()
  } else {
    console.error("affirm: no refresh function")
  }

  return true;
}
