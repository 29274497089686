import React, {useState} from 'react';

import {getNewItems, useNewItemListChange} from "../logic/cartHelpers";
import {sendAddToCartEvent} from "../../Analytics/adobeCartEvents";

const isDev = (process.env.NODE_ENV == 'development');

export default function AdobeAddToCartReporter({...props}) {

  const [debugInfo, setDebugInfo] = useState(null);

  // my first custom hook!
  useNewItemListChange(props => {
    const {shoppingCart} = props;

    const items = getNewItems(props);

    const event = sendAddToCartEvent({
      items, shoppingCart
    })

    setDebugInfo(event);
  }, props);

  // if (isDev) {
  //   return <pre className="debug">
  //     <b>AdobeAddToCartReporter:</b>
  //     {JSON.stringify(debugInfo, null, 2)}
  // </pre>
  // }

  return null;
}



