import React, {useState, useEffect, useRef, useCallback, useContext} from 'react';

import {calculateItemsPerSlide, calculatePageCount} from "./logic";
import {useWindowSize} from "../lib/hooks";
import CarouselDisplay from "./CarouselDisplay";
import CatchErrors from "../Utility/CatchErrors";

export default function CarouselContainer({config = {}, items = []}) {
  const [itemIndex, setItemIndex] = useState(0);

  const [paused, setPaused] = useState(false);

  const [timer, setTimer] = useState(null);

  const currentItem = items[itemIndex] || items[0];

  const millis = isNaN(config.seconds) ? 3500 :
    (1000 * (+config.seconds));

  const dims = useWindowSize();

  const itemsPerSlide = calculateItemsPerSlide(config, {width: dims[0]});

  // when 'paused' state changes, set/clear the window timer, and then update timer in state
  useEffect(() => {
    if (paused) {
      if (timer) {
        globalThis.clearInterval(timer);
        setTimer(null);
      }
    } else {
      if (!timer)
        setTimer(globalThis.setInterval(advance, millis));
    }
  }, [paused]);

  // handle click on a prev/nextbutton
  const advance = useCallback((count = null) => {
    setItemIndex(oldIndex => {
      count ||= itemsPerSlide;

      let n = (oldIndex + count) % items.length;
      return (n >= 0 ? n : items.length + n); // handle underflow
    });
  }, [itemsPerSlide]);

  const togglePause = () => setPaused(val => !val);

  const handleClick = (evt) => {
    evt?.stopPropagation();
    if (currentItem) {
      if (currentItem.link)
        document.location = currentItem.link;
    }
  }

  const callbacks = {
    togglePause, advance, setPaused, setItemIndex, handleClick};

  return <CatchErrors>
    <CarouselDisplay
      config={config} callbacks={callbacks}
      items={items} itemsPerSlide={itemsPerSlide}
      itemIndex={itemIndex}
    /></CatchErrors>
}
