import React, {Component} from 'react';
import PropTypes from 'prop-types';

/**
 * 2022-01 copy predefined text to clipboard
 *
 */
export default class TextToClipboard extends Component {
  static propTypes = {
    text: PropTypes.string.isRequired,
    icon: PropTypes.string,
    message: PropTypes.string,
    caption: PropTypes.string
  };

  static defaultProps = {
    icon: 'fa fad fa-copy'
  };

  state = {
    copied: false
  }

  onClick = (evt) => {
    evt.preventDefault();

    this.copyToClipboard(this.props.text);
  }

  copyToClipboard(text) {
    const {message} = this.props;

    const {navigator = {}} = globalThis;
    const {clipboard} = navigator;

    if (!clipboard || !clipboard.writeText) {
      alert("Unable to write to your system clipboard.\nPlease check permissions using the padlock icon next to the address at the top of your browser.")
      return false;
    }

    clipboard.writeText(text)
      .then((r) => {
        this.setState({copied: true }, () => {
          if (message)
            alert(message);
        });
      })
      .catch(err => alert("error: " + err))
  }

  render() {
    const {caption = null} = this.props;
    const {copied = false} = this.state;

    let icon = copied ? 'fa fa-check' : this.props.icon;

    let classes = ['text-to-clipboard',
      (copied ? 'text-to-clipboard-done' : '')]

    return (
      <a className={classes.join(' ')} onClick={this.onClick}>
        {caption && <span className="caption">{caption}</span>}
        {' '}
        <span className={icon}/>
      </a>
    );
  }
}

