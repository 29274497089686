import React, {useState} from 'react';
import _ from 'lodash';
import clsx from "clsx";
import CatchErrors from "../../Utility/CatchErrors";
import ZoidbergIndexTop from "./ZoidbergIndexTop";
import ZoidbergFlavourSection from "./ZoidbergFlavourSection";
import TabBar from "./TabBar";

export default function ZoidbergMainPage({
  callbacks, groupImages, groupId, ...props
}) {
  const styles = {
    minWidth: "900px",
    minHeight: "600px"
  }

  // sections - group, group_swatch, etc.
  const sections = _.groupBy(_.values(groupImages), img => img.flavour);

  const keys = _.sortBy(_.keys(sections));

  const [activeTab, setActiveTab] = useState(keys[0]);

  return <div className="relative" style={styles}>
    <CatchErrors>
      <ZoidbergIndexTop
        groupId={groupId}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        sections={sections} callbacks={callbacks}
      >
        <TabBar sections={sections} activeTab={activeTab} setActiveTab={setActiveTab} />
      </ZoidbergIndexTop>
    </CatchErrors>

    <CatchErrors>
      {activeTab && <ZoidbergFlavourSection
        flavour={activeTab}
        callbacks={callbacks}
        imageList={sections[activeTab]}
      />}
    </CatchErrors>
  </div>
}


