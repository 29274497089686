import React from 'react';

// Display text from forecast.shipDateRange, as preformatted HTML.
export default function ShippingForecastDisplay({forecast, ...props}) {
  if (!forecast)
    return null;

  // raw html in here
  let html = _.get(forecast, 'estimate.shipDateRange.text');

  return <div className="shipping-forecast"
    dangerouslySetInnerHTML={{__html: html || ''}}/>
}
