export default class CheckoutStateBuilder {
  constructor(props, state) {
    this.props = props;
    this.state = state;

    this.newState = {};
  }

  call() {
    // once set this is not changed.
    if (!this.state.paymentType) {
      this.newState.paymentType =
        this.paymentTypeFromParam() ||
        this.paymentTypeFromCheckout();
    }

    return this.newState;
  }

  initState() {
     let {startup = {}} = this.props;

    return {
      // Step: where were are in CheckoutSteps, the 1.2.3... widget.
      // Step 1 is actually in a different app (ShoppingCart) so we
      // start at
      step: +(startup.step || 2),
    }
  }

  paymentTypeFromParam() {
    const { location } = globalThis;
    if (!location) return;

    const params = new URLSearchParams(location.search);
    return params.get('pt');
  }

  paymentTypeFromCheckout() {
    let { checkout } = this.props;
    if (!checkout || !checkout.cartCode)
      return null;

    // FIXME.
    return 'credit_card';
  }

}
