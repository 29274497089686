import LloydCart from "../models/LloydCart";
import Vehicle from '../../../models/Vehicle';

/**
 * Create a LloydCart, which allows for selection of lloyd items.
 *
 * Used by LloydContainer.
 */
export default class LloydCartFactory {
  constructor({customerVehicle, vehicleChoices, groupProperties, lloydAttributes, ...props} = {}) {
    this.customerVehicle = customerVehicle;
    this.vehicleChoices = vehicleChoices;
    this.groupProperties = groupProperties;
    this.lloydAttributes = lloydAttributes;

    this.props = props;
  }

  // vehicle selector puts things in several different places.
  // Merge and camelcase.
  getVehicle() {
    const vc = this.vehicleChoices;

    let attrs = _.assign({}, this.customerVehicle);
    if (vc) {
      attrs.fitmentId = vc.fitment;
      attrs.lloydUnique = vc.lloyd_unique || vc.lloydUnique;
    }

    return new Vehicle(attrs);
  }

  createCart() {
    let cart = new LloydCart({
      groupProperties: this.groupProperties,
      attributeNames: _.keys(this.lloydAttributes),
      // vehicle_id: customerVehicle && customerVehicle.id,
      vehicle: this.getVehicle()
    });

    return cart;
  }
}
