import _ from 'lodash';
import $ from 'jquery';
import React from 'react';

/**
 * Provide names and values for HTML form fields, from one CartItem.
 */
export default class ItemFormAdapter {
  constructor(cartItem) {
    this.cartItem = cartItem;
    this.product = cartItem.product;
    this.vehicle = cartItem.vehicle;
  }

  // Get a field prefix matching what the Rails app expects,
  // cart_item123456789
  get prefix() {
    // clean part number - UM16_3010540 => 160003010540
    let pn = this.cartItem.extended_part_number
      .replace('_', '0').replace(/[A-Z]+/, '');

    return `cart_item${pn}`;
  }

  // Make rails-style field names from simple ones:
  // makeName(quantity) => cartItem7[quantity]
  makeName(name) {
    return `${this.prefix}[${name}]`
  }

  toRailsParams() {
    let contents = this.contents();
    return _.mapKeys(contents, (v, k) => this.makeName(k))
  }

  // Export all our essential fields, with simple names
  // (use makeName() to transform keys)
  contents() {
    return _.assign({},
      this.itemFields(),
      this.vehicleFields(),
      this.optionFields());
  }

  /**
   * Core attributes of the Cart Item, as required by
   * our standard Add-to-Cart
   *
   * @returns {Object}
   */
  itemFields() {
    const { cartItem, product } = this;

    return {
      quantity: 1,
      product_id: product.id,
      extended_part_number: cartItem.extended_part_number
      // FIXME: lloyd product unique?
    }
  }

  /**
   * Vehicle attributes, as used by AddToCart
   *
   * @returns {Object}
   */
  vehicleFields() {
    let { vehicle } = this.cartItem;
    if (!vehicle) {
      console.error("no vehicle for cart item");
    } else {
      return {
        vehicle_id: vehicle.id,
        vehicle_fitment_id: (vehicle.fitmentId || vehicle.fitment),
        lloyd_unique: vehicle.lloydUnique
      }
    }
  }

  optionFields() {
    let {options} = this.cartItem;

    let contents = options.toHash();

    let result = {};
    // some options are Object, some are String or Int
    // if an object it'll have an ID
    for (let name in contents) {
      let value = contents[name];
      result["opt_" + name] = (value && value.id) ? value.id : value;
    }
    return result;
  }
}
