import React from 'react';

export default function ZoidbergIndexTop({groupId, callbacks, children, sections, ...props}) {

  const handleImport = evt => {
    alert(`Scan for new uploads ing ${groupId} - feature not yet available`);
  }

  return <div>
    <b className="text-xl text-black">Group {groupId}:</b>

    {children}

    <button onClick={handleImport}
      className="px-2 py-1 font-bold bg-brand-orange text-white">Import...</button>
  </div>

}


