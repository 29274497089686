import _ from 'lodash';
import {eachWithObject} from "../../../lib/enumerable";
import {gridParamName} from "../logic/gridParams";

/**
 * Used in filterChange watcher, this builds a params hash from
 * the user's current selections, using filterMap.paramName for each.
 *
 * Returns a hash like: {
 *   fb: "3,k,s"
 *   fc: "",  // empty string if nothing selected
 *      // - this is important, it's an indication to clear the parameter
 * }
 * @param filterMap
 * @param allFilterSelections
 * @returns {*}
 */
export function buildParamsFromFilters({
  gridId, filterState={}
}) {
  const {filterMap, allFilterSelections} = filterState;
  const filterNames = _.keys(filterMap);

  return eachWithObject(filterNames, (filterName, params) => {
    const filter = filterMap[filterName];
    const paramName = gridParamName(gridId, filter?.paramName)

    if (paramName) {
      params[paramName] = buildFilterParamValue({
        selections: allFilterSelections[filterName]
      })
    }
  })

}

function buildFilterParamValue({selections}) {
  const value = _(selections).keys()
    .map(id => encodeParamValue(id))
    .sortBy(x => x)
    .join(',');

  return (value === '') ? null : value;
}


export function encodeParamValue(val) {
  return (+val).toString(36);
}

/**
 * Decode the full value string like "2h,8s,s"
 * @param str
 */
export function decodeFilterParamValue(str) {
  // because ""+null = "null", stupid js
  if (!str || !str.split) return [];

  return str.split(',').map(val => parseInt(val, 36))
}


/*
 * The back end sends a paramName member as part of the
 * filterMap, like: { brands => {paramName: 'fb'}
 * Add the gridId suffix.
 *
 * @param gridId
 * @param filterMap
 * @returns {{}} like {brands: "fb", colors: "f49"},
 *    or {brands: "fb:2"} for secondary grids
 */
export function extractFilterParamNames(gridId, filterMap = {}) {
  if (!gridId || !filterMap) return {};

  return _.mapValues(filterMap, (filter, filterName) => {
    return gridParamName(gridId, filter.paramName);
  })
}
