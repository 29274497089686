import {buildItemListEvent, groupToGoogleItem} from "./googleAnalyticsItems";
import {safeGtag} from "./googleAnalyticsFour";
import {useEffect, useState} from "react";
import {camelizeKeysDeep} from "../ReduxStore/Helpers/transformObject";
import {sum, isEmpty, mapValues} from 'lodash';

/**
 * React Hook.
 *
 * @example useReportCartToGoogleOnce({shoppingCart});
 *
 * @param eventType
 * @param shoppingCart
 * @param cartItems
 */
export function useReportCartToGoogleOnce({eventType = "view_cart", shoppingCart, cartItems, params = {}}) {
  const [reported, setReported] = useState(false);

  useEffect(() => {
    if (reported || isEmpty(shoppingCart))
      return;

    sendCartEvent({eventType: 'view_cart', shoppingCart, params});
    setReported(true);
  }, [shoppingCart, reported])
}

export function sendCartEvent({eventType = "view_cart", shoppingCart, cartItems, params = {}}) {
  let event = buildCartEvent({eventType, shoppingCart, cartItems});
  if (isEmpty(event)) {
    console.error("sendCartEvent failed");
  } else {
    console.log("SENDING: ", event);
    safeGtag(...event);
  }

  return event;
}

export function buildCartEvent(
  {
    eventType = "view_cart",
    shoppingCart,
    cartItems,
    params = {}
  }) {

  if (isEmpty(shoppingCart))
    return null;

  shoppingCart = camelizeKeysDeep(shoppingCart);

  cartItems ||= shoppingCart?.cartItems || [];

  let googleItems = cartItems.map(cartItem =>
    cartItemToGoogleItem({cartItem, shoppingCart}))

  let total = sum(googleItems.map( item => item.price ? +item.price : 0)) || 0;

  return buildItemListEvent({
    eventType: eventType,
    items: googleItems,
    params: Object.assign({
      currency: 'USD',
      value: Money.fromDollars(total).toDollars(),
      coupon: shoppingCart.couponCode || ''
    }, params)
  });
}

export function cartItemToGoogleItem(
  {cartItem, shoppingCart}) {

  let productGroup = findGroupForCartItem({cartItem, shoppingCart});
  if (!productGroup)
    return null;

  cartItem = camelizeKeysDeep(cartItem);
  let {effectiveUnitPrice, regularUnitPrice, vehicle={}} = cartItem;


  let out = groupToGoogleItem(productGroup);

  let discountCents = (regularUnitPrice && effectiveUnitPrice) ? regularUnitPrice - effectiveUnitPrice : 0;
  let discountUSD = (discountCents > 0) ?  +(new Money(discountCents||0).toDollars()) : 0;

  Object.assign(out, {
    quantity: cartItem.quantity,
    item_variant: cartItem.googleSku || `${productGroup.id}-${cartItem.vendorsku}`,
    item_variant_name: cartItem.name,
    price: +(new Money(effectiveUnitPrice || regularUnitPrice).toDollars()),
    discount: +discountUSD,
    coupon: shoppingCart?.couponCode,
    vehicle_make: vehicle.makeName,
    vehicle_model: vehicle.vehicleModelName,
    vehicle_year: vehicle.year,
  })


  return mapValues(out, v => (v ===null) ? '' : v);
}

export function findGroupForCartItem({cartItem, productGroupId, shoppingCart, groupCollection}) {
  groupCollection ||= shoppingCart?.groupCollection;
  productGroupId ||= +(cartItem?.productGroupId || 0);

  if (!groupCollection || !productGroupId) {
    console.error("findGroupForCartItem: group collection missing");
    return null;
  }

  return (groupCollection[+productGroupId] ||
    groupCollection["" + productGroupId])
}
