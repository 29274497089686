import _ from 'lodash';

/*
 * Build a full URL given an arbitrary origin URL,
 * which should normally be https://something.
 *
 * There will always be a slash put between them.
 * If that's not what you want, then use another method.
 *
 * This is used by LocalUrlMaker to find the origin of the current
 * server, and build urls from it.
 */
export default class UrlBuilder {
    constructor(origin) {
        if (!origin) {
            origin = "";
        } else if (_.endsWith(origin, "/")) {
            origin = origin.slice(0, -1);
        }

        this.origin = origin;
    }

    url(path) {
        if (!path)
            path = "";

        if (!path.startsWith("/"))
            path = "/" + path;

        return this.origin + path;
    }

}
