import React from "react";

import FieldWithApply from '../utility/FieldWithApply';

export default function CartSpecialOfferEntry(props) {

  const {shoppingCart, callbacks} = props;

  const {errors = {}} = shoppingCart;
  const err = errors.couponCode;

  return <div id="coupon-entry">
    <FieldWithApply shoppingCart={shoppingCart} callbacks={callbacks}
      id="cart_coupon_code" name='couponCode' placeholder="Coupon Code"
    />
    {err && <div className="offer-not-available">{err}</div>}
  </div>;

}
