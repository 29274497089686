import SmartySDK from "smartystreets-javascript-sdk";
import _ from 'lodash';
import EmbeddedConfig from "../../lib/EmbeddedConfig";

const SmartyCore = SmartySDK.core;
const Lookup = SmartySDK.usAutocompletePro.Lookup;

const SMARTY_LICENSE_KEY = '120291282888955543';


/**
 * 2022-05 perform address lookups with Smarty
 * US Autocomplete API.
 */
export default class SmartyLookup {
  constructor({agent} = {}) {
    this.count = 0;
    this.cache = {};
    this.agent = agent;
  }

  get client() {
    return (this._client ||
      (this._client = this.buildClient()));
  }

  cleanQuery(query) {
    return _.trim('' + query)
      .replaceAll('  ', ' ');
  }

  call({query, onSuccess, onFailure, options = {}}) {
    query = this.cleanQuery(query);

    console.log("SMARTY REQUEST: " + query);

    if (!options.selected) {
      let handled = this.handleViaCache({query, onSuccess});
      if (handled) return;
    }

    let words = query.split(/\s+/).filter(word => word);
    // let longWords = words.filter( w => w.length > 1);

    if (words.length > 1) {
      return this.performLookup({query, onSuccess, onFailure, options});
    } else // query not long enough - do not attempt lookup
      return false;
  }

  handleViaCache({query, onSuccess, onFailure}) {
    query = this.cleanQuery(query);

    let response = this.cache[query];
    if (response) {
      onSuccess({
        response: response,
        results: this.filterResults(response.result)
      });
      return true;
    } else {
      return false;
    }
  }

  buildRequest({query, options = {}}) {
    let lookup = new Lookup(query);

    if (options.selected)
      options.selected = this.cleanQuery(options.selected);

    // copy more options like 'selected'
    Object.assign(lookup, options);

    if (this.agent) {
      lookup.preferGeolocation = 'none';
    }

    return lookup;
  }

  async performLookup({query, onSuccess, onFailure, options = {}}) {
    // enforce limits.
    this.count = this.count + 1;
    if (this.count > 40)
      return;

    const lookup = this.buildRequest({query, options});

    if (!this.client) {
      let err = "no smarty client, cannot perform lookups.";

      console.error(err);
      if (onFailure)
        onFailure({error: err})

      return;
    }

    const response = await this.client.send(lookup);

    return this.handleResponse({query, response, onSuccess, onFailure});
  }

  // Given a result list, we'll see some entries for the
  // base address (1926 N Jefferson) and the ones that have
  // Unit, Apt, etc.
  // When this is the case, discard the ones that lack the

  handleResponse({query, response, onSuccess, onFailure}) {
    if (response && response.result) {
      // add to cache
      this.cache[query] = response;

      // and invoke the callback
      onSuccess({
        response: response,
        results: this.filterResults(response.result)
      });
    } else {
      console.error("SmartyLookup: failed to look up " + query);

      if (onFailure) {
        onFailure({response: response})
      }
    }
  }

  // secondary designator, if it exactly matches one that does.
  filterResults(list) {
    // a lambda to give us core attrs as string
    let essenceOf = (entry) => _.at(entry,
      ['streetLine', 'city', 'state', 'zipcode']).join('/');

    let index = _.keyBy(list.filter(x => x.secondary), essenceOf);

    return list.filter(entry => {
      return entry.secondary || !index[essenceOf(entry)];
    });
  }


  buildClient() {
    const credentials = new SmartyCore.SharedCredentials(SMARTY_LICENSE_KEY);

    let clientBuilder = new SmartyCore.ClientBuilder(credentials)
      .withLicenses(["us-autocomplete-pro-cloud"]);

    let client = clientBuilder.buildUsAutocompleteProClient();
    return client;
  }
}

