import React  from 'react';
import PropTypes from 'prop-types';
import {every, isEmpty, mapValues} from 'lodash';

import BorderPicker from './BorderPicker';
import ColorPicker from './ColorPicker';
import LloydCustomiser from '../Customiser/LloydCustomiser';
import OptionGallery from "./OptionGallery";
import CartOptions from "../models/CartOptions";

/**
 * The bulk of a LloydProductSection is the LloydOptionsSection.
 *
 * This is the container for the selectors for each option -
 * colour, border/binding, logo/lettering
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const LloydOptionsSection = (props) => {
  if (!shouldRender(props)) {
    return null;
  }

  // hash of {color: lots-of-lloyd-data, border: lots-of-lloyd-data, logo: ...}
  let opts = getOpts(props);

  return <div id="lloyd-options-section">
    {opts.color && <section id="lloyd-color-and-border">
      <h2>Step 2: Select Color {opts.border && " and Binding"}</h2>

      {opts.color && <OptionGallery
        option={opts.color}
        pickWith={ColorPicker}
        {...props}
      />}

      {opts.border && <OptionGallery
        option={opts.border}
        pickWith={BorderPicker}
        {...props} />}
    </section>}

    {(opts.logo || opts.lettering) &&
      <LloydCustomiser {...props} />}
  </div>;

}

LloydOptionsSection.propTypes = {
  lloyd: PropTypes.object.isRequired,
  cart: PropTypes.object.isRequired
}

export default LloydOptionsSection;

// Render only when cart has items, and every item
// has its "product unique" selected.
function shouldRender(props) {
  let {cart = {}} = props;

  if (!cart || cart.isEmpty())
    return false;

  let uniques = cart.items.map((item) =>
    item.options.getOption(CartOptions.PRODUCT_UNIQUE));

  // all most be set.
  return ((!isEmpty(uniques)) && every(uniques, (u) => !!u));
}

function getOpts(props) {
  const {lloyd} = props;
  const {options} = lloyd;

  // Color should always be present.  Border is present for UM and VT only.
  const out = {
    color: options.getOption('color'),
    border: options.getOption('border'),
    logo: options.getOption('logo'),
  }

  // Logo/Lettering only for some vehicles
  // Reject logo option if it has no choices.
  return mapValues(out, (v, _k) => {
    if (v && v.isEmpty && v.isEmpty())
      return false;
    return v;
  });
}
