import ErrorReporter from "./ErrorReporter";
import EmbeddedConfig from "../../lib/EmbeddedConfig";

const isDev = (process.env.NODE_ENV == 'development');

const disableErrorHandler = isDev;  // normally false


/**
 * Register a global error handler as window.onerror,
 * which calls a static function (everything in this class is static).
 *
 */
export default class ErrorHandler {
  static config = null;

  static errorCount = 0;

  static lastError = null;

  // call next error handler in the chain, if any
  static nextHandler = null;

  // these arguments are predefined for the window.onerror event.
  static catchError = (msg, file, line, col, error) => {
    try {
      if (globalThis.IN_ERROR_HANDLER) return;    // loop you must not do

      ErrorHandler.errorCount = (ErrorHandler.errorCount || 0) + 1;

      ErrorHandler.lastError = error;

      const event = {
        message: msg,
        file: file,    // https://....application-<version>.js
        line: line,   // 53246
        col: col,     // 15
        error: error,    // Error (exception) object with message/stack - may be null
        count: ErrorHandler.errorCount,
      }

      console.error("-------- ErrorHandler caught: ", event);

      if (ErrorHandler.errorCount > 10) {
        console.error("10+ errors received, turning off error service.");
        return false;
      }

      const reporter = new ErrorReporter({
        errorEvent: event, config: ErrorHandler.config
      })
      reporter.call();

      globalThis.IN_ERROR_HANDLER = false;

      // 2023-02
      const nxt = ErrorHandler.nextHandler;
      if (nxt && (typeof nxt === 'function') && (nxt !== ErrorHandler.catchError)) {
        console.log("calling next error handler...")
        nxt(msg, file, line, col, error)
      }
      return true;
    } catch (veryBadThing) {
      console.error("error in error handler: ", veryBadThing);
    }
  }

  // NOT YET, ErrorReporter does not handle plain 'error' object yet
  //
  // static handleError(error, attrs={}) {
  //   if (globalThis.IN_ERROR_HANDLER) return;    // loop you must not do
  //
  //   const reporter = new ErrorReporter({
  //     error: error, attrs: attrs, config: ErrorHandler.config
  //   })
  //   reporter.call();
  //
  //   globalThis.IN_ERROR_HANDLER = false;
  // }

  // static installLater = ({config, delay = 200}) => {
  //   if (window) {
  //     window.setTimeout( () => ErrorHandler.install({config}), 500);
  //   }
  // }

  // Install error handler as window.onerror, and also save the config.
  static install = ({config} = {}) => {

    if (disableErrorHandler) {
      console.log("* ErrorHandler disabled");
      return false;
    }

    if (typeof window == 'undefined') {
      console.error("ErrorHandler: cannot find window object!");
      return false;
    }

    // Note that this won't prevent loops if other error handlers invoke ours
    if (window.onerror == ErrorHandler.catchError) {
      console.log("Error handler is already set, do nothing.");
      return;
    }

    // save previous handler, so we can chain them
    ErrorHandler.nextHandler = window.onerror;

    ErrorHandler.config = config || new EmbeddedConfig();

    window.onerror = ErrorHandler.catchError;

    // also make this class global
    window.ErrorHandler = ErrorHandler;

    // console.log("Installed global error handler");

    return true;
  }
}
