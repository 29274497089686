import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Money from "../../../lib/Money";

const AgentPercentDiscountField = (props) => {
  const {callbacks = {}, item = {}, shoppingCart = {}, value} = props;

  const onInput = (evt) => {
    const agentPercentDiscount = +(evt.target.value);  // 5, 10, 20

    // 2022-11 use cataloguePrice instead of regularUnitPrice
    let {cataloguePrice} = item;
    if (!cataloguePrice) {
      console.error("cataloguePrice is missing, fall back on regularUnitPrice");
      cataloguePrice = item.regularUnitPrice;
    }

    const centsDiscount = Math.round(cataloguePrice * agentPercentDiscount / 100.0);

    const agentUnitPrice = cataloguePrice - centsDiscount;
    // let multiplier = (100.0 - percent) / 100.0;
    // let agentUnitPrice = Math.round(multiplier * regularUnitPrice);

    callbacks.edit({
      agentUnitPrice,
      centsDiscount,
      agentPercentDiscount: evt.target.value,
      agentUnitPriceFormatted: new Money(agentUnitPrice).toDollars()
    })
  }

  const onBlur = (evt) => {
    callbacks.save({ agentPercentDiscount: value })
  }

  return (
    <input className="admin-percent-discount" placeholder=""
      value={value || ''}
      onInput={onInput}
      onBlur={onBlur}
      readOnly={shoppingCart.readonly || false}/>
  );
}

// AgentPercentDiscountField.propTypes = { }

export default AgentPercentDiscountField;

