import React from 'react';
import PropTypes from 'prop-types';
import AddToCartForm from "./AddToCartForm";

/*
 * Inside the .cluster-cart column we have the
 * cluster-cart-inner, which wraps a
 * CartPriceDisplay
 * AddToCartCheckbox or AddToCartButton
 * CartNotesDisplay
 *
 * props are items=array, dispatch, cluster, clusterState
 *
 */
export default class ClusterAddToCart extends React.Component {
  static propTypes = {
    itemApplications: PropTypes.array,
    products: PropTypes.object.isRequired,
    // productGroup: PropTypes.object.isRequired,
    // dispatch,
    pageType: PropTypes.string, // "CMf"
    cluster: PropTypes.object,
    clusterState: PropTypes.object,
    itemCache: PropTypes.object, // AppliedItemCache
    cartItem: PropTypes.object
  }

  state = {}

  // AddToCart buttons need a unique index for making field names.
  getIndex() {
    let {cluster} = this.props;
    let {index} = cluster;
    if (isNaN(index)) {
      // just use something random and unlikely to conflict
      index = 500 + Math.round(10000 * Math.random());
    }
    return index;
  }

  getAppliedItem() {
    let {clusterState = {}} = this.props;

    return clusterState.selectedItem;
  }

  getChildProps(item) {
    let {cluster, clusterState} = this.props;

    let {productGroup} = cluster;

    item = item || this.getAppliedItem() || {};

    let childProps = _.extend({}, this.props, {
      index: this.getIndex(),
      application: item.application,
      product: item.product,
      productGroup: productGroup,
      matchingProducts: clusterState.matchingProducts,
      products: this.props.products,
      clusterState: clusterState
    });

    return childProps;
  }

  unstable_handleError(err) {
    let message = (err && err.message) ? err.message : "Unknown error."

    this.setState({error: message});
  }

  renderError({className, error}) {
    if (!error)
      error = this.state.error;

    return <div className={className}>
      <h2 className="red">Internal Error:</h2>
      {error}
    </div>
  }

  render() {
    if (this.state.error) {
      return this.renderError({
        error: this.state.error, className: "cluster-cart"
      })
    }

    let appliedItem = this.getAppliedItem();

    let childProps = this.getChildProps(appliedItem);

    let enabled = !!appliedItem;

    return <div className="cluster-cart">
      <AddToCartForm enabled={enabled} {...childProps} />
    </div>
  }
}

