import React  from 'react';

/**
 * App.footnote is internally delimited by @@. Split it.
 *
 * @param footnote
 * @param TagName
 * @param className
 * @returns {unknown[]|null}
 * @constructor
 */
export default function FootnoteList(
  {
    footnote, TagName = 'div', className = "footnote"
  }) {

  if (!footnote) return null;

  const list = ('' + footnote).split(/@@/);
  return list.map((f, i) => <TagName key={i} className={className}>{f}</TagName>);
}
