import React from 'react';

export default function ValidationError({saveCartStatus = {}, ...props}) {
  let {error} = saveCartStatus;
  if (!error)
    return null;

  return (
    <div className="validation-error">
      <div className="message">{error}</div>
    </div>
  );
}

