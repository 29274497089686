/*
 * Google Analytics 4 product adapters
 *
 * https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag#view_item
 *
 */

import {camelizeKeysDeep} from "../ReduxStore/Helpers/transformObject";
import {boldlyLog} from "../Utility/logging";

const isDev = (process.env.NODE_ENV == 'development');

export function safeGtag(...args) {
  try {
    const {gtag} = globalThis;
    if (!gtag) {
      console.error("cannot find global gtag function");
      return;
    }

    // tag ALL of our events with accessories=true, to distinguish from NOL-Core events
    if (typeof (args[2]) === 'object') {
      // args[2].accessories = true;
      args[2].section = 'accessories';
    }

    if (isDev) {
      window.LAST_GOOGLE_EVENT = args;
      //boldlyLog(`!! GTAG called: ${JSON.stringify(args, null, 2)}`);
    }

    // gtag function always returns 'undefined'
    gtag(...args);
    return args;
  } catch (err) {
    console.error("gtag error: " + err.toString());
  }
}

export function reportAddToCart({product, productGroup}) {
  if (!product || !productGroup) {
    console.error("reportAddToCart called without product or group");
    return;
  }

  product = camelizeKeysDeep(product);
  let itemInfo = itemParams({product, productGroup});

  safeGtag("event", "add_to_cart", {
    currency: "USD",
    value: itemInfo.price,
    items: [itemInfo]
  });

}

export function itemParams({product, productGroup}) {
  if (!product) return {};

  let price = new Money(product.regularPriceCents || 0);

  let out = Object.assign(productGroupItemParams(productGroup), {
    item_id: product.googleProductId,
    item_name: product.name,
    price: price.toDollars(),
    quantity: 1
  });

  return out;
}

export function productGroupItemParams(productGroup) {
  if (!productGroup)
    return {};

  const {topCategories = [], subCategories = []} = productGroup;

  let hash = {
    item_brand: productGroup.brandName,
    // item_category: top category name,
    // item_category2: // category mount name
    item_category: topCategories[0] || 'FIXME: CATEGORY MISSING'
  }

  subCategories.map((sub, i) => {
    hash["item_category" + (i + 2)] = sub;
  });

  return hash;
}
