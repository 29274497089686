import React from 'react';

import {connect, Provider} from "react-redux";
import VehicleYearChooserContainer from "./VehicleYearChooserContainer";
import {getReduxStore} from "../ReduxStore/store";

function mapStateToProps(state) {
  return state.vehicleSlice || {};
}

const VehicleChooserYearWrapper = connect(mapStateToProps)(VehicleYearChooserContainer);

export default function VehicleYearChooserApp(props) {
  return <Provider store={getReduxStore()}>
    <VehicleChooserYearWrapper {...props} />
  </Provider>
}


