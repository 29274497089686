// 2023-11 MH migrating scattered URL paths to this file
//
import LocalUrlMaker from "./LocalUrlMaker";

// Page to compare items by a list of IDs.
// Used by ProductGrid's "compare now" widget.
export function compareItemsPath(compareList) {
  return LocalUrlMaker.prefixedPath(
    "/compare_items?ids=" + compareList.join(','))
}
