import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import LocalUrlMaker from "../../lib/LocalUrlMaker";
import PostWithParams from "../Helpers/PostWithParams";
import JsonDelete from "../Helpers/JsonDelete";
import {
  addRailsPrefix,
  camelizeKeys,
  camelizeKeysDeep,
  snakeifyKeys
} from "../Helpers/transformObject";

const isDev = (process.env.NODE_ENV == 'development');

/**
 * saveVehicle({params, vehicleContext})
 *
 * @type {AsyncThunk<void, void, {}>}
 */
export const saveSessionVehicle = createAsyncThunk(
  "vehicle/save",
  async (payload, thunkAPI) => {
    // context: additional state that will be merged in
    const {productContext = {}} = payload;

    // 2024-04 params example: {
    //   "year": "2021",
    //   "make": "208",
    //   "model": "20829",
    //   "product_context[page_type]": "top_category",
    //   "product_context[category_id]": 136,
    //   "product_context[top_category_id]": 136
    // }
    //
    let params = Object.assign({},
      snakeifyKeys(payload.params),
      addRailsPrefix(snakeifyKeys(productContext), 'product_context')
    );

    // thunkAPI has dispatch, getState, extra, requestID
    // const response = await fetch(vehicleUrl());
    // return response.data;

    const url = vehicleUrl();

    // Build a POST
    const post = new PostWithParams({url, params});

    const opts = post.toFetchOptions();
    // console.error(`vehicleSlice: saving vehicle to ${url} with ${JSON.stringify(opts)}`);

    console.log("vehicleSlice: saving customer vehicle to server: ", JSON.stringify(params));
    // console.log("action: vehicle context is " + JSON.stringify(vehicleContext));


    const rawResponse = await fetch(url, opts).then(r => r.json());

    const response = camelizeKeys(rawResponse);

    // FIXME: make camelizeKeysDeep when customerVehicle is ready to be camelcase
    const update = {
      customerVehicle: response.customerVehicle,
      vehicleContext: camelizeKeysDeep(Object.assign({},
        payload.vehicleContext, response.vehicleContext))
    }

    // AFTER receiving update, pass to setVehicle to update state's customerVehicle
    thunkAPI.dispatch(vehicleSlice.actions.setVehicle(update));
  }
);

/**
 * 2024-04 extracted from saveVehicle
 *
 * @type {AsyncThunk<unknown, void, AsyncThunkConfig>}
 */
export const deleteSessionVehicle = createAsyncThunk(
  "vehicle/save",
  async (payload, thunkAPI) => {
    const { uplink } = payload;

    const url = vehicleUrl({reset: true});
    const request = new JsonDelete({url, verbose: true});

    const response = await fetch(url, request.toFetchOptions())
      .then((r) => r.json());

    // 2024-05 - if clearing vehicle on a page like /tonneau-covers/chevy-colorado,
    // redir to /tonneau-covers after. This is passed in as a prop to those components
    // that use this action
    if (uplink) {
      console.log("deleteSessionVehicle: redirect to uplink: " + uplink);

      window.location = uplink;
      return;
    } else {
      console.log("deleteSessionVehicle: no uplink, will not redirect");
    }

    // only after it returns do we clear the vehicle from Redux.
    // this is because it can prompt a page reload, and we have to
    // ensure that the back-end vehicle was cleared before the reload.
    thunkAPI.dispatch(vehicleSlice.actions.clearVehicle({
      by: "deleteSessionVehicle",
      uplink: uplink
    }))
  }
);

export const vehicleSlice = createSlice({
  name: 'vehicleSlice',
  initialState: {customerVehicle: null, vehicleContext: null},
  reducers: {
    //
    // WARNING: extra reducers listen for this action in other slices
    //
    setVehicle: (state, action) => {
      const payload = camelizeKeys(action.payload);
      let {customerVehicle, vehicleContext} = payload;

      // merge vehicleContext into customerVehicle
      if (customerVehicle && vehicleContext)
        customerVehicle = Object.assign({}, customerVehicle, vehicleContext);

      if (_.isEmpty(customerVehicle) && isDev) {
        alert("DEPRECATED: setVehicle with empty vehicle: " +
          JSON.stringify(payload, null, 2))
      }

      console.log("vehicleSlice.setVehicle: ", payload, customerVehicle);
      console.log(`---------- CUST VEHICLE: ${typeof customerVehicle}`, customerVehicle);

      Object.assign(state, payload, {customerVehicle});
      //console.log("STATE IS NOW:", state);

      return state;
    },

    // NEW 2024-04.
    //
    // WARNING: extra reducers listen for this action in other slices
    //
    clearVehicle: (state, action) => {
      console.log(`clearVehicle called: ${JSON.stringify(action.payload)}`)
      state.customerVehicle = null;
    }
  }
});

function vehicleUrl({reset = false} = {}) {
  let url = LocalUrlMaker.prefixedPath("/customer_vehicle.json");
  if (reset) {
    // reset is done as a 'delete' request, this param is for backup
    url += "?reset=1"
  }
  return url;
}

export default vehicleSlice;

