import LocalUrlMaker from "../../lib/LocalUrlMaker";
import {safeGtag} from "../../Analytics/googleAnalyticsFour";

const isDev = (process.env.NODE_ENV == 'development');

export const performOnCompleteAction = ({
  dispatch, customerVehicle, config, vehicleContext
}) => {

  // 2022-04 - support more onComplete actions to allow for vehicle selector tube sections
  const {onComplete} = config;

  if (!customerVehicle || !onComplete) return false;

  if (isInitialVehicle(customerVehicle))
    return;

  if (typeof onComplete === 'function') {
    if (isDev) {
      alert("onComplete is a function - deprecated")
    }
    return onComplete({
      dispatch, customerVehicle, config, vehicleContext
    });
  } else if (onComplete === 'reload') {
    reloadOnComplete({
      dispatch, customerVehicle, config, vehicleContext
    });
  } else if (onComplete === 'redirect') {  // redirect to the ShowVehicle page (badly named)
    if (isDev) {
      alert("DEPRECATED onComplete==redirect"); // 2024-04
    }
    redirToVehiclePage({
      dispatch, customerVehicle, config, vehicleContext
    });
  } else {
    console.error("vehicle selector config.onComplete unknown value: " + onComplete);
  }
}

export function isInitialVehicle(customerVehicle) {
  return customerVehicle?.source === 'initial'
}

export function reportVehicleSelected(customerVehicle) {
  if (_.isEmpty(customerVehicle))
    return false;

  if (isInitialVehicle(customerVehicle))
    return;

  let essence = {
    vehicle_make: customerVehicle.vehicleMakeName,
    vehicle_model: customerVehicle.vehicleModelName,
    vehicle_year: customerVehicle.year,
    vehicle_type: customerVehicle.vehicleType
  }

  safeGtag('event', 'set_vehicle', essence);
}

function redirToVehiclePage({customerVehicle}) {
  let {vehicleUrl, vehicleType, year} = customerVehicle;

  let url = new URL(globalThis.location);
  if (vehicleUrl) {
    url.pathname = vehicleUrl;
    url.search = "year=" + year;
  } else {
    // go to /accessories/truck
    url.pathname = LocalUrlMaker.prefixedPath("/" + _.toLower(vehicleType));
  }

  globalThis.location = url.toString();
}

function reloadOnComplete({customerVehicle, vehicleContext}) {
  console.log("reloadOnComplete vehicle");

  if (vehicleContext.targetPath) {
    globalThis.location = vehicleContext.targetPath;
    return;
  }

  let url = new URL(globalThis.location);
  url.search = `set-vehicle=1`;

  // alert(JSON.stringify(customerVehicle, null, 2))
  globalThis.location = url.toString();
}
