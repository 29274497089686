import React, {useState, useEffect} from 'react';

import clsx from "clsx"
import SubscribeInput from "./SubscribeInput";
import SubscribeButton from "./SubscribeButton";
import SubscribeSuccess from "./SubscribeSuccess";

export default function SubscribeForm({
  className,
  inputClasses,
  placeholder,
  buttonText = "Subscribe",
  buttonClasses,
  successText,
  successClasses,

  ...props
}) {

  const [email, setEmail] = useState('');
  const [subscribed, setSubscribed] = useState(false);

  function handleSubmit(evt) {
    if (evt?.preventDefault) {
      evt?.stopPropagation();
      evt?.preventDefault();
    }

    setSubscribed(true);
    setEmail('');

    return false;
  }

  return (

    <form
      className={clsx(className)}
      onSubmit={handleSubmit}>


      <SubscribeInput
        className={inputClasses}
        placeholder={placeholder}
        value={email}
        setValue={setEmail}/>

      {subscribed ? <SubscribeSuccess classNames={successClasses} caption={successText}/> :
        <SubscribeButton onSubmit={handleSubmit} classNames={buttonClasses} caption={buttonText}/>}
    </form>
  );
}

