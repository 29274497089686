import React, {useState, useEffect} from 'react';
import clsx from 'clsx';

export default function SubscribeInput({
  value='',
  setValue = (x) => {},
  className,
  placeholder="Enter your email address",
  ...props
}) {

  className ||= "text-black bg-white px-4 py-2 rounded-sm";

  function handleChange(evt) {
    setValue(evt?.target?.value);
  }

  return (
    <div>
      <input className={clsx(className)}
        type="email" value={value} onChange={handleChange}
        placeholder={placeholder}/>
    </div>
  );
}


