import React from "react";
import LocalUrlMaker from "../../../lib/LocalUrlMaker";
import CartItemTitle from "./CartItemTitle";
import CartItemDetail from "./CartItemDetail";

// Left column of items table.
export default function ItemDetailsColumn({item, ...props}) {
  let url = LocalUrlMaker.prefixedPath(item.groupUrl);

  let img = item.smallImage || item.midImage;

  return <div className="col-detail">
    <CartItemTitle item={item} url={url}/>

    {img && <a className="image-box" href={url}>
      <img src={img} alt={item.name}/></a>
    }

    <CartItemDetail item={item}/>
  </div>
}



