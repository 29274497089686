import LocalUrlMaker from "../../lib/LocalUrlMaker";

/**
 * Get correct checkout path (old vs new), site-prefix aware.
 *
 * 2022-06 was already camelcase-agnostic
 */
export default class CheckoutPath {
  constructor({cart, cartCode, flavour}) {
    if (!cart)
      cart = {checkoutFlavour: 'new', cartCode: null}

    // FIXME: deprecate the snake_case versions
    this.cartCode = cartCode || cart.cartCode || cart.cart_code;
    this.flavour = flavour || cart.checkoutFlavour || cart.checkout_flavour;
  }

  toString() { return this.fullPath()}

  fullPath() {
    return LocalUrlMaker.jsonPath(this.nativePath());
  }

  nativePath() {
    return `/new_checkout/${this.cartCode}`
  }

}
