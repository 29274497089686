import React from 'react';
import PropTypes from 'prop-types';

import GridItem from "./GridItem";

import clsx from "clsx";

/**
 * 2023-04 MH - modernized this component and all components it uses.
 *
 * ProductGroupGrid - usually part of GroupGridApp (the bulk of a TopCategory page)
 * but could be called without it.
 *
 "dispatch",
 "startupState",
 "gridState",
 "customerVehicle",
 "vehicleContext",
 "callbacks",
 "visibleItems"
 */
export default function StandardGrid({
  gridId,
  dispatch, visibleItems, gridState,
  customerVehicle
}) {
  const {columns = 4, currentPage=1} = gridState;

  let classNames = clsx('product-grid', 'product-grid-view');

  let innerClasses = clsx("product-grid-inner", "grid", "grid-cols-2");
  if (columns >= 4) {
    innerClasses = clsx(innerClasses, `lg:grid-cols-${columns - 1}`)
  }
  innerClasses = clsx(innerClasses, `xl:grid-cols-${columns}`)

  return (
    <div className={classNames}>
      <div className={innerClasses}>
        {
          visibleItems.map((item, i) => <GridItem
            key={item.id}
            gridId={gridId}
            dispatch={dispatch}
            item={item} index={i}
            gridState={gridState}
          />)
        }
      </div>
    </div>
  );
}

StandardGrid.propTypes = {
  gridState: PropTypes.object.isRequired,
};
