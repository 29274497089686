import React, {useState, useEffect, useCallback} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {findNodeOrAncestor, textToHtml} from "../Utility/html";
import Modal from "../Modals/Modal";

export default function FancyboxContainer({
  bindTargets = "fancybox", ...props
}) {

  const [content, setContent] = useState(null);

  function handleClose(evt) {
    setContent(null);
  }


  const handleClick = useCallback((evt) => {
    evt.stopPropagation();
    evt.preventDefault();

    const link = findNodeOrAncestor(evt?.target, n => n?.href);

    const url = link?.href;
    if (!url) {
      console.error("no URL for remote content fancybox");
      return;
    }

    if (url.toString().match(/(jpg|png|gif)$/)) {
      setContent(`<html><body><img src="${url}" alt="${link.title || "Image"}"/></body></html>`);
    } else {
      fetch(url)
        .then(r => r.text())
        .then(text => setContent(text));  // leave unparsed, for dangerouslySetInnerHTML
    }

  }, []);

  useEffect(() => {
    if (!bindTargets)
      return;

    const links = document.getElementsByClassName(bindTargets);
    for (let link of links) {
      link.onclick = handleClick;
    }

  }, [bindTargets, handleClick])

  if (!content) return null;

  return <Modal handleClose={handleClose}>
    <div dangerouslySetInnerHTML={{ __html: content }} />
  </Modal>

}
