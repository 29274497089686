import CartItemFactory from "../logic/CartItemFactory";

/**
 * Items in a shopping cart.
 */
export default class CartItemsCollection {
  constructor({contents} = {}) {
    this.contents = contents ? _.cloneDeep(contents) : {}
  }

  map(fn) {
    return this.toArray().map(fn);
  }

  // CART ITEMS.
  count() {
    return _.keys(this.contents).length;
  }

  isEmpty() {
    return _.isEmpty(this.contents);
  }

  // mapItems(fn) {
  //   return _.values(this.contents).map(fn);
  // }

  /**
   *
   * @param arg - a part number or LloydItem
   * @returns {*}
   */
  getItem(arg) {
    let partNumber = arg.extended_part_number ? arg.extended_part_number : arg;

    return this.contents[partNumber];
  }

  requireItem(pn) {
    let item = this.getItem(pn);
    if (!item)
      throw new Error("cannot find cart item " + pn);
    return item;
  }

  /**
   * Get the items, optionally filtering them.
   *
   * @param fn {function|null}
   * @returns {Array}
   */
  toArray(fn = null) {
    let list = _.values(this.contents);
    if (fn)
      list = list.filter(fn);
    return list;
  }

  /**
   *
   * @param lloydItem
   * @param state
   */
  toggleItem(partNumber, {factory}) {
    console.log(`ToggleItem - CartItemsCollection: items are ${JSON.stringify(Object.keys(this.contents))}`);

    if (this.getItem(partNumber)) {
      console.log("remove item " + partNumber);
      this.removeItem(partNumber);
    } else {
      console.log("add item " + partNumber);
      this.addItem(partNumber, {factory});
    }
  }

  removeItem(partNumber) {
    delete this.contents[partNumber];
  }

  // Add an item to the cart.
  addItem(partNumber, {factory}) {
    const cartItem = factory.createCartItem(partNumber);
    this.contents[partNumber] = cartItem;
    console.log(`CartItemsCollection: items are ${JSON.stringify(Object.keys(this.contents))}`);
    return cartItem;
  }

}
