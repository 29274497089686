import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Modal from "../Modals/Modal";
import RequestReceiptForm from "./RequestReceiptForm";

export default function RequestReceiptApp({
  caption = "Resend Email Receipt",
  ...props //   authToken, orderId, orderToken, formPath,
}) {
  // FIXME
  const [open, setOpen] = useState(false);

  const handleOpen = () => {setOpen(true);}

  const handleClose = () => {setOpen(false);}

  return <div>
    <a onClick={handleOpen} className="click-me text-brand-orange">
      <span className="fa fa-lg fa-envelope"/>
      {' ' + caption}
    </a>
    {open && <Modal handleClose={handleClose}>
      <RequestReceiptForm {...props}
        handleClose={handleClose} />
    </Modal>}
  </div>

  return

}
