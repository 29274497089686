// 2020-09 AppliedItemCache - look up product given app id or item id.
//
// built by Cluster on render, this is passed to children, and allows finding
// of AppliedItem { application: ..., product: ... }
// via application id or product id.
export default class AppliedItemCache {

    // data: Array<AppliedItem>
    constructor({ apps, products } = {}) {
        if (!apps || !products) {
            console.error("AppliedItemCache: param missing");
        }

        this.products = _.keyBy(products, "id");
        this.applied_items = AppliedItemCache.buildAppliedItems(apps, products);
    }

    // indexed by app id.
    getAppliedItem(id) {
        if (!id)
            return null;

        return this.applied_items[+id];
    }

    // given a list of ids, return an array of
    // all those Products that match.
    getProductsById(id_list) {
        return id_list.map( (id) =>
            this.products[+id] );
    }

    toArray() {
        let list = _.values(this.applied_items);
        // sort?
        return list;
    }

    getApplicationList() {
      return _.values(this.applied_items).map( i => i.application);
    }

    getAppsForProduct(productId) {
      productId = +productId; // force numeric

      let apps = this.getApplicationList();

      let matching = apps.filter( (app) =>
        (+app.product_id == productId));

      return matching;
    }

    // { app_id => appliedItem, app_id => ... }
    static buildAppliedItems(apps, products) {
        let list = apps.map( (app) => ({
            id: +app.id,
            application: app,
            product: products[app.product_id]}))

        return _.keyBy(list, 'id');
    }

    // { product_id => product }
    // static buildProducts(data) {
    //     return _(data).map( (i) => i.product )
    //         .keyBy( (p) => p.id )
    //         .value();
    // }

}
