import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {buildOrderEvent} from "../Analytics/googleOrderAdapter";
import {safeGtag} from "../Analytics/googleAnalyticsFour";

// https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag#purchase

const isDev = (process.env.NODE_ENV == 'development');

export default function ReportOrderToGoogleAnalytics4({...props}) {
  const {order, orderedItems, groupCollection} = props;

  const [reported, setReported] = useState(null);

  useEffect(() => {
    if (reported || !order || _.isEmpty(orderedItems)) return;

    let event = buildOrderEvent({
      order, orderedItems, groupCollection
    });

    // inhibit reporting it again
    setReported(event)

    // report to google
    safeGtag(...event);
  }, [order, reported]);

  if (isDev) {
    return <pre className="debug" style={{color: 'teal', fontWeight: 'bold'}}>
    {JSON.stringify(reported, null, 2)}
  </pre>
  }

  return null;
}
