import React, {useEffect} from 'react';

import {facebookTrack} from "../../../Facebook/facebookHelpers";

export default function FacebookNewItemsReporter({newItems, shoppingCart}) {
  useEffect(() => {
    if (_.isEmpty(newItems)) return;
    let contents = newItems.map(item => {
      return {
        id: item.facebookSku || item.productGroupId,
        quantity: item.quantity
      }
    });

    facebookTrack('AddToCart', {contents});
  }, [newItems]);

  return null;
}
