import React from 'react';
import PropTypes from 'prop-types';
import ShowCustomerVehicleUI from "./ShowCustomerVehicleUI";
import NoVehicleDisplay from "./NoVehicleDisplay";

import {vehicleSlice} from "../ReduxStore/CustomerVehicle/vehicleSlice";

/**
 * 2022 - modernized to use vehicleSlice.
 *
 * Handle Redux interactions for ShowCustomerVehicleApp, which is
 * the top-of-screen widget that shows the currently selected
 * customer vehicle.
 *
 */
export default function ShowCustomerVehicleContainer(props) {
  const {customerVehicle, icon, uplink} = props;

  // 2024-06 now ignores initialVehicle, as Redux populates that.
  //const {initialVehicle, dispatch} = props;
  // useEffect(() => {
  //   if (!initialVehicle)
  //     return;
  //
  //   // FIXME: camelize when putting into redux
  //   console.log(`[ShowCustomerVehicleContainer] set initial vehicle (VC.source=initial):`, initialVehicle);
  //
  //   if (isDev) {
  //     console.error("FIX ME DO NOT EXPORT VEHICLE");
  //     return; // vehicleSlice: customerVehicle, vehicleContext: {source: 'initial')
  //   }
  //
  //   // source=initial -- this vehicle was here on page load
  //   // source=user -- user manipulated vehicle selector after page load
  //
  //   dispatch(setVehicle({
  //     customerVehicle: initialVehicle,
  //     vehicleContext: {source: 'initial'}
  //   }));
  // }, [initialVehicle]);

  if (customerVehicle) {
    return <ShowCustomerVehicleUI
      icon={icon}
      customerVehicle={customerVehicle}
      uplink={uplink}
    />
  } else {
    return <NoVehicleDisplay/>
  }
}

ShowCustomerVehicleContainer.propTypes = {
  // from Rails view template: customer_vehicle&.serializable_hash.
  // Upon loading this will be used to initialize Redux, becoming customerVehicle
  // @example {"id":1602666,"year":2018,"vehicle_make_id":160,
  //   "vehicle_model_id":16026,"vehicle_make_name":"Chevrolet",
  //   "vehicle_model_name":"Corvette","vehicle_type":"Car",
  //   "short_make_name":"Chevy","short_model_name":"Corvette"}
  initialVehicle: PropTypes.object,

  // include an FontAwesome icon when a vehicle is present?
  // AAG theme has none, NOL theme has 'fas fa-garage-open'
  icon: PropTypes.string,

  // from Redux
  dispatch: PropTypes.func.isRequired,

  // optional - only when set - often null
  customerVehicle: PropTypes.shape({
    id: PropTypes.number
  }),
};
