import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {safeGtag} from "../../../Analytics/googleAnalyticsFour";
import {buildItemCategories} from "../../../Analytics/googleAnalyticsItems";
import {gridItemsToGoogleEvent, reportGridEvent} from "../../../Analytics/googleGridAdapter";

const GoogleGridItemsReporter = ({gridState = {}, gridItems, flavour, ...props}) => {
  const [reported, setReported] = useState(false);
  window.GRID_PROPS = props;

  useEffect(() => {
    if (reported) return;

    if (_.isEmpty(gridItems))
      return;

    setReported(true);

    // gtag's remote back-end gives a 413 error if request is huge
    // (tested with Towing-Hitches topcat, has 271 items).
    // Truncating it to 24 items for safety; exact limit unknown
    reportGridEvent({
      gridItems: gridItems.slice(0,24),
      params: {
        item_list_name: flavour
      }
    })

  }, [gridItems])

  return null;
}

// GridItemsReporter.propTypes = { }

export default GoogleGridItemsReporter;



