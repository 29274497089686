// Enumerable stuff that I think should have been in lodash.

/**
 * Like ruby's eachWithObject. Defaults to an empty object
 * as the memo value. Return value of function is ignored.
 * Better syntax and conceptually simpler than reduce()...
 *
 * @param list
 * @param fn
 * @param obj
 * @returns {{}}
 */
export function eachWithObject(list, fn, obj={}) {
  for (let item of list) {
    fn(item, obj);
  }
  return obj;
}
