import Validator from "./Validator";

/**
 * Validator for the dummy credit card object, using
 * braintree field statuses to determine if it's valid.
 *
 * MUTATES this.object, so wrap in Immer if that's no good.
 */
export default class DummyCardValidator extends Validator {
  constructor({allStatuses, ...stuff}) {
    super(stuff);

    // Braintree Hosted Fields
    this.allStatuses = allStatuses;
  }

  /**
   * @override
   *
   * @param fieldName
   * @param value
   * @returns {boolean}
   */
  validateField(fieldName, value) {
    this.clearError(fieldName);

    const status = this.getFieldStatus(fieldName);
    if (status.isValid)
      return true;

    if (status.isEmpty) {
      this.validatePresence(fieldName, "");
    } else if (fieldName == "expiration_month") {
      this.setError(fieldName, "Card has expired")
    } else if (fieldName == "expiration_year") {
      this.setError(fieldName, "Card has expired")
    }
  }

  getFieldStatus(fieldName) {
    let status = this.allStatuses[fieldName];
    if (status)
      return status;

    console.error("no braintree field status for " + fieldName + " -- have only "
      + JSON.stringify(_.keys(this.allStatuses)));
    return {};
  }
}
