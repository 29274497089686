/*
 * LiveChat integration -
 * LiveChat is loaded in header_scripts.
 * This module simply reconfigures it after it loads.
 */
import SpeakerToAnything from "./SpeakerToAnything";
import ChatInfoStash from "./LiveChat/ChatInfoStash";

export default class SpeakerToLiveChat extends SpeakerToAnything {

  api() {
    return globalThis.LC_API;
  }

  isLoaded() {
    let api = this.api();
    if (api && api.is_loaded) {
      return api.is_loaded();
    }
    return false;
  }

  // call live chat api to disable sounds

  // Doing it before load doesn't seem to work (2017-12).
  // Doing it after page load only works if LC itself reaches
  // some particular stage in its initialization... otherwise
  // disable_sounds will run but throw an error when it calls
  // on some other object that does not exist.
  // a 2 second delay seems to work on firefox and chrome.
  reconfigure() {
    let {LC_API} = globalThis;

    if (LC_API && LC_API.disable_sounds) {
      // this.log("LiveChat - disabling sounds");
      LC_API.disable_sounds();
    } else {
      console.error("SpeakerToLiveChat: No LC_API.disable_sounds");
    }
  }

  preconfigure() {
    this.name = 'SpeakerToLiveChat';

    this.configureApi();

    this.sendVariables(this.getCartVariables());

    // 2020-07: try once again to reconfigure it to disable sounds
    if (globalThis.setTimeout)
      globalThis.setTimeout(() => this.reconfigure(), 2000);
  }

  getChatStash() {
    return new ChatInfoStash({read: true})
  }

  // 2020-03 - log start of chat for later updating shopping cart.
  // function is bound to Speaker when assigned to LC_API (verified binding works)
  /**
   * @param details hash from on_chat_started callback, like { agent_name: "Tina" }
   */
  onChatStarted(details={}) {
    //console.log("*********** ON CHAT STARTED: ", details);

    let stash = new ChatInfoStash({read: true});
    stash.onChatStarted({details: details});

    //console.log("*********** ON CHAT STARTED: ", stash.contents);
  }


  getStash() {
    return new ChatInfoStash({read: true});
  }

  getVariables() {
    if (!this.variables)
      this.variables = {};
    return this.variables;
  }

  addVariables(data) {
    this.variables = _.merge({}, this.variables || {}, data);
    // "counter" was intended to increment but it doesn't work that way, killing it 2021/11
    // this.variables.counter = (this.variables.count || 0) + 1;
    return this.variables;
  }

  // Sends the variables built by cartConnector
  // ConnectID and CartURL and SiteName
  getCartVariables() {
    let {cartConnector} = global;

    let payload = {};
    let agent = this.getAgent();
    if (agent) {
      // 2022-05 when it's an AGENT on this page, send that fact, so we can
      // suppress it in the LC interface itself. But I'm also going to
      // hide the popups because agents find them annoying...
      payload['AgentName'] = agent.username;
    }

    if (cartConnector && cartConnector.chatVariables) {
      Object.assign(payload, cartConnector.chatVariables());
    }

    return payload;
  }

  configureApi() {
    // callbacks executed in context of LC_API, so we need to be indirect with 'this'
    let speaker = this;
    let agent = this.getAgent();

    globalThis.LC_API ||= {};

    const {LC_API} = globalThis;

    // 2020 - bind the chat_started event.
    LC_API.on_chat_started = this.onChatStarted.bind(this);

    // 2022-05 remove chat window for agents
    if (agent) {
      LC_API.on_after_load(function() {
        let api = globalThis.LC_API || {};

        if (api.hide_chat_window) api.hide_chat_window();
        if (api.hide_eye_catcher) api.hide_eye_catcher();
      });
    }

    // send cart variables.
    LC_API.on_chat_window_opened = function (arg) {
      let vars = speaker.getCartVariables();

      // console.error("******************* LIVE CHAT - chat opened");
      // console.log("container: " + $('#livechat-compact-container').length);
      // console.log("sending: " + JSON.stringify(vars));

      speaker.addVariables({chat_state: 'window_opened'})
      speaker.sendVariables(vars);
    };

    LC_API.on_after_load = function () {
      // console.log("* LIVE CHAT - after load");

      // Wait for it to load then call reconfigure()
      globalThis.withService("live_chat", (s) => s.reconfigure());
    };

    // LC_API.on_chat_window_minimized = function(arg) {};

    // LC_API.on_chat_state_changed = function(data) {};

    LC_API.on_chat_state_ended = function (data) {
      console.error("******************* LIVE CHAT - ended; agent is " + data.agent_name);
      console.dir(data);
    };

    LC_API.on_message = function (data) {
      console.error("******************* LIVE CHAT - message " + data.text);
      console.dir(data);
    };
  }

  hashToVariableList(hash) {
    return _.keys(hash).map((k) => ({name: k, value: hash[k]}));
  }

  sendVariables(hash) {
    if (_.isEmpty(hash))
      return;

    this.addVariables(hash);

    let data = this.hashToVariableList(this.variables);

    let {LC_API = {}, __lc} = globalThis;

    if (LC_API.set_custom_variables) {
      LC_API.set_custom_variables(data);
      //console.log("livechat - sending via custom vars", data);
    } else {
      //console.log("livechat - sending via lc params", data);
      __lc.params = data;
    }
  }
}
