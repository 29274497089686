import PhotoUploader from '../PhotoUploader/PhotoUploader';

import GenericForm from '../../lib/GenericForm';
import ReviewCritic from './ReviewCritic';
import StarWidgetGroup from './StarWidgetGroup';
import LocalUrlMaker from "../../lib/LocalUrlMaker";

export default class CreateReviewForm extends GenericForm {
    constructor($form) {
        super($form);

        this.url = LocalUrlMaker.prefixedPath("/reviews.json");

        this.photo_uploader = new PhotoUploader($('#new_product_photo'));
        this.critic = new ReviewCritic($("#product_review_body"));
        this.stars = new StarWidgetGroup();

        $('#submit-button').click(
            () => this.onSubmit());

    }

    errors() {
        let fields = this.formContents();
        let errs = {};

        let required = ['overall_rating', 'performance_rating', 'quality_rating', 'appearance_rating', 'install_time'];
        for (let f of required) {
            let v = fields["product_review[" + f + "]"];
            if (!v) {
                if (f === 'install_time') {
                    errs['install_time'] = "Don't forget to tell us how long it took to install.";
                } else {
                    errs['stars'] = "Be sure to select a star for each of the four rating criteria.";
                }
            }
        }

        if (!this.critic.reviewValid()) {
            errs.body = "Please write a longer review.";
        }


        return errs;
    }



    onSubmit() {
        if (this.displayErrors()) {
            return false;
        }
        return this.post();
    }

    // after a successful submission, display the #after-review part,
    // which is found in views/product_reviews/_after_review.html.haml
    onSuccess(response, status, xhr) {
        let review = response.product_review;

        if (review && review.id) {
            $('main').addClass('review-complete');

            let $after = $('#after-review');
            let o = $after.offset();
            let y = o ? o.top - 40 : 200;

            $after.show(200, function () {
                $('html, body').animate({scrollTop: y}, 500);

                window.location.hash = 'review-complete';
            });
        }
    }

}

