import {useEffect, useState} from "react";
import {getWindowSize} from "./windowSize";

/**
 * HOOK: useWindowSize()
 *
 * @returns {[number,number]}
 */
export function useWindowSize() {
  const [dimensions, setDimensions] = useState(getWindowSize());

  useEffect(() => {
    if (!globalThis.window) return;

    const handleResize = () => {
      let d = getWindowSize();
      //console.log(`RESIZE: to ${d}`);
      setDimensions(d);
    };

    // console.log("add resizeListener");
    window.addEventListener('resize', handleResize);

    return (() => {
      // console.log("remove resizeListener");
      window.removeEventListener('resize', handleResize);
    })
  }, []);

  return dimensions;
}


