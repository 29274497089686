import React from "react";
import clsx from "clsx";

export default function PendingIcon({className}) {
  className = clsx(
    "fas fa-traffic-cone text-orange-600 absolute bg-white rounded-full",
    "-right-2 -bottom-4 text-4xl px-2 py-2 drop-shadow-lg",
    className
  )

  return <span title="Update Pending..."
    className={className}
  />
}
