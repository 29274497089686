import React, {Component} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import ModernCartIcon from "./ModernCartIcon";
import {camelizeKeys} from "../ReduxStore/Helpers/transformObject";
import LocalUrlMaker from "../lib/LocalUrlMaker";
import AagCartIcon from "./AagCartIcon";
import {getCartUrl, getOverview} from "./logic";
import SnowplowsCartIcon from "./SnowplowsCartIcon";

export default function CartIcon(
  {
    theme = 'aagarage', ...props
  }) {

  let overview = getOverview(props);
  let cartUrl = getCartUrl(props);

  if (theme === "aagarage") {
    return <AagCartIcon {...overview} cartUrl={cartUrl}/>
  } else if (theme === "snowplows") {
    return <SnowplowsCartIcon {...overview} cartUrl={cartUrl}/>
  } else {
    return <ModernCartIcon {...props} {...overview} cartUrl={cartUrl}/>
  }
}

CartIcon.propTypes = {
  overview: PropTypes.object,
  theme: PropTypes.string,
  currentAgent: PropTypes.object,
  shoppingCart: PropTypes.object
};
