import React, {useState, useEffect} from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import PropTypes from 'prop-types';
import _ from 'lodash';

export default function ExploreStrangeNewWorldsApp({...props}) {
  return (<BrowserRouter>
    <div className="text-2xl" >
      navbar:
      <a className="mx-4" href="/demo/foo">foo</a> +
      <a className="mx-4" href="/demo/bar">bar</a> +

    </div>
    <Routes>
      <Route path="/demo/foo" element={<Foo/>} />
      <Route path="/demo/bar" element={<Bar/>} />
      <Route index element={<MyRoot />}></Route>
    </Routes>
  </BrowserRouter>)
}
function MyRoot(props) {
  console.error("RENDERING ROOT...")
  return <div>I AM ROOT</div>
}
function Foo(props) {
  console.error("RENDERING FOO...")
  return <div>Foo</div>
}

function Bar(props) {
  console.error("RENDERING BAR...")
  return <div>Bar</div>
}
function Baz(props) {
  console.error("RENDERING BAZ...")
  return <div>Baz</div>
}
