import React, {useState,useEffect} from 'react';

import {safeRefreshAffirm} from "../../../lib/affirmHelpers";

// Used by both shopping cart and checkout
export default function AffirmEstimate({amount}) {
  const [ranOnce, setRanOnce] = useState(false);

  useEffect(() => {
    safeRefreshAffirm();
  }, [amount, ranOnce]);

  // force the useEffect to trigger a second time
  if (!ranOnce)
    setRanOnce(true);

  return <div className="affirm-wrapper">
    <p className="affirm-as-low-as"
      data-page-type="cart"
      data-amount={amount}
    />
  </div>
}
