//
// ShippingForecast for a product.
// Created by ShoppingCartStore from JSON forecasts: { product_id : data }
//
export default class ShippingForecast {
  // keys: now, class_name, product, brand, ship
  constructor(data = {}) {
    _.extend(this, data);

    this.inventories = _.mapValues(
      (data.inventories || {}),
      (val, _k, _h) => new ShipperInventoryResult(val));

    this.loaded_at = new Date().getTime();
  }

  age() {
    return new Date().getTime() - this.loaded_at;
  }

  inventoryForShipper(shipper_id) {
    return this.inventories[+shipper_id];
  }

  stockCountForShipper(shipper_id) {
    let inventory = this.inventoryForShipper(shipper_id);

    if (inventory) {
      return _.min(inventory.partQuantities());
    }
    return '??';
  }
}

class ShipperInventoryResult {
  constructor(data) {
    _.extend(this, data);
  }

  partQuantities() {
    return (this.parts || []).map((p) => +(p.quantity));
  }
}

//// An individual shipper's forecast; there will be a list of these.
//class ShipperForecast {
//    // actual_days_to_ship, biz_days_to_ship, can_ship, forecast_text, holidays_between, parts, ship_date
//    // cutoff:
//    constructor(data) {
//        // this.loaded_at = new Date().getTime();
//        _.extend(this, data);
//        // Timestamp loaded, such as 1475262069042, based on user's clock.
//    }
//
//    leastQuantity() {
//        let quantities = (this.parts || []).map( (p) => p.quantity );
//        return _.min(quantities);
//    }
//
//}
//
