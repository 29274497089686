import React from 'react';
import ShippingForecast from "../../models/ShippingForecast";

export default class CartItemAgentStockMessage extends React.Component {
  getStockCount() {
    let {forecast = {}} = this.props;
    let {estimate} = forecast;

    let shippingForecast = new ShippingForecast(forecast)
    return shippingForecast.stockCountForShipper(estimate.shipperId);
  }

  render() {
    let {forecast = {}} = this.props;
    let {estimate} = forecast;

    if (!estimate)
      return null;

    let {shipperName} = estimate;

    return <div className="inventory">
      <span className="fa fa-tachometer"/>
      {' ' + shipperName + ': '}
      {this.renderMessage(forecast, estimate)}
    </div>
  }

  renderMessage(forecast, estimate) {
    let count = this.getStockCount();

    if (count > 0) {
      return <span><strong>{count}</strong> in stock </span>
    } else if (estimate.willMake) {
      return <em> can ship </em>
    } else {
      return <span><strong>0</strong> in stock </span>
    }
  }
}
