import React, {useState, useEffect, useMemo} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {selectFilteredGridItems} from "../logic/filterBucketLogic";
import {selectPageOfItems, sortGridItems} from "../logic/itemSelection";
import GridWithControls from "./GridWithControls";

export default function GridItemsProvider({
  gridId,
  gridState,
  gridItems,
  filterState={},
  // sidebarOpen,
  // currentAgent,
  // customerVehicle,
  // vehicleContext,
  ...props
}) {
  const sortOrder = gridState.sort;

  const {filteredItemIds=[]} = filterState;

  // filteredItems: a SORTED list of those that survived filtering.
  // Get the list of filtered items here.
  const filteredItems = useMemo(
    () => {
      let items = selectFilteredGridItems({
        gridItems, filteredItemIds
      });

      // filter logic on the fritz? return everything.
      if (_.isEmpty(items)) items = gridItems;

      return sortGridItems(items, sortOrder);
    }, [gridItems, filteredItemIds, sortOrder]);

  // visibleItems: filteredItems, limited by sortOrder.
  // also supports a pagination feature
  const visibleItems = selectPageOfItems(filteredItems,
    gridState.pageSize,
    gridState.currentPage || 1);

  return <GridWithControls
    gridId={gridId}
    gridState={gridState}
    filterState={filterState}
    gridItems={gridItems}
    filteredItems={filteredItems}
    visibleItems={visibleItems}
    {...props}
  />

}
