import EmbeddedConfig from "../lib/EmbeddedConfig";
import {boldlyLog} from "../Utility/logging";

const isDev = (process.env.NODE_ENV == 'development');

/**
 * Abstract base class for things like SpeakerToKlaviyo,
 * SpeakerToMaxmind, etc.
 *
 */
export default class SpeakerToAnything {
  constructor(opts = {}) {
    _.extend(this, opts);
  }

  startup(page) {
    try {
      if (this.checkKillSwitch())
        return;

      this.preconfigure();

      if (this.disabled()) {
        console.log(`${this.constructor.name}: service is disabled, will not load script`);
        // this.setError(`${this.constructor.name}: service is disabled.`);
        return;
      }

      this.loadScript();
    } catch (ex) {
      this.setError(`${this.constructor.name}: startup error: ` + ex);
    }
    return this;
  }

  // 2022-05 some of these modules are suppressed for agents, like Justuno
  getAgent() {
    const conf = new EmbeddedConfig();
    let agent = conf.fetch('current_agent', null);
    return (agent && agent.id) ? agent : null;
  }

  api() {
    this.setError(`${this.constructor.name}: forgot to implement self.api()!`);
    return null;
  }

  withApi(fn) {
    let api = this.api();
    if (api)
      return fn(api);
    else {
      this.setError(`${this.constructor.name}: called withApi(fn) but api is not available.`);
    }
  }

  // on startup, look for disable_(name) in back_end_vars config,
  // like disable_live_chat (this is in the seo asset pages)
  checkKillSwitch() {
    let {back_end_vars} = global;
    if (!back_end_vars || !this.name) {
      console.error("cannot find service config for name=" + this.name);
      return false;
    }

    let kill_switch = "disable_" + this.name;

    if (back_end_vars[kill_switch]) {
      // console.log("service " + this.name + " is disabled");
      this._disabled = true;
    }
    return this._disabled;
  }

  setEnabled(enabled) {
    // console.log(`${this.name}: setting enabled=` + enabled);
    this._disabled = !enabled;
  }

  disabled() {
    return (this._disabled || false);
  }

  getInfo() {
    return {
      loaded: this.isLoaded(),
    }
  }

  // Get the config for a service from the back_end_vars object
  getServiceConfig(service) {
    let {back_end_vars} = global;
    if (!back_end_vars) {
      return this.setError("no back_end_vars config in window");
    }

    if (!service)
      service = this.name;

    let cfg = back_end_vars[service];
    if (!cfg) {
      return this.setError("no " + service + " config in back_end_vars object");
    }
    return cfg;
  }

  getName() {
    if (this && this.name)
      return this.name;

    return (this && this.constructor) ? this.constructor.name : 'NoClass';
  }

  log(msg) {
    let name = this && this.getName();
    console.log(`${this.getName()} log: ${msg}`);
  }

  setError(msg) {
    let name = this && this.getName();

    this.error = msg;
    console.error(`${this.getName()} error: ${msg}`);
    // return value should be undefined, as we often 'return setError(foo)' from something
    //return undefined;
  }

  isLoaded() {
    return false;
  }

  preconfigure() {
    // Do things that have to be done before the
    // Script loads, like set API keys,
    // register callbacks...
  }

  getScriptAttributes() {
    // some of them want additional attrs like id
    // return nothing by default here
  }

  getScriptUrl() {

  }

  loadScript() {
    let url = this.getScriptUrl();

    if (!url)
      return false;

    boldlyLog(`${this.name}: attempt to load url ${url}`)
    if (isDev) {
      alert(`${this.name}: attempt to load url ${url}`)
    }

    let node = this.createScriptNode(url);

    if (!node) {
      return this.setError("Cannot create Script node; url is " + url);
    }

    // this.log("adding async script: " + url);
    this.injectScriptNode(node);
  }

  injectScriptNode(script) {
    let sib = document.getElementsByTagName('script')[0];
    sib.parentNode.insertBefore(script, sib);
  }

  /**
   *
   * @param url
   * @param attrs
   * @returns {null|any}
   */
  createScriptNode(url, attrs = null) {
    if (!url)
      return null;

    if (!attrs)
      attrs = this.getScriptAttributes();

    let node = document.createElement('script');
    node.type = 'text/javascript';
    node.async = true;
    node.src = url;
    _.extend(node, attrs); // any more arbitrary attrs?

    return node;
  }
}
