import {updateCurrentUrl} from "../../../Utility/urls";

export const GRID_PARAMS = {
  currentPage: 'page',
  sort: 'sort',
  pageSize: 'ps',
  compare: 'cmp'
}

export function updateGridPageUrl({gridId, params}) {
  params = wrapGridParams({gridId, params});
  return updateCurrentUrl({params})
}

/**
 * For any grid but product-grid-1, the main one on the page,
 * suffix the parameters with :2, :3, etc.
 *
 * @param gridId
 * @param params
 * @returns {*|{}}
 */
export function wrapGridParams({gridId, params}) {
  params ||= {};

  if (!gridId) {
    console.warn("wrapGridParams: gridId missing");
    return {};
  }

  return _.mapKeys(params, (v, k) => gridParamName(gridId, k));
}

export function gridParamName(gridId, name) {
  if (!gridId || !name) return null;

  const suffix = ("" + gridId).replaceAll(/[^0-9]+/g, '');

  if (suffix && (+suffix > 1)) {
    return `${name}:${suffix}`
  } else {
    return name;
  }
}

export function buildParamsFromGridState({
  gridId, gridState
}) {
  if (!gridId || !gridState)
    return {};

  const params = {};
  const {sort, compareList} = gridState;
  const page = +(gridState.currentPage || 0);

  params[GRID_PARAMS.sort] = (sort === 'rel') ? null : sort;
  params[GRID_PARAMS.currentPage] = (page > 1) ? page : null;

  const pageSize = gridState.pageSize;

  if (isNaN(pageSize))
    params[GRID_PARAMS.pageSize] = pageSize;
  else if (+pageSize === 24)
    params[GRID_PARAMS.pageSize] = null;
  else
    params[GRID_PARAMS.pageSize] = pageSize;

  params[GRID_PARAMS.compare] = _.isEmpty(compareList) ? null : compareList.join(",");
  return _.mapKeys(params, (v, k) => gridParamName(gridId, k));
}
