//
// AgentTools - present in LocalApi for all users,
// but only does anything if the toolbar is physically
// present in the page - rails renders it only if logged in.
//
import LocalUrlMaker from "../lib/LocalUrlMaker";

export default class AgentTools {
    constructor() {
        this.$toolbar = $('#agent-toolbar');
        if (this.$toolbar[0]) {
            this.initWidgets();
        }
    }

    initWidgets() {
        $('#agent-reset').click(function (evt) {
            if (confirm("Reset vehicle and disconnect from cart?")) {
                window.location = LocalUrlMaker.prefixedPath("/agent_home?reset=1");
            }

            return false;
        });

        // respond to pull-down cart code list selection ,
        // by copying it into the text field and submitting form.
        this.$cart_code_picker = $('#agent-cart-pick-code')
            .change( (evt) => this.pickCartCode(evt) );
    }

    // respond to list box select by copying & submitting
    pickCartCode() {
        let cart_code = this.$cart_code_picker.val();

        if (cart_code) {
            let $form = this.$cart_code_picker.parents('form');
            $form.find('input[name=cart_code]').val(cart_code);
            return $form.submit();
        }
    }
}
