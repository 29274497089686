import LevelList from "../../models/LevelList";

export default class ClusterAutoSelect {
  constructor({name, cluster, appliedItems}) {
    this.name = name; // selector level name like "fitment"
    this.cluster = cluster;
    this.appliedItems = appliedItems;
  }

  get levelList() {
    return new LevelList(this.cluster.levels);
  }

  /**
   *
   * @param clusterState - immer proxy for object, feel free to modify it
   */
  call(clusterState) {
    // Proceed only if we have one product and one more selector ahead...
    const productIds = _.keys(clusterState.matchingProducts);

    if (_.isEmpty(productIds)) {
      // console.warn(`ClusterAutoSelect(${this.name}) - ERROR, matchingProducts is empty`);
      return false;
    }

    if (!this.penultimate()) {
      // console.log(`ClusterAutoSelect(${this.name}) - fail, not penultimate`);
      return false;
    }

    if (productIds.length !== 1) {
      // console.log(`ClusterAutoSelect(${this.name}) - fail, ${productIds.length} prods remain`);
      return false;
    }

    const productId = productIds[0];

    // ...and one matching application
    const apps = this.getMatchingApps(clusterState.selections, productId);
    if (apps.length !== 1) {
      // console.log(`ClusterAutoSelect(${this.name}) - fail, ${apps.length} applications remain for ${productId}`);

      // let ids = apps.map((a) => a.id);
      // console.log("matching product ids: " + productIds.join(' '));
      // console.log(`ClusterAutoSelect: ${apps.length} apps remain, cannot select: ` + _.join(ids, ", "))
      return false;
    }

    if (apps[0]) {
      let appId = apps[0].id;
      let appliedItem = this.appliedItems.getAppliedItem(appId);

      if (appliedItem) {
        clusterState.selections['application_id'] = appId;
        clusterState.selectedItem = appliedItem;
        return true;
      }
    }

    return false;
  }

  hasOneProduct(clusterState) {
    let productCount = _.size(clusterState.matchingProducts);

    return (productCount === 1);
  }

  penultimate() {
    let futureLevels = this.levelList.levelsAfter(this.name);

    return futureLevels && (futureLevels.length === 1);
  }

  getMatchingApps(selections = {}, productId) {
    // console.log(`Get matching apps for ${productId}`, selections);

    let apps = this.appliedItems.getAppsForProduct(productId);

    // 2024-05 do case-insensitive / trimmed comparison, due to
    // badly cleaned data ("5-lug" on app record vs "5-Lug" on fitment record)
    const fitment = selections.fitment ?
      _.toLower(""+selections.fitment).trim() : null;

    if (fitment) {
      apps = apps.filter(app => {
        const appFitment = _.toLower(""+app.fitment_text).trim();
        let result = (fitment === appFitment);
        console.log(`ClusterAutoSelect: '${fitment}' ${result ? '==' : '<>'} '${appFitment}'`);
        return result;
      });
    } else {
      console.log(`ClusterAutoSelect: no fitment, not filtering apps`)
    }

    return apps;
  }

}


