/**
 * JUSTUNO.
 *
 * clear your JU session with #juProfile
 *     https://lisa.aagarage.net/#juProfile
 *  testing with matt+20240828a@autoaccessoriesgarage.com
 */
import SpeakerToAnything from "./SpeakerToAnything";

import {saveUserToCart} from "../User/stashedUser";
import {sendKlaviyoIdentify} from "./Klaviyo/KlaviyoHelpers";

import {setEmailUser, stashUser} from "../ReduxStore/userSlice";
import {getDispatch} from "../Utility/ReduxHelper";

window.SEU = setEmailUser;

// typeCode like 10
// email like "matt+20240828a@autoaccessoriesgarage.com"
// coupon INSIDER
export function justunoCallback(typeCode, email, coupon) {
  let user = {email, source: 'ju', juData: {typeCode, coupon}};

  try {
    let dispatch = getDispatch();

    if (dispatch) {
      console.log("justunoCallback - stashing user in redux: ", user);
      dispatch(setEmailUser(user));
    } else {
      console.error("justunoCallback - no store");
      stashUser(user);
    }

    sendKlaviyoIdentify({
      user,
      attrs: {_debug: 'juCallback'}
    });

    saveUserToCart(user);
  } catch (err) {
    console.error("justunoCallback error: " + err)
  }
}

globalThis.ju_callback = justunoCallback;

export default class SpeakerToJustuno extends SpeakerToAnything {
  isLoaded() {
    // A juapp does not exist - never invoked loader function
    // B external script failed - loader function will still
    //   create a simple window.juapp like:
    //   ƒ () { (i[r].q = i[r].q || []).push(arguments);}
    // C external script succeeded - window.juapp is replaced
    //  with a much bigger function.

    return (typeof globalThis.juapp == "function");
  }

  preconfigure() {
    if (this.getAgent()) {
      console.log("SpeakerToJustuno: Agent detected, suppressing Justuno init.");
      this.setEnabled(false);
      return false;
    }
  }

  /**
   * After acquiring user from Justuno, send
   * to Klaviyo and our own stash.
   */
  saveUser(email) {
    console.error("DEPRECATED saveUser!")
  }

  handleEvent(action, promoId) {
    console.log("Handle Justuno Event:", action, promoId)
  }

  api() {
    return window.juapp;
  }
}
