
/*
 * 2018-12 refactored from LocalApi
 */

import Orchestrator from '../../lib/Orchestrator';
import SubscriberList from './SubscriberList';
import IdleTimer from './IdleTimer';
import PageLeaveWatcher from './PageLeaveWatcher';

export default class PageEvents extends Orchestrator {
    // Create event subscriptions right away.
    constructor() {
        super();

        this.resize_subscribers =
            new SubscriberList('onResize');

        this.scroll_subscribers =
            new SubscriberList('onScroll');

        this.body_click_subscribers =
            new SubscriberList('onBodyClick');

        this.idle_tick_subscribers =
            new SubscriberList('onIdleTick');

        // FIXME: name is a bit inconsistent
        this.page_leave_subscribers =
            new SubscriberList('onLeave');
    }

    startup(page) {
        let self = this;

        const w = $(globalThis);

        // Watch for resise events and send to resize subscribers
        w.bind('resize',
            (evt) => self.resize_subscribers.notifyAll(evt));

        // watch for scroll events and send to scroll subscribres
        w.bind('scroll',
            (evt) => self.scroll_subscribers.notifyAll(evt));

        // watch for body click events and send to body click subscribers
        // (example - menu system - someone clicks body when menu is open)
        $('#super-container,#footer').click(
            (evt) => self.body_click_subscribers.notifyAll(evt));

        // watch for idleness and send to idle tick subscribers
        // (currently none?)
        this.idle_timer = new IdleTimer({
            subscribers: this.idle_tick_subscribers
        });

        // watch for page leave events and send to page leave subscribers
        // example - cart save watcher in shopping cart
        this.page_leave_watcher = new PageLeaveWatcher({
            subscribers: this.page_leave_subscribers
        });

        // Do a fake resize event right away, so all subscribers learn the window size
        this.resize_subscribers.notifyAll();

        return this;
    }

    subscribeEvent(name, callback) {

    }
}
