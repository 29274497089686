import ReviewGauge from './ReviewGauge';

export default class ReviewCritic {
    constructor($textarea) {
        this.$textarea = $textarea;
        this.$textarea.on('change paste blur keyup', (function (_this) {
            return function (evt) {
                return _this.changed(evt);
            };
        })(this));
        this.score = 'empty';
        this.gauge().update(0);
    }

    gauge() {
        return this._gauge || (this._gauge = new ReviewGauge($('#review-quality-gauge')));
    }

    readWords() {
        let text, words;
        text = "" + this.$textarea.val();
        words = text.replace(/[^a-zA-Z0-9 ]/g, '').split(/\s+/);
        if (words.length === 1 && words[0] === "") {
            return [];
        }
        return words;
    }

    updateQuality() {
        let q;
        this.words = this.readWords();
        q = new ReviewQuality(this.words);
        this.logQualityChange(q);
        return this.quality = q;
    }

    logQualityChange(new_quality) {
        let new_score;
        new_score = new_quality.score;
        if (this.score === new_score) {
            return;
        }

        return this.score = new_score;
    }

    reviewValid() {
        this.words = this.readWords();
        return this.words && this.words.length > 0;
    }

    changed(evt) {
        return this.displayStatus();
    }

    displayStatus() {
        this.updateQuality();
        this.displayVerdict();
        return this.gauge().update(this.words.length);
    }

    displayVerdict() {
        let old_message;
        this.$verdict || (this.$verdict = $('#review-verdict'));
        this.$verdict.attr('class', this.quality.score);
        old_message = this.$verdict.text();
        if (old_message === this.quality.message) {
            return;
        }
        return this.$verdict.text(this.quality.message);
    }
}

class ReviewQuality {
    constructor(words1) {
        this.words = words1;
        if (this.words === null) {
            this.words = [];
        }
        this.score = this.analyseQuality();
        this.message = this.getMessage();
    }

    analyseQuality() {
        let length;
        length = this.words.length;
        if (length < 1) {
            return "empty";
        }
        if (length < 8) {
            return "short";
        }
        if (length < 20) {
            return "better";
        }
        if (length < 30) {
            return "good";
        }
        if (length < 40) {
            return "very_good";
        }
        return "awesome";
    }

    getMessage() {
        this.messages = {
            empty: "",
            short: "Too short...",
            better: "Getting better...",
            good: "Pretty good...",
            very_good: "Very good!",
            awesome: "Awesome! Now that's a Review!"
        };
        return this.messages[this.score];
    }
}
