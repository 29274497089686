import {camelizeKeys} from "../ReduxStore/Helpers/transformObject";
import LocalUrlMaker from "../lib/LocalUrlMaker";

export function getOverview({overview, shoppingCart}) {

  if (shoppingCart && shoppingCart.cartCode) {
    return {
      cartCode: shoppingCart.cartCode,
      itemCount: (shoppingCart.cartItems || []).length,
      subtotalCents: shoppingCart.effectiveSubtotal
    }
  } else if (!_.isEmpty(overview)) {
    let result = camelizeKeys(overview || {});
    result.itemCount = +result.itemCount;
    return result;
  }

  // failed, return something reasonable
  return {cartCode: null, itemCount: 0}
}

export function getAgentParams({currentAgent}) {
  if (currentAgent && currentAgent.username) {
    return {
      utm_source: `AAG-CSR-${currentAgent.username}`,
      utm_medium: "Phone-Order",
      utm_campaign: "AAG-Phone",
      // utm_content: "reset"
    }
  }

  return {};
}

export function getCartUrl({cartCode, currentAgent = null}) {
  let basePath = cartCode ? `/cart/${cartCode}` : "/cart";

  if (currentAgent && currentAgent.username) {
    basePath = basePath + "?" + new URLSearchParams(
      getAgentParams({currentAgent})).toString();
  }

  return LocalUrlMaker.prefixedPath(basePath);
}
