import React, {Component} from "react";

/**
 * Small-print note under the mat image.
 * Renders nothing if source item has no note.
 */
export default class PartNumberNote extends Component {
  render() {
    let {lloydItem={} } = this.props;

    let {part_number_note} = lloydItem;
    if (!part_number_note)
      return null;

    part_number_note = part_number_note.replaceAll("|", ' - ');

    return (<p className="note"><strong>Note:</strong>{' '}
      <em dangerouslySetInnerHTML={{__html: part_number_note}}/></p>);
  }
}
