/**
 * Encapsulates the Lloyd data that defines one option (color, binding, etc.)
 *
 */
import OptionValue from "./OptionValue";

export default class OptionDefinition {
  // top level keys (pre-camelizing) are:
  // ["group_class", "value_class", "type", "image",
  // "description", "group_list", "sub_product_data", "name"]
  constructor(data) {
    data = camelize(data);

    // camelize second level hash members too
    data = _.mapValues(data, (v, _k) =>
      (typeof v === 'object') ? camelize(v) : v);

    _.extend(this, data);
  }

  /**
   *
   * @returns {Array<Object>}
   */
  getGroups() {
    let groupList = this.groupList;
    if (!Array.isArray(groupList))
      groupList = _.values(groupList);

    return _.map(groupList, (g) => {
      g = Object.assign({}, g);
      g.value_list = this.convertValues(g.value_list);
      return g;
    })
  }

  allValues() {
    return this.convertValues(_.flatMap(this.getGroups(), (g) => g.value_list));
  }

  isEmpty() {
    return _.isEmpty(this.allValues());
  }

  convertValues(list) {
    return list.map((val) => new OptionValue(this.name, val))
  }
}

const camelize = (hash) => _.mapKeys(hash, (v, k) => _.camelCase(k));
