import React from 'react';
import GridItem from "./GridItem";

/**
 * Return a FRAGMENT - a list of rendered items.
 *
 * @param dispatch
 * @param gridState
 * @param items
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function GridItemsList({
  gridId,
  dispatch, gridState, items = [],
  startIndex = 0,  // offset for indexes, especially below-the-fold
  ...props
}) {

  startIndex = +startIndex;

  if (!items?.map) {
    console.error("GridItemsList: expected items=(Array), got: ", items);
    return null;
  }

  items = _.uniqBy(items, i => i.id);

  // return FRAGMENT. We cannot have them all inside one div when
  // in the regular ProductGroupGrid.
  return (
    <>
      {items.map((item, i) => <GridItem key={item.id}
        gridId={gridId}
        dispatch={dispatch}
        item={item}
        index={i + startIndex}
      />)}
    </>
  );
}
