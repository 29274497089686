import PropTypes from 'prop-types';
import _ from 'lodash';
import React from 'react';

import GridItemRank from "./GridItemRank";
import GridItemPicture from "./GridItemPicture";
import PromoBadge from "./PromoBadge";
import LocalUrlMaker from "../../../lib/LocalUrlMaker";

import {reportGridItemClick} from "../../../Analytics/googleGridAdapter";
import GridItemTitle from "./GridItemTitle";
import GridItemDesc from "./GridItemDesc";
import GridItemCompare from "./GridItemCompare";

const isDev = (process.env.NODE_ENV == 'development');

// 2023-11 modernised
export default function GridItem({
  gridId,
  item,
  dispatch, // needed for compare buttons
  showRanks = false,
  flavour,
  index = -1, // printed onscreen if showRanks is true; also used for reporting
  gridState = {},
  ...props
}) {

  if (!item)
    return null;

  // used by GridItemPicture, GridItemInfo
  const handleClick = (e) => {
    reportGridItemClick({
      e, item,
      flavour: flavour,
      index: index
    });

    // IMPORTANT: do default action for a click (follow the url)
    return true;
  }

  const path = LocalUrlMaker.prefixedPath(item.path);

  const {promos = []} = item;

  return (
    <div className={getClassNames({item}).join(' ')} id={`group-${item.id}`}>
      <div className="inner">

        {promos.map((promo) =>
          <PromoBadge key={promo.type} promo={promo} item={item}/>)}

        {showRanks && <GridItemRank index={index}/>}

        <GridItemPicture item={item} path={path} handleClick={handleClick}/>

        <div className="p-2">
          <GridItemTitle item={item} path={path} handleClick={handleClick}/>
          <GridItemDesc item={item} path={path} handleClick={handleClick}/>
          {gridState.showCompare && <GridItemCompare
            gridId={gridId}
            item={item}
            dispatch={dispatch}
            gridState={gridState}
          />}
        </div>

        {isDev && <div className="debug"
          style={{position: 'absolute', right: 0, bottom: 0, fontSize: '0.7rem'}}>{item.id} {item.pt} {item.rel}</div>}
      </div>
    </div>
  );
}

function getClassNames({item}) {
  let classNames = ["grid-item"];
  if (_.includes(item.tags, "suggest")) {
    classNames.push('tag-suggest')
  }
  return classNames;
}

GridItem.propTypes = {
  item: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  //dispatch: PropTypes.function.isRequired
}
