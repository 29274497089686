import React from 'react';
import PropTypes from 'prop-types';
import LocalUrlMaker from "../lib/LocalUrlMaker";

export default function SnowplowsCartIcon(props) {
  let {cartCode, cartUrl, itemCount} = props;

  cartUrl ||= LocalUrlMaker.prefixedPath(`/cart/${cartCode}`)

  // no count icon, so I do &&false
  return (
    <div className="">
      <a href={cartUrl} rel="nofollow" className="text-white">
        <i className="fas fa-shopping-cart"/>
        {' '} View Cart
        {(+itemCount) > 0  && false &&
          <span className="">{itemCount}</span>}
      </a>
    </div>
  );
}


