import React from 'react';
import LocalUrlMaker from "../../../lib/LocalUrlMaker";

// Presented under the Add button when an item is already in the cart.
export default function CheckoutLink({cartCode, shoppingCart}) {
  if (!cartCode && shoppingCart)
    cartCode = shoppingCart.cartCode;

  const url = LocalUrlMaker.prefixedPath(`/cart/${cartCode || ''}`);

  return <div className="checkout-link">
    <div className="headline-text">Item Added to Cart</div>
    <div className="action-link"><a href={url}>Go to Checkout</a>
    </div>
  </div>
}
