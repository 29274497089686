// Functions for working with the groupCollection object, as used
// on the View Cart page.

export function getGroup(collection, groupId) {
  collection ||= {};

  let value = collection["" + groupId] || collection[+groupId];
  if (value)
    return value;

  const keys = Object.keys(collection);
  console.log(`group ${+groupId} not found in collection, has groups ${keys}`);
  return {};
}
