import AgentStatusDisplay from './AgentStatusDisplay';
import AgentStatusTimer from './AgentStatusTimer';
import CallerCartHelper from './CallerCartHelper';

import EmbeddedConfig from '../lib/EmbeddedConfig';
import LocalUrlMaker from "../lib/LocalUrlMaker";

//
// maintains state, provides outer div,
// which might be empty.
//
const isDev = (process.env.NODE_ENV == 'development');

export default class AgentStatusContainer extends React.Component {
  state = {}

  reload = () => {
    this.setState({timer: new AgentStatusTimer()}, () => this.loadAgentStatus())
  }

  callbacks = {
    reload: this.reload
  }

  componentDidMount() {
    this.loadAgentStatus();

    // 2019-01 - in dev mode disable the timer entirely,
    // just to avoid the visual clutter in console
    if (isDev) {
      console.error("AgentStatusContainer - disabling automatic reload in dev mode");
      return;
    }

    let ticker = window.setInterval(() => this.timerTick(), 1000);

    this.setState({
      timer: new AgentStatusTimer(),
      ticker: ticker
    });
  }

  stopTimer() {
    if (this.state.ticker)
      window.clearInterval(this.state.ticker);
    this.setState({timer: null})
  }

  timerTick() {
    let {timer} = this.state;
    timer = timer ? timer.tick() : new AgentStatusTimer();

    // after half an hour of being idle, assume the agent has gone home,
    // and stop hammering the server with stupid requests.
    if (timer.elapsed > 1800) {
      this.stopTimer();
      return;
    }

    if (timer.isZero()) {
      this.setState({timer: timer}, () => { this.loadAgentStatus() });
    } else {
      this.setState({timer: timer});
    }
  }

  statusUrl() {
    let {agent = {}} = this.state;

    let d = new Date();
    let ext = agent.phone_extension;
    if (!ext || isNaN(ext))
      ext = Math.floor(Math.random() * 9000 + 1000);

    let id = (+ext * 1000000) + (Math.floor(d.getTime() / 1000) % 1000000);
    let url = LocalUrlMaker.jsonPath(`/agent_status/${id}.json`); // /agent_status/1518114136214
    return url;
  }

  loadAgentStatus() {
    let {timer = {}, agent = {}} = this.state;

    // agent_status/numeric always returns current agent status. The 'id' is
    // meaningless.
    // We're going to shove something random in there to prevent caching.
    // Caching this is bad.
    let d = new Date();
    let url = this.statusUrl();
    // console.log(`Load status at ${timer.elapsed} seconds on ${url}`);

    $.ajax({    // FIXME JQUERY
      method: 'get',
      url: url,
      // these params are also for cache-busting
      data: {ext: agent.phone_extension, now: d.getTime() + "." + d.getMilliseconds(),},
      success: (response) => {
        this.receiveStatus(response);
      },
      //error: (xhr, status, err) => {
      //  alert("error: " + err);
      //  this.stopTimer();
      //}
    })
  }

  //
  // Receive the agent status - including phone call - from back-end
  //
  receiveStatus(response) {

    if (typeof response === "string") {
      alert("Unexpected response from server. Try logging out and logging in again");
      this.stopTimer();
      return;
    }

    // Explicitly grab phone call - it may be null! That lets us overwrite
    // previous.
    let {phone_call, agent, caller_id, cart_followup} = response;

    // BAD KLUGE - disable the agent-cart-code up above.
    // FIXME JQUERY
    if (phone_call)
      $('#agent-cart-code, #agent-cart-code-go').attr('disabled', true);
    else
      $('#agent-cart-code, #agent-cart-code-go').removeAttr('disabled');

    let newState = {phone_call, agent, caller_id, cart_followup, loading: false};

    // new in 2020: send cart followup.
    newState.caller_cart_helper = new CallerCartHelper({
      phone_call: phone_call,
      cart_followup: cart_followup,
      // cart id/code that the agent already had, perhaps before the call.
      // often blank.
      agent_cart_id: this.props.agentCartId,
      agent_cart_code: this.props.agentCartCode
    });

    this.setState(newState);
  }

  // as of 2020-03 this looks like:
  // ["callbacks", "phone_call", "agent", "caller_id", "loading", "caller_cart_helper"]
  getChildProps() {
    return {...this.state};
  }

  render() {
    let {phone_call={}} = this.state;
    let childProps = this.getChildProps();

    return <div id="agent-status-app">
      <AgentStatusDisplay {...childProps} />
    </div>;
  }
}
