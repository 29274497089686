import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {findModalContent} from "./modalHelpers";
import Modal from "./Modal";


const HiddenModalManager = ({...props}) => {

  const [content, setContent] = useState(null);

  const handleTriggerClicked = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();

    const trigger = evt.target;

    const contentSource = findModalContent(trigger, 0);
    if (contentSource) {
      let clone = contentSource.cloneNode(true)
      if (clone.classList)
        clone.classList.remove('hidden-modal-content');

      setContent(clone);
    } else {
      setContent(null);
      console.error("Failed to find modal content");
    }
  }

  // STARTUP - bind all the triggers in the page.
  useEffect(() => {
    let triggers = document.getElementsByClassName('hidden-modal-trigger');
    for (let t of triggers) {
      t.onclick = handleTriggerClicked;
    }
  }, []); // run only once

  const handleCloseModal = (evt) => {
    setContent(null);
  }

  if (!content) return null;

  return <Modal
    handleClose={handleCloseModal} contentNode={content} />
}

// HiddenModalManager.propTypes = { }

export default HiddenModalManager;

