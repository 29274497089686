import React from 'react';
import {reportGridEvent} from "../../../Analytics/googleGridAdapter";
import groupGridSlice from "../../../ReduxStore/GroupGrid/groupGridSlice";
import {useDispatch} from "react-redux";

// 2023-11 cleaned up, brand sort fixed, Relevance label added
// 2022-07 updated for groupGridSlice
const sortKeys = [
  "default", "rel", "new", "price", "price_reverse",
  "rating", "name", "brand", "pop",
];

const labels = {
  default: "Sort By", // probably Relevance
  rel: "Relevance",
  new: "Newest",
  price: "Price: Low to High",
  price_reverse: "Price: High to Low",
  rating: "Customer Rating",
  name: "Alphabetical",
  brand: "Brand",
  pop: "Top Sellers"
};

export default function GridSortControl({
  gridId,
  gridState = {},
  gridItems
}) {
  const {sort = 'default', previousSort = null} = gridState;
  const dispatch = useDispatch();

  const handleChange = (e) => {
    let newSort = e?.target?.value;

    if (!newSort || (newSort === 'default'))
      newSort = "rel";

    const payload = {
      gridId,
      gridState: {
        sort: newSort,
        previousSort: sort,
        sortTouched: true,
        // currentPage: 1,
      }
    };

    dispatch(groupGridSlice.actions.updateGridState(payload));

    reportGridEvent({
      eventType: 'product_grid_sort',
      gridItems: gridItems,
      gridState: gridState,
      params: {sort_order: newSort}
    });
  }

  // used only for the widget, so we know to show "Sort by" or "Relevance"
  const widgetValue = gridState.sortTouched ? sort : "default";

  return (
    <div className="grid-sort-control">
      {' '}
      <select name="sort" value={widgetValue} onChange={handleChange}>
        {sortKeys.map((k) =>
          <option key={k} value={k}>{labels[k] || k}</option>)}
      </select>
    </div>
  );

}
