import React, {Component} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import LloydMatChooser from "../ItemChooser/LloydMatChooser";
import LloydFormBottom from "../FormBottom/LloydFormBottom";
import LloydOptionsSection from "../OptionBuilder/LloydOptionsSection";
import LloydCatalogue from "../models/LloydCatalogue";
import LocalUrlMaker from "../../../lib/LocalUrlMaker";

// For debugging - a fixed position yellow overlay that shows cart
// import CartDebug from "../FormBottom/CartDebug";
//
// let isDev = (process.env.NODE_ENV == 'development') || true; //FIXME

/**
 * Internals of a LloydContainer.
 */
export default class LloydUI extends Component {
  // ["callbacks","customerVehicle","vehicleChoices","itemsUrl",
  // "itemApplications","dispatch","lloyd","cart"]

  static propTypes = {
    callbacks: PropTypes.object.isRequired,
    customerVehicle: PropTypes.object,
    // vehicleChoices // dont care?
    // itemsUrl // dont care
    // itemApplications, dispatch // dont care
    cart: PropTypes.object.isRequired,
    lloyd: PropTypes.instanceOf(LloydCatalogue).isRequired,
    // products: PropTypes.object.isRequired,  // keyed by product id
    // lloydProducts: PropTypes.object.isRequired,
    submitting: PropTypes.bool
  }

  static defaultProps = {
    submitting: false
  }

  getClasses() {
    let {cart, lloyd} = this.props;

    let cartCount = cart.items.count();
    let cartErrors = cart.validate({lloyd: lloyd});

    let submitEnabled = (cartCount > 0) && _.isEmpty(cartErrors);

    let classes = [
      ((cartCount > 0) ? "items-in-cart" : "no-items-in-cart"),
      (submitEnabled ? "submit-enabled" : "submit-disabled")
    ];

    return classes;
  }

  // not yet in react 15
  // static getDerivedStateFromProps(props, state) {
  //
  // }

  render() {
    let {cart, callbacks, lloyd, submitting} = this.props;

    // Show items only if every item has its product_unique selected.
    // let showOptions = (!cart.isEmpty()) &&
    //   _.every(cart.itemValuesForOption('product_unique'));

    let cartErrors = cart.validate({lloyd: lloyd}); //FIXME
    let url = new LocalUrlMaker().prefixedPath('/cart_items');
    
    return (

      <div id="lloyd-products" className={this.getClasses().join(' ')}>
        <form method="post" id="lloyd-cart-form" action={url}>

          <LloydMatChooser
            cart={cart}
            lloyd={lloyd}
            callbacks={callbacks}
          />

          <LloydOptionsSection
            cart={cart}
            lloyd={lloyd}
            callbacks={callbacks}
          />


          <LloydFormBottom
            cart={cart}
            callbacks={callbacks}
            cartErrors={cartErrors}
            submitting={submitting}/>
        </form>
      </div>
    );

    // for debug add this somewhere:
    // {isDev && <CartDebug cart={cart} lloyd={lloyd}/>}
  }

  renderPickItemsPrompt() {
    return <div className="pick-items-prompt">
      Select your mat locations and details above to see customization options.
    </div>
  }

}
