import React from 'react';

import SaveCartNameInput from '../components/SaveCartNameInput';
import SaveCartEmailInput from '../components/SaveCartEmailInput';
import SaveCartMailingListInput from '../components/SaveCartMailingListInput';
import SaveCartTitle from "../components/SaveCartTitle";
import SaveCartSubmit from "../components/SaveCartSubmit";
import CloseSaveCart from "../components/CloseSaveCart";
import ValidationError from "../components/ValidationError";

// REQUESTED - invoked by user pressing the button.
// This version has a plain white background and no logo,
// but has three inputs - name, email, mailing-list checkbox -
// while the automatic versions have none of these.

export default function SaveCartRequestedContents(props) {
  return <div className="inner">
    <SaveCartTitle theme={props.theme} logo={false} title="Save Cart" />

    <div className="save-cart-prompt">
      <p className="instructions">Enter your email below and we'll send you a link to your cart.</p>
    </div>

    <ValidationError saveCartStatus={props.saveCartStatus} />
    <div className="save-cart-form-contents">
      <SaveCartNameInput {...props} />
      <SaveCartEmailInput {...props} />
      <SaveCartMailingListInput {...props} />
    </div>

    <SaveCartSubmit {...props} />
    <CloseSaveCart {...props}/>
  </div>
}

