import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import ValidAddressDisplay from "./ValidAddressDisplay";


/**
 * ReviewAddress - give a brief summary of address with oppportunity to change it (go back)
 *
 * @param headline
 * @param address
 * @param editUrl
 * @returns {JSX.Element|null}
 * @constructor
 */
export default function ReviewAddress(props) {
  const {headline, address, changeAddress, usage} = props;

  if (_.isEmpty(address))
    return null;

  return (<div className="review-address review-shipping-address">
    <div className="address-caption">
      <h3>{headline}</h3>
    </div>

    { (address.city && address.last_name) ?
      <ValidAddressDisplay {...props} /> :
      <InvalidAddressDisplay usage={usage} address={address} changeAddress={changeAddress} /> }
  </div>);
}

ReviewAddress.propTypes = {
  usage: PropTypes.string.isRequired,
  impediments: PropTypes.array,
  // if 'false', suppress the change link
  // changeAddress: PropTypes.oneOf([PropTypes.func, PropTypes.bool])
}

ReviewAddress.defaultProps = {
  headline: "Ship To: ",
  changeAddress: false, // function() {console.log("changeAddress is null")}
  impediments: []
}

function InvalidAddressDisplay({address, changeAddress}) {
  return <div className="address address-bad">
    <strong>WARNING: </strong>
    Your address is not set. Please
    {' '}<a className="click-me" onClick={changeAddress}>go back</a>{' '}
    and enter your shipping address.
  </div>
}
