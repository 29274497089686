import braintree from 'braintree-web';

const isDev = (process.env.NODE_ENV == 'development');

export function withBraintree(callback) {
  if (!braintree) {
    console.error("braintree-web missing");
    return 'fail';
  }

  if (!isDev) {
    // FIXME: in prod only, continue to set the deprecated window.braintree
    globalThis.braintree ||= braintree;
  }

  return callback ? callback(braintree) : braintree;
}

export function getDataCollector({api} = {}) {
  return withBraintree(braintree => {
    const {dataCollector} = braintree;

    if (!dataCollector) {
      console.error("Braintree library lacks dataCollector: ", braintree);
      return null;
    }
  });
}

/**
 *
 * @param token
 * @returns {Promise<never>|*}
 */
export function requestBraintreeClient({token}) {
  const {client} = braintree;

  let error = null;
  if (!client || !client.create) {
    error = "braintree.client.create undefined";
  } else if (!token) {
    error = "no braintree token, cannot create client";
  }

  if (error) {
    console.error(`requestBraintreeClient: ${error}`);
    alert(error);
    return Promise.reject(new Error(error))
  }

  return client.create({authorization: token});
}
