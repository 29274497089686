import PageLogic from '../PageLogic';

import PhotoUploader from '../PhotoUploader/PhotoUploader';

// dependencies: google charts -
// %script{src: "https://www.gstatic.com/charts/loader.js" }

import CreateReviewForm from './CreateReviewForm';

export default class NewReviewPage extends PageLogic {

    startup(localApi) {
        this.loadGoogleCharts();
        this.review_form = new CreateReviewForm($('#new_product_photo'));
    }

    loadGoogleCharts() {
        let { google } = window;
        if (! google || !google.charts) {
            console.log("google charts not loaded, will try again");
            window.setTimeout( () => this.loadGoogleCharts(), 200);
            return;
        }

        google.charts.load('current', {packages: ['gauge']});
    }

}
