import React from 'react';
import _ from 'lodash';

import GenericSmartyWidget from "./GenericSmartyWidget";
import AutocompleteResults from "./AutocompleteResults";

export default class SmartApartmentWidget extends GenericSmartyWidget {

  componentDidUpdate(prevProps, prevState, snapshot) {
    let newAddr = _.get(this.props, 'address._autocomplete_addr');
    let oldAddr = _.get(prevProps, 'address._autocomplete_addr');

    if (!_.isEqual(newAddr, oldAddr)) {
      this.setState({suggestions: []}, () => {
        this.fetchUnits({addr: newAddr});
        // console.error("FIXME - FOCUS ON APARTMENT WIDGET");
        this.focusInput();
      })
    }

  }

  /**
   * Fetch apartment numbers for a street address.
   *
   * @param fullAddr {Object}
   * @returns {boolean}
   */
  fetchUnits({addr, secondary, search}) {
    if (!addr || !addr.streetLine) return false;

    let street = `${addr.streetLine} (${addr.entries})`;
    console.log("using street " + street);

    let lookup = this.state.smartyLookup || this.buildSmartyLookup();

    if (!secondary)
      secondary = addr.secondary;

    if (!search)
      search = addr.streetLine;

    lookup.call({
      query: search,
      options: {
        selected: `${addr.streetLine} ${secondary} (${addr.entries}) ${addr.city} ${addr.state} ${addr.zipcode}`,
        // includeOnlyZIPCodes: [ addr.zipcode ]
      },
      onSuccess: ({response, results, ...stuff}) => {
        this.setState({suggestions: results, response: response})
      }
    })

    // this.requestAutocomplete(fullAddr);
  }

  /**
   * @override
   * @param evt
   */
  onChange = (evt) => {
    let {address = {}} = this.props;

    let {value} = evt.target;

    this.setState({value: value}, () => {
      let sourceAddr = address._autocomplete_addr;

      if (sourceAddr) {
        sourceAddr = Object.assign({}, sourceAddr, {entries: 100});

        this.fetchUnits({
          addr: sourceAddr,
          secondary: value,
          search: `${sourceAddr.streetLine} ${value}`
        })
      }

      this.afterChange();
      // this.requestAutocomplete(value)
    });
  }

  onSelectAddress = ({evt, addr}) => {
    this.setState({suggestions: []});

    if (addr && addr.secondary) {
      this.setState({
        value: addr.secondary,
        suggestions: []
      }, () => { this.sendUpdate() })
    }
  }

  renderAutocomplete() {
    const {address} = this.props;
    const {suggestions} = this.state;

    let baseAddr = address._autocomplete_addr;
    let caption = "Unit Number (optional)";
    if (baseAddr) {
      caption = `At ${baseAddr.streetLine}, ${baseAddr.city}` || caption;
    }

    return <AutocompleteResults
      suggestions={suggestions}
      caption={caption}
      unitsOnly={true}
      onSelectAddress={this.onSelectAddress}/>
  }

}
