// 2023-05 use new univeral-cookies, not jquery.cookie
import Cookies from "universal-cookie";

export default class CartCodeCookie {
  constructor() {
    this.parseCookie();
  }

  parseCookie() {
    let cookies = new Cookies();
    let value = cookies.get('sc2');

    return this.parseText(value);
  }

  parseText(text) {
    if (text && text.length > 0) {
      let words = text.split('/');
      this.cart_code = words[0];
      this.item_count = +words[1];
    } else {
      this.cart_code = null;
      this.item_count = null;
    }

    return +this.item_count;
  }
}
