import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import clsx from "clsx";
import {presenceOr} from "../Utility/logic";

// FIXME: needs current item to gernate link

export default function Paginator({
  paginatorConfig, itemCount, itemIndex, callbacks, ...props
}) {

  return <PaginatorWrapper
    callbacks={callbacks}
    paginatorConfig={paginatorConfig}
  >
    <InnerPaginator
      paginatorConfig={paginatorConfig}
      itemCount={itemCount}
      itemIndex={itemIndex}
      callbacks={callbacks}
      {...props} />
  </PaginatorWrapper>
}

/**
 * This is an invisible, full-width stripe, which was really the only way to
 * absolutely position something and center it.
 *
 * @param children
 * @param callbacks
 * @param paginatorConfig
 * @param props
 * @returns {Element}
 * @constructor
 */
function PaginatorWrapper({
  children,
  callbacks,
  paginatorConfig={}, ...props
}) {
  const classes = presenceOr(paginatorConfig.wrapperClasses, [
    //'bg-yellow-400',
    'absolute',
    'w-full',
    'text-center',
    'hidden',
    'md:block'
  ]);

  const styles = presenceOr(paginatorConfig.wrapperStyles, {
    bottom: '1rem',
    left: 0,
    right: 0
  });

  return <div onClick={callbacks.handleClick}
    style={styles} className={clsx(classes)}>{children}</div>
}

function InnerPaginator({paginatorConfig={}, itemCount, itemIndex, callbacks={}}) {
  const classes = presenceOr(paginatorConfig.classes, [
    'inline-block',
    'w-auto',
    'mx-auto',
    'py-2',
    'px-8',
    'text-black',
    'text-center',
    'bg-gray-50',
    'opacity-75',
    'rounded-full'
  ]);

  const styles = presenceOr(paginatorConfig.styles, {
    // border: '3px solid grey',
    // width: 'auto',
    //maxWidth: '50%',
    //margin: 'auto',
  });

  return <div style={styles} className={clsx(classes)}
    onClick={e => e.stopPropagation()}>
    {_.times(itemCount).map(i =>
      <PaginatorLink key={i}
        paginatorConfig={paginatorConfig}
        itemIndex={itemIndex}
        callbacks={callbacks}
        page={i}
      />)}
  </div>
}

export function PaginatorLink({
  page, itemIndex, paginatorConfig, callbacks,
}) {
  const classNames = [
    'mx-1',
    'text-lg',
    "inline-block",
    "w-3",
    "h-3",
    "rounded-full",
    "cursor-pointer",
    (page===itemIndex) ? "bg-brand-orange" : "bg-gray-400"
  ];

  return <a className={clsx(classNames)}
    onClick={ () => callbacks.setItemIndex(page) }/>
}
