import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {loadChooserOptions} from "./chooserOptionsSlice";

const isDev = (process.env.NODE_ENV == 'development');

// public utility function - merge a new choice into choices, returning choices.
export function mergeNewChoice({name, value, levels, choices = {}}) {
  // clone for immutability
  choices = Object.assign({}, choices);

  // Clear the additional choices after this level.
  if (Array.isArray(levels)) {
    let n = levels.indexOf(name);
    if (n > -1) {
      for (let k of levels.slice(n + 1)) {
        delete choices[k];
      }
    }
  }

  // and assign the new one
  choices[name] = value;

  return choices;
}

// public utility function - build choices from a customerVehicle


// Async action creator.
// Called from VehicleChooserContainer when a selector level choice is made
export const setVehicleChoice = createAsyncThunk(
  "setVehicleChoice",
  (payload, thunkAPI) => {

    const {name, value, levels, choices = {}, productContext} = payload;

    // Update the hash of choices, which looks like
    // { year: 2021, make: 'Toyota', etc. }
    // choices = mergeNewChoice({name, value, choices, levels});
    // console.log("set vehicle choices:", choices);

    // now update our own state, also saving the productContext
    thunkAPI.dispatch({
      type: "vehicleChoicesSlice/setVehicleChoices",  // plural!
      payload: {choices, productContext}
    });

    if (!levels || (name == 'reset')) {
      if (isDev) {
        alert("vehicleChoicesSlice: no levels or name=reset");
        console.log("vehicleChoicesSlice: no levels or name=reset", );
      }
      return;

    }

    // now send those same choices to the vehicle options loader,
    // which will determine the URL and load the list
    let nextLevel = levels[1 + levels.indexOf(name)];
    if (nextLevel) {
      thunkAPI.dispatch(loadChooserOptions({
        level: nextLevel,
        params: [productContext, choices]
      }));
    }
  }
);

export const vehicleChoicesSlice = createSlice({
  name: 'vehicleChoicesSlice',
  initialState: {choices: {}},

  reducers: {
    setVehicleChoices: (state, action) => {
      const {payload} = action;

      Object.assign(state, payload);
      console.log("vehicleChoices updated state to ",
        Object.assign({}, state));
      return state;
    }
  },
  extraReducers: builder => {
    // clear our choices on reset vehicle
    // builder.addCase(
    //   "vehicleSlice/setVehicle",
    //   (state, action) => {
    //   const payload = camelizeKeys(action.payload);
    //   if (!payload.customerVehicle)
    //     state.choices = {};
    //
    //   window.FIXME1 = Object.assign({}, payload);
    //
    //     console.error(`[vehicleChoicesSlice] DEPRECATED SET VEHICLE CLEARING; keys=${JSON.stringify(_.keys(payload))}`);
    //
    //   });

    // 2024-04 migrate to clearVehicle.
    // When clearing the vehicle, clear the choices object.
    builder.addCase(
      "vehicleSlice/clearVehicle",
      (state, action) => {
        console.log(`[vehicleChoicesSlice] clearVehicle called: ${JSON.stringify(action.payload)}`)
        state.choices = {};
        if (state.options) {
          // FIXME: it would be better if we knew the levels list,
          // but I'll just clear fitment options
          delete state.options.fitment;
        }
      }
    );
  },

})

export default vehicleChoicesSlice;
