import React from 'react';
import LocalUrlMaker from "../../../lib/LocalUrlMaker";
import DynamicAddButton from "../DynamicAddButton";

export default function AddSingleItemUi(props) {
  let {product, dispatch, flavour} = props;
  if (!product) return null;

  let url = LocalUrlMaker.jsonPath("/cart_items");

  // skip displaying sku when we have the dummy button for google
  const displaySku = (flavour !== 'non-universal-item');
  // {displaySku && <SingleItemSkuDisplay product={product} />}

  return (
    <div className="add-single-item">

      <DynamicAddButton dispatch={dispatch} product={product} flavour={flavour}/>

    </div>
  );
}


