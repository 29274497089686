import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import cartSlice from "../../../ReduxStore/ShoppingCart/cartSlice";

// 2022-11 display error message from the back end.
const CartItemErrorDisplay = ({itemErrors = {}, item, callbacks, dispatch, ...props}) => {
  if (_.isEmpty(itemErrors) || !item)
    return null;

  let id = item.id;
  let error = itemErrors["" + id] || itemErrors[id];

  if (!error) return null;

  const dismissError = () => {
    let newErrors = _.omit(itemErrors, +id, '' + id);
    if (dispatch && cartSlice.actions.update) {
      dispatch(cartSlice.actions.update({itemErrors: newErrors}));
    } else {
      console.error("CartItemErrorDisplay: dispatch or cartSlice.actions.update is missing");
    }
  }

  return (
    <div className="dark-red error click-me">
      <a onClick={dismissError}><i className="fa fa-lg fa-times"/></a>{' '}
      {error}
    </div>
  );
}

// CartItemErrorDisplay.propTypes = { }

export default CartItemErrorDisplay;

//this is CartItemErrorDisplay with props ["callbacks","currentAgent","featureFlags","cartCode","now",
// "authToken","coreCart","saveCartStatus","shoppingCart","dynamicCart","forecasts","itemErrors","customerVehicle","item"]
