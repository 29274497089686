import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {getNewItems, useNewItemListChange} from "../logic/cartHelpers";
import {facebookTrack} from "../../Facebook/facebookHelpers";

export default function FacebookAddToCartReporter(props) {

  useNewItemListChange(props => {
    let newItems = getNewItems(props);
    // let groupIds = newItems.map(i => i.productGroupId);

    let contents = newItems.map(item => {
      return {
        id: item.facebookSku || item.productGroupId,
        quantity: item.quantity
      }
    });

    facebookTrack('AddToCart', {contents});
  }, props);

  return null;
}

FacebookAddToCartReporter.propTypes = {
  shoppingCart: PropTypes.object.isRequired,
  newItemIds: PropTypes.array.isRequired
}
