import React from 'react';
import HideButton from "./HideButton";

function RemoveCell({item, callbacks}) {
  let path = item.path;

  return <th>
    <div className="more-details"><a href={path}>More Details</a></div>
    <HideButton item={item} callbacks={callbacks}/>
  </th>
}

const RemoveItemsRow = ({items, callbacks, ...props}) => {
  if (items.length <= 1)
    return null;

  return (
    <tr>
      <th className="empty"></th>
      {items.map((item, i) => <td className="remove" key={item.id}><HideButton item={item} callbacks={callbacks}/></td>)}
    </tr>
  );
}

// TableHead.jsx.propTypes = { }

export default RemoveItemsRow;

