import React from 'react';

export default function RequestReceiptFormContents({
  form, handleCancel, result = {}, ...props
}) {

  const succeeded = result.status === 'success';

  const {register, formState} = form;
  const {errors} = formState;

  return <div>

    <div>
      <label htmlFor="email">Email:</label>
      <input
        type="email"
        placeholder={"Email Address"}
        readOnly={succeeded}

        {...register('email', {
          required: "Email address is required.",
          pattern: {value: /^\S+@\S+$/i, message: 'Not a valid email address'}
        })}
      />
      {errors.email &&
        <div className="text-red-600 text-bold pb-2">{errors.email.message}</div>}

    </div>

    <div className="grid grid-cols-2">
      <div className="p-2">
        {!succeeded &&
          <button className="w-full p-3 bg-brand-orange text-white text-lg text-bold"
            type="submit">Send Receipt
          </button>}
      </div>

      <div className="p-2">
        {handleCancel &&
          <button type="button"
            onClick={handleCancel}
            className="w-full p-3 bg-white text-gray-400 text-lg text-bold border-2 border-gray-400">
            {succeeded ? 'Close' : 'Cancel'}
          </button>}
      </div>

    </div>
  </div>
}
