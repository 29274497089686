import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {markItemReported, selectUnreportedItemIds} from "./ReportedItemsStore";
import GoogleNewItemsReporter from "./GoogleNewItemsReporter";
import FacebookNewItemsReporter from "./FacebookNewItemsReporter";
import CatchErrors from "../../../Utility/CatchErrors";

export default function NewItemsReporter({shoppingCart, ...props}) {
  const cartItems = shoppingCart?.cartItems;

  const [newItemIds, setNewItemIds] = useState([]);

  useEffect(() => {
    if (!cartItems) return;

    let itemIds = cartItems.map(item => item.id);
    let newIds = selectUnreportedItemIds(itemIds)
      .map(id => ('' + id));

    setNewItemIds(newIds);
  }, [shoppingCart, cartItems])


  if (_.isEmpty(newItemIds) || _.isEmpty(cartItems))
    return null;

  let newItems = cartItems.filter(item => _.includes(newItemIds, '' + item.id))

  if (_.isEmpty(newItems)) {
    console.warn("found no new items, expected " + newItemIds.join(' '));
    return null;
  }


  return (
    <CatchErrors invisible={true}>
      <GoogleNewItemsReporter shoppingCart={shoppingCart} newItems={newItems}/>
      <FacebookNewItemsReporter shoppingCart={shoppingCart} newItems={newItems}/>
      <ResetNewItemIds newItemIds={newItemIds} setNewItemIds={setNewItemIds} />
    </CatchErrors>
  );
}

// Clear the list - do it in the child component to guarantee that this will
// render exactly once, then clear the list.
function ResetNewItemIds({newItemIds, setNewItemIds}) {
  useEffect(() => {
    if (_.isEmpty(newItemIds))
      return;

    for (let id of newItemIds) {
      markItemReported(id);
    }

    setNewItemIds([]);
  }, [newItemIds]);

  return null;
}
