import React, {Component} from "react";
import PropTypes from "prop-types";

export default class LloydMatCaption extends Component {
  static propTypes = {
    lloydItem: PropTypes.object.isRequired,
  }

  render() {
    let {lloydItem, cartItem} = this.props;

    let product = lloydItem.product;
    if (!product) {
      return <div className="caption">
        Product not found
      </div>
    }

    let price = new Money(product.regular_price_cents);
    let desc = (lloydItem.package_content_description || "")
      .replaceAll("|", ' - ');

    return <div className="caption">
      <span className={"far fa-lg " + (cartItem ? 'fa-check-square' : 'fa-square')}></span>
      {desc}
      {" "} <span className="base-price">{"" + price}</span>
    </div>;
    // for debug include this before /div:
    // <span className="debug"> {lloydItem.lloydCode.toString()}</span>
  }
}
