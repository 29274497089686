import {
  buildCartItemsList, buildProductList,
  cartItemToAdobeCartItem,
  cartItemToAdobeProductInfo,
  cartItemToCartAdobeItem
} from "./adobeCart";
import {sendAdobeEvent} from "./adobeAnalyticsHelpers";

const isDev = (process.env.NODE_ENV == 'development');


/**
 *
 * @param cartItems
 * @param shoppingCart
 * @returns {{eventInfo: (*|{})}}
 */
export function sendAddToCartEvent({
  shoppingCart, items
}) {
  const eventInfo = {
    eventName: "addToCart",
    type: "cart add"
  }

  const event = buildAdobeCartEvent({
    shoppingCart, eventInfo,
    withProductList: items
  })

  sendAdobeEvent(event);

  return event;
}

export function sendRemoveFromCartEvent({
  items, // the newly removed item(s),
  cartItems,
  shoppingCart,
}) {
  const eventInfo = {
    eventName: "removeFromCart",
    type: "cart remove"
  }

  const event = buildAdobeCartEvent({
    shoppingCart, cartItems, eventInfo,
    withProductList: items
  })

  event.product = simplifyProductList(event.product);

  return sendAdobeEvent(event);
}

export function sendViewCartEvent({
  shoppingCart, cartItems,
}) {
  const eventInfo = {
    eventName: "NOLACartView",
    type: "cart view"
  }

  const event = buildAdobeCartEvent({
    shoppingCart, cartItems, eventInfo,
    withCartItems: cartItems
  });

  // if (isDev) {
  //   alert("send adobe event: " + JSON.stringify(eventInfo, null, 2));
  // }

  return sendAdobeEvent(event);
}

export function sendStartCheckoutEvent({
  shoppingCart, cartItems,

}) {
  cartItems ||= shoppingCart.cartItems;

  const eventInfo = {
    eventName: "Cartcheckoutstart",
    // type: "cart view"
  }

  const event = buildAdobeCartEvent({
    shoppingCart, cartItems, eventInfo,
    withProductList: cartItems
  });

  return sendAdobeEvent(event);
}

export function buildAdobeCartEvent({
  shoppingCart,
  eventInfo = {},
  withProductList = null,
  withCartItems = null,
  event = {}
}) {
  eventInfo = Object.assign({}, eventInfo); // clone it.
  //eventInfo.type ||= "cart view";
  eventInfo.eventName ||= "NOLACartView";
  eventInfo.timeStamp ||= new Date().toISOString();
  eventInfo.processed ||= {adobeAnalytics: false};

  event = Object.assign({eventInfo}, event);

  if (withCartItems && withCartItems.map) {
    event.Cart ||= {}
    event.Cart.item = buildCartItemsList({items: withCartItems, shoppingCart})
  }

  if (withProductList && withProductList.map) {
    event.product = buildProductList({items: withProductList, shoppingCart});
  }

  return event;
}


function simplifyProductList(list) {
  if (list && list.map) {
    list = list.map(item => {
      item.productInfo = simplifyProductInfo(item.productInfo);
      return item;
    });
  }
  return list;
}

function simplifyProductInfo(productInfo) {
  return _.pick(productInfo,
    "productID", "currency", "cartId", "sellingPrice",
    "quantity", "fulfillmentMethod",
    "productHQID", "searchPlatform")
}
