import React, {Component} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

const isDev = (process.env.NODE_ENV == 'development');

/**
 * VehicleChangeWatcher - this component watches its properties for
 * changes in the customerVehicle. When this happens, it loads products.
 *
 */
export default class VehicleChangeWatcher extends Component {
  static propTypes = {
    customerVehicle: PropTypes.object,
    watchedFields: PropTypes.array.isRequired,
    onVehicleChanged: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired
  };

  static defaultProps = {
    // FIXME camelCase
    watchedFields: ['vehicle_make_id', 'fitment', 'lloyd_unique']
  };

  componentDidMount() {
    this.componentDidUpdate({}, {});
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let oldVehicle = prevProps.customerVehicle || {};
    let newVehicle = this.props.customerVehicle || {}
    let changes = this.compareVehicles(oldVehicle, newVehicle);
    // console.error("########## VehicleChangeWatcher - changes are " + JSON.stringify(changes));

    if (_.isEmpty(changes))
      return;

    // 2024-08 this is now a no-op, because KlaviyoInformant watches for vehicle changes.
    // 2022-05 report change in vehicle to Klaviyo
    // this.reportVehicle();

    this.vehicleDidChange({changes, oldVehicle, newVehicle});
  }

  vehicleDidChange({changes, oldVehicle, newVehicle}) {
    // on category pages this is in GridWithControls and does a reload.
    const {onVehicleChanged} = this.props;

    if (typeof onVehicleChanged == 'function') {
      return onVehicleChanged(changes);
    }

    if (typeof onVehicleChanged == 'object') {
      const {action, target} = onVehicleChanged;

      if (action == 'redirect') {
        return this.redirectOnChange({target, oldVehicle, newVehicle})
      }
    }

    console.error("unknown onVehicleChanged action: " +
      JSON.stringify(onVehicleChanged, null, 2))
  }

  redirectOnChange({target, oldVehicle, newVehicle}) {
    // 2022-08 do not redirect when setting initial vehicle
    if (_.isEmpty(newVehicle) || (newVehicle.source == 'initial')) {
      return false;
    }

    if (target) {
      // "set-vehicle=" + newVehicle.id;
      window.location = target + '?set-vehicle=1';

      return true;
    }
  }

  // compare old/new vehicles (either may be empty)
  compareVehicles(oldVehicle, newVehicle) {
    const {watchedFields = []} = this.props;

    oldVehicle = oldVehicle || {};
    newVehicle = newVehicle || {};

    let changes = {};
    // console.error("compare:" , oldVehicle, newVehicle);

    for (let k of watchedFields) {
      let old = oldVehicle[k];
      let value = newVehicle[k];
      if (old != value) {
        changes[k] = [old, value];
      }
    }

    return changes;
  }

  // watchedFields =
  render() {
    return <div className="vehicle-change-watcher"/>
  }
}

