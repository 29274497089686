import React from 'react';
import _ from 'lodash';
import GroupGridSidebar from "../sidebar/GroupGridSidebar";
import PropTypes from "prop-types";
import GridSidebarApp from "./GridSidebarApp";
import {FilterStateContext} from "../../../ReduxStore/GroupGrid/gridFilterSlice";
import CatchErrors from "../../../Utility/CatchErrors";

/**
 * This component takes the static filterSet / allFilterSelections
 * from the app's props, copies them to the dynamic filter state
 * in redux, then renders the child component WITHOUT those
 * properties.
 *
 * @param filterSet
 * @param allFilterSelections
 * @param dispatch
 * @param otherProps
 * @returns {JSX.Element}
 * @constructor
 */
export default function GridSidebarContainer({
  gridId,
  allGridStates = {},
  allFilterStates = {},
  relatedPages,
  thisPage
}) {
  const filterState = allFilterStates ? allFilterStates[gridId] : null;
  // const gridState = allGridStates ? allGridStates[gridId] : {};

  if (!gridId || _.isEmpty(filterState))
    return null;

  return <CatchErrors>
    <FilterStateContext.Provider value={filterState}>
      <GroupGridSidebar
        gridId={gridId}
        thisPage={thisPage}
        sidebarOpen={filterState.sidebarOpen}
        filterState={filterState}
        relatedPages={relatedPages}/>
    </FilterStateContext.Provider>
  </CatchErrors>

  //   linkBoxes={linkBoxes}

}

GridSidebarApp.propTypes = {
  // present on first run
  filterSet: PropTypes.object.isRequired,

  // present on subsequent runs
  filterMap: PropTypes.array,
  allFilterOptions: PropTypes.object,
  allFilterSelections: PropTypes.object,
  allFilterBuckets: PropTypes.object,

  // non-filter stuff
  relatedPages: PropTypes.object
}
