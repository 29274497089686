import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {cellClasses, cellStyles, STATUS_CLASSES} from "../constants";
import clsx from "clsx";
import NumberIcon from "./NumberIcon";
import PendingIcon from "../editor/PendingIcon";
import StatusIcon from "./StatusIcon";

export default function ZoidbergGridItem({image, columnIndex, callbacks}) {
  if (!image)
    return <div></div>

  const {status, sizeCode, updatePending} = image;

  const handleClick = evt => {
    // open image in editor.
    callbacks.setSelectedId(image.id);
  }

  let classNames = [
    "text-center p-2 relative",
    cellClasses(image)
  ];

  return (
    <div style={cellStyles(image)}
      className={clsx(classNames, "")}>

      <a className="block cursor-pointer relative bg-gray-50"
        style={{aspectRatio: 1.36}}
        onClick={handleClick}>
        <img className="inline-block centered mx-auto max-w-full"
          src={image.fullPath} alt={image.title || 'no title'}
          title={image.fullPath}/>
      </a>

      {!columnIndex && <NumberIcon ordinal={image.sortOrder}/>}

      { updatePending && <PendingIcon/>}

      <a onClick={handleClick}><StatusIcon classNames="centered text-4xl"
        image={image} status={status} /></a>

      <TitleDisplay image={image}/>

    </div>)
}

function TitleDisplay({image}) {
  let {title, sizeCode} = image;

  // suppress for large for space reasons, set it on all the others
  if (sizeCode === 'lg')
    return null;

  if (!title)
    return <div className="text-gray-200">no title</div>;

  return <div className="font-bold">{title}</div>
}
