// Price fields for the agents.
import React from "react";
import _ from "lodash";
import Money from "../../../lib/Money";
import AgentPercentDiscountField from "./AgentPercentDiscountField";
import AgentUnitPriceField from "./AgentUnitPriceField";

const NEUTRAL_STATE = {
  agentUnitPrice: null,
  agentPercentDiscount: null,
  agentUnitPriceFormatted: null,
  centsDiscount: null
};

function buildState(props) {
  let {item = {}} = props;

  let state = _.pick(item, "agentUnitPrice", "agentPercentDiscount");

  state.agentUnitPriceFormatted = new Money(state.agentUnitPrice).toDollars();

  return state;
}

export default class AgentPriceAssist extends React.Component {
  state = buildState(this.props);

  callbacks = {
    edit: (changes, fn) => {
      console.log("AgentPriceAssist.edit: ", changes);
      this.setState(changes, fn);
    },
    save: (changes) => {
      console.log("AgentPriceAssist.save: ", changes);
      let {item} = this.props;

      this.props.callbacks.updateItem({
        id: item.id,
        changes
      });

    }
  }

  componentDidUpdate(prevProps, prevState) {
    console.log("AgentPriceAssist did update", this.props.item);

    let a = _.pick(prevProps.item, "agentUnitPrice", "agentPercentDiscount")
    let b = _.pick(this.props.item, "agentUnitPrice", "agentPercentDiscount");
    if (_.isEqual(a, b)) return;

    this.setState(buildState(this.props))
  }

  // discount exceeds the private_ceiling?
  hasBadDiscount() {
    const {privateCeilingPercent = 0} = this.props.item;
    const {agentPercentDiscount = 0} = this.state;

    return (+agentPercentDiscount) > (+privateCeilingPercent);
  }

  render() {
    const {item, shoppingCart} = this.props;
    const {privateCeilingPercent = 0} = item;

    let classes = ["agents-only agent-price-assist"]
    if (this.hasBadDiscount())
      classes.push("max-discount-violation");

    // we send the formatted version to the widget, as this will
    // be the user's keystrokes when editing (or formatted value if from elsewhere)
    let {agentPercentDiscount, agentUnitPrice = 0, agentUnitPriceFormatted} = this.state;

    if (agentUnitPrice && !agentUnitPriceFormatted)
      agentUnitPriceFormatted = new Money(agentUnitPrice).toDollars();

    return <div className={classes.join(' ')}>
      <AgentPercentDiscountField
        item={item}
        maxPercent={privateCeilingPercent}
        callbacks={this.callbacks}
        shoppingCart={shoppingCart}
        value={agentPercentDiscount || ''}/>

      <AgentUnitPriceField
        item={item}
        maxPercent={privateCeilingPercent}
        callbacks={this.callbacks}
        shoppingCart={shoppingCart}
        value={agentUnitPriceFormatted || ''}/>
    </div>
  }

}
