/**
 * Wrapper for the POJSO data from Lloyd describing one value for an option.
 */
export default class OptionValue {
  constructor(attributeName, data) {
    Object.assign(this, data);
    this.attributeName = attributeName;
  }

  get label() {
    // color: name=Bamboo (560), description=null
    // border: name= White, description = null;
    // logo: name=null, description= "Porsche (849001-B) & Carrera (849005-B) Red on Black Dbl App"
    if (this.attributeName == 'color' || this.attributeName == 'border')
      return this.name;

    let label = this.name || this.description || "";

    // for logos, remove the crap in parentheses, and "App" at the end
    return _.trim(label)
      .replace(/\([^\)]*\)/ig, '')
      .replace(/ Dbl App/, '')
      .replace(/ App$/, '');
  }

  canSelectColor() {
    let threads = this.logo_thread_color;
    return (threads && threads.length > 0);
  }

}
