/*
 * 2018 - make self-referential urls using either configured site
 * name (from EmbeddedConfig) or window.location.  Most of the logic in here
 * is about figuring out the base url in several different ways; then
 * it just delegates to a UrlBuilder configured with that URL.
 *
 */

import UrlBuilder from "./UrlBuilder";
import OriginFinder from "./OriginFinder";

export default class LocalUrlMaker {
  static _instance = null;

  // Hi I am a weak singleton. Use global instance if you like or just call new()
  static instance() {
    if (! LocalUrlMaker._instance) {
      LocalUrlMaker._instance = new LocalUrlMaker();
    }
    return LocalUrlMaker._instance;
  }

  // static to singleton pass-thrus
  static jsonPath(arg) { return LocalUrlMaker.instance().jsonPath(arg); }
  static prefixedPath(arg) {  return LocalUrlMaker.instance().prefixedPath(arg);  }
  static url(arg) { return LocalUrlMaker.instance().url(arg); }

  // return full url - https://whatever
  url(path) {
    return this.getBuilder().url(this.toLocalPath(path));
  }

  toLocalPath(path) {
    const prefix = this.prefix;
    if (prefix && path) {
      if (!path.startsWith(prefix)) {
        path = prefix + path;
      }
    }
    return path;
  }

  // Preferred alias for JSON urls.
  jsonPath = this.toLocalPath;
  prefixedPath = this.toLocalPath;

  // look up the prefix.
  get prefix() {
    if (! LocalUrlMaker._prefix && (LocalUrlMaker._prefix != "")) {
      const { back_end_vars={} } = globalThis;
      const { url_prefix } = back_end_vars;

      LocalUrlMaker._prefix = url_prefix || "";
    }

    return LocalUrlMaker._prefix;
  }

  getBuilder() {
    return this._builder ||
      (this._builder = new UrlBuilder(this.getOrigin()));
  }

  getOriginFinder() {
    return this._origin_finder ||
      (this._origin_finder = new OriginFinder());
  }

  getOrigin() {
    return this._origin ||
      (this._origin = this.getOriginFinder().getOrigin());
  }
}
