import React from 'react';
import ContinueButton from "../form/ContinueButton";
import FetchPaypalToken from "../../paypal/FetchPaypalToken";

export default function PaypalFormSection(props) {
  const {checkout} = props;

  const handleSubmit = async (evt) => {
    if (evt) { evt.stopPropagation(); }

    let fetcher = new FetchPaypalToken({
      cartCode: checkout.cartCode, flavour: 'new_checkout'
    });

    await fetcher.requestToken()
      .then((r) => r.json())
      .then((payload) => fetcher.handleResponse(payload));
  }

  return (
    <div className="paypal-form-section">

      <p className="paypal-address-warning">
        <strong>Ship-To Address Note: </strong>

        Due to our agreements with PayPal, we can only ship
        to the address on your PayPal account. This will override
        any address entered on the previous screen. To ship elsewhere,
        choose a different payment method.
      </p>

      <p>Press the button below to continue checkout with PayPal.</p>

      <ContinueButton id="pay-with-paypal" caption="" onSubmit={handleSubmit}>
        <span>Pay with </span>
        <img className="inline" src="/img/badges/paypal_200.png" alt="Paypal Express checkout"/>
      </ContinueButton>
    </div>
  );
}

