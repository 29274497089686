import React from 'react';
import AttributeCell from "./AttributeCell";

const AttributeRow = ({attribute, items, values}) => {
  return (
    <tr className={`group-attr group-attr-${attribute.attrType || 'unknown'}`}>
      <th>
        {attribute.displayName || attribute.name}
      </th>
      {items.map((item) => <AttributeCell key={item.id} item={item} valueList={values[item.id]}/>)}
    </tr>
  );
}

export default AttributeRow;
