import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

export default function ChangeAddressLink({changeAddress, paypal = false}) {

  const handlePaypalClick = (evt) => {
    evt.preventDefault();

    let msg = "Due to our agreements with PayPal, " +
      "we can only ship to the address on your PayPal account. " +
      "This will override any address entered on the previous screen. " +
      "To ship elsewhere, choose a different payment method.";

    alert(msg);
  }

  if (paypal) {
    return <a className="click-me" onClick={handlePaypalClick}>change</a>
  } else if (changeAddress) {
    return <a className="click-me" onClick={changeAddress}>change</a>
  }

  return null;
}
