import React  from 'react';
import _ from 'lodash';
import clsx from "clsx";

export default function TabBar({sections, activeTab, setActiveTab}) {
  const keys = _.sortBy(_.keys(sections));

  return <ul className="inline-block mb-2 mx-2">
    {keys.map(k => <TabLink key={k} id={k}
      label={labelFor(k, sections)}
      active={activeTab === k}
      setActiveTab={setActiveTab}/>)}
  </ul>
}

function labelFor(k, sections = {}) {
  const list  = sections ? sections[k] : [];
  const largeCount = list.filter( i => i.sizeCode == 'lg').length;
  const smallCount = list.length - largeCount;

  return `${_.startCase(k)}: ${smallCount}+${largeCount}`;
}

function TabLink({id, active, setActiveTab, label}) {
  const handleClick = e => setActiveTab(id);
  const classes = clsx([
    'inline-block',
    'font-bold',
    'text-lg',
    'py-0 px-3',
    'mr-4',
    'rounded-full',
    active ? 'bg-brand-blue' : 'bg-gray-200',
  ]);

  const linkClasses = clsx([
    active ? 'text-white' : 'text-black'
  ]);

  label ||= _.capitalize(_.camelCase(id));

  return <li className={classes}><a
    className={linkClasses}
    onClick={handleClick}>{label}</a></li>
}
