import React, {Component} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import {addToCart} from "../../ReduxStore/ShoppingCart/cartSlice";
import ProductWrapper from "../logic/ProductWrapper";

export default function DynamicAddButton(props) {
  const {dispatch, product, productGroup, application, flavour, caption = "Add to Cart"} = props;

  let p = new ProductWrapper(product);
  let outOfStock = p.outOfStock();

  const handleClick = (evt) => {
    evt.preventDefault();

    if (_.isEmpty(product)) {
      alert("Please select all options before adding to cart.");
    } else if (outOfStock) {
      alert("Sorry, this product is out of stock.");
      // } else if (flavour === 'non-universal-item') {
      //   if (confirm("Are you sure?  This product may not fit your vehicle.")) {
      //     dispatch(addToCart({product, application}));
      //   }
    } else {
      dispatch(addToCart({product, application}));
    }
  }

  let disabled = props.disabled || _.isEmpty(product);

  return (
    <button type="button" className="btn add-to-cart-button" disabled={outOfStock || disabled}
      onClick={handleClick}>
      {outOfStock ? "Out of Stock" : caption}
    </button>
  );
}

DynamicAddButton.propTypes = {
  product: PropTypes.object,
  application: PropTypes.object, // optional, for universal
  dispatch: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  flavour: PropTypes.string
};

DynamicAddButton.defaultProps = {
  disabled: false,
  caption: 'Add to Cart',
  flavour: ''
};

