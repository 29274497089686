import ProductImageCollection from "./ProductImageCollection";

export default class ProductPicker {
  // items = array of ALL AppliedItems
  // clusterState
  constructor({products, itemCache, clusterState}) {
    if (clusterState) {
      // from cluster we get the selectedItem,
      // otherwise we'll fall back on matchingProducts.
      _.extend(this, _.pick(clusterState,
        'matchingProducts', 'selectedItem'));
    }

    this.itemCache = itemCache;
  }

  // Main entry point - will try multiple ways to return a product
  // with images.
  getProductForImage() {
    return this.getSelectedProduct() || this.getMatchingProductWithImages();
  }

  // Get the item selected by the user, after all of the filter
  // widgets have been applied. When this is true, this product
  // will be used for the images, even if it has none.
  getSelectedProduct() {
    let {selectedItem} = this;

    // selectedItem: AppliedItem with { application, product }
    if (selectedItem && selectedItem.product) {
      return selectedItem.product;
    }
  }

  getMatchingProducts() {
    let {matchingProducts = {}} = this;
    // like  ["253045239", "253045244"]
    let product_ids = _.keys(matchingProducts);
    if (_.isEmpty(product_ids)) {
      return _.values(this.itemCache.products);
    }
    return this.itemCache.getProductsById(product_ids);
  }

  // From the Matching Products (those that match the filtering criteria
  // selected by the user, ie Color=Black and Style=Bucket Seats,
  // return the first one with a small image.
  getMatchingProductWithImages(size = 'small') {
    let candidates = this.getMatchingProducts();

    // return first candidate product for which a small image exists
    return _.find(candidates, (product) => {
      let coll = new ProductImageCollection({product: product});
      return !!coll.getFirstImagePath(size);
    });
  }
}
