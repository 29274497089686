import React from 'react';
import PropTypes from 'prop-types';
import LocalUrlMaker from "../../lib/LocalUrlMaker";
import CartButtons from "./CartButtons";
import CartItemVehicle from "../../ShoppingCart/components/cart_contents/CartItemVehicle";

// 2023-11 added VehicleDisplay (from cart) - display a alert when vehicle missing
// 2023-04 modernized, camelized props
export default function NewItemDisplay({
  newItem, shoppingCart, handleClose, customerVehicle}) {

  if (!newItem)
    return null;

  //console.log("NEW ITEM", newItem);

  let price = new Money(newItem.effectiveUnitPrice || 0);

  let {smallImage} = newItem;

  return (
    <div className="new-item-display p-2">
      {smallImage && <div className="new-item-pic">
        <img className="inline-block"
          src={smallImage} alt={newItem.name || "New Item"}/>
      </div>}

      {newItem && <div className="new-item-details">
        <h3>{newItem.brandName} {newItem.name}</h3>

        <div className="price">{price.toString()}</div>
        <div className="shipping">Free shipping</div>

        <CartButtons handleClose={handleClose} cartCode={shoppingCart.cartCode}/>

        <CartItemVehicle item={newItem} quiet={true} />

        <VehicleLink vehicle={customerVehicle}/>
      </div>}

      <div className="clear-me"></div>
    </div>
  );
}

NewItemDisplay.propTypes = {
  newItem: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired
};

// Show a "Shop more Ford F150 accessories" link.
// vehicleUrl may be missing as we don't have pages for obscure vehicles
function VehicleLink({vehicle}) {
  if (!vehicle || !vehicle.vehicleUrl)
    return null;

  return <div className="vehicle-link">
    <a href={LocalUrlMaker.prefixedPath(vehicle.vehicleUrl)}>
      Shop more {' '}
      {vehicle.shortMakeName || vehicle.vehicleMakeName} {' '}
      {vehicle.shortModelName || vehicle.vehicleModelName}
      {' '}
      accessories</a>
  </div>
}
