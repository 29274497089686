/**
 * NOT FOR PROD.
 *
 * Print children and properties; used for testing component
 * embedding in tubes.
 */

import React from 'react';

export default function DemoComponent({children, ...props}) {
  return <div className="dumper-demo-component">
    <h4> Children: {_.size(children)} </h4>
    <div style={{minHeight: '2rem'}}>
      {children || null}
    </div>

    <h4> Properties: {_.size(props)}</h4>
    <pre>
    {JSON.stringify(props, null, 2)}
  </pre>
  </div>
}
