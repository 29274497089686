import React  from 'react';
import CartFields from "./CartFields";

export default function EnabledFormBottom(props) {

  let {callbacks} = props;

  return <section id="lloyd-item-section-bottom">
    <div id="lloyd-item-section-bottom-submit">
      <button type="button" className="btn" onClick={callbacks.submit}>Add to Cart</button>
    </div>
    <CartFields {...props} />
  </section>

}

