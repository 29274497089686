/**
 * Handle changes to the form values.
 */
import EditAddress from "./EditAddress";
import Address from "./Address";

export default class EditCheckout {
  /**
   *
   * @param checkout {Proxy}
   * @param payload {Object}
   */
  constructor({checkout, payload}) {
    this.checkout = checkout;
    this.payload = payload;
  }

  trace(msg) {
    // console.error("EDIT CHECKOUT - TRACE - " + msg)
  }

  applyChanges() {
    const {checkout, payload} = this;
    if (!checkout)
      return;

    this.trace("EDIT CHECKOUT " + JSON.stringify(payload));

    this.updateSimpleFields();

    this.updateAddresses();

    const { braintreeCheckout } = payload;

    // merge any new tokens in; this is used to save the braintree
    // payment nonce.

    if (! _.isEmpty(braintreeCheckout)) {
      console.error("editCheckout: braintreeCheckout contains: ", braintreeCheckout);
      checkout.braintreeCheckout = braintreeCheckout;
    }

    return this.checkout;
  }

  updateAddresses() {
    const {checkout, payload} = this;

    // these are set only when changing it.
    const { sameAddress=false, diffAddress=false } = payload;

    this.updateAddress(checkout.shippingAddress, payload.shippingAddress, 'shipping');
    this.updateAddress(checkout.billingAddress, payload.billingAddress, 'billing');

    if (sameAddress) {
      checkout.billingAddress = { id: checkout.shippingAddress.id };
    } else if (diffAddress) {
      checkout.billingAddress = {};
      // this.copyShippingToBilling();
    }
  }

  // Clone only the core data fields of the shippingAddress,
  // onto a billingAddress object, favouring values from the
  // previous billing address if any.
  copyShippingToBilling() {
    const { shippingAddress={}, billingAddress={} } = this.checkout;

    let newBill = {id: shippingAddress.id};
    for (let f of Address.CORE_FIELDS) {
      newBill[f] = billingAddress[f] || shippingAddress[f];
    }

    this.checkout.billingAddress = newBill;
  }

  updateSimpleFields() {
    let changes =  _.pick(this.payload,
      'deviceData', // a string from the Braintree data collector

      // hash of {first_name, last_name, email},
      // used only by the EmailWidget's autosave feature
      'customer',

      // sameAddress = true/false
      'sameAddress'
    );

    for (let key in changes) {
      console.error(`changing checkout ${key} to ${changes[key]}`)
      this.checkout[key] = changes[key];
    }
  }

  // Apply edits to an address.
  updateAddress(addr, edits, usage='unknown') {
    // this.trace("update address " + typeof(addr) + " with " + JSON.stringify(edits));

    if (_.isEmpty(edits))
      return;

    if (!addr) addr={};

    let editor = new EditAddress({
      usage: usage, object: addr, edits: edits
    })
    editor.call();
  }

}
