import React, {Component} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import LloydCart from "../models/LloydCart";
import OptionDefinition from "../models/OptionDefinition";
import OptionGroup from "../OptionBuilder/OptionGroup";


export default class LogoPicker extends Component {
  static propTypes = {
    cart: PropTypes.instanceOf(LloydCart).isRequired,
    option: PropTypes.instanceOf(OptionDefinition).isRequired
  }

  static defaultProps = {
    id: 'lloyd-logos',
    name: 'logo',
  }


  render() {
    let {option} = this.props;

    let values = option.allValues();

    return (
      <div className="inner">
        <OptionGroup {...this.props} values={values}/>
      </div>);
  }

}



