import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

// Wrap any content.  When not 'active', instead it will
// show a link to enable it.  Used for the agents-only
// Tax Exemption widget.

export default function ActivateLink(
  {
    children, onActivate = null, ...props
  }) {

  const [active, setActive] = useState(false);

  useEffect(() => {
      if (!active) return;

      if (onActivate)
        onActivate(props);
    }, [active]
  );

  const handleClick = (e) => {
    setActive(true);
  }

  if (!active) {
    const {prompt = "Click to activate"} = props;

    return (<a className="activate-link"
      onClick={handleClick}>{prompt}</a>);
  }

  const {tagName = 'div'} = props;

  const TagName = tagName; // avoid warnings
  return <TagName>{children}</TagName>
}
