import GroupPageType from "../models/GroupPageType";
import {loadGroupItems} from "../../ReduxStore/ProductGroupPage/groupItemsSlice";
import {safeGtag} from "../../Analytics/googleAnalyticsFour";
import {pushUrlState, editUrlParams} from "../../Utility/urls";

export default class VehicleChangeHandler {
  constructor({changes, customerVehicle, pageType, groupId, location}) {
    this.changes = changes || {};
    this.customerVehicle = customerVehicle;
    this.pageType = new GroupPageType(pageType)
    this.groupId = groupId;
    this.location = location || globalThis.location;
  }

  call({dispatch}) {
    this.onBaseVehicleChange({dispatch});

    // console.error("---------- Vehicle change handler - changes are ", this.changes);

    if (this.pageType.canLoadProducts(this.customerVehicle)) {
      // console.log("LOAD PRODS ")
      this.loadProducts({dispatch})
    } else {
      // console.log("CANNOT LOAD PRODS ");
    }
  }

  onBaseVehicleChange({dispatch}) {
    let change = this.changes['id'];
    if (!change) return null;


    return change[1] ? this.onSetVehicle() : this.onClearVehicle();
  }

  onSetVehicle() {
    let cv = this.customerVehicle;
    // console.log("ON SET VEHICLE", this.changes, this.customerVehicle);

    // 2022-06 do not redirect to ?set-vehicle=1 on initial load.
    if (cv && (cv.source == 'initial')) {
      return;
    }

    return this.rewriteLocation({'set-vehicle': 1, 'clear-vehicle': null})
  }


  onClearVehicle() {
    // this.customerVehicle is null already, nothing to extract from it
    safeGtag('event', 'clear_vehicle', {});

    return this.rewriteLocation({'set-vehicle': null, 'clear-vehicle': 1})
  }

  rewriteLocation(edits) {
    let url = editUrlParams(globalThis.location, edits);

    pushUrlState(url);
  }

  loadProducts({dispatch}) {
    // extract id = vehicle_id, fitment, lloyd_unique (the watched fields)
    // send to group items store

    let params = this.buildParams({group_id: this.groupId});

    dispatch(loadGroupItems({params: params}));
  }

  buildParams(base = {}) {
    const {customerVehicle} = this;
    const watchedFields = this.pageType.watchedFields();

    return _.reduce(
      watchedFields, (out, k) => {
        if (k == 'id')
          out['vehicle_id'] = customerVehicle[k];
        else
          out[k] = customerVehicle[k];
        return out;
      }, base);
  }

  handleModelChanged({dispatch}) {

  }
}
