import React from 'react';
import _ from 'lodash';
import GridItemsProvider from "./GridItemsProvider";
import CatchErrors from "../../../Utility/CatchErrors";
import {FilterStateContext} from "../../../ReduxStore/GroupGrid/gridFilterSlice";
import {GridStateContext} from "../../../ReduxStore/GroupGrid/groupGridSlice";

/**
 * The second-level component of this selects the gridState
 * and filterState for this particular grid, and renders the
 * child component.
 */
export default function ProductGridContainer({
  gridId,
  allGridStates,
  allFilterStates,
  // these props are passed to children
  ...props
}) {

  const gridState = allGridStates ? allGridStates[gridId] : null;

  // do not render until gridState is present.
  if (!gridState)
    return null;

  const filterState = allFilterStates ? allFilterStates[gridId] : {};

  return <CatchErrors>
    <FilterStateContext.Provider value={filterState}>
      <GridStateContext.Provider value={gridState}>
        <GridItemsProvider
          gridId={gridId}
          gridState={gridState}
          filterState={filterState}
          {...props}
        />
      </GridStateContext.Provider>
    </FilterStateContext.Provider>
  </CatchErrors>

}
