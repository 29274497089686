import {sum, isEmpty, mapValues} from 'lodash';
import {groupToGoogleItem} from "./googleAnalyticsItems";

/**
 * Used only on the OrderComplete page, this builds the "purchase" event for GA4.
 *
 * @param eventType
 * @param order
 * @param orderedItems
 * @param params
 * @param groupCollection
 * @returns {(string|string|({transaction_id, coupon: string, shipping: *, currency: string, tax: *, value: *} & {items: *[]}))[]|null}
 */
export function buildOrderEvent(
  {
    eventType = 'purchase',
    order = {},
    orderedItems = [],
    params = {},
    groupCollection = {}
  }) {

  if (isEmpty(order))
    return null;

  let googleItems = orderedItems.map((orderedItem, index) =>
    orderedItemToGoogleItem({orderedItem, order, index, groupCollection}));

  params = Object.assign({
    transaction_id: order.id,
    currency: 'USD',
    value: new Money(order.totalCents).toDollars(),
    coupon: order.couponCode,
    shipping: new Money(order.shippingCents || 0).toDollars(),
    tax: new Money(order.taxCents).toDollars()
  }, params, {items: googleItems});

  return ['event', eventType, params];
}

export function orderedItemToGoogleItem(
  {
    orderedItem, order, index, groupCollection = {}, productGroup
  }) {
  const {catalogPriceEachCents = 0, actualPriceEachCents = 0, productGroupId} = orderedItem;
  const discountCents = catalogPriceEachCents - actualPriceEachCents;

  productGroup ||= groupCollection["" + productGroupId] || groupCollection[+productGroupId];

  let groupStuff = groupToGoogleItem(productGroup);

  let discountUSD = (discountCents > 0) ?  +(new Money(discountCents||0).toDollars()) : 0;

  let itemStuff = {
    item_variant: `${orderedItem.productGroupId}-${orderedItem.vendorsku}`,
    quantity: orderedItem.quantity,
    index: index,
    item_variant_name: orderedItem.name,
    price: +(new Money(actualPriceEachCents).toDollars()),
    discount: +discountUSD,
    coupon: (discountCents>0) ? order?.couponCode : null,
    vehicle_make: orderedItem.vehicleMakeName,
    vehicle_model: orderedItem.vehicleModelName,
    vehicle_year: orderedItem.vehicleYear,
    vehicle_type: orderedItem.vehicleType
  };

  return mapValues(
    Object.assign({}, groupStuff, itemStuff),
    x => (x===null) ? '' : x);
}
