import {isDesktop} from "../lib/windowSize";

export function calculateItemsPerSlide(config, {width = 0}) {
  let {columns = 1, columnsLarge = 1} = config;

  let n = isDesktop(width) ? columnsLarge : columns;

  n = isNaN(n) ? 1 : (+n);

  return (n < 1) ? 1 : n;
}

export function calculatePageCount(itemCount, itemsPerSlide) {
  if (itemCount < 1)
    return 0;

  if (itemsPerSlide < 1)
    return 1;

  return Math.ceil(itemCount / itemsPerSlide);
}

export function calculateLeftPosition({
  config = {},
  itemsPerSlide = 1,
  itemOffset = 0
}) {
  const width = 100 / itemsPerSlide;
  const {gutter = 0} = config;
  const leftPos = (+itemOffset) * ((width) + gutter);
  return leftPos;
}
