import _ from 'lodash';

export default class ErrorEvent {
  constructor({message, file, line, col, error, path, module_name}) {
    if (!error) { error = {} };

    this.error = error;

    let { window, document, navigator } = globalThis;

    this.contents = {
      file: file,   // /packs/application-whatever.js
      line: line,   // numeric
      application: 'CanyoneroJS',
      module_name: module_name,
      error_type: (error.constructor ? error.constructor.name : 'Unknown'),
      message: message || error.message || error.toString(),
      path: path || ErrorEvent.getUrlPath(),
      backtrace: error && error.stack,
      referer: document && document.referrer,
      user_agent: navigator && navigator.userAgent
    }

  }

  getFields() {
    return this.contents;
  }

  static getUrlPath() {
    const { location } = globalThis;

    if (location && location.pathname) {
      return location.pathname;
    }
  }

  static toErrorEvent(thingy) {
    if (thingy instanceof ErrorEvent)
      return thingy;
    if (thingy instanceof Error) {
      return new ErrorEvent({message: thingy.message, error: thingy});
    }

    return new ErrorEvent(thingy); // decompose fields
  }
}
