import React, {useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import GridFilterActivator from "../controls/GridFilterActivator";
import RelatedPagesWidget from "./RelatedPagesWidget";
import _ from "lodash";
import StaticLinkBox from "./StaticLinkBox";
import FilterWidgetList from "../filters/FilterWidgetList";
import {useSkeletonLoading} from "../../../hooks/useSkeletonLoading";
import CatchErrors from "../../../Utility/CatchErrors";

export default function GroupGridSidebar({
  gridId,
  filterState={},
  relatedPages,
  thisPage,
  sidebarOpen = false,
  linkBoxes = [] // not used?
}) {
  const myRef = useSkeletonLoading();

  const {filterMap} = filterState;

  // FIXME: where does this come from
  const staticLinkBoxes = (linkBoxes || [])
    .filter(b => b && b.links && b.links[0]);

  const classNames = [
    "product-grid-menu", "side-slide-menu",
    (sidebarOpen ? 'slide-in' : 'slide-out')
  ];

  // GridFilterActivator provides a close button as a "X" icon, on the
  // page background outside the sliding widget. This handles changes to Open/Close status.
  return (
    <div ref={myRef} className={classNames.join(' ')}>

      <CatchErrors>
        <GridFilterActivator
          gridId={gridId}
          sidebarOpen={sidebarOpen}
          icon="fa fa-times"/>
      </CatchErrors>

      {!_.isEmpty(filterMap) && <CatchErrors>
        <div className="filter-widgets-wrapper">
          <FilterWidgetList
            gridId={gridId}
            filterState={filterState}
            thisPage={thisPage}
          />

          {!_.isEmpty(relatedPages) &&
            <RelatedPagesWidget relatedPages={relatedPages}/>}

          {staticLinkBoxes.map((box, i) =>
            <StaticLinkBox key={i} {...box} />)}
        </div>
      </CatchErrors>}

      <CatchErrors>
        <GridFilterActivator
          gridId={gridId}
          sidebarOpen={sidebarOpen}
          className="grid-close-button"
          caption="DONE"
          icon=""/>
      </CatchErrors>
    </div>
  );

}

GroupGridSidebar.propTypes = {
  relatedPages: PropTypes.object,
  linkBoxes: PropTypes.array
}
