import _ from "lodash";
import LloydItemFactory from "../logic/LloydItemFactory";
import OptionsCatalogue from "./OptionsCatalogue";
import LogoPriceCalculator from "../logic/LogoPriceCalculator";

/**
 * 2021-02 - hold all the lloyd data in one place,
 * so we aren't passing so many props around.
 */
export default class LloydCatalogue {
  constructor({groupId, lloydProducts, products, lloydAttributes, groupProperties}) {
    this.groupId = groupId;
    this.lloydProducts = lloydProducts;
    this.products = products;

    // a hash with keys like:
    // ["border", "color", "logo", "logo_thread", "lettering_thread", "typestyle"]
    this._options = new OptionsCatalogue({lloydAttributes});

    this.groupProperties = groupProperties;

  }

  get options() {
    return this._options;
  }

  getOption(name) {
    return this.options.getOption(name);
  }

  groupConfig(key, fallback = null) {
    let props = this.groupProperties;
    if (props.hasOwnProperty(key))
      return props[key];

    console.warn("group config has no value for " + key + ", using default")

    if (fallback && (typeof fallback === 'function'))
      return fallback({key: key, props: props})
    else
      return fallback;
  }

  /**
   * Get the items in correct sort order.
   *
   * @returns {Array<LloydItem>}
   */
  getItemsAsList() {
    return this.getItemFactory().itemList();
  }

  // How many different mat codes are available.
  itemCount() {
    return _.size(this.lloydProducts)
  }

  getItemFactory() {
    return new LloydItemFactory({
      lloydProducts: this.lloydProducts,
      products: this.products
    });
  }

  getOptionCalculator(option) {
    let name = option.name;

    if (name == 'logo') {
      return new LogoPriceCalculator({
        option: option, groupProperties: this.groupProperties
      })
    }

  }
}
