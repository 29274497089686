import _ from 'lodash';
import React from 'react';

export default class AgentDiscountIndicator extends React.Component {
  render() {
    let { limit, type } = this.props;
    let caption = _.capitalize(type);

    let classes = "agent-discount discount-"+type;

    if (limit < 0.01) {
      classes = classes + " discount-zero";

//      if (type == 'public')
//        return <span className={classes}><b>NO COUPONS</b> </span>;;

//      return <span className={classes}><em>{caption}</em> <b>NO</b> </span>;
    }

    limit = (""+limit).replace('.0', '');

    return <span className={classes}><em>{caption}:</em> <b>{limit}%</b> </span>;
  }
}
