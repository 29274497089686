import ScrollTo from "../../../lib/ScrollTo";

export function generateGridId({quiet = false} = {}) {
  if (!quiet)
    console.error("Grid ID not provided, generating a random one.");

  const n = 100_000_000 * Math.random();

  return "product-grid-" + Math.round(n);
}

export function scrollToGrid(gridId) {
  let scroller = new ScrollTo($('#' + gridId)); // FIXME JQuery!
  scroller.call();
}

