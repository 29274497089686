import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {buildCartEvent} from "../../../Analytics/googleShoppingCart";
import {safeGtag} from "../../../Analytics/googleAnalyticsFour";

export default function GoogleNewItemsReporter({newItems, shoppingCart}) {
  useEffect(() => {
    if (_.isEmpty(newItems)) return;
    let event = buildCartEvent({
      eventType: 'add_to_cart', cartItems: newItems, shoppingCart
    })

    safeGtag(...event);
  }, [newItems]);

  return null;
}
