import _ from 'lodash';
import LloydProductCode from './LloydProductCode';

//
// LloydItem - wraps a raw lloydProduct data hash from
// the back end.
//
//  Created from JSON, then the AAG appliedItem { product + application }
//  gets added in.  The LloydProductFactory (see bottom) will not create
//  unless both Lloyd and AAG products exist.
//
export default class LloydItem {
  constructor(raw) {
    _.extend(this, raw);
  }

  getName() {
    return this.package_content_description || this.part_number;
  }

  getId() { return this.extended_part_number; }

  get product() { return this._product; }
  set product(x) { this._product = x; }

  productUniques() {
    return this.product_unique_list || [];
  }

  /**
   *
   * @returns {LloydProductCode}
   */
  get lloydCode() {
    return this._lloyd_code ||
      (this._lloyd_code = new LloydProductCode(this.part_number))
  }

  /**
   * Return a hash describing the mat positions in this row
   * (see allRowInfo)
   *
   * @returns {Object}
   */
  get rows() {
    let code = this.lloydCode;

    if (code && code.digits)
      return LloydItem.allRowInfo()[+(code.digits)];

    return {unknown: 1 }
  }

  // sort by the numeric part of the lloyd_code
  sortOrder() {
    return +(this.lloydCode.digits);
  }

  /**
   * Which rows are populated?
   *
   * For each code position (like the "4" in "UM4"),
   * return a hash with keys of row name (first, second, deck, etc.)
   * and how many actual mats are at that position - ie, a four-piece
   * set would have {first: 2, second: 2}, because 2 mats at each row.
   *
   * @returns {Object}
   */
  static allRowInfo() {
    return {
      1: {first: 2, second: 2},
      2: {first: 2, second: 1},
      3: {first: 1, second: 2},
      4: {first: 1},
      5: {first: 2},
      6: {first: 2},
      7: {second: 1},
      8: {rear: 1},
      9: {second: 2},
      10: {third: 1},
      11: {third: 2},
      12: {second_third: 1}, // wtf?

      13: {cargo: 1},
      14: {deck: 1},
      15: {trunk: 1},
      16: {cargo: 1},
      17: {deck: 1},
      18: {deck: 1},
      19: {trunk: 1}
    }
  }

}



