import React, {useEffect, useState} from 'react';
import _ from 'lodash';
import VehicleChooserLevel from "./VehicleChooserLevel";
import FitmentNote from "./FitmentNote";
import ScrollToProductsButton from "./ScrollToProductsButton";
import {DetailsOnlyHeadline} from "./headlines";
import clsx from "clsx";
import {nonCoreLevels, useVehicleSelectorConfig} from "../logic/chooserConfig";
import {resetVehicle, setChoicesFromVehicle} from "../logic/callbacks";
import {useDispatch} from "react-redux";
import {useSkeletonLoading} from "../../hooks/useSkeletonLoading";

export default function DetailsOnlySelector({
  customerVehicle, choices, options, onChoiceMade
}) {
  const config = useVehicleSelectorConfig();
  const levels = nonCoreLevels(config);

  const [storedId, setStoredId] = useState(null);

  const dispatch = useDispatch();
  const myRef = useSkeletonLoading();

  // set choice lists from preloaded vehicle
  useEffect(() => {
    const id = customerVehicle?.id;
    if (!id || (id === storedId))
      return;

    setChoicesFromVehicle({
      vehicle: customerVehicle,
      dispatch, config
    })

    setStoredId(id); // only do it once
  }, [customerVehicle, dispatch, storedId]);

  const handleResetVehicle = (evt) => {
    resetVehicle({dispatch, by: "DetailsOnlySeletor"})
  }

  const classes = clsx(
    "vehicle-selector",
    "vehicle-selector-details-only",
    "vehicle-selector-" + (config.layout || 'stacked')
  )

  return (
    <div ref={myRef} id="vehicle-selector" className={classes}>
      <DetailsOnlyHeadline
        headline={config.headline}
        customerVehicle={customerVehicle}/>

      <div className={"selectors selectors-" + _.size(levels)}>
        {levels.map((level, index) => {
          // highlight fitment with .shake-later
          const highlighted = (index === 0);

          return <VehicleChooserLevel
            key={level}
            config={config}
            choices={choices}
            options={options}
            onChoiceMade={onChoiceMade}
            level={level}
            highlighted={highlighted}
          />
        })
        }
      </div>

      <div className="change-it">
        <a className="link" onClick={handleResetVehicle}>Change Vehicle</a>
      </div>

      <FitmentNote config={config}/>

      {false && <ScrollToProductsButton disabled={true}/>}
    </div>);

}
