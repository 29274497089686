/*
 * 2018-12 migrated from localApi.js
 *
 * When creating, give it a handler name like "onFoo"
 * each subscriber must be an object that responds to .onFoo()
 */
export default class SubscriberList {
    constructor(handler_name = 'nothing') {
        this.handler_name = handler_name;
        this.subscribers = [];
    }

    add(subscriber) {
        return this.subscribers.push(subscriber);
    }

    unsubscribe(subscriber) {
        this.subscribers = this.subscribers.filter(x => x !== subscriber);
    }

    notifyAll(event) {
        return this.subscribers.map(
            sub => this.notify(sub, event)
        )
    }

    notify(sub, event) {
        let { handler_name } = this;

        if (sub[handler_name]) {
            return sub[handler_name](event);
        } else {
            return console.log(`subscriber does not respond to ${handler_name}`);
        }
    }
}


