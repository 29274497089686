import React, {useState} from 'react';
import PropTypes from 'prop-types';

import ExitIntentWatcher from "./ExitIntentWatcher";
import SaveCartTrigger from "./SaveCartTrigger";
import {getSaveCartFlavour} from "./saveCartLogic";

const isDev = (process.env.NODE_ENV == 'development');

export default function SaveCartInvoker({
  saveCartStatus = {},
  openSaveCart,
  delaySeconds = (isDev ? 20 : 4),
  ...props
}) {
  const [startTime, setStartTime] = useState(new Date().getTime());
  const [triggered, setTriggered] = useState(false);

  const trigger = new SaveCartTrigger({
    delaySeconds, startTime, triggered
  });

  function invokeSaveCart() {
    const flavour = getSaveCartFlavour(props.shoppingCart);

    console.log("open save cart - flavour " + flavour);

    openSaveCart({flavour});
  }

  // CALLBACK - invoked when user intends to leave page.
  const handleExitPage = (evt) => {
    // This prevents it from displaying if the user has pressed the SaveCart button
    if (saveCartStatus.openedAt || saveCartStatus.closedAt) {
      // console.log("already opened: " + saveCartStatus.openedAt + " / closed " + saveCartStatus.closedAt);
      return false;
    }

    if (!trigger.canTrigger())
      return false;

    setTriggered(n => n + 1);
    trigger.setTriggered(true);
    invokeSaveCart();
  }

  return <div className="save-cart-invoker">
    <ExitIntentWatcher onExitPage={handleExitPage}/>
  </div>
}

SaveCartInvoker.propTypes = {
  shoppingCart: PropTypes.object.isRequired,
  saveCartStatus: PropTypes.object.isRequired,
  openSaveCart: PropTypes.func.isRequired
};

