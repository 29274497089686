import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import ItemClusterMain from "./ItemClusterMain";
import AppliedItemCache from "../../../models/AppliedItemCache";
import ItemClusterImages from "../image_display/ItemClusterImages";
import ClusterAddToCart from "../cart_display/ClusterAddToCart";
import CatchErrors from "../../../../Utility/CatchErrors";

/**
 * ItemCluster - a group of selectors for choosing among
 * a group of items, by their attributes (which may include fitment).
 *
 * PROPS: ["items","dispatch","cluster","clusterState", "multi_add"]
 */
export default class ItemCluster extends React.Component {

  state = {}

  getItemCache() {
    return new AppliedItemCache({
      apps: this.props.itemApplications,
      products: this.props.products
    });
  }

  // componentDidMount() { alert('3'); this.componentDidUpdate({}, {})}

  // Init cluster state when props.itemApplications changes.
  componentDidUpdate(prevProps, prevState, snapshot) {
    const {cluster, itemApplications = {}} = this.props;
    const {clusterState} = this.state;

    if (!clusterState)
      return this.initClusterState();

    if (!cluster || (!cluster.id) || _.isEmpty(itemApplications)) {
      // console.error("no apps, skip cluster init");
      return;
    }

    let oldApps = prevProps.itemApplications;
    if (oldApps && _.isEqual(_.keys(oldApps), _.keys(itemApplications))) {
      // console.log("apps have not changed");
      return;
    }

    this.initClusterState();
  }

  initClusterState() {
    this.setState({clusterState: this.createClusterState()})
  }

  createClusterState() {
    const {cluster} = this.props;

    let data = _.merge({clusterId: cluster.id},
      _.pick(cluster, 'levels'));

    // at first, all products match.
    data.matchingProducts = cluster.productIndex;

    return {
      selections: {},
      matchingProducts: cluster.productIndex,
      selectedItem: null,
      levelOptions: {},
      highlighted: false
    }
  }

  updateState = (newState, callback) => {
    console.log("ItemCluster: updateState", newState);
    this.setState(newState, callback);
  }

  getChildProps() {
    const childProps = _.extend({}, this.props, this.state);

    childProps.itemCache = this.getItemCache();
    childProps.updateState = this.updateState;

    // get selected item
    childProps.cartItem = this.getSelectedCartItem();
    return childProps;
  }

  getSelectedCartItem() {
    const productId = _.get(this.state, 'clusterState.selectedItem.product.id');
    if (productId) {
      const {shoppingCart} = this.props;
      const cartIndex = _.keyBy((shoppingCart && shoppingCart.cartItems), 'productId');
      return cartIndex[productId];
    }
    return null;
  }

  getClasses() {
    let {clusterState = {}} = this.state;

    let classes = ['item-cluster'];
    if (clusterState.highlighted)
      classes.push('item-cluster-highlighted');

    return classes.join(' ');
  }

  render() {
    const {cluster} = this.props;

    const {clusterState} = this.state;

    if (!clusterState) {
      console.info("ItemCluster: render before cluster state init");
      // this.initClusterState();
      return null;
    }

    const cp = this.getChildProps();

    return <div className={this.getClasses()} id={"item-cluster-" + cluster.id}>
      <CatchErrors><ItemClusterImages {...cp} /></CatchErrors>
      <CatchErrors><ItemClusterMain {...cp} /></CatchErrors>
      <CatchErrors><ClusterAddToCart {...cp} /></CatchErrors>
    </div>
  }

}

ItemCluster.propTypes = {
  cluster: PropTypes.shape({
    id: PropTypes.string,
    index: PropTypes.number.isRequired,
    labels: PropTypes.object, // field_name: "Label for user"
    levels: PropTypes.arrayOf(PropTypes.string),
    // FIXME: camelcase this.
    productGroup: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      universal: PropTypes.bool
    }),
    productIndex: PropTypes.object // product_id: { product attrs... }
  }),
  // clusterState: PropTypes.object,
  dispatch: PropTypes.func,
  itemApplications: PropTypes.arrayOf(PropTypes.object).isRequired,
  products: PropTypes.object,
}
