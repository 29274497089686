
// same as rails view helper pluralize()

// pluralize(2, "user") => "2 users"
// pluralize(3, "child", "children") => "3 children"
export function pluralize(count, word, pluralWord) {
    return `${count} ` + pluralizeWithoutCount(count, word, pluralWord);
}

export function pluralizeWithoutCount(count, word, pluralWord) {
    return (count == 1) ? word :
      pluralizeWord(word, pluralWord);
}

export function pluralizeWord(word, pluralWord) {
    if (pluralWord)
        return pluralWord;
    return word + "s";
}
