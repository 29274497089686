/**
 * Base class for SubmitAddress,
 *   SubmitBraintreeCheckout, etc.
 */
export default class SubmitButtonHandler {
  constructor({checkout, callbacks, route}) {
    this.checkout = checkout;
    this.oldCheckout = checkout;  // reference copy

    this.callbacks = callbacks;
    this.route = route;
  }

  call() {
    // this will modify this.checkout
    this.modifyCheckout();
    return this.saveIfChanged();
  }

  saveIfChanged() {
    if (this.checkout === this.oldCheckout) {
      console.error(`${this.constructor.name} checkout did not change, will not submit`);
      return "skip";
    } else {
      return this.updateStoredCheckout();
    }
  }

  // update the checkout in the redux store via callback
  updateStoredCheckout() {
    const {setCheckout, saveCheckout} = this.callbacks;

    // Update stored checkout in redux.
    setCheckout({checkout: this.checkout});

    // And save to back-end.
    return saveCheckout({checkout: this.checkout, route: this.route})
      .then((action) => this.afterUpdate(action))
  }

  // Receive an object with type/payload/meta
  // meta has requestStatus=fulfilled
  afterUpdate(action) {
    // NOP - subclasses should override.
  }

  // Subclasses should override.
  modifyCheckout() { }
}
