import {updateFiltersTouchedList} from "./filterLogic";
import {allFilterSelectionsToItemIds,
  selectDisabledOptionIds,
  selectFilteredItemIds} from "./filterBucketLogic";




/**
 * Called by various actions after updating filterSelections
 *
 * @param state
 * @returns {*}
 */
export function rebuildFilterState(filterState) {
  const {allFilterSelections, allFilterBuckets, allFilterOptions} = filterState;

  // add/remove items from the filtersTouched list
  const filtersTouched = updateFiltersTouchedList(
    filterState.filtersTouched, allFilterSelections);

  // populate filteredItemIdLists: a hash of {filterName => [groupId, groupId...]}
  // for all touched filters
  let filteredItemIdLists = allFilterSelectionsToItemIds({
    allFilterBuckets: allFilterBuckets,
    allFilterSelections: allFilterSelections
  })

  Object.assign(filterState, {filtersTouched, filteredItemIdLists})

  filterState.filteredItemIds = selectFilteredItemIds(filteredItemIdLists);

  filterState.disabledFilterOptions = selectDisabledOptionIds({
    filtersTouched,
    filteredItemIdLists,
    allFilterBuckets,
    allFilterOptions,
  });

  return filterState;
}
