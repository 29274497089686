import React  from 'react';

import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';

import OptionGroup from './OptionGroup';

/**
 *
 * Choose carpet (or rubber) color.
 * Lloyd attribute name is "color".
 *
 * ColorPicker is always present (when color attribute exists).
 * Its internals will either be the contents of renderPicker() or
 * the parent class's renderWithSelection.
 *
 * @param props  ["option","cart","lloyd","callbacks","selection"]
 * @returns {JSX.Element}
 * @constructor
 */
const ColorPicker = ({...props}) => {
  let {option} = props;

  // each group is a plain object - the raw data from the lloyd API
  // with snake_case field names.
  const groups = option.getGroups() || [];

  return (<div className="inner">
    <Tabs>
      <TabList>
        {groups.map((g, i) =>
          <Tab key={g.id || i}>{g.title}</Tab>
        )}
      </TabList>

      {groups.map((g, i) =>
        <TabPanel key={g.id || i}>
          <OptionGroup {...props} values={g.value_list}/>
        </TabPanel>
      )}
    </Tabs>
  </div>);
}

ColorPicker.defaultProps = {
  id: 'lloyd-colors',
  name: 'color',
}

export default ColorPicker;






