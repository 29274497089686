/*
 * A list of named levels for a multi-part selector.
 *
 * 2020 - used by VehicleChooser and ClusteredProductSection
 */
export default class LevelList {
    constructor(levels=[]) {
        this.levels = levels;
    }

    toArray() {
        return _.clone(this.levels);
    }

    // position of the named level in the list;
    // returns -1 if not found (or if levels undefined)
    levelPosition(name) {
        return _.indexOf(this.levels, name);
    }

    // Return list of levels before the desired level.
    // ex. [ "fitment", "style" ]
    levelsBefore(target) {
        let p = this.levelPosition(target);
        if (p >= 0)
            return _.slice(this.levels, 0, p);
        else
            return [];
    }

    levelsAfter(target) {
        let p = this.levelPosition(target);
        if (p >= 0)
            return _.slice(this.levels, p+1);
        else
            return [];
    }

    levelAfter(target) {
        let ll = this.levelsAfter(target);
        return ll ? ll[0] : null;
    }

    // Called when a user makes a selection, this
    // builds a new selection hash with values up and
    // including the currently selected level only.
    extendSelections(selections, name, value) {
        let out = {};

        for (let level of this.levels) {
            if (level === name) {
                // fixme: if null, should we still store it?
                // for now I'm going to have the product filter
                // ignore nulls.
                out[name] = value;
                // and return it - do not look at levels above!
                return out;
            } else {
                // one of the selections BEFORE the current.
                out[level] = selections[level];
            }
        }
        return out;
    }
}
