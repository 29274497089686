import React, {useState} from 'react';
import PropTypes from 'prop-types';
import SkuTooltip from "./SkuTooltip";

// SKU display with popup tooltip.

// hidden on NOL with a display:none in
// app/assets/stylesheets/themes/napaonline/_overrides.scss
export default function SkuDisplay(props) {
  let {sku, tagName: TagName = 'div'} = props;

  const [open, setOpen] = useState(false);

  const handleMouseUp = (evt) => {
    let text = getSelectedText();

    if (text && text.length >= 1)
      setOpen(true)
  }

  const handleClose = (e) => {
    setOpen(false);
  }

  let displaySku = formatSku(sku);

  return (<TagName className="sku" onMouseUp={handleMouseUp}>
      <span>
        <span>Part Number: </span>
        <strong onTouchStart={handleMouseUp} onMouseUp={handleMouseUp}>{displaySku}</strong>
      </span>
    {open && <SkuTooltip sku={sku} handleClose={handleClose}/>}
  </TagName>)
}

SkuDisplay.propTypes = {
  sku: PropTypes.string,
  tagName: PropTypes.string
}

// put spaces around the pipes, so Lloyd's long-ass skus display properly
function formatSku(sku) {
  return sku.split('|')
    .map((word) => word ? (" " + word) : word)    // prepend non-blank ones with space
    .join("|");
}


function getSelectedText() {
  let text = "";
  if (window.getSelection) {
    text = window.getSelection().toString();
  }

  if (!text && document.selection && document.selection.type != "Control") {
    text = document.selection.createRange().text;
  }
  return text;
}
