// Warnings printed across the entire bottom of each item's row.
// These are things like messages about coupon being not valid for this item.
import React from "react";

export default class CartItemWarnings extends React.Component {
  // 2016-11:
  renderForSaleItem(item, cart) {
    // Print nothing special when no promo code is active.

    //let message = '';

    // if (cart && cart.specialOffer) {
    //   if (item.regularExtendedPrice == item.effectiveExtendedPrice) {
    //     // Coupon has NO effect.
    //     message = "A discount has already applied to this item, additional coupons do not apply.";
    //   } else {
    //     // Coupon has SOME effect, but less than customer expected.
    //     // ALter the usual back end message.
    //     let {warnings = []} = item;
    //     message = warnings[0];
    //     for (let warning of warnings) {
    //       let match = warning.match(/only offer ([0-9]+)%/);
    //       if (match && match[1]) {
    //         message = `An additional ${match[1]}% discount has been applied to this item's existing promotional price.`;
    //       }
    //     }
    //   }
    // }
    // <div className="warning">{message}</div>

    return <div className="clear-me warnings">
      <div className="orange">Prices already reduced, limited time offer!</div>
    </div>
  }

  render() {
    let {shoppingCart, item = {}} = this.props;
    let {promotions = {}} = item;

    if (promotions && promotions.sale) {
      let out = this.renderForSaleItem(item, shoppingCart);
      if (out)
        return out;
    }

    let warnings = [...(item.warnings || [])];

    if (shoppingCart.site_id == 20) { // NAPA Site ID
      warnings.push("NAPA Rewards and additional discounts not eligible.");
    }

    if (!warnings || !warnings.length)
      return null;

    return <div className="clear-me warnings">
      {warnings.map((w, i) => <div key={i}>{w}</div>)}

    </div>
  }
}
