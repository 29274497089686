import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {getNewItems, useNewItemListChange} from "../logic/cartHelpers";
import {buildCartEvent} from "../../Analytics/googleShoppingCart";
import {safeGtag} from "../../Analytics/googleAnalyticsFour";

const isDev = (process.env.NODE_ENV == 'development');

export default function GoogleAddToCartReporter(props) {
  // const [lastEvent, setLastEvent] = useState(null);

  // my first custom hook!
  useNewItemListChange(props => {
    const {shoppingCart} = props;

    let newItems = getNewItems(props);

    let event = buildCartEvent({
      eventType: 'add_to_cart', cartItems: newItems, shoppingCart
    })

    safeGtag(...event);

    // setLastEvent(event);
  }, props);

  return null; // render nothing
}

GoogleAddToCartReporter.propTypes = {
  shoppingCart: PropTypes.object.isRequired,
  newItemIds: PropTypes.array.isRequired
}

