import React from 'react';
import LocalUrlMaker from "../lib/LocalUrlMaker";

export default function AagCartIcon(props) {
  let {cartCode, cartUrl, itemCount = 0} = props;

  cartUrl ||= LocalUrlMaker.prefixedPath(`/cart/${cartCode}`)

  const isEmpty = (!itemCount || (+itemCount < 1));

  return (
    <div id="floating-cart"
      className={isEmpty ? 'floating-cart-empty' : 'floating-cart-full'}>
      <ul>
        <li>
          <a className="header-cart-icon" rel="nofollow" href={cartUrl}>
            <img id="shopping-cart-icon"
              src="/img/design/body/shopping-cart.png"
              alt="Shopping Cart"/>
          </a>
          {!isEmpty && <a className="header-cart-qty"><span>{itemCount}</span></a>}
        </li>
        <li>
          <div id="floating-cart-button">
            <a rel="nofollow" href={cartUrl}>{isEmpty ? "Cart" : "Checkout"}</a>
          </div>
        </li>
      </ul>
    </div>
  )
}

