import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {jsonOptions} from "./Helpers/requestHelpers";
import LocalUrlMaker from "../lib/LocalUrlMaker";

// Storage slice - holds any arbitrary stuff we want to stash.
// chat_info, braintreeData, error live here.
export const scrapyardSlice = createSlice({
  name: 'scrapyardSlice',
  initialState: {},
  reducers: {
    setItems: (state, action) => {
      console.log("scrapyardSlice.setItems: ", action.payload)
      Object.assign(state, action.payload);
    },

    // Set an error message that must be cleared by the user,
    // as a popup - replacing alert().
    // This will be displayed by EyeInTheSkyApp
    setError: (state, action) => {
      const {payload} = action;

      // if payload has an error object, use it, otherwise treat the payload
      // itself as an error object.
      let {error = null} = payload;
      if (!error && payload.message)
        error = payload;

      if (!error) {
        state.error = null;
      } else {
        state.error = {
          headline: error.headline || payload.headline,
          message: error.message || error.toString() || "Unknown error",
          type: error.constructor?.name
        }
      }

    }
  }
});

/**
 * 2023-06 isolate braintree fetchToken
 *
 * @type {AsyncThunk<Promise<Returned|RejectWithValue<GetRejectValue<AsyncThunkConfig>,
 *   GetRejectedMeta<AsyncThunkConfig>>|Returned extends any ? Promise<Returned> : never>, void, AsyncThunkConfig>}
 */
export const fetchBraintreeToken = createAsyncThunk(
  'scrapyard/fetchBraintreeToken',
  async (payload, thunkAPI) => {
    const {cartCode} = payload;
    const {dispatch} = thunkAPI;

    if (!cartCode) {
      console.error("fetchBraintreeToken: no cartCode");
      return;
    }

    let url = LocalUrlMaker.prefixedPath(
      `/new_checkout/${cartCode}/braintree_token.json`);

    return await fetch(url, jsonOptions({method: 'POST'}))
      .then(r => r.json())
      .then(data => {
        dispatch(scrapyardSlice.actions.setItems({braintreeData: data.braintree}))
      })
  }
);



export default scrapyardSlice;
