import React, {useEffect, useRef, useState} from 'react';
import CarouselItem from "./CarouselItem";
import clsx from "clsx";
import CarouselDisplay from "./CarouselDisplay";

export default function CarouselContent({
  items, config = {}, itemIndex, itemsPerSlide = 1, callbacks
}) {

  const ref = useRef(null);

  const {aspectRatio = 3.25} = config;

  const styles = {aspectRatio};

  // const displayItems = [
  //   items[itemIndex - 1] || items[items.length - 1],
  //   items[itemIndex],
  //   items[itemIndex + 1] || items[0]
  // ];

  const classNames = clsx(
    "relative w-full overflow-x-hidden overflow-y-hidden whitespace-nowrap rounded-lg",
    config.containerClasses);

  return <div ref={ref}
    className={classNames}
    style={styles}>

    {items.map((item, index) => {
        const itemOffset = index - itemIndex; // -3, -2, -1, 0, 1 ...
        return <CarouselItem key={item.id}
          item={item}
          index={index}
          config={config}
          itemsPerSlide={itemsPerSlide}
          itemOffset={itemOffset}/>
      }
    )}
  </div>
}
