import React from 'react';
import {connect, Provider} from "react-redux";
import VehicleChangeWatcher from "../components/GroupItems/VehicleChangeWatcher";
import {getReduxStore} from "../../ReduxStore/store";

function mapStateToProps(state) {
  const {vehicleSlice = {}} = state;

  return vehicleSlice;
}

let Wrapper = connect(mapStateToProps)(VehicleChangeWatcher);

export default function VehicleChangeWatcherApp(props) {
  return <Provider store={getReduxStore()}>
    <Wrapper {...props} />
  </Provider>
}


