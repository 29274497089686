import React from 'react';
import {Provider, connect} from 'react-redux';
import _ from 'lodash';

import CheckoutContainer from "./CheckoutContainer";
import {getReduxStore} from "../../ReduxStore/store";

// receive state of all slices.
// 2023-06 - move braintree token to scrapyardSlice, rename its container to braintreeData
function mapStateToProps(storeState) {
  const {checkoutSlice = {}, scrapyardSlice={}} = storeState;

  return Object.assign({}, checkoutSlice,
    _.pick(scrapyardSlice, 'braintreeData', 'currentAgent'));
}

let Wrapper = connect(mapStateToProps)(CheckoutContainer);

export default function CheckoutApp({checkout, ...props}) {
  return <Provider store={getReduxStore()}>
    <Wrapper {...props} />
  </Provider>
}
