import React from 'react';
import FetchPaypalToken from "../../../Checkout/paypal/FetchPaypalToken";
import {reportStartCheckout} from "../utility/startCheckout";

/**
 * START the paypal checkout process; this appears on View Cart
 * page as well as on the Payments tab of new checkout.
 *
 * 2021-08 - modified to use the New Checkout's
 * FetchPaypalToken class.
 */
export default function PaypalCheckoutButton(props) {
  const {cartCode, shoppingCart} = props;

  /**
   * When pressed, do a JSON call to the back-end
   * to get a Paypal token, then redirect user to paypal.com
   * with it.
   *
   * @param evt
   */
  const handleClick = (evt) => {
    if (evt) { evt.stopPropagation(); }

    let fetcher = new FetchPaypalToken({
      cartCode: cartCode, flavour: 'view_cart'
    });

    fetcher.call();

    reportStartCheckout({
      shoppingCart, checkoutType: 'paypal'})
  }

  // let paypal_url = `/cart/${cartCode}/paypal_checkout/new`;

  return (<div id="paypal-container">
    <a className="click-me"
      onClick={handleClick}><img className="m-auto"
      src="/img/badges/paypal_200.png"
      alt="Paypal Express Checkout"/></a>
  </div>);

}
