import React from 'react';

// private component
function AgentItemWarning({warning}) {
  return <div>
    <span className="fa fa-exclamation-triangle"/>
    {' ' + warning}
  </div>
}

// modernized 2021-07
export default class CartItemAgentItemBottom extends React.Component {
  render() {
    const {item} = this.props;
    const {agentWarnings} = item;

    if (!agentWarnings || !agentWarnings[0])
      return null;

    return <div className="agents-only agent-item-warnings">
      {agentWarnings.map((warning, i) =>
        <AgentItemWarning key={i} warning={warning}/>)}
    </div>
  }
}

