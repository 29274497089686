import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import clsx from "clsx";

export default function DownscaleButton({classNames, ...props}) {
  function handleClick(evt) {
    alert("not yet supported")
  }

  return <button className={clsx(classNames, 'bg-gray-200')}
    onClick={handleClick}>
    <span className="fa fa-paste"/>
    {' '}
    Generate
  </button>
}
