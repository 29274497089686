import Money from "../lib/Money";

export function cartItemToAdobeCartItem(cartItem) {
  let fullSku = cartItem.googleSku || `${cartItem.productGroupId}-${cartItem.vendorsku}`;

  let adobeItem = {
    productID: fullSku,
    productHQID: cartItem.fullNapaSku || fullSku,
    quantity: cartItem.quantity,
    currency: "USD",
    sellingPrice: new Money(cartItem.effectiveUnitPrice).toDollars(),
    fulfillmentMethod: "bol",
    pickupEstimation: ""
  };

  return adobeItem;
}

export function cartItemToAdobeProductInfo(cartItem, shoppingCart) {
  let fullSku = cartItem.googleSku || `${cartItem.productGroupId}-${cartItem.vendorsku}`;

  let productInfo = {
    productID: fullSku,
    currency: "USD",
    dcID: "",
    recommSourcePlacement: "", //"PDP:Belowproductdescription",
    recomSourceType: "",
    recommendationengine: "",
    recommendationRank: 0,
    cartAddType: "PDP",
    cartId: shoppingCart?.cartCode,
    parentProductID: "",
    sellingPrice: new Money(cartItem.effectiveUnitPrice).toDollars(),
    quantity: cartItem.quantity,
    fulfillmentMethod: "BOL",
    storePickupEnabled: "",
    shipToHome: "",
    imageAvailable: "Yes",
    isPromo: "No",
    plpImpressionRank: 0,
    productHQID: cartItem.fullNapaSku || fullSku,
    searchPlatform: ""
  }

  return productInfo;
}



/**
 * event.product = buildProductList({items, shoppingCart}
 *
 * @param items
 * @param shoppingCart
 * @returns {*}
 */
export function buildProductList({items, shoppingCart}) {
  // Note that each member of the list has a single .productInfo
  return items.map(cartItem => {
    let productInfo = cartItemToAdobeProductInfo(cartItem, shoppingCart)
    return {productInfo};
  });
}

export function buildCartItemsList({items, shoppingCart}) {
  return items.map(cartItem => {
    return cartItemToAdobeCartItem(cartItem, shoppingCart)
  });
}
