import AdvanceButton from "./AdvanceButton";
import Paginator from "./Paginator";
import React from "react";
import CatchErrors from "../Utility/CatchErrors";
import CarouselContent from "./CarouselContent";
import {useSkeletonLoading} from "../hooks/useSkeletonLoading";

// TODO: try https://www.npmjs.com/package/react-swipeable

export default function CarouselDisplay({
  callbacks, config = {}, itemsPerSlide, itemIndex, items = []
}) {
  const {setPaused} = callbacks;

  const {aspectRatio = 3.25} = config;

  const styles = {}// {aspectRatio};
  // const pageCount = calculatePageCount(items.length, itemsPerSlide);

  const myRef = useSkeletonLoading();

  let buttons = widgetConfig(config.buttons);
  let paginator = widgetConfig(config.paginator);

  return <div className="carousel relative"
    ref={myRef}
    style={styles}
    onMouseEnter={() => setPaused(true)}
    onMouseLeave={() => setPaused(false)}
  >
    <CarouselContent
      items={items}
      itemsPerSlide={itemsPerSlide}
      config={config}
      itemIndex={itemIndex}
      callbacks={callbacks}
    />

    {buttons.disabled || <AdvanceButton count={-itemsPerSlide} callbacks={callbacks}
      buttonConfig={buttons}/>}

    {buttons.disabled || <AdvanceButton count={+itemsPerSlide} callbacks={callbacks}
      buttonConfig={buttons}/>}

    {paginator.disabled || <Paginator
      itemIndex={itemIndex}
      itemCount={items.length}
      paginatorConfig={paginator}
      callbacks={callbacks}
    />}
  </div>
}

function widgetConfig(thingy) {
  let t = typeof thingy;
  if (t === 'boolean')
    return {disabled: !thingy};

  if (t === 'object') {
    return thingy;
  }

  console.log("CarouselControls: cannot parse config: ", thingy);
  return {disabled: true};
}
