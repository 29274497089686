export function isEmptyCheckout(checkout) {
  if (!checkout) return true;

  const {cartCode, cartItems, dummy} = checkout;

  if (dummy) return true;

  if (!cartCode || !cartItems)
    return true;

  return false;
}

// Used on AddressPanel, this determines whether the
// same address switch widget is visible.
export function showSameAddressSwitch({checkout = {}, paymentType}) {
  const {sameAddress = true, billingAddress, shippingAddress = {}} = checkout;

  if (paymentType !== 'affirm')
    return true;

  if (!sameAddress) return true;

  if (billingAddress) {
    if (billingAddress.id != shippingAddress.id) {
      return true;
    }
  }

  return false;
}
