import _ from 'lodash';
import VehicleModel from './VehicleModel';

/**
 *
 * example members:
 * {
 *    "id": 1924422,
      "year": 2017,
      "vehicleMakeId": 192,
      "vehicleModelId": 19244,
      "vehicleMakeName": "Dodge",
      "vehicleModelName": "Durango",
      "vehicleType": "SUV",
      "shortMakeName": "Dodge",
      "shortModelName": "Durango",
      "fitmentId": "1910656",
      "lloydUnique": "With 3rd Seat"
    };
 */
export default class Vehicle {
  constructor(data) {
    data = _.mapKeys(data, (v, k) => _.camelCase(k));
    Object.assign(this, data);
  }

  fullName() {
    return `${this.vehicleMakeName} ${this.vehicleModelName}`;
  }

  fullDescription() {
    return `${this.year} ${this.vehicleMakeName} ${this.vehicleModelName}`;
  }

  toVehicleModel() {
    return new VehicleModel({
      id: this.vehicleModelId,
      name: this.vehicleModelName,
      makeName: this.vehicleMakeName,
      vehicleType: this.vehicleType
    });
  }

  // format as a VehicleChooser option.
  toOption() {
    return {
      v: this.id,
      l: `${this.year} ${this.vehicleMakeName} ${this.vehicleModelName}`
    }
  }
}
