import React from "react";

export default function SaveCartSubmit({callbacks, caption, saveCartStatus={}, ...props}) {
  const { flavour } = (saveCartStatus || {});
  if (!caption) {
    caption = (flavour === 'requested' || flavour === 'fallback') ?
      "Save Cart" : "Send My Offer";
  }

  return (<div className="save-cart-submit">
    <button type="button" id="save-cart-button" className="btn" onClick={callbacks.submit}>
      {caption}
    </button>
  </div>)
}
