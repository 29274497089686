import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

export default function DisabledFormBottom(props) {
  let {cartErrors} = props;

  if (_.isEmpty(cartErrors))
    return null;

  // if (!cartErrors)
  //   cartErrors = props.cart.validate();

  return <section id="lloyd-item-section-bottom">
    <div id="lloyd-item-section-bottom-submit">
      <div className="tooltip-item">
        <div className="tooltip">
          <h4>Complete These Items:</h4>

          <ul>
            {_.keys(cartErrors).map((k) =>
              <li key={k}>{cartErrors[k]}</li>)}
          </ul>
        </div>
        <button className="btn" type="button" disabled={true}>Add to Cart</button>
      </div>
    </div>
  </section>
}

DisabledFormBottom.propTypes = {
  cartErrors: PropTypes.object
}
