import React, {Component} from 'react';
import _ from 'lodash';

import SaveCartPublicSavingsContents from './SaveCartPublicSavingsContents';

export default class SaveCartPersonalSavingsContents extends SaveCartPublicSavingsContents {
  getSavings() {
    let {cartTotals} = this.props;
    let {max_personal_savings} = cartTotals;
    return new Money(max_personal_savings);
  }

  renderInstructions() {
    return <div className="save-cart-prompt">
      <p className="headline">
        Your shopping cart qualifies for a discount
        we can’t advertise online.
      </p>

      <p className="instructions">
        Enter your email below and we’ll send you a private offer!
      </p>
    </div>
  }
}

SaveCartPersonalSavingsContents.defaultProps = _.extend({},
  SaveCartPublicSavingsContents.defaultProps, {
    submitCaption: "Send My Offer"
  });
