import React from 'react';
import RatingWidget from "./RatingWidget";

// 2023-11 modernised
export default function GridItemTitle({item, path, handleClick= (e => true)}) {

  return (
    <div className="group-item-title">
      <div className="group-item-title-name">
        <a href={path} onClick={handleClick}>{item.name}</a>
      </div>

      <RatingWidget item={item}/>
    </div>
  );

}
