import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import BorderOption from "./BorderOption";
import CartOptions from "../models/CartOptions";

const OPT_BORDER_TYPE = 'border_type';

/**
 * 2023-02 modernized
 *
 * BorderTypeSwitch: presents the "standard binding" / "premium binding"
 * radio buttons.  Child of BorderPicker
 */
export default function BorderTypeSwitch(props) {
  const {cart, lloyd, callbacks} = props;
  const {selectOption} = callbacks;

  const selected = cart.options.getOption(CartOptions.BORDER_TYPE) || "";

  const isPremium = (selected === "premium");

  return (
    <div id="lloyd-border-type-switch">
      <BorderOption
        borderType="plain"
        selected={!isPremium}
        lloyd={lloyd}
        selectOption={selectOption}
      />

      <BorderOption
        borderType="premium"
        selected={isPremium}
        lloyd={lloyd}
        selectOption={selectOption}
      />
    </div>
  );

}

BorderTypeSwitch.propTypes = {
  cart: PropTypes.object.isRequired,
  lloyd: PropTypes.object.isRequired
}
