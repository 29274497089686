import React from "react";

export default function AutocompleteOption({onSelectAddress, addr, unitsOnly = false}) {
  if (!addr) return;

  let {streetLine, secondary, city, state, zipcode, entries} = addr;

  let text = [streetLine, secondary, city, state, zipcode].filter(x => x).join(', ');
  if (unitsOnly) {
    text = secondary;
  }

  const handleClick = (evt) => {
    console.log("AutoCompleteOption.onClick", evt, addr);

    onSelectAddress({evt, addr});
  }

  return <li>
    <a onClick={handleClick}>
      {(entries > 1) &&
        <div className="option-count">{entries} options</div>}
      {text}
    </a>
  </li>
}
