import React from 'react';

export default class AgentStatusReloader extends React.Component {
  render() {
    let { callbacks } = this.props;

    return <div className="agent-status-reloader">
      <a className="button" onClick={ () => { callbacks.reload() } }>
        <span className="fa fa-lg fa-sync" title="Reload"/></a>
      <AgentStatusReloadTimer timer={this.props.timer} />
    </div>
  }
}

class AgentStatusReloadTimer extends React.Component {
  // Make an array of length <seconds> so we can map to components
  bars() {
    let { timer } = this.props;

    if (!timer)
      timer = {seconds: 5};

    let bars = [];
    for (let i=0; i < timer.seconds; i++)
      bars.push(i);
    return bars;
  }

  render() {

    return <div className="agent-status-timer">
      <div className={"timer-inner-" + 5}>
        {this.bars().map( (i) => <span key={i} className="bar" /> )}
      </div>
    </div>
  }
}

//
// class AgentStatusReloadButton extends React.Component {
//
// }
