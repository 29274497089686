import React, {useState, useEffect} from 'react';
import { isEmpty } from 'lodash';
import {setEmailUser} from "../ReduxStore/userSlice";
import {useDispatch} from "react-redux";

/**
 * Used only for agents; obliterates the emailUser
 * in Redux & LocalStorage, so that Klaviyo doesn't
 * get bogus tracking info.
 *
 * @param currentAgent
 * @param user
 * @param props
 * @returns {React.JSX.Element|null}
 * @constructor
 */
export default function RemoveStashedUser({
  currentAgent, user, ...props
}) {
  const [attempts, setAttempts] = useState(0);

  const dispatch = useDispatch();

  useEffect(() => {
    if (attempts > 5) return;  // avoid bad loops

    if (isEmpty(user)) return;

    console.log(`**** clearing stored email user, attempt ${attempts + 1}`);
    setAttempts(x => +x + 1);

    // call the async thunk; it will update Redux
    dispatch(setEmailUser(null));
  }, [user]);

  return null;
}
