import FetchBuilder from "./FetchBuilder";

/**
 * let post = new JsonPost({payload: { foo: bar }, url: "/foo"}
 * post.perform().then( r => r.json() ).then( data => ... )
 */
export default class JsonPost extends FetchBuilder {
  constructor({ body, payload, ...opts} = {}) {
    super(opts);

    // Pass in either body (String) or payload (Object), not both.
    if (body)
      this.body = body; // String
    else
      this.payload = payload || {};
  }

  getDefaultHeaders() {
    return {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    };
  }

  /**
   *
   * @returns {String}
   */
  getBody() {
    if (!this.body) {
      this.body = JSON.stringify(this.payload)
    }

    return this.body;
  }
}
