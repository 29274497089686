import HostedFieldsHelper from "./HostedFieldsHelper";

/**
 * Tokenize braintree checkout data, getting a "nonce"
 * from the braintree API. Once obtained, invoke the
 * onSuccess callback, which will send it to CartEngine's
 * back end to finish the checkout.
 */
export default class GetBraintreeNonce {
  constructor({checkout, hostedFields, onSuccess, onFailure}) {
    this.checkout = checkout;
    this.hostedFields = hostedFields;

    this.onSuccess = onSuccess; // callback
    this.onFailure = onFailure; // callback, should set state.error in caller
  }

  call() {
    // Validate using an alert() if anything goes wrong.
    if (this.validateLoudly())
      return false;

    // Submit to Braintree (and invoke callback).
    this.submitCardData();
    return true;
  }

  // https://braintree.github.io/braintree-web/3.76.4/HostedFields.html#tokenize
  transformBillingAddress() {
    let { billingAddress, shippingAddress } = this.checkout;

    let addr = (billingAddress && billingAddress.city && billingAddress.zip) ?
      billingAddress : shippingAddress;

    return {
      firstName: addr.first_name,
      lastName: addr.last_name,
      company: addr.company,
      streetAddress: addr.street,
      extendedAddress: addr.street2,
      locality: addr.city,
      region: addr.state_code,
      countryCodeAlpha2: addr.country_code || 'US',
      postalCode: addr.zip
    };
  }

  getArgsForTokenize() {
    // 2021-09 tokenize billing address
    return {
      billingAddress: this.transformBillingAddress()
    };
  }

  // Send to braintree, invoking our callback after.
  submitCardData() {
    const opts = this.getArgsForTokenize();
    this.hostedFields.tokenize(opts)
      .then(payload => this.receiveNonce(payload))
      .catch(err => this.onFailure({message: err.message || err.toString()}));
  }

  receiveNonce(payload) {
    const {onSuccess} = this;

    // keys nonce binData description details type
    const {nonce} = payload;
    if (!nonce) {
      throw new Error("Failed to obtain payment nonce from Braintree")
    }

    onSuccess(payload);
  }

  // Validate field contents and present user a pop-up error if invalid.
  validateLoudly() {
    let h = this.getFieldsHelper();
    let errors = h.getErrorMessages();

    // no errors! Happy to submit.
    if (_.isEmpty(errors))
      return false;

    let message = _.values(errors).join("\n")

    this.onFailure({message: message});

    return _.keys(errors);
  }

  getFieldsHelper() {
    const {hostedFields} = this;
    return new HostedFieldsHelper({hostedFields});
  }
}

// SAMPLE payload:

// {
//   "nonce": "tokencc_bf_2p7975_dszy3x_2fzqvn_hfsn9c_fpy",
//   "details": {
//     "cardholderName": "James Tiberius Kirk",
//     "expirationMonth": "06",
//     "expirationYear": "2026",
//     "bin": "545454",
//     "cardType": "MasterCard",
//     "lastFour": "5488",
//     "lastTwo": "88"
//   },
//   "description": "ending in 88",
//   "type": "CreditCard",
//   "binData": {
//     "prepaid": "Unknown",
//     "healthcare": "Unknown",
//     "debit": "Unknown",
//     "durbinRegulated": "Unknown",
//     "commercial": "Unknown",
//     "payroll": "Unknown",
//     "issuingBank": "Unknown",
//     "countryOfIssuance": "Unknown",
//     "productId": "Unknown"
//   }
// }
