import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import CreditCardFormSection from "./CreditCardFormSection";
import AffirmFormSection from "./AffirmFormSection";
import PaypalFormSection from "./PaypalFormSection";
import AppleFormSection from "./AppleFormSection";
import AgentNoPaymentFormSection from "./AgentNoPaymentFormSection";

function FallbackSection({paymentType, ...props}) {
  if (paymentType) {
    return <div className="red">
      ERROR, unknown payment type {paymentType}
    </div>
  }

  return null;
}

const SECTION_TYPES = {
  credit_card: CreditCardFormSection,
  affirm: AffirmFormSection,
  paypal: PaypalFormSection,
  apple: AppleFormSection,
  agent_none: AgentNoPaymentFormSection,
  default: FallbackSection
}

export default function DynamicPaymentPanel(props) {
  const {paymentType} = props;

  const DynamicSection = SECTION_TYPES[paymentType] || FallbackSection;

  return <DynamicSection {...props} />
}
