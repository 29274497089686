import React from 'react';
import PropTypes from "prop-types";
import gridFilterSlice from "../../../ReduxStore/GroupGrid/gridFilterSlice";
import {useDispatch} from "react-redux";
import clsx from "clsx";

// Shared with ResetAllFilters
export const FILTER_PILL_CLASSES = [
  'filter-pill',
  'relative',
  'cursor-pointer',
  'inline-block',
  'bg-brand-blue',
  'text-white',
  'rounded-full',
  'py-1', 'px-3',
  'mr-1', 'mb-1',
  'h-6', // explicit height so ResetAllFilters matches
  'text-sm',
  'leading-none',   // line-height: 1
  'hover:opacity-75',
  'hover:text-white',
  'transition-opacity',
];

const ICON_CLASSES = [
  'far',
  'bg-gray-900',
  'relative',
  'inline-block',
  'rounded-full',
  'text-center',
  'font-normal',
  //'py-1', 'px-1',
  'text-gray-100',
  'w-4',
  'h-4',
  '-mr-1', // margin-right -4px
  'aspect-square',
  //'leading-snug',   // line-height: 1
];
/**
 * 2024-05 GridId aware
 *
 * @param dispatch
 * @param selection
 * @param props
 * @returns {JSX.Element|null}
 * @constructor
 */
export default function FilterPill({gridId, selection = {}}) {
  const dispatch = useDispatch();

  // like "383", "brands", "Armordillo"
  const {id, filterName, label} = selection;

  if (!id) return null;

  // Whe clicked, update the filter state in redux, to associate false with this value's id
  function handleClick(e) {
    dispatch(gridFilterSlice.actions.selectFilterItem({
      gridId,
      filterName,
      id,
      selected: false
    }));
  }

  let classNames = clsx(FILTER_PILL_CLASSES);

  return (
    <a className={classNames} onClick={handleClick}>
      {label || id} {' '}
      <span className={clsx(ICON_CLASSES)}><span className="fa fa-times"/></span>
    </a>
  );
}

FilterPill.propTypes = {
  selection: PropTypes.object.isRequired, // id,name,filter
}
