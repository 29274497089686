import React from 'react';
import _ from 'lodash';
import RatingBar from "./RatingBar";

const scoreNames = ['overall', 'performance', 'quality', 'appearance'];

const ScoresCol = ({item, scoreData}) => {
  let {scores = {}} = (scoreData || {});

  // { "overall": 9.1, "performance": 9, "quality": 9.2, "appearance": 9.3 }
  let scoreValues = _.mapValues(scores, data => data.score);

  let average = item.r;
  if (!_.isEmpty(scoreValues))
    average = _.sum(_.values(scoreValues)) / _.size(scoreValues);

  return <td>
    <div className="clear-me"><RatingBar name="rating" rating={average}/></div>

    {scoreNames.map(scoreType => <div key={scoreType} className="clear-me">
      <RatingBar name={scoreType}
        rating={scoreValues[scoreType]}/></div>)}
  </td>
}

const ScoresRow = ({items, allScores}) => {
  if (_.isEmpty(allScores))
    return null;

  return (
    <tr className="scores-row">
      <th>Customer Ratings</th>
      {items.map((item) => <ScoresCol key={item.id} item={item} scoreData={allScores[item.id]}/>)}

    </tr>
  );
}

// ScoresRow.jsx.propTypes = { }

export default ScoresRow;

