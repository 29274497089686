import React from 'react';

import gridFilterSlice from "../../../ReduxStore/GroupGrid/gridFilterSlice";
import clsx from "clsx";
import {useDispatch} from "react-redux";
import {FILTER_PILL_CLASSES} from "../controls/FilterPill";

// 2024-05 OK, gridId aware
export default function ResetAllFilters({
  gridId, classNames = FILTER_PILL_CLASSES,
  label = "Reset Filters", ...props
}) {
  const dispatch = useDispatch();

  // Whe clicked, update the filter state in redux, to associate false with this value's id
  const handleClick = e => {
    dispatch(gridFilterSlice.actions.resetAllFilters({gridId}));
  }

  return (
    <a className={clsx(classNames)}
      onClick={handleClick}>
      {label}
    </a>
  );
}


