import Money from "../../../lib/Money";

// Calculate a price by multiplying base price by a constant,
// then adding another constant.
// This is used for logo pricing.
class ScaledPriceCalculator {
  constructor({scale =  1, plus = 0} = {}) {
    this.scale = isNaN(scale) ? 1 : (+scale);
    this.plus = isNaN(plus) ? 0 : (+plus);
  }

  // Get the base price of a selection, in cents
  basePrice(optionValue) {
    let p = optionValue.price;
    if (p)
      return Money.fromDollars(p).value();
    return null;
  }

  /**
   * Return price in cents.
   *
   * @param optionValue
   * @returns {number}
   */
  getPrice(optionValue) {
    let base = this.basePrice(optionValue);
    let p = this.scalePrice(base);
    //console.log(`scaled price: ${this.scale} * ${base} + ${this.plus} => ${p}`);
    return p;
  }

  /**
   *
   * @param basePrice {number}
   * @returns {number}
   */
  scalePrice(basePrice) {
    let price = (+ basePrice);

    if (this.scale)
      price = price * +(this.scale);
    if (this.plus)
      price = price + +(this.plus);

    return Math.round(price);
  }
}

export default class LogoPriceCalculator extends ScaledPriceCalculator {
  constructor({ groupProperties, option } = {}) {
    super({});

    if (groupProperties) {
      // 2021: the configuration is:
      //     lloyd.logo.price-markup: "0"
      //     lloyd.logo.price-scale: "1.0"
      this.scale = +(groupProperties['lloyd.logo.price-scale'] || 1);
      this.plus = +(groupProperties['lloyd.logo.price-markup'] || 0);
    }
  }
}
