
import _ from 'lodash';


/**
 * Like ruby's "foo.presence || default", but using lodash's _isEmpty
 * so it doesn't choke on zeroes, empty hashes, etc.
 * (I really struggled to name this one)
 *
 * @param thing
 * @param deflt
 * @returns {*}
 */
export function presenceOr(thing, deflt) {
  return _.isEmpty(thing) ? deflt : thing;
}

