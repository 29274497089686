import React, {createElement, useState, useEffect} from 'react';
import {omit, mapValues} from 'lodash';

import {createRoot} from 'react-dom/client';
import {extractAttributes} from "../Utility/html";
import {camelizeKeys} from "../ReduxStore/Helpers/transformObject";
import {getRegisteredComponent, nodeListToReactElements} from "../Utility/reactHelpers";

export default function ActivateComponentTags({
  tagName = "Component", ...props
}) {
  const [ranOnce, setRanOnce] = useState(false);

  useEffect(() => {
    if (ranOnce) return;
    if (!tagName) return;

    // convert nodelist to array, otherwise it breaks when the first element becomes invalid
    let tagList = [...document.getElementsByTagName(tagName)];

    for (let e of tagList) {
      activateComponentTag(e);
    }
    setRanOnce(true);
  }, [ranOnce]);

  return null;
}

function activateComponentTag(src) {
  try {
    console.log("Activate component tag: " + src.tagName);

    // attribute names are always lowercase in HTML. We'll
    // use snake_case to get around this in the API, and then
    // camelize it for React.
    let attrs = camelizeKeys(extractAttributes(src));

    let children = nodeListToReactElements(src.childNodes);

    // children - not yet supported.
    let component = makeComponent(attrs, children);
    if (component) {
      // Build and mount the div, replacing source component
      let div = document.createElement('div');
      if (attrs.id)
        div.setAttribute('id', attrs.id);
      if (attrs.class)
        div.setAttribute('class', attrs['class']);
      src.replaceWith(div);

      const root = createRoot(div);
      root.render(component);
    }

  } catch (err) {
    alert(`activating component: ${err}`); //FIXME
  }
}



function makeComponent(attrs, children = null) {
  const type = attrs['reactClass'];
  const func = getRegisteredComponent(type);

  if (!type || !func) {
    console.error(`makeComponent: unknown component ${type}, cannot continue`);
    return null;
  }

  const props = transformProps(omit(attrs, 'className'));

  return React.createElement(func, props, ...children);
}

function transformProps(raw) {
  return mapValues(raw, (v, k, obj) => transformProp(v));
}

function transformProp(v) {
  try {
    if (v.toString().startsWith("json:")) {
      return JSON.parse(v.substring(5));
    } else if (v === "null") {
      return null;
    } else {
      return v;
    }
  } catch (err) {
    console.error(`error transforming prop: ${v}: ${err})`);
    return null;
  }
}
