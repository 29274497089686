import React, {Component} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import GenericAddressWidget from "./GenericAddressWidget";
import CheckoutFormLabel from "../form/CheckoutFormLabel";
import ValidationMessage from "../form/ValidationMessage";
import EmbeddedConfig from "../../../lib/EmbeddedConfig";
import {mailingListNames} from "../../../Utility/staticConfig";

export default class SubscribingWidget extends GenericAddressWidget {

  static defaultProps = _.extend({}, GenericAddressWidget.defaultProps, {
    type: "text",
    addressType: 'shipping',
    name: 'subscribing'
  });

  state = {};

  componentDidMount() {
    let config = new EmbeddedConfig();

    this.setState({
      config: config,
      theme: config.fetch('name')
    });
  }

  onChange = (evt) => {
    let {target = {}} = evt;
    let {checked = false} = target;

    // console.log("CHANGED --------");
    // console.dir(evt);
    // console.log(`V=${target.value} C=${target.checked}`);

    this.setState({value: !!checked})
  }

  isChecked() {
    const {value} = this.state;

    return (value !== false) && (value !== 'false');
  }

  renderInput(helper) {
    const checked = this.isChecked();

    if (!helper)
      helper = this.getHelper();

    // const focused = helper.isFocused();

    return <input
      id={helper.getId()}
      name={helper.getName()}
      type="checkbox"
      value={"1"}
      checked={checked}
      autoComplete="never"
      onFocus={this.onFocus}
      onBlur={this.onBlur}
      onChange={this.onChange}
    />
  }

  getListName() {
    const {theme} = this.state;

    const listName = mailingListNames[theme];
    return listName || "our mailing list";
  }

  render() {
    const {optional, focused} = this.props;

    const helper = this.getHelper();
    // const error = helper.getError();

    // console.log(name + " render with value: " + value);

    return (<div className={helper.getClassString()}>
      <label>
        {this.renderInput(helper)}

        <span className="label">
          Join the <strong>{this.getListName()}</strong> for exclusive coupons and inside deals!
        </span>
      </label>
    </div>);
  }
}
