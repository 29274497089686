import React, {Component} from 'react';
import PulsingLinesThrobber from "../../../Common/components/PulsingLinesThrobber";
import _ from "lodash";
import VehicleChangeWatcher from "./VehicleChangeWatcher";

const isDevelopment = (process.env.NODE_ENV == 'development');

export default class EmptyProductsSection extends Component {
  render() {
    let {loading, id='product-section-empty'} = this.props;

    return (
      <section id={id} className="product-section-empty">
        {loading && <PulsingLinesThrobber/>}
      </section>);
  }

  // notused() {
  //   return {isDevelopment && <div className="debug">
  //     <h2>EmptyProductsSection</h2>
  //
  //     {_.keys(this.props).map((k) =>
  //       <div key={k}><strong>{k}:</strong>{JSON.stringify(this.props[k])}</div>
  //     )}
  //   </div>}
  // }
}

