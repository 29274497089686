import LocalUrlMaker from "../lib/LocalUrlMaker";
import JsonPost from "../ReduxStore/Helpers/JsonPost";
import {JSON_SCHEMA} from "js-yaml";
import {camelizeKeysDeep} from "../ReduxStore/Helpers/transformObject";

export function editImage({image, edits, callbacks}) {
  const { id } = image;
  const { updateGroupImage } = callbacks;

  updateGroupImage(id, image => {
    image = Object.assign(image, edits);

    let url = LocalUrlMaker.prefixedPath(`/published_images/${id}.json`);

    console.log("IMAGE IS: ", image);

    const post = new JsonPost({
      url,
      verbose: true,
      method: 'put',
      payload: image // Rails will put under published_image.
    });

    post.perform()
      .then(r => r.json() )
      .then(data => {
        data = camelizeKeysDeep(data);

        const { publishedImage, status, error, message } = data;

        if (status === 'error' || error) {
          alert("ERROR: \n" + (message || error));
          return;
        }

        if (!_.isEmpty(publishedImage)) {
          callbacks.updateGroupImage(
            publishedImage.id, () => publishedImage);
        }
      }).catch(err => alert("Error: " + err));

    return Object.assign(image, { updatePending: true });
  })
}
