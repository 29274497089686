import React from 'react';

export default class Agent {
  constructor(data) {
    _.extend(this, data);
  }

  // IMPORTANT - identifies the class, like .is_a?
  isAgent() {
    return true;
  }

  // Split list of powers.
  powersList() {
    return (this._powers_list ||
      (this._powers_list = _.split(this.powers, ' ')));
  }

  // hasPower(p1, p2, p3...)
  // Return true if the agent has ANY of the powers in the list,
  // or has 'super', which matches everything.
  hasPower(...wanted) {
    let my_powers = this.powersList();

    for (let pow of wanted) {
      if (_.includes(my_powers, pow)) {
        return true;
      }
      if (_.includes(my_powers, _.camelCase(pow))) {
        return true;
      }
      if (_.includes(my_powers, _.snakeCase(pow))) {
        return true;
      }
    }

    // failing exact match, only "super" can do it.
    return _.includes(my_powers, 'super');
  }
}
