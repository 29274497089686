import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Money from "../../../lib/Money";

const isDev  = (process.env.NODE_ENV == 'development');

const AgentUnitPriceField = (props) => {
  const {callbacks = {}, item = {}, shoppingCart = {}, value} = props;

  const onInput = (evt) => {
    let newVal = evt.target.value;  // string, may have '.'

    if (_.isEmpty(newVal) || (newVal === "0")) {
      callbacks.edit({
        centsDiscount: 0,
        agentPercentDiscount: 0,
        agentUnitPrice: null,
        agentUnitPriceFormatted: ''
      });
      return;
    }

    let dollars = Money.fromDollars(+newVal);
    const agentUnitPrice = dollars.value(); // cents

    let {cataloguePrice} = item;
    if (!cataloguePrice) {
      console.error("cataloguePrice is missing, fall back on regularUnitPrice");
      cataloguePrice = item.regularUnitPrice;
    }

    const centsDiscount = cataloguePrice - dollars.value();
    const agentPercentDiscount = 100.0 * (cataloguePrice - agentUnitPrice) / cataloguePrice;

    callbacks.edit({
      centsDiscount,
      agentPercentDiscount: agentPercentDiscount.toFixed(1),
      agentUnitPrice,  // cents
      agentUnitPriceFormatted: newVal,  // RAW value as typed, for next render
    })
  }

  const onBlur = (evt) => {
    if (!value) {
      console.log("AgentUnitPriceField - value blank - ignore");
      return;
    }

    callbacks.save({agentUnitPrice: Math.round(100.0 * (+value))})

    if (isDev) {
      // let foo = {}; foo.baz();
    }
  }

  return (
    <input className="admin-unit-price" placeholder="each"
      name={`cart_items[${item.id}][agent_unit_price]`}
      value={value || ""}
      onInput={onInput}
      onBlur={onBlur}
      readOnly={shoppingCart.readonly || false}/>
  );
}

// AgentPercentDiscountField.propTypes = { }

export default AgentUnitPriceField;

