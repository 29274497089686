import _ from 'lodash';
import React from 'react';
import {Provider, connect} from 'react-redux';

import ShowCustomerVehicleContainer from "./ShowCustomerVehicleContainer";
import {getReduxStore} from "../ReduxStore/store";

function mapStateToProps(state) {
  const {vehicleSlice = {}} = state;

  return _.pick(vehicleSlice, 'customerVehicle');
}

let Wrapper = connect(mapStateToProps)(ShowCustomerVehicleContainer);

/*
 * ShowCustomerVehicleApp -
 * found in the header of every page, this component has the vehicle
 * stored in the user's Ruby session, if any, in its initialVehicle property.
 * Upon initial load it will send this to Redux for use by the VehicleChooser.
 * Later changes made by the VehicleChooser will be indicated in this component
 * as well.
 */
export default function ShowCustomerVehicleApp({...props}) {
  return <Provider store={getReduxStore()}>
    <Wrapper {...props} />
  </Provider>
}
