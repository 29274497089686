import React, {Component} from 'react';
import EmbeddedConfig from "../../../lib/EmbeddedConfig";

/**
 * Display product's NAPA sku, inside a 'span' tag.
 * Camelcase-agnostic.
 *
 * @param sku
 * @param product
 * @param caption
 * @returns {JSX.Element|null}
 * @constructor
 */
export default function NapaSkuDisplay({sku, product, caption = 'Store SKU:', tagName: TagName = 'span'}) {

  let conf = EmbeddedConfig.instance();

  if (conf.fetch('name') != 'napaonline') {
    return null;
  }

  if (!sku && product)
    sku = product.fullNapaSku || product.full_napa_sku;

  if (!sku) {
    return <TagName className="napa-sku-display napa-sku-empty">
      <span>Availability: Online / Ship To Home Only</span>
    </TagName>
  }

  return (
    <TagName className="napa-sku-display napa-sku-exists">
      <span>{caption} </span>
      {' '}
      <strong>{sku}</strong>
    </TagName>
  );
}
