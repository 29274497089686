import React from 'react';
import PropTypes from 'prop-types';
import ItemClusterLevel from "./ItemClusterLevel";
import SelectedItemDetail from "./SelectedItemDetail";
import {camelizeKeysDeep} from "../../../../ReduxStore/Helpers/transformObject";
import {levelIsEnabled} from "../../../logic/ClusterLevels";

/*
 * ItemClusterMain - the "widgets" component of a cluster, with
 * the selectors; the central of the three columns.
 *
 */
export default function ItemClusterMain({
  cluster, clusterState, itemCache, updateState,
}) {

  const {selections} = clusterState;
  const {levels} = cluster;

  const enabledLevels = cluster.levels.filter(
    levelName => levelIsEnabled({levelName, levels, selections}));

  const selectedItem = camelizeKeysDeep(clusterState.selectedItem);

  return <div className="cluster-main">
    <h3 className="text-brand-blue text-xl">{cluster.headline}</h3>

    <div className="cluster-selectors">
      {enabledLevels.map((levelName, position) =>
        <ItemClusterLevel key={levelName} name={levelName} position={position}
          enabled={true}
          value={selections[levelName]}
          clusterState={clusterState}
          cluster={cluster}
          itemCache={itemCache}
          updateState={updateState}
        />
      )}
    </div>

    {selectedItem && <SelectedItemDetail item={selectedItem}/>}
  </div>
}

ItemClusterMain.propTypes = {
  itemApplications: PropTypes.array.isRequired,
  products: PropTypes.object.isRequired,
  clusterState: PropTypes.object,
  updateState: PropTypes.func.isRequired
}
