import PageLogic from './PageLogic';

import OrderStatusPage from './Orders/OrderStatusPage';
import NewReviewPage from './Reviews/NewReviewPage';

export default class PageLogicFactory {
    static classes = {
        "orders-show": OrderStatusPage,
        // "product_groups-show" : GroupPage,
        // "products-show" : GroupPage,
        "product_reviews-new": NewReviewPage,
        "default" : PageLogic
    };

    setBody(attrs = {}) {
        let { $body } = (attrs || {});

        if (!$body)
            $body = $('body');

        this.$body = $body;
        this.body_id = $body.attr('id');
    }

    classForPage() {
        let klass = PageLogicFactory.classes[this.body_id];
        if (!klass)
            klass = PageLogic;

        return klass;
    }

    createForPage(attrs = {}) {
        this.setBody(attrs);

        let klass = this.classForPage();
        return new klass({ body: this.$body });
    }
}
