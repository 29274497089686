/**
 * Not used in production, this is a placeholder for
 * new components as I write them, to first ensure I
 * get the properties right.
 */
import React from 'react';

export default function PropsDumper(props) {
  return <pre>
    {JSON.stringify(props,null,2)}
  </pre>
}
