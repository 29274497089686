/*
 * 2023-05 MH ripped out obsolete loader stuff and jquery.cookie
 * 2018-12 MH refactored from LocalApi
 *
 * withService('maxmind', (mm) => alert(mm.isLoaded()));
 */

import SpeakerToAnything from './SpeakerToAnything';
import Cookies from 'universal-cookie';

export default class SpeakerToMaxmind extends SpeakerToAnything {
  isLoaded() {
    const cookies = new Cookies();
    const c = cookies.get('__mmapiwsid');
    return !!c;
  }
}
