import _ from 'lodash';
import LloydCartItem from "../models/LloydCartItem";
import LloydItemFactory from "./LloydItemFactory";

/**
 * Used by LloydCart for item creation.
 */
export default class CartItemFactory {
  constructor({cart, lloydProducts, products}) {
    this.cart = cart; // ???
    this.lloydProducts = lloydProducts;
    this.products = products;
  }

  get lloydItemFactory() {
    return new LloydItemFactory({
      products: this.products,
      lloydProducts: this.lloydProducts
    })
  }

  /**
   *
   * @param partNumber
   * @returns {LloydCartItem}
   */
  createCartItem(partNumber) {
    let {cart} = this;

    let lloydItem = this.lloydItemFactory.buildItem(partNumber);
    if (!lloydItem)
      throw new Error("cannot find lloyd item " + partNumber);

    // Create the item, set its options, return it.
    let cartItem = new LloydCartItem({
      lloydItem: lloydItem,
      vehicle: cart.vehicle
    });

    // Set the default "Product Unique", if there is only one option.
    this.setDefaultUnique(cartItem);

    this.setDefaultOptions(cartItem);

    //cartItem.setDefaultOptions();
    //for (let opt in this.options)
    //cartItem.setOption(opt, this.options[opt]);
    return cartItem;
  }

  setDefaultUnique(cartItem) {
    let {lloydItem} = cartItem;

    if (cartItem.requiresUniqueSelection()) {
      console.log(`cart item requires selection of product unique from: ${lloydItem.productUniques()}`);
      return;
    }

    // no product_unique? use brand_application_code here.
    cartItem.options.setOption('product_unique', {
      id: lloydItem.brand_application_code
    });
  }

  /**
   * Upon activating a new CartItem (ticking the box), copy all global options
   * from the cart into this item.
   *
   * @param cartItem
   */
  setDefaultOptions(cartItem) {
    let {options = {}} = this.cart;

    cartItem.options.inheritOptions(options);
  }

  // getLloydItem(partNumber) {
  //
  // }

}
