
const DIGITS = [ "0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];

/**
 * Parse any input phone number and deal with international prefix.
 *
 */
export default class WorldPhoneNumber {
  constructor(raw) {
    raw = this.raw = (raw || '').toString().trim();
    this.chars = raw.split('');
    this.digits = this.chars.filter( (n) => _.includes(DIGITS, n) );
  }

  // user entered in international format.
  hasPlus() {
    return (this.chars[0] === '+');
  }

  isAmerican() {
    const { digits } = this;
    const len = digits.length;

    if (this.hasPlus()) {
      return (digits[0] == '1'); // && len >= 11
    } else {
      return (len >= 10);
    }
  }
}
