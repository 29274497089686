import Orchestrator from '../lib/Orchestrator';
import SpeakerToLiveChat from './SpeakerToLiveChat';
import SpeakerToMaxmind from './SpeakerToMaxmind';
import SpeakerToJustuno from './SpeakerToJustuno';
import SpeakerToKlaviyo from './SpeakerToKlaviyo';
import SpeakerToAffirm from './SpeakerToAffirm';

// FIXME / TODO:
// Klaviyo and Justuno logic is elsewhere - see AAGUser
// Refactor those when we start doing server-side JS.
//
export default class ExternalServices extends Orchestrator {
  constructor() {
    super();

    if (!global.withService) {
      global.withService = this.withService.bind(this);
      global.withServiceUnsafe = this.withServiceUnsafe.bind(this);
    }
  }

  allStatus() {
    let out = {};
    for (let key of _.keys(this)) {
      let s = this[key];
      if (s && s.getInfo)
        out[key] = s.getInfo();
      else
        out[key] = 'UNKNOWN';
    }
    return out;
  }

  startup(page) {
    // 2023-05 Facebook, Facebook Events, Twitter, Inspectlet were previously disabled in prod

    this.safely("load Klaviyo", () =>
      this.klaviyo = new SpeakerToKlaviyo({name: 'klaviyo'}).startup(this));

    this.safely("load Maxmind", () =>
        this.maxmind = new SpeakerToMaxmind({name: 'maxmind'}).startup(this));

    this.safely("load LiveChat", () =>
        this.live_chat = new SpeakerToLiveChat({name: 'live_chat'}).startup(this));

    // 2021-07 inspectlet update broke jQuery global; disable for now
    // this.safely("load external inspectlet",
    //   () => this.inspectlet = new SpeakerToInspectlet({name: 'inspectlet'}).startup(this));

    this.safely("load Justuno", () =>
        this.justuno = new SpeakerToJustuno({name: 'justuno'}).startup(this));

    this.safely("load affirm", () =>
        this.affirm = new SpeakerToAffirm({name: 'affirm'}).startup(this));

    return this;
  }

  // window.withService is bound to this.
  // Wait for a service to become available, then invoke the function
  // (passing in the SpeakerToService as arg)
  // Will retry automatically every 100ms
  withService(name, fn, opts = {}) {
    if (!fn)
      return this.getService(name);

    return this.safely("with service " + name,
      () => this.withServiceUnsafe(name, fn, opts));
  }

  // withService('live_chat', function(service) { }, )
  withServiceUnsafe(name, fn, opts = {}) {
    let s = this.getService(name);
    if (!s) {
      let msg = `No such service: ${name}`;
      console.error(msg);
      return {status: 'error', message: msg};
    }

    if (s.isLoaded()) {
      return fn(s);
    } else {
      if (!opts)
        opts = {};

      let count = opts._with_service_retry ? (+(opts._with_service_retry) + 1) : 1;
      // stash updated count asap
      opts._with_service_retry = count;

      if (count > 10) {
        console.error(`withService(${name}) - service not yet loaded, retry count exceeded`)
        return false;
      }

      // Try again in 100ms
      let retry = 100 * count;
      console.warn(`withService(${name}) [count=${count}] - service not yet loaded, try again in ${retry}ms (retry count: ${opts._with_service_retry}).`)
      globalThis.setTimeout && globalThis.setTimeout((() => this.withServiceUnsafe(name, fn, opts)), retry);
    }
  }

  getService(name) {
    let s = this[name];
    if (!s) {
      console.warn(`getService(${name}) called but service does not exist`);
      return null;
    }
    return s;
  }
}
