import React, {Component} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import CheckoutFormLabel from "../form/CheckoutFormLabel";
import ValidationMessage from "../form/ValidationMessage";
import FieldHelper from "../form/FieldHelper";

function getHelper(props) {
  let args = _.pick(props,
    'name', 'label', 'optional', 'activeField', 'object');

  return new FieldHelper({
    objectName: `credit_card`,
    // value: '',
    ...args
  });
}

export default function BraintreeWidget(props) {
  const {optional = false} = props;

  const helper = getHelper(props);
  const error = helper.getError();
  const id = helper.getId();

  // console.log(name + " render with value: " + value);

  return (<div className={'braintree-field ' + helper.getClassString()}>
    <CheckoutFormLabel label={helper.getLabel()} id={id + "-label"}
      optional={optional} target={helper.getId()}/>

    <div className="bt-input2b" id={id}/>

    {error && <ValidationMessage error={error}/>}
  </div>);
}


