import React, {Component} from 'react';

export default function TinyCartContents(props) {
  const {cartItems} = props;

  // 1x 09235135
  //    Curt Manufacturing
  return <div className="tiny-cart-contents">
    <table className="tiny-cart-table">
      <tbody>
      {cartItems.map((item) =>
        <TinyCartItem item={item} key={item.id}/>)}
      </tbody>
    </table>
  </div>
}

function TinyCartItem({item}) {
  let total = new Money(item.effectiveUnitPrice);

  return <tr>
    <td className="detail" title={item.vendorsku}>
      <strong>{item.brandName}</strong>
      <div className="item-name">{item.name}</div>
    </td>
    <td className="tot">
      <div className="total">{total.toString()}</div>
      {(item.quantity != 1) &&
        <div className="q"><span>x {item.quantity}</span></div>}
    </td>
  </tr>
}
