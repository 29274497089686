import React from 'react';
import {Provider, connect} from 'react-redux';

import EyeInTheSkyContainer from "./EyeInTheSkyContainer";
import {getReduxStore} from "../ReduxStore/store";

/**
 * 2022-08 MH - this invisible component, in the page header, watches
 * everything in Redux. Its children can subscribe to various properties
 * and take action when something changes.
 */

function mapStateToProps(state) {
  const {scrapyardSlice = {}, vehicleSlice = {}, railsContext = {}, userStore={}} = state;

  let props = Object.assign({},
    scrapyardSlice, // promote to top level
    {railsContext, userStore},
    _.pick(vehicleSlice, 'customerVehicle')
  );
  props.cartSlice ||= state.cart; // badly named slice.

  return props;
}

let Wrapper = connect(mapStateToProps)(EyeInTheSkyContainer);

export default function EyeInTheSkyApp({...props}) {
  return <Provider store={getReduxStore()}>
    <Wrapper {...props} />
  </Provider>
}
