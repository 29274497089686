
import lightGallery from "lightgallery";
import lgVideo from 'lightgallery/plugins/video';

const GALLERY_DEFAULTS = {
  licenseKey: '6519A1DC-82FE4CA7-BD5E36A4-B0D9072E',
  closable: true,
  selector: '.pic',
  closeOnTap: true,
  plugins: [lgVideo],
  youTubePlayerParams: {
    //modestbranding : 0,
  }
};

function resolveContainer(container) {
  let element = container;

  if ( (typeof element) == 'string') {
    element = document.getElementById(container);
  }

  if (!element) {
    console.error("createLightGallery: unable to find element " + container + ", cannot init." );
    return null;
  }

  return element;
}

export default function createLightGallery(container_or_id, options={}) {
  let container = resolveContainer(container_or_id);
  if (!container) {
    console.error("cannot find container for light gallery", container_or_id);
    return null;
  }

  options = _.extend({}, GALLERY_DEFAULTS, options);

  return lightGallery(container, options);
}

