import React from 'react';

import SaveCartEmailInput from '../components/SaveCartEmailInput';

import SaveCartTitle from '../components/SaveCartTitle';
import CloseSaveCart from '../components/CloseSaveCart';
import SaveCartSubmit from '../components/SaveCartSubmit';
import ValidationError from "../components/ValidationError";

export default class SaveCartFallbackContents extends React.Component {
  static defaultProps = {
    title: "",
    logo: true,
    prompt: "Still thinking?  Save your cart now and access it from any device later.",
    closeCaption: "Close",
    submitCaption: "Save Cart"
  };

  getSavings() {
    let {shoppingCart} = this.props;
    let {subtotalSavings = 0} = shoppingCart;

    return new Money(subtotalSavings);
  }

  renderInstructions() {
    let {prompt} = this.props;

    return (<div className="save-cart-prompt">
      <p className="headline">{prompt}</p>
      <p className="instructions">Enter your email below and we'll send you a link to your cart.</p>
    </div>);
  }

  renderForm() {
    return <div className="save-cart-form-contents">
      <SaveCartEmailInput {...this.props} />
    </div>
  }

  render() {
    return this.defaultRender();
  }

  defaultRender() {
    const {saveCartStatus} = this.props;
    const {flavour} = saveCartStatus;

    return (
      <div className="inner">
        <SaveCartTitle {...this.props} />
        {this.renderInstructions()}

        <ValidationError saveCartStatus={this.props.saveCartStatus} />

        {this.renderForm()}
        <SaveCartSubmit {...this.props} />
        <CloseSaveCart {...this.props}/>
      </div>
    );

  }
};

// live experiment - K&N filter
// mouse off page
// blue background popup - small-logo, "Still thinking? Save your cart...any device later.",
// enter your email, Email addr, Save Cart Button, Close.

// after submitting - popup goes away, page reloads, "Success!" in grey box across top.
// url becomes /cart/MZ-B88ZE?saved=1

// PAGE LEAVE - Power Stop brake (with coupon)
// leave idle 1 minute - nothing
// move off page
// blue background popup
// small logo, Lock in Savings of $8!, enter email, email input, save button, close.
// cancel, click Save, get plain white popup - FN, Email, checkbox.
