import _ from 'lodash';
import $ from 'jquery';
import React from 'react';

import AgentStatusCartMissing from './AgentStatusCartMissing';
import AgentStatusCartExists from './AgentStatusCartExists';
import CallerCartHelper from './CallerCartHelper';

export default class AgentStatusCart extends React.Component {
  renderNonexistent() {
    return <div className="agent-status-cart disconnected"  />;
  }

  render() {
    let { caller_cart_helper, phone_call } = this.props;

    if (!phone_call)
      return this.renderNonexistent();

    caller_cart_helper = caller_cart_helper || new CallerCartHelper();

    let childProps = { caller_cart_helper, phone_call };

    if (! caller_cart_helper.customer_cart_id)
      return <AgentStatusCartMissing {...childProps} />;
    else
      return <AgentStatusCartExists {...childProps} />
  }
}




/*
 * scenario 1: caller HAS CART
 *    - agent has RIGHT cart - display with [ V Connected ]
 *    - agent has WRONG CART or NO CART - need to switch to it in ONE CLICK.
 *      -- link to it in the cart display area

 * scenario 2: caller has NO CART
 *    - ask for cart id - entry field.
 *    - agent has WRONG CART - big warning message
 */
