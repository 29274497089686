import { camelizeKeysDeep} from "../../ReduxStore/Helpers/transformObject";
import React from "react";
import LinearProductGridApp from "../../GroupGrid/components/container/LinearProductGridApp";

function buildHeadline({customerVehicle, item}) {
  let v = customerVehicle;
  if (v) {
    let make = v.shortMakeName || v.vehicleMakeName;
    if (make)
      return `Recommended for your ${make} ${v.shortModelName || v.vehicleModelName}`
  }

  if (item && item.productGroup) {
    let name = item.productGroup.shortName || item.productGroup.name;
    if (name)
      return "Recommended based on your " + name;
  }

  return  "Other customers shopped for";
}

export default function ModalCrossSells({
  item, customerVehicle, recommendations, ...props
}) {

  if (!recommendations || !recommendations.gridItems)
    return null;

  const { gridItems } = recommendations;

  const headline = buildHeadline({customerVehicle, item});

  return <div className="modal-cross-sells bg-gray-50 p-1 pb-2 mt-2 clearfix">
    <h3 className="text-brand-primary text-xl text-bold m-1 ">{headline}</h3>

    <LinearProductGridApp items={gridItems} showControls={false} mobileColumns="2" />
  </div>
}

