// Quantity column, with +/- buttons and Trashcanman link.
import React from "react";
import {sendRemoveFromCartEvent} from "../../../Analytics/adobeCartEvents";

export default function CartItemQty({item, cartItems, shoppingCart, callbacks, ...props}) {

  const handleUpdateQuantity = (evt) => {
    let {target} = evt;

    callbacks.updateItem({
      id: item.id,
      changes: {quantity: target.value}
    });
  }

  const handleRemoveItem = (evt) => {
    if (!confirm("Remove item from your cart?"))
      return;

    callbacks.deleteItem({id: item.id});

    try {
      const event = sendRemoveFromCartEvent({
        items: [item], cartItems, shoppingCart})
    } catch (err) {
      console.error(err);
    }
  }

  return <div className="col-qty">
    <div className="above">
      <input className="item-quantity" type="number"
        min="1" max="999" name={`cart_items[${item.id}][quantity]`}
        onChange={handleUpdateQuantity}
        defaultValue={item.quantity}
      />
    </div>

    <div className="below">
      <a className="remove-link" title="Remove Item"
        onClick={handleRemoveItem}>
        <span className="fa fa-lg fa-trash"/>
      </a>
    </div>
  </div>
}

// had a throbber under "below" that wasn't being used.
//<div className="throbber"><img
//  src="/img/design/ajax/throbber-horizontal-red.gif" alt="loading"/></div>
