import _ from 'lodash';

export default class VehicleModel {
    constructor(data) {
      data = _.mapKeys(data, (v, k) => _.camelCase(k));
      Object.assign(this, data);
    }

    fullName() {
        return `${this.makeName} ${this.name}`;
    }
}
