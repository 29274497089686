import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {sendKlaviyoIdentify} from "../ExternalServices/Klaviyo/KlaviyoHelpers";
import {setEmailUser} from "../ReduxStore/userSlice";

export default function KlaviyoUnsubscribeApp({
  email, ...props
}) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!email) return;

    const user = {email, source: 'unsubscribe'};
    dispatch(setEmailUser(user));

    const attrs = {
      subscribed: false,
      unsubscribed: true,
      _debug: 'KlaviyoUnsubscribeApp'
    }

    sendKlaviyoIdentify({
      user, attrs
    });

    withService('klaviyo', (speaker) => {
      // FIXME KLAVIYO
      let k = speaker.getClient();
      k.user = user;
      k.track("Unsubscribed", { customer_properties: attrs })
    });

  }, [email]);

  return null;
}

KlaviyoUnsubscribeApp.propTypes = {
  email: PropTypes.string.isRequired
}
