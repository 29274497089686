import React from 'react';
import PropTypes from 'prop-types';
import {find} from 'lodash';

import OptionGroup from './OptionGroup';
import BorderTypeSwitch from './BorderTypeSwitch';
import LloydCart from "../models/LloydCart";
import OptionDefinition from "../models/OptionDefinition";
import CartOptions from "../models/CartOptions";

//
// BorderPicker - for Ultimat, choose the border around the
// mats.  (This is properly called Binding but as Ruby uses that for
// something else I have elected to censor it)
//
// The possible states for cart.options.border:
//
//  null - nothing has been selected
//  { id: STANDARD } - standard border selected
//  { id: numeric } - premium border selected.
//
//  LloydBorderchooser
//      BorderTypeSwitch - select std/premium
export default function BorderPicker(props) {
  const {option, callbacks, lloyd, cart} = props;
  const borderType = cart.options.getOption(CartOptions.BORDER_TYPE);
  const isPremium = (borderType === 'premium');

  let values = [];
  if (isPremium) {
    // FIXME object methods
    let groups = option.getGroups() || []; // [ {code: STANDARD, ...}, { code: PREMIUM, ...}
    let g = find(groups, (g) => g.code !== 'STANDARD') ||
      groups[groups.length - 1];

    // FIXME snake_case
    if (g && g.value_list)
      values = g.value_list;
  }

  return (
    <div className="inner">
      <div id="lloyd-borders-top">
        <BorderTypeSwitch
          cart={cart}
          callbacks={callbacks}
          lloyd={lloyd}/>
      </div>
      <div id="lloyd-borders-bottom"
        className={isPremium ? "premium-selected" : "nothing-selected"}>
        <OptionGroup {...props} values={values}/>
      </div>
    </div>);

}

BorderPicker.propTypes = {
  cart: PropTypes.instanceOf(LloydCart).isRequired,
  option: PropTypes.instanceOf(OptionDefinition).isRequired
};

BorderPicker.defaultProps = {
  id: 'lloyd-borders',
  name: 'border',
};


