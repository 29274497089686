import {camelizeKeys} from "../../ReduxStore/Helpers/transformObject";

export function requireVehicleField(vehicle, field) {
  vehicle ||= {};
  let value = vehicle[field];
  if (!value) {
    vehicle = camelizeKeys(vehicle);
    value = vehicle[field];
  }

  if (!value) {
    console.error(`requireVehicleField: no ${field} in ${JSON.stringify(vehicle)}`);
  }

  return value;
}

export function vehicleToChoices(vehicle) {
  vehicle = camelizeKeys(vehicle);

  if (!vehicle) return {};

  const choices = {
    year: vehicle.year,
    make: requireVehicleField(vehicle, "vehicleMakeId"),
    model: requireVehicleField(vehicle, "vehicleModelId")
  };

  return choices;
}

export function describeVehicle(vehicle) {
  vehicle = camelizeKeys(vehicle);

  const words = [
    vehicle.year,
    vehicle.shortMakeName || vehicle.vehicleMakeName,
    vehicle.shortModelName || vehicle.vehicleModelName
  ];

  if (!words[0] || !words[1]) {
    console.warn("describeVehicle - missing name fields:" , vehicle);
  }

  return words.join(" ");
}
