import LloydItem from "../models/LloydItem";
import _ from "lodash";

/*
 * Build LloydItems from raw data in props.
 *    lloydProducts property, managed by the Redux store, is a
 *    hash of extended product code like UM5_3306650
 *    and snake-cased raw data from the API.
 *
 * A LloydItem is a class that wraps this, and also sets "product" to
 * be the AAG product.
 */
export default class LloydItemFactory {
  constructor({lloydProducts, products} = {}) {
    this.lloydProducts = lloydProducts;
    this.products = products;
  }

  /**
   *
   * @returns {*|{}}
   */
  allItems() {
    return this._allItems || (this._allItems =
    _.mapValues(this.lloydProducts, (v, k) => this.buildFromData(v)));
  }

  /**
   * Given a part number, build a LloydItem.
   *
   * @param partNumber
   * @returns {LloydItem}
   */
  buildItem(partNumber) {
    let raw = this.lloydProducts[partNumber];
    if (!raw) {
      alert("no such product " + partNumber);
      return;
    }

    return this.buildFromData(raw);
  }

  /**
   * Get the items in correct sort order.
   *
   * @returns {Array<LloydItem>}
   */
  itemList() {
    let all = this.allItems();

    return _(all).values()
      .orderBy((i) => i.sortOrder())
      .value();
  }

  /**
   * @private
   * @param data {Object} raw Lloyd item with fields like part_number,
   *   {"part_number":"UM5","product_line_code":"UM","products_per_row":"0",
   *   "package_content_description":"2 Pc Front Mats","package_content_description_sequence":"5.000",
   *   "add_to_cart_grouping_flag":"1","pcds_group":null,"pcds_group_copy":null,"price_group_code":"2","part_number_note":"","quantity_default":null,"quantity_max":null,"show_disclaimer_flag":null,"brand_application_code":"3081750","vehicle_unique_string":["GT"],"product_unique_list":[{"product_unique_string":"Convertible","product_unique_note":"","product_unique_image":"https://s3.amazonaws.com/configuretech/manufacturer/LloydMats/CLRB/media/image/PCDS3-400.jpg","product_unique_group_code":null,"brand_application_code":"3081750"},{"product_unique_string":"Coupe","product_unique_note":"","product_unique_image":"https://s3.amazonaws.com/configuretech/manufacturer/LloydMats/CLRB/media/image/PCDS3-400.jpg","product_unique_group_code":null,"brand_application_code":"3081710"}],"desired_retail_price":"98.90","package_content_description_copy":null,"long_copy":"Lloyd Mats is the industry’s largest aftermarket manufacturer of all makes and models, custom fit carpet logo floor mats for passenger cars, trucks, vans and SUVs. Lloyd’s Ultimat, Velourtex, LUXE, TruBerber and Classic Loop custom floor mats are produced in our state of the art Northridge, California facility from materials made in the USA. Our exact fit, precision cut mats are available for vehicle applications from the 1950s to the present and include many one-piece mats for complete passenger area coverage, trunk and cargo areas. ","option_group_list":[],"instruction_files":[],"warranty_files":[],"media_list":["https://s3.amazonaws.com/configuretech/manufacturer/LloydMats/CLRB/media/image/PCDS3-400.jpg"],"product_note":[],"type":"LloydProduct","extended_part_number":"UM5_3081750"}
   *
   * @returns {LloydItem}
   */
  buildFromData(data) {
    let item = new LloydItem(data);

    // add the AAG product as item.product
    let prods = this.productsForCode(item.lloydCode);
    if (prods[0])
      item.product = prods[0];
    if (prods.length != 1)
      console.error(`for code ${item.lloydCode.toString()} there are ${prods.length} products`);

    return item;
  }

  /**
   * @private
   * @param code {LloydProductCode}
   * @returns {*}
   */
  productsForCode(code) {
    if (typeof code == 'string')
      code = new LloydProductCode(code);

    return _(this.products).values()
      .filter( (p) => code.equals(p.vendorline))
      .value();
  }

}

