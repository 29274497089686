import React from 'react';
import CompareItemsContainer from "./CompareItemsContainer";
import PropTypes from "prop-types";

/**
 * CompareItems app - single page, non-Redux.
 * 2023-04 All components modern, all data is camelized
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function CompareItemsApp(props) {
  // return <pre>{JSON.stringify(props, null, 2)}</pre>
  return <CompareItemsContainer {...props} />
}

CompareItemsApp.propTypes = {
  allItems: PropTypes.array,
  attributes:PropTypes.array,
  compatibility: PropTypes.object,
  vehicle: PropTypes.object,
  allScores: PropTypes.object,
  allValues: PropTypes.object
}
