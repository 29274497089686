import _ from 'lodash';

const isDevelopment = (process.env.NODE_ENV == 'development');

export default class FieldHelper {
  constructor({object, objectName, name, value, optional, label, placeholder,
                activeField, autoComplete, currentAgent, addressType}) {

    this.object = object || {};
    this.name = name;
    this.objectName = objectName || 'UNKNOWN';
    this.value = value;
    this.label = label;
    this.optional = optional;
    this.activeField = activeField;
    this.autoComplete = autoComplete;
    this.currentAgent = currentAgent;
    this.addressType = addressType;
  }

  // return a Rails-style field ID.
  getId() {
    return `${this.objectName}-${this.name}`;
  }

  // return a Rails-style field name
  getName() {
    return `${this.objectName}[${this.name}]`;
  }

  getLabel() {
    if (this.label)
      return this.label;

    let label = (""+this.name); // .replace('_', ' ');
    return _.startCase(label);
  }

  isTouched() {
    const {touched} = this.object;
    return (touched && touched[this.name]);
  }

  isFocused() {
    const {name, activeField} = this;
    return activeField && (activeField === name);
  }

  // Classes for the CONTAINER.
  getClasses() {
    // const {focused, value} = this.state;
    let classes = [
      'checkout-field',
      this.name
    ];

    if (this.isFocused()) {
      classes.push('focused');
    }

    if (this.getError()) {
      classes.push('with-error');
    }

    if (this.optional)
      classes.push('optional');

    if (this.value)
      classes.push('populated');

    return classes;
  }

  getClassString() {
    return this.getClasses().join(' ');
  }

  getError() {
    const {errors = {}} = this.object;

    const error = errors[this.name];
    return Array.isArray(error) ? error[0] : error;
  }

  /**
   * Return the full value of the autocomplete field,
   * or false if it should be "off".
   *
   * @returns {string|boolean}
   */
  getAutoComplete() {
    if (this.currentAgent)
      return false;
    else if (!this.autoComplete)
      return false;

    return `${this.addressType} ${this.autoComplete}`;
  }

  getPlaceholder() {
    let { placeholder, label, name } = this;
    if (placeholder) return placeholder;
    if (label) return label;

    return _.startCase(name);
  }
}
