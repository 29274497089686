import EditObject from "../logic/EditObject";
import DummyCardValidator from "../logic/DummyCardValidator";

/**
 * Editor for the "Dummy Card" object. We don't
 * actually see field values for this object.
 */
export default class EditDummyCard extends EditObject {
  // static fieldRules = {};

  static fieldLabels = {
    number: "Card Number",
    postal_code: "Billing Zip Code",
    cvv: "CVV Security Digits"
  };

  constructor({allStatuses, ...stuff}) {
    super(stuff);
    this.allStatuses = allStatuses;
  }

  // Subclasses should override.
  getValidator() {
    return new DummyCardValidator({
      object: this.object,
      rules: this.constructor.fieldRules,
      labels: this.constructor.fieldLabels,
      allStatuses: this.allStatuses
    })
  }
}
