import React from 'react';
import PropTypes from 'prop-types';

/**
 * Continue button (and its div wrapper) at the bottom of each of the
 * check out panels. Any child components will be rendered inside
 * the button, so use that technique for affirm/paypal logos.
 *
 */
export default function ContinueButton(props) {
  const {onSubmit, caption, className, disabled, children, id, tabIndex} = props;

  const allClasses = [
    "continue-button-wrapper",
    (disabled ? 'submit-disabled' : 'submit-enabled'),
    className
  ];

  const handleClick = (evt) => {
    if (!disabled) {
      onSubmit(evt);
    }
  }

  return (
    <div id={id || null} className={allClasses.join(' ')}>
      <button type="button" className="btn" tabIndex={tabIndex} onClick={handleClick} disabled={disabled}>
        {children}
        {caption}
      </button>
    </div>
  );

}

ContinueButton.propTypes = {
  id: PropTypes.string,
  tabIndex: PropTypes.number,
  caption: PropTypes.string,
  classes: PropTypes.array,
  disabled: PropTypes.bool,
  onSubmit: PropTypes.func,
  htmlCaption: PropTypes.string
};

ContinueButton.defaultProps = {
  caption: "",
  className: "",
  disabled: false,
  tabIndex: 0
};
