import Money from "../../../lib/Money";
import React from "react";

export default function CartDisplayTotal(props) {
  let { label, value } = props;

  // savings line is optional, suppress when zero
  if (!value && props.optional)
    return null;

  let id = "summary-" + props.id;
  return <li id={id}>
    <div className="lbl">{label}:</div>
    <div className="amt">
      <span>{new Money(value).toString()}</span>
    </div>
  </li>
}
