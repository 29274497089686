import React, {Component} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

export default class SameAddressSwitch extends Component {
  static propTypes = {
    same: PropTypes.bool.isRequired,
    setSameAddress: PropTypes.func.isRequired,
    tabIndex: PropTypes.number
  };

  static defaultProps = {
    tabIndex: 0
  };

  onChange = (e) => {
    const checked = e.target.checked;
    // radio values are 1/0, convert these to bool
    // const value = +(e.target.value);
    this.props.setSameAddress(checked);
  }

  render() {
    let { same, tabIndex=0 } = this.props;

    return (
      <div className="same-address-switch">
        <div className="option option-same">
          <label>
            <input type="checkbox" name="same" value="1" tabIndex={tabIndex} checked={same} onChange={this.onChange}/>
            <span className="label">
              <strong>Billing Address</strong>
              {' is the same as '}
              <strong>Shipping Address</strong>
              </span>
          </label>
        </div>
      </div>
    );
  }

  old_render() {
    let { same } = this.props;

    return (
      <div className="same-address-switch">
        <div className="option option-same">
          <label>
            <input type="radio" name="same" value="1" checked={same} onChange={this.onChange}/>

            Same as Shipping Address
          </label>
        </div>

        <div className="option option-different">
          <label>
            <input type="radio" name="same" value="0" checked={!same} onChange={this.onChange}/>

            Enter a Billing Address
          </label>
        </div>

      </div>
    );
  }
}

