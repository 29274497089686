import ProductIndexEntry from "./ProductIndexEntry";
import LevelList from "../../models/LevelList";
import ProductIndexFilter from "./ProductIndexFilter";
import produce from 'immer';
import ClusterAutoSelect from "./ClusterAutoSelect";

//
// Implements callback logic for an ItemClusterLevel
//
export default class SelectionHandler {
  constructor({name, cluster, clusterState, itemCache, value}) {
    this.name = name;  // like "fitment" or "colour"
    this.value = value;

    this.cluster = cluster;  // cluster configuration
    this.clusterState = clusterState; // cluster current state - immutable but will clone it

    this.itemCache = itemCache; // look up items here.
  }

  get levelList() {
    return new LevelList(this.cluster.levels);
  }

  getUpdatedState() {
    if (! this.updatedState) {
      this.updatedState = produce(this.clusterState,
        (cs) => this.buildUpdate(cs));
    }

    return this.updatedState;
  }

  buildUpdate(clusterState) {
    // Combine current selection with the selections of
    // levels above, in state.
    this.addNewSelections(clusterState);

    // clear things left by previous operations
    this.resetState(clusterState);

    // get new products
    clusterState.matchingProducts = this.selectMatchingProducts(
      clusterState.selections);

    let autoSelect = new ClusterAutoSelect({
      name: this.name,
      cluster: this.cluster,
      appliedItems: this.itemCache
    });

    if (autoSelect.call(clusterState)) {
      // success
      this.hideLastLevel(clusterState, true);
    } else {
      clusterState.focusOn = this.levelList.levelAfter(this.name);
    }

    this.setAppliedItem(clusterState);
  }

  setAppliedItem(clusterState) {
    let applicationId = clusterState.selections.application_id;
    if (applicationId) {
      let item = this.itemCache.getAppliedItem(applicationId);
      if (item)
        clusterState.selectedItem = item;
    }
  }

  resetState(clusterState) {
    // move focus to next...
    clusterState.focusOn = null;

    // clear previously selected winning item
    clusterState.selectedItem = null;

    // show application_id if absent
    this.hideLastLevel(clusterState, false);

    // remove flashing orange box if present
    clusterState.highlighted = false;
  }

  // Update this.selections based on previous selections
  // and the newly selected level.
  addNewSelections(clusterState) {
    let selections = this.levelList.extendSelections(
      clusterState.selections, this.name, this.value
    );

    clusterState.selections = selections;
    return selections;
  }

  /**
   * Filter products after making the new selection.
   *
   * @param selections {Object}
   * @returns {Object} product_id => product, product_id => product
   */
  selectMatchingProducts(selections) {
    let filter = new ProductIndexFilter(this.cluster.productIndex);

    return filter.filterProducts(selections);
  }

  hideLastLevel(clusterState, hidden) {
    let futureLevels = this.levelList.levelsAfter(this.name);
    let name = _.last(futureLevels);

    let levelOptions = clusterState.levelOptions || (clusterState.levelOptions = {});
    let nextOptions = levelOptions[name] || (levelOptions[name] = {});
    nextOptions['hidden'] = hidden;
  }
}
