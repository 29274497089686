import React, {useState, useEffect} from 'react';

import createLightGallery from "../../Common/services/createLightGallery";
import {bindScrollButtons} from "../../Utility/scroll";

export default function GroupPageApp({group, product, ...props}) {
  return <div>
    <LightGalleryLauncher/>
    <InitReviewLinks />
  </div>
}

function LightGalleryLauncher(props) {
  const [gallery, setGallery] = useState(null);

  function buildGallery() {
    let g = createLightGallery('product-group-gallery', {
      selector: '.pic',
    });

    if (g)
      setGallery(g);
    else
      console.error("FAILED to init light gallery!")
  }

  useEffect(() => {
    buildGallery();
  }, []);

  return null;
}

function InitReviewLinks(props) {
  useEffect(() => {
    bindScrollButtons('.link-to-reviews', 'group-review-summary' )
  }, []); // run once
  return null;
}
