import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import AutocompleteOption from "./AutocompleteOption";

/**
 * 2023-01 modernized
 *
 * Smarter autocomplete widget. Displays whenever "suggestions"
 * is not empty.
 */
export default function AutocompleteResults(props) {
  const {unitsOnly, onSelectAddress, suggestions = [], caption} = props;

  const handleCloseClicked = (evt) => {
    onSelectAddress({evt});  // addr: null
  }

  console.log("AutocompleteResults: suggestions = " + (suggestions || []).length);

  if (_.isEmpty(suggestions))
    return <div className="autocomplete-results-empty"/>;

  return (
    <div className="autocomplete-results">
      <div className="close-link"><a className="click-me"
        onClick={handleCloseClicked}><span className="fal fa-lg fa-times"/></a></div>
      <div className="caption">
        {caption}
      </div>

      {suggestions && suggestions.map && <ul className="pick-list">
        {suggestions.map((addr, i) =>
          <AutocompleteOption
            onSelectAddress={onSelectAddress}
            unitsOnly={unitsOnly}
            key={i} addr={addr}
          />)}
      </ul>}
    </div>
  );

}

AutocompleteResults.propTypes = {
  caption: PropTypes.string,
  unitsOnly: PropTypes.bool,
  onSelectAddress: PropTypes.func.isRequired,
  suggestions: PropTypes.array.isRequired
};

AutocompleteResults.defaultProps = {
  caption: 'Suggestions',
  unitsOnly: false,
  suggestions: []
};
