export function buildItemListEvent(
  {
    items,    // items already in Google format.
    eventType = "view_item_list",
    params = {}
  }) {

  if (!items) {
    console.error("buildItemListEvent: no items")
  }

  // the third argument, full of optional stuff. "params" may overwrite these.
  // let total = _.sum(items.map(item => item?.price ? +item.price : 0))
  // let opts = {
  //   value: total ? total.toFixed(2) : 0,
  //   currency: 'USD'
  // };

  // merge in items last so it's physically last in the dump
  return ["event", eventType, Object.assign({}, params, {items})];
}

export function groupToGoogleItem(productGroup = {}) {
  if (!productGroup)
    return {};

  let {priceLowCents} = productGroup;
  let price = new Money(priceLowCents||0);

  let out = {
    item_id: productGroup.id,
    item_name: productGroup.name,
    item_brand: productGroup.brandName,
    item_variant: 'group',
    price: price.toDollars(),
    quantity: 1,
  }

  buildItemCategories({productGroup, out});

  return out;
}

export function buildItemCategories({categoryNames, productGroup, out = {}}) {
  if (productGroup && !categoryNames) {
    let {topCategories = [], subCategories = []} = productGroup;
    categoryNames = [...topCategories, ...subCategories];
  }

  if (categoryNames) {
    categoryNames.forEach((name, i) => {
      let key = (i > 0) ? `item_category${i + 1}` : "item_category";
      out[key] = name;
    });
  }

  return out;
}
