import _ from 'lodash';
import $ from 'jquery';
import React from 'react';

import AgentStatusMessage from './AgentStatusMessage';

export default class AgentStatusMain extends React.Component {
  render() {
    let { phone_call } = this.props;

    return <div className="agent-status-main">
      <AgentStatusCallDisplay {...this.props} />
      <AgentStatusMessage {...this.props} />
    </div>
  }
}

class AgentStatusCallDisplay extends React.Component {
  formatPhone(num) {
    num = (""+num).replace(/[^0-9]/g, '');  // digits only

    return [
      num.substr(0,3), num.substr(3,3), num.substr(6,4)
    ].join('.');
  }

  render() {
    let {phone_call, caller_id} = this.props;
    if (!phone_call)
      return <div className="agent-call">No phone call detected.</div>;

      //alert(caller_id);

    return <div className="agent-call">
      <span className="num">{this.formatPhone(phone_call.caller_id_number)} </span>
      {' • '}
      <span className="name">{phone_call.caller_id_name}</span>
    </div>;
  }
}

