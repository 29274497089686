import {useEffect, useRef} from "react";
import {findNearestAncestorWithClass} from "../Utility/html";

/**
 * Returns a reference. Attach this reference to your app's
 * top-level component, and it will then destroy any '.skeleton'
 * siblings or niblings upon first render.
 *
 * @returns {React.MutableRefObject<null>}
 */
export function useSkeletonLoading() {
  const ref = useRef(null);
  useSkeletonLoadingWithRef(ref);
  return ref;
}

export function useSkeletonLoadingWithRef(ref) {
  useEffect(() => {
    let node = ref?.current;
    if (!node) return;

    const container = findNearestAncestorWithClass(node, 'skeletonized', {quiet: true});
    if (container) {
      deSkeletonize(container);
    }
  }, [ref?.current]);
}

function deSkeletonize(container) {
  const skellies = container.getElementsByClassName('skeleton');

  Array.from(skellies).forEach(n => n.remove());

  if (container.classList) {
    container.classList.remove('skeletonized');
    container.classList.add('content-loaded');
  }
}
