// SHipping column, with forecast
import React from "react";
import ShippingForecastDisplay from "./ShippingForecastDisplay";

export default function CartItemShipping(props) {
  const {item, forecast} = props;
  if (!item)
    return null;

  let {productGroup = {}, effectiveShippingTotal = 0} = item;

  // tags: list of strings like [ "prop65", "klaviyo-welcome1", ]
  let delayed = _.includes(productGroup.tags, "covid-delay");

  let shipDollars = (effectiveShippingTotal && effectiveShippingTotal > 0) &&
    new Money(effectiveShippingTotal);

  return <div className="col-shipping">
    <div className="shipping">
      {
        shipDollars ? <div className="item-shipping">{shipDollars.toString()}</div>
          : <div className="free-shipping"><strong>Free Shipping</strong></div>
      }

      {delayed ? <CovidDisplay item={item}/> :
        <ShippingForecastDisplay forecast={forecast}/>}
    </div>
  </div>
}

function CovidDisplay(props) {
  return <div className="shipping-forecast shipping-forecast-covid tooltip-item">
    <div className="label">Delayed Shipping</div>
    <div className="tooltip">
      <p>Due to circumstances related to COVID-19,
        delivery times for product are taking longer than normal.
        We are doing everything we can to ship product as soon as possible.</p>
    </div>
  </div>
}

