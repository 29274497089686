import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import LloydCart from "../models/LloydCart";
import DisabledFormBottom from "./DisabledFormBottom";
import SubmittingFormBottom from "./SubmittingFormBottom";
import EnabledFormBottom from "./EnabledFormBottom";

// Note that component is rendered as nothingness
// when cart is empty.

// Under the cart items; holds the add to cart button and maybe some kind of
// summary with totals and whatnot.
export default function LloydFormBottom(props) {
  const {cart, cartErrors, submitting} = props;
  const items = (cart && cart.items) ? cart.items.toArray() : [];

  if (_.isEmpty(items)) return null;

  if (submitting) {
    return <SubmittingFormBottom {...props} />
  } else if (_.isEmpty(cartErrors)) {
    return <EnabledFormBottom {...props} />
  } else {
    return <DisabledFormBottom cartErrors={cartErrors}/>
  }
}

LloydFormBottom.propTypes = {
  cart: PropTypes.instanceOf(LloydCart)
}
