import Orchestrator from '../lib/Orchestrator';
import {garbage} from "../lib/deprecation";

const isDev = (process.env.NODE_ENV == 'development');

// NEW 2018 - support all the custom non-react logic of various
// page types - all the stuff that used to be in controller-specific
// files.
// localApi.page_logic will point to some subclass of this.

// 2024 note: this is a BASE CLASS, PageLogicFactory returns one subclass or another.
// these are defined in PageLogicFactory

export default class PageLogic extends Orchestrator {
    constructor(attrs) {
        super();
        _.extend(this, attrs);
    }

    startup(page) {
      // NOP, subclasses might override.
    }

}
