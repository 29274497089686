import React from 'react';

// modernized 2021-07
export default class AgentNoteTool extends React.Component {
  onCommentChanged = (evt) => {
    const {callbacks} = this.props;
    const {target} = evt;
    const {value} = target;

    callbacks.updateCart({agentComment: value})
  }

  render() {
    const {shoppingCart} = this.props;

    return (
      <div className="agents-only" id="#cart-agent-tools">
        <textarea id="shopping_cart_agent_comment"
          name="agent_comment"
          defaultValue={shoppingCart.agentComment}
          placeholder="Agent: write something here to help you remember this customer."
          onBlur={this.onCommentChanged}
        />
      </div>)
  }
}
