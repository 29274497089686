import CartCodeCookie from './CartCodeCookie';

export default function LegacyCartCountDisplay() {
}

LegacyCartCountDisplay.prototype.update = function () {
    var cc;
    cc = this.getCount();
    if (cc === null || isNaN(cc)) {
        return this.displayCount(0);
    } else {
        return this.displayCount(+cc);
    }
};


LegacyCartCountDisplay.prototype.displayCount = function (count) {
alert("legacy display count")
    var $box = $('#floating-cart');
    var $btn = $('#floating-cart-button a');

    if (count > 0) {
        $box.removeClass('floating-cart-empty').addClass('floating-cart-full');
        $btn.text("Checkout");
    } else {
        $box.removeClass('floating-cart-full').addClass('floating-cart-empty');
        $btn.attr('href', "/cart");
    }

    var $mob = $('#mobile-cart .mobile-cart');
    if (count > 0) {
        $mob.append("<span>" + count + "</span>");
    }

    // update the count, that goes over the cart icon
    var $c = $('.header-cart-qty');
    if (count > 0) {
        $c.html('<span>' + count + '</span>');
    }
};

LegacyCartCountDisplay.prototype.getCount = function () {
    return new CartCodeCookie().item_count;
};
