import React, {useEffect, useState} from 'react';

import {camelizeKeysDeep} from "../../ReduxStore/Helpers/transformObject";
import {sendCartEvent} from "../../Analytics/googleShoppingCart";

const GoogleCheckoutReporter = ({checkout, ...props}) => {
  const [reported, setReported] = useState(false);

  useEffect(() => {
    let shoppingCart = camelizeKeysDeep(checkout);

    if (reported || shoppingCart.dummy || !shoppingCart.cartItems)
      return;

    sendCartEvent({
      eventType: 'begin_checkout',
      shoppingCart,
      params: { coupon: shoppingCart.promocode}
    });

    setReported(true);
  }, [checkout, reported] );

  return null;
}

// GoogleCheckoutReporter.propTypes = { }

export default GoogleCheckoutReporter;

