import _ from 'lodash';

import ItemFormAdapter from '../logic/ItemFormAdapter';
import CartItemOptions from "./CartItemOptions";
import CartOptions from "./CartOptions";
import Money from "../../../lib/Money";

/*
 * One item in the lloyd cart.
 *
 * members:
 *      part_number     String      like UM5
 *      options         Object      like { color => LloydAttributeValue, border => .. }
 */
export default class LloydCartItem {
  /**
   *
   * @param lloydItem {LloydItem}
   * @param vehicle {Vehicle}
   */
  constructor({lloydItem, vehicle, options = {}, ...extras}) {
    // other legacy params: "vehicle_id", "vehicle_fitment_id", "lloyd_unique"
    this.lloydItem = lloydItem;
    this.product = lloydItem.product;

    this.vehicle = vehicle;

    if (options)
      this.options = options;

    // copy from Lloyd product
    _.extend(this, _.pick(lloydItem, "part_number", "extended_part_number"));

    // copy from AAG product.
    // let product = lloydItem.product || {id: 'NOTFOUND'};

  }

  get productId() {
    let {product} = this;

    return product ? product.id : null;
  }

  get options() {
    if (!this._options)
      this.options = {};
    return this._options;
  }

  set options(opts) {
    return this._options = (opts instanceof CartItemOptions) ?
      _.cloneDeep(opts) :
      new CartItemOptions({cartItem: this, contents: opts});
  }

  getDefaultOptions(args) {
    let attrs = args.lloyd_attributes || {};
    let {logo} = attrs;
    let customise = 0;

    // enable customisation only if we have multiple logo groups (ie, some non-junky logos)
    if (logo) {
      let {group_list} = logo;
      if (group_list && group_list[1])
        customise = this.lloydItem.row_count
    }

    return {
      customise: customise
    };
  }

  requiresUniqueSelection() {
    // some have just ONE unique, which we require a selection for anyway,
    // just to be sure the user has seen it.
    // ex., 2009 Ford F-150, Standard Cab [1 Pc Front]
    return !_.isEmpty(this.lloydItem.productUniques());
  }

  /** @returns {ItemFormAdapter} */
  formFields() {
    return new ItemFormAdapter(this);
  }

  getPriceCents() {
    let base_price = this.regular_price_cents;
    let upgrades = _.values(this.option_prices);

    let total_upgrades = (upgrades.length > 0) ? _.sum(upgrades) : 0;

    return base_price + total_upgrades;
  }

  getId() {
    return this.lloydItem.getId();
  }

  getName() {
    return this.lloydItem.getName();
  }

  getPrice() {
    return new Money(this.getPriceCents());
  }

  getOptionPrice(name) {
    return this.option_prices[name];
  }

  /**
   * How many rows is the user choosing to customise?
   *
   * @returns {number}
   */
  rowsToCustomise() {
    let val = this.options.getOption(CartOptions.CUSTOMISE_ROWS);
    return val ? +val : 0;
  }

}
