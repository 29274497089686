import EmbeddedConfig from "../../lib/EmbeddedConfig";
import {checkoutUrl} from "./checkoutHelpers";
import JsonPost from "../../ReduxStore/Helpers/JsonPost";
import {camelizeKeysDeep} from "../../ReduxStore/Helpers/transformObject";

import {
  appleTotalLineItem,
  buildAppleLineItem,
  buildAppleLineItems,
  companyName,
  convertAppleAddress
} from "./applePayHelpers";
import {extractCartTotals} from "../../DynamicCart/logic/cartHelpers";
import {boldlyLog} from "../../Utility/logging";
import {displayError} from "../../Utility/ReduxHelper";


/**
 * Create an ApplePayPaymentRequest
 * https://developer.apple.com/documentation/apple_pay_on_the_web/applepaypaymentrequest
 *
 * @param applePayInstance from Braintree API
 * @param shoppingCart
 * @returns {ApplePayPaymentRequest|null}
 */
export function createApplePaymentRequest({applePayInstance, shoppingCart = {}}) {
  let totals = extractCartTotals(shoppingCart);
  let amount = totals.effectiveTotal;

  if (!amount) {
    displayError({error: "Cannot determine total for Apple Pay"})
    return null;
  }

  const req = {
    total: buildAppleLineItem(amount, companyName()),
    // if these are missing we don't get the email...
    merchantCapabilities: ["supports3DS"],
    supportedNetworks: ["amex", "discover", "masterCard", "visa"],
    countryCode: 'US',
    currencyCode: 'USD',
    supportedCountries: ['US'],
    requiredBillingContactFields: ["postalAddress", "name", "phone", "email"],
    requiredShippingContactFields: ["postalAddress", "name", "phone", "email"]
  }

  console.log("creating request with params: ", req);

  return applePayInstance.createPaymentRequest(req);
}

function handleValidateMerchant({event, applePayInstance, session, shoppingCart}) {
  try {
    console.debug(`ApplePay: perform validation with ${event.validationURL}`);

    trackApplePayEvent("startValidateMerchant");

    // This is something that happens server-side, according to Apple.
    // I believe Braintree's server is communicating with theirs
    applePayInstance.performValidation({
      validationURL: event.validationURL, displayName: EmbeddedConfig.getDisplayName()
    }).then(merchantSession => {
      session.completeMerchantValidation(merchantSession);
      trackApplePayEvent("merchantValidated");
    }).catch(error => {
      displayError({error})
      session.abort();
      trackApplePayEvent("merchantValidatedError");
    });
  } catch (error) {
    displayError({error})
  }
}

function handleShippingContactSelected({event, dispatch, applePayInstance, session, shoppingCart}) {
  const {ApplePaySession} = globalThis;

  try {
    const {shippingContact, billingContact} = event;

    trackApplePayEvent("shippingContactSelected");

    const url = checkoutUrl({
      cartCode: shoppingCart?.cartCode, action: 'apple_address'
    })

    const update = {
      debug: {from: 'handleShippingContactSelected', url: url},

      checkout: {
        shippingAddress: convertAppleAddress(shippingContact),
        billingAddress: convertAppleAddress(billingContact)
      }
    }

    console.debug(`Shipping address received from Apple, posting to ${url}: `, update);

    let post = new JsonPost({url: url, payload: update});

    post.perform()
      .then(r => r.json())
      .then(stuff => {
        // Deal with the response and possible error.
        stuff = camelizeKeysDeep(stuff) || {};

        let {status, error} = stuff;

        if (error || (status === "error")) {
          throw new Error(error || stuff.message || "Unknown server error.");
        }

        // Present a happy response
        let cart = stuff.checkout || stuff.shoppingCart;

        let newTotal = appleTotalLineItem(cart);

        if (!newTotal)
          throw new Error("Cannot determine total amount. Please select another payment method, or contact us.");

        let lineItems = buildAppleLineItems(cart);

        session.completeShippingContactSelection({
          status: ApplePaySession.STATUS_SUCCESS,
          newTotal,
          newLineItems: lineItems
        });
      }).catch(err => {
      displayError({error: err, dispatch});
      session.abort();
    })

  } catch (err) {
    displayError({error: err, dispatch});
    session.abort();
  }
}

/**
 * When payment is authorized, create the order. On success, redirect to
 * order complete page.
 *
 * @param event
 * @param applePayInstance
 * @param session
 * @param shoppingCart
 */
function handlePaymentAuthorized({event, applePayInstance, session, shoppingCart}) {
  try {
    const {payment} = event;

    trackApplePayEvent("paymentAuthorized");

    applePayInstance.tokenize({
      token: payment.token
    }).then(tokenPayload => {
      // Success - tell Apple we're good, then create the order.
      console.debug(`ApplePay: tokenized`);

      const {nonce} = tokenPayload;
      console.log("NONCE is " + nonce);

      const paymentResult = session.completePayment(ApplePaySession.STATUS_SUCCESS);

      const url = checkoutUrl({cart: shoppingCart, action: 'apple_payment'});

      const post = new JsonPost({url, payload: {tokenPayload, payment, paymentResult}});

      post.perform().then(r => r.json()).then(data => {
        data = camelizeKeysDeep(data);

        window.STUFF = data;

        let order = findOrder(data);

        if (!order || !order.id) {
          throw new Error(data.message || data.error || "Unable to complete order: unknown error.");
        }

        let {statusUrl} = order;
        if (statusUrl)
          document.location = statusUrl;
        else
          alert("no order status url in " + JSON.stringify(order, null, 2));

      }).catch(error => {
        displayError({error});
      })
    });
  } catch (error) {
    displayError({error});
  }
}

function findOrder(data) {
  let {order, checkout = {}, shoppingCart = {}} = data;

  for (let o of [order, checkout?.order, shoppingCart?.order]) {
    if (o && o.id)
      return o;
  }
  return null;
}

/**
 *
 * @param applePayInstance from the Braintree API
 * @param shoppingCart
 * @returns {*}
 */
export function startApplePay({applePayInstance, shoppingCart, dispatch}) {
  try {
    trackApplePayEvent("start");

    const {ApplePaySession} = globalThis;

    boldlyLog(`Starting ApplePay with ApplePaySession ${typeof ApplePaySession}`);

    if (ApplePaySession.canMakePayments) {
      console.log(`ApplePaySession.canMakePayments: ${ApplePaySession.canMakePayments()}`);
    }

    const paymentRequest = createApplePaymentRequest({applePayInstance, shoppingCart});

    console.log("Created payment request", paymentRequest);

    // session can only be created in an event handler
    const session = new ApplePaySession(3, paymentRequest);
    if (!session || !paymentRequest)
      throw new Error("Cannot create Apple Pay session");

    console.log("Created apple pay session", session);

    // https://developer.apple.com/documentation/apple_pay_on_the_web/applepaysession/1778015-completemerchantvalidation
    // e: {ApplePayValidateMerchantEvent}
    session.onvalidatemerchant = event => {
      console.debug(`onValidateMerchant callback invoked`);
      try {
        return handleValidateMerchant({
          event, dispatch, applePayInstance, session, shoppingCart
        });
      } catch (error) {
        console.error(`onValidateMerchant error: ${error}`)
        displayError({error});
      }
    }

    session.onpaymentauthorized = event => {
      return handlePaymentAuthorized({
        event, dispatch, applePayInstance, session, shoppingCart
      });
    }

    session.onshippingcontactselected = event => {
      return handleShippingContactSelected({
        event, dispatch, applePayInstance, session, shoppingCart
      });
    }

    session.oncancel = event => {
      globalThis.APPLE_CANCEL_EVENT = event;
      trackApplePayEvent("sessionCancel");

      boldlyLog("ApplePay - received cancel event");
      console.dir(event);
    }

    globalThis.APPLE_SESSION = session;

    session.begin();
    boldlyLog("Started apple pay session", session);
    trackApplePayEvent("sessionBegin");

    if (globalThis.setTimeout)
      globalThis.setTimeout(verifyApplePayValidated, 3000);

    return session;
  } catch (err) {
    console.error(`startApplePay error: ${err}`)
    displayError({error: `Could not set up Apple Pay: ${err}`, dispatch})
  }
}

function verifyApplePayValidated() {
  const { applePayStatus={} } = globalThis;

  const { merchantValidatedError, merchantValidated } = applePayStatus;

  console.log("Verify apple pay validated: ", applePayStatus);

  if (merchantValidatedError || !merchantValidated) {
    console.error(`verifyApplePayValidated: validated=${merchantValidated} error=${merchantValidatedError}`);

    displayError({error: "Apple Pay (R) service is not responding. Please select another payment method."})
  }

  trackApplePayEvent("verifyValidation");
}

function trackApplePayEvent(eventName) {
  // reset on start
  if (eventName === "start")
    globalThis.applePayStatus = {};

  globalThis.applePayStatus = Object.assign({}, globalThis.applePayStatus, {
    [eventName]: new Date()
  })

  return globalThis.applePayStatus;
}
