// A long-ass time ago I mistakenly encoded these
// as UM01, UM04 etc, instead of UM1, UM4...
// This is my clumsy solution until we can
// deprecate the old app and fix the data
export default class LloydProductCode {
  constructor(vendorline) {
    _.extend(this, this.parse(vendorline));
  }

  // Parse an AAG-style vendorline, removing leading zeroes
  parse(vendorline) {
    const chars = (vendorline || "").trim().split('');
    const parts = _.partition(chars, (c) => isNaN(c));

    // Remove leading zero
    let digits = parts[1] || [];
    if (digits.length > 1 && digits[0] == "0")
      digits.shift();

    return {
      letters: parts[0].join(''),
      digits: digits.join('')
    }
  }

  /**
   *
   * @returns {string} like "UM5"
   */
  toString() {
    return this.letters + this.digits;
  }

  /**
   *
   * @param other {LloydProductCode|string}
   * @returns {boolean}
   */
  equals(other) {
    if (typeof other == 'string')
      other = new LloydProductCode(other);

    return (this.letters == other.letters) && (this.digits == other.digits);
  }
}
