import React  from 'react';

// 2023-11 modernised
export default function RatingWidget({item}) {
  const rating = +item.r;
  const reviewCount = +item.rc;

  // 2023-04 add no-rating
  if (isNaN(reviewCount) || isNaN(rating) || (reviewCount < 1) || (rating < 1)) {
    return <div className="no-rating">&nbsp;</div>
  }

  return (
    <div className="corona">
      <div className={`stars stars${rating}`}>
        <span className="r">Rating:</span>{' '}
      </div>
      <div className="review-count">({reviewCount})</div>
    </div>
  );
}
