import React from 'react';

import SaveCartInputWidget from './SaveCartInputWidget';

export default class SaveCartEmailInput extends SaveCartInputWidget {
  render() {
    let { contents } = this.props;

    // let error = this.getError('email');
    let error = false;

    return (<div id="save_cart_email_wrapper">
      {error && <div className="red">{error} </div>}
      <input type="email"
        className="text-black"
        autoComplete="email"
        id="saved_cart_email"
        name="email"
        onChange={this.onChange}
        value={contents.email || ""}
        placeholder="Email Address"/>
    </div>);
  }
}
