import React, {useState} from 'react';
import ZoidbergMainPage from "../mainpage/ZoidbergMainPage";
import Modal from "../../Modals/Modal";
import EditImage from "../editor/EditImage";
import CatchErrors from "../../Utility/CatchErrors";
import produce from "immer";

export default function ZoidbergApp({groupImages: _groupImages, groupId, ...props}) {
  // copy groupImages from props into somewhere where we can mutate it.
  // Force numeric keys
  const [groupImages, setGroupImages] = useState(
    () => _.mapKeys(_groupImages, (v, k) => +k));

  const [modalOpen, setModalOpen] = useState(false);

  // if set, this image is open in editor
  const [selectedId, setSelectedId] = useState(null);

  // may be null; if present, will show editor page for this image
  const selectedImage = groupImages[selectedId];

  const updateGroupImage = (id, fn) => {
    let img = groupImages[+id];
    if (!img)
      throw new Error("cannot find image: " + id);


    img = produce(img, fn); // call the callback
    // and set the result back into the hash
    setGroupImages(Object.assign({}, groupImages, {[+id]: img}))
  }

  const handleToggleModal = (evt) => setModalOpen(value => !value);

  const callbacks = {handleToggleModal, updateGroupImage, setSelectedId};

  return <div className="relative">
    <a className="absolute top-4 -left-8 pointer-cursor z-50" onClick={handleToggleModal}>
      <span
        className="inline-block rounded-full text-white p-3 far fa-camera-retro text-3xl bg-agent-purple hover:opacity-50"/>
    </a>

    {modalOpen && <Modal handleClose={handleToggleModal}>
        <CatchErrors>
          {selectedImage ?
            <EditImage image={selectedImage} groupImages={groupImages} callbacks={callbacks}/> :
            <ZoidbergMainPage
              groupId={groupId}
              groupImages={groupImages}
              callbacks={callbacks}/>
          }
        </CatchErrors>
      </Modal>
    }
  </div>
}
