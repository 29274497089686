import React from 'react';
import clsx from 'clsx';

export default function SubscribeButton({
  classNames,
  caption,
  onSubmit,
  ...props
}) {
  caption ||= "Subscribe";
  classNames ||= "my-4 px-6 py-3 font-bold font-bold rounded-sm border border-black";

  // bg-venom text-brand-dark
  return (
    <div>
      <button type="button" className={clsx(classNames)} onClick={onSubmit}>{caption}</button>
    </div>
  );
}


