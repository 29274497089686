import AffirmCheckoutAdapter from './AffirmCheckoutAdapter';
import LocalUrlMaker from "../../lib/LocalUrlMaker";

export default class SpeakerToAffirm {

  static configuredUI = false;

  getAffirm() {
    const {affirm} = globalThis;
    if (affirm) return affirm;

    alert("cannot find affirm library");
  }

  configureUI(affirm) {
    // Do this only once per page load.
    if (SpeakerToAffirm.configuredUI)
      return false;

    SpeakerToAffirm.configuredUI = true;

    // probably irrelevant for non-modals but I want to try it
    affirm.ui.ready(() => {

      // removed 2023-01
      // affirm.ui.error.on("close", () => {
      //   window.location = LocalUrlMaker.prefixedPath("/cart") })
    })
  }

  // Starting an Affirm checkout is a two-step process:
  //  affirmCheckout = affirm.checkout({ lotsa required stuff }) creates an object
  //  affirmCheckout.open() displays a popup to the user
  startCheckout(checkout) {
    let affirm = this.getAffirm();

    // simple object with members shipping_address, merchant, etc.
    let args = this.buildAffirmCheckoutArgs(checkout);

    console.log("initializing affirm with arguments: ", args);

    if (affirm && args) {
      this.configureUI(affirm);

      // ask affirm to create a checkout object which has methods
      let ch = affirm.checkout(args);

      if (ch) {
        // display a popup.
        ch.open();  // ch.post() also works
        this.affirmCheckout = ch;

        // cancel_url should point back to our view cart page.
      } else {
        // FIXME: should have some sort of critical error reporting service.
        alert("Unable to start Affirm checkout. Please contact us, or choose a different payment method.");
      }
    }
  }

  // Extract the args needed for Affirm Checkout from our checkout object.
  buildAffirmCheckoutArgs(checkout) {
    let adapter = new AffirmCheckoutAdapter(checkout);

    return adapter.build();
  }
}
